import {Button, List, ListItem, Paper, Popover} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/styles';
import LanguageIcon from "@material-ui/icons/Language";
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import React from 'react'
import {useTranslation} from 'react-i18next';
import {Theme} from "@mui/system";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        lngBtn: {
            fontSize: 14,
            marginRight: 11,
            [theme.breakpoints.down(1025)]: {
                color: "rgba(0, 0, 0, 0.6)!important",
                margin: "5px auto"
            },
        },
        lngIcon: {
            // color: "#000000",
            width: 24,
            height: 24,
            [theme.breakpoints.down(1025)]: {
                color: "rgba(0, 0, 0, 0.6)!important"
            },
        },
        expandIcon: {
            // color: "#000000",
            width: 24,
            height: 24,
            [theme.breakpoints.down(1025)]: {
                color: "rgba(0, 0, 0, 0.6)!important"
            },
            [theme.breakpoints.down("xs")]: {
                display: "none"
            },
        },
        lngPaper: {
            width: 98,
        },
        span: {
            fontSize: 14,
            textTransform: "capitalize",
            [theme.breakpoints.down(1025)]: {
                color: "rgba(0, 0, 0, 0.6)!important"
            },
        }
    })
);

export function LangSelection(props: {
    color?: string
}) {
    const classes = useStyles();
    const {i18n} = useTranslation();
    const [Language, setLanguage] = React.useState(i18n.language);

    // const theme = useTheme();

    // Language Popover
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "simple-popover" : undefined;


    // Update Language
    React.useEffect(() => {
        i18n.changeLanguage(Language);
    }, [Language, i18n]);


    i18n.on('languageChanged', function (lng) {
        setLanguage(lng)
    });

    const handleOnChangeLanguage = (event: any) => {
        handleClose();
        setLanguage((event.target.innerText as string).toLowerCase());

    }

    return (
        <>
            <Button 
                style={{color: props.color ?? "#000000"}}
                className={classes.lngBtn}
                onClick={handleClick}
                startIcon={
                    <LanguageIcon style={{color: props.color ?? "#000000"}}
                                className={classes.lngIcon}
                        // style={{ color: bp ? 'white': '#890608'}}
                    />
                }
                endIcon={
                    <ExpandMoreIcon style={{color: props.color ?? "#000000"}}
                                    className={classes.expandIcon}
                        // style={{ color: bp ? 'white': '#890608'}}
                    />
                }
            >
                <span className={classes.span}>
                    {Language === "fr" ? "Fr" : "En"}
                </span>
            </Button>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
            >
                <Paper
                    elevation={3}
                    className={classes.lngPaper}
                >
                    <List
                        component="ul"
                        aria-label="secondary mailbox folders"
                    >
                        <ListItem
                            component="li"
                            button
                            onClick={handleOnChangeLanguage}
                        >
                            Fr
                        </ListItem>
                        <ListItem
                            component="li"
                            button
                            onClick={handleOnChangeLanguage}
                        >
                            En
                        </ListItem>
                    </List>
                </Paper>
            </Popover>
        </>
    )
}
