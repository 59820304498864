import Api from "../https/Api";

export class UserTransactionService {
    private api: Api;
    constructor() {
      this.api = new Api();
    }

    async getAllTransactions(filter:any, page: number = 1, limit: number = 10) {
        return this.api.simpleGetWithFilter(`transactions`,filter);
    }

    
    async getAllTransactionsByUserId(userId:string) {
        return this.api.simpleGet(`user-transactions`,userId);
    }
    
}