import React, { useContext } from "react";
import { UserReservationService } from "./services/UserReservationService";
import { IReservationReq } from "../../../utils/ReservationInterfaces";
import { UserContext } from "../../../contexts/AppContext";
import { BookingCard } from "./components/BookingCard";
import { PropagateLoader } from "react-spinners";
import { Box, Tab, Tabs, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import SessionHandler from "../../../https/SessionHandler";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Masonry from "react-masonry-css";
import { useTranslation } from "react-i18next";
import { BookingList } from "./BookingList/BookingList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            width: "100%",
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        reservationTittle: {
            fontSize: 22,
            fontFamily: "Josefin Sans",
            color: "#890608",
            marginTop: 5,
            marginBottom: 25,
        },
        promotionContainer:{
            paddingBottom: '25%',
            margin: 2
        },
        tabs: {
            borderBottom: "1px solid #e8e8e8",
        },
        labelTab: {
            textTransform: "none",
        },
    })
);

function TabPanel(props: any) {
    const { children, value, index, ...other } = props
    return (
        <div {...other} hidden={value !== index}>
            {value === index && children}
        </div>
    )
}

const userReservationService = new UserReservationService();

export const UserReservations = () => {
    const [reservations, setReservations] = React.useState<IReservationReq[]>(
        []
    );
    const userContext = useContext(UserContext);
    const { user, setUser } = userContext;
    const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(true);
    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const [tabValue, setTabValue] = React.useState(0)
    const isConnected = sessionHandler.isConnected;
    const [refreshReservList, setRefreshReservList] =
        React.useState<boolean>(false);

    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    React.useEffect(() => {
        if (!user.email) {
            isConnected()
                .then((response: any) => {
                    if (response.success) {
                        setUser(response.data);
                    }
                })
                .catch((err) => {
                    history.push("/");
                });
        } else {
            userReservationService
                .getAllReservations({ traveller: user._id })
                .then((response) => {
                    if (response.success) {
                        setReservations(response.data);
                    }
                    setIsLoadingPage(false);
                })
                .catch((error) => {
                    console.log("userReservations error component", error);
                });
        }
    }, [user, history, isConnected, setUser]);

    const handleChangeTab = (_event: any, newValue: number) => {
        setTabValue(newValue)
    }

    React.useEffect(() => {
        if (refreshReservList) {
            setIsLoadingPage(true);
            userReservationService
                .getAllReservations({ traveller: user._id })
                .then((response) => {
                    if (response.success) {
                        setReservations(response.data);
                    }
                    setIsLoadingPage(false);
                    setRefreshReservList(false);
                })
                .catch((error) => {
                    console.log("userReservations error component", error);
                });
        }
    }, [refreshReservList, user._id]);

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        900: 2,
        500: 1,
    };

    return (
        // <div className={classes.promotionContainer}>
            <Box sx={{ flexGrow: 1 }}>
                <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    className={classes.tabs}
                >
                    <Tab className={classes.labelTab} label={t("reservation.label.reservation-sur-mes-annonces")}/>
                    <Tab className={classes.labelTab} label={t("label.mes-reservations")}/>
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    {user.accountType === "host" && (
                        <Grid container spacing={4} style={{marginTop: 30}}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography
                                    className={classes.reservationTittle}
                                    variant="h5"
                                    gutterBottom
                                >
                                    {t("reservation.label.reservation-sur-mes-annonces")}
                                </Typography>
                                <Typography variant='subtitle2'>{t('label.reservations-subtitle')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <BookingList />
                            </Grid>
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={4} justifyContent="center" style={{marginTop: 30}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                                className={classes.reservationTittle}
                                variant="h5"
                                gutterBottom
                            >
                                {t("label.mes-reservations")}
                            </Typography>
                            <Typography variant='subtitle2'>{t('label.mes-reservations-subtitle')}</Typography>
                            {isLoadingPage && (
                                <div className={classes.loading}>
                                    <PropagateLoader color="#36D7B7" size={15} />
                                </div>
                            )}
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {!isLoadingPage &&
                                    reservations.map((reservation) => {
                                        return (
                                            <BookingCard
                                                key={reservation._id || ""}
                                                reservation={reservation}
                                                setRefreshReservationList={
                                                    setRefreshReservList
                                                }
                                            />
                                        );
                                    })}
                            </Masonry>
                        </Grid>
                    </Grid>
                </TabPanel>
                {/* <Grid container spacing={4} justifyContent="center">
                    
                    
                </Grid> */}
            </Box>
        // </div>
    );
};
