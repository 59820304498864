import { createContext } from "react";
import { IUser } from "../utils/UserInterfaces";
import { IReservation } from "../utils/ReservationInterfaces";
import {
    defaultUserValue,
    reservationDefaultValue,
    searchDefaultValue,
} from "../utils/defaultNullValues";

const UserContext = createContext<{
    user: IUser;
    setUser: (prop: IUser) => void;
}>({
    user: defaultUserValue,
    setUser: (prop) => {},
});

const ReservationContext = createContext<{
    reservation: IReservation;
    setReservation: (prop: IReservation) => void;
}>({
    reservation: reservationDefaultValue,
    setReservation: (prop) => {},
});
const SearchContext = createContext<{
    searchDta: any;
    setSearchData: (prop: any) => void;
}>({
    searchDta: searchDefaultValue,
    setSearchData: (prop) => {},
});
const CurrencyContext = createContext<{
    currency: string;
    setCurrency: (prop: any) => void;
}>({
    currency: "xaf",
    setCurrency: (prop) => {},
});

const CurrencyBaseContext = createContext<{
    currencyBase: string;
    setCurrencyBase: (prop: any) => void;
}>({
    currencyBase: "XAF",
    setCurrencyBase: (prop) => {},
});

const LoadingMenuContext = createContext<{
    loadingMenu: boolean;
    setLoadingMenu: (prop: any) => void;
}>({
    loadingMenu: false,
    setLoadingMenu: (prop) => {},
});

export {
    UserContext,
    ReservationContext,
    SearchContext,
    CurrencyContext,
    CurrencyBaseContext,
    LoadingMenuContext,
};
