import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { RiSearch2Line } from "react-icons/ri";
import { HiOutlineTrash } from "react-icons/hi";
import { alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: `0 6px 12px 0 ${alpha("#000", 0.24)}`,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TableToolBar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  currentCol: PropTypes.string,
  onColChange: PropTypes.func,
  onFilterName: PropTypes.func,
  onDeletSelected: PropTypes.func,
  colLists: PropTypes.array,
};

export default function TableToolBar({
  numSelected,
  filterName,
  onFilterName,
  onDeletSelected,
  currentCol,
  onColChange,
  colLists,
}) {
  const { t } = useTranslation();
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('label.selectionee')}
        </Typography>
      ) : (
        <Stack direction="row" spacing={3}>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={t('label.rechercher-l-element')}
            startAdornment={
              <InputAdornment position="start">
                <RiSearch2Line />
              </InputAdornment>
            }
          />
          <Select
              labelId="pnb-simple-select-label"
              id="pnb-simple-select"
              value={currentCol}
              onChange={onColChange}
            >
              {colLists.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onDeletSelected}>
            <HiOutlineTrash />
          </IconButton>
        </Tooltip>
      ) : (
        null
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <BsFilter />
        //   </IconButton>
        // </Tooltip>
      )}
    </RootStyle>
  );
}
