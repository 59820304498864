import React, { Suspense }  from "react";
import "./App.css";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
//import AdminRoutes from "./pages/Admin/AdminRoutes";
//import Routes from "./pages/Routes";
import {CurrencyBaseContext, CurrencyContext, ReservationContext, SearchContext} from './contexts/AppContext';
import {gStyles} from "./styles/globalStyles";
import {reservationDefaultValue, searchDefaultValue} from './utils/defaultNullValues';
import {UserAdminRoutes} from "./pages/UserAdmin/UserAdminRoutes";
import {UserProvider} from "./Hooks/UserHooks";
import {IReservation} from "./utils/ReservationInterfaces";
import { DohoneProvider } from "./Hooks/useDohone";
import { LoadingMenuProvider } from "./Hooks/LoadingMenuProvider";
import { HelmetProvider } from 'react-helmet-async';
const Routes = React.lazy(() => import("./pages/Routes"));
const AdminRoutes = React.lazy(() => import("./pages/Admin/AdminRoutes"));

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

// ErrorBoundary component to catch errors in lazy-loaded components
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error("Error caught by Error Boundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div>Something went wrong. Please try again later.</div>;
        }

        return this.props.children;
    }
}


function App() {
    gStyles()
    const [reservation, setReservation] = React.useState<IReservation>(reservationDefaultValue)
    const reservationValue = {reservation, setReservation}
    const [searchDta, setSearchData] = React.useState<any>(searchDefaultValue)
    const searchValue = {searchDta, setSearchData}
    const [currency, setCurrency] = React.useState<any>("XAF")
    const currencyValue = {currency, setCurrency}
    const [currencyBase, setCurrencyBase] = React.useState<any>("XAF")
    const currencyValueBase = {currencyBase, setCurrencyBase}

    return (
    <HelmetProvider>
        <CurrencyBaseContext.Provider value={currencyValueBase}>
            <CurrencyContext.Provider value={currencyValue}>
                <DohoneProvider>
                    <UserProvider>
                        <SearchContext.Provider value={searchValue}>
                            <ReservationContext.Provider value={reservationValue}>
                                <LoadingMenuProvider>
                                    <BrowserRouter>
                                        <ErrorBoundary>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <Switch>
                                                    <Route path="/users/admin">
                                                        <UserAdminRoutes/>
                                                    </Route>
                                                    <Route path="/admin">
                                                        <AdminRoutes/>
                                                    </Route>
                                                    <Route path="/">
                                                        <Routes/>
                                                    </Route>
                                                </Switch>
                                            </Suspense>
                                        </ErrorBoundary>
                                    </BrowserRouter>
                                </LoadingMenuProvider>
                            </ReservationContext.Provider>
                        </SearchContext.Provider>
                    </UserProvider>
                </DohoneProvider>
            </CurrencyContext.Provider>
        </CurrencyBaseContext.Provider>
    </HelmetProvider>    
    );
}

export default App;
