/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import  moment, {Moment} from 'moment';
import { IAnnounce, IAnnounceAd } from '../../../utils/AnnounceInterfaces';
import { annnonceAdPaymentDefaultValue, announceAdDefaultValue } from '../../../utils/defaultNullValues';
import { AfriqAnnouncesSelectList, ISelectProps } from '../../components/AfriqAnnouncesSelectList';
import {  DataAnnounceAd } from "./components/List/ListTableAnnounceAd";
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SessionHandler from '../../../https/SessionHandler';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/AppContext';
import { PropertyService } from '../../Properties/services/PropertyService';
import { CircularProgress } from '@material-ui/core';
import { AdErrorMessage, getErrorLabel } from '../../../utils/UserInterfaces';
import { Error } from '@material-ui/icons';
import { colors } from '../../../styles/defaultColors';
import { ListAnnouncesAds } from './components/ListAnnouncesAds'; 
import { ISnackbar } from '../../../utils/enum/snackBarType';
import { AfriqSnackbar } from '../../components/OverridingMaterial/AfriqSnackbar';
import Spin from '../../../images/Spin';
// import { RouteAdminUser } from '../../Admin/constant/routesName';
import { AnnounceAdService } from './services/AnnounceAdService';
import AfriqDatePicker from '../../components/AfriqDatePicker';
import SelectAdsOffer from '../../components/SelectAdsOffer';
import { RecapAnnounceAd } from './components/RecapAnnounceAd';
import { IAnnounceAdPayment } from '../../../utils/PaymentInterfaces';
import { IAdParam } from '../../../utils/SettingIntefaces';
import { EAnnounceStatus} from '../../../utils/enum/AnnounceStatus';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        promotionContainer:{
            paddingBottom: '25%'
        },
        Button: {
            fontFamily: "Josefin Sans",
            color: "#890608",
            textTransform: "capitalize",
        },
        cancelButton: {
            textTransform: "capitalize",
            color: "#000000",
        },
        promotionTitle: {
            fontSize: 22,
            fontFamily: "Josefin Sans",
            color: "#890608",
            marginTop: 5,
            marginBottom: 25,
        },
        promotionDateRange:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginTop: 16, 
            marginBottom: 16,
            '& .DateRangePickerInput':{
                justifyContent: 'flex-start!important'
            },
            '& .DateInput':{
                width: '80px!important'
            }

        },
        enregistrer: {
            borderRadius: 100,
            textTransform: "none",
            fontSize: 14,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: "center",
            fontFamily: 'Josefin Sans',
            background: "#890608",
            color: "#ffffff",
            padding: "8px 14px",
            "&:hover":{
                background: "#B5551B"
            }
        },
        updateButton: {
            borderRadius: 100,
            textTransform: "none",
            fontSize: 14,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: "center",
            fontFamily: 'Josefin Sans',
            background :"#AAAAAA",
            color: "#ffffff",
            padding: "8px 14px",
            "&:hover": {
                background: "#B5551B"
            },
        },
        errors: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        inpuText: {
            marginBottom: 14,
            marginTop: 6
        },
        inputLabel: {
            fontSize: 14,
            fontFamily: "Josefin Sans",
            color: "#000000",
        },
        containerLabel: {
            textAlign: "left",
            marginTop: 16
        },
        promotionBtns: {
            display: "flex",
            maxWidth: 225,
            justifyContent: "space-between",
            marginBottom: 20,
            [theme.breakpoints.down("sm")]: {
                margin: "0 auto",
            },
        },
    })
);

const announceAdService= new AnnounceAdService();
const propertyService = new PropertyService();
export default function UserPromotion() {
    const classes= styles();
    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const isConnected = sessionHandler.isConnected;
    const userContext = useContext(UserContext);
    const { user, setUser } = userContext;
    const history = useHistory();
    const [announcesAdsParent, setAnnouncesAdsParent] = React.useState<DataAnnounceAd[]>([]);
    const [selectedAnnounce, setSelectedAnnounce]=React.useState<ISelectProps>({
        id: '',
        name: '',
        price: 0
    });
    const [announcesList, setAnnouncesList]=React.useState<ISelectProps[]>([]);
    const [announceAd, setAnnounceAd]= React.useState<IAnnounceAd>(announceAdDefaultValue)
    const [paymentData, setPaymentData]=React.useState<IAnnounceAdPayment>(annnonceAdPaymentDefaultValue);
    const [recapData, setRecapData]=React.useState<{title: string, placement:string, duration: number, price: number, startDate: Moment | null}>({
        title:'',
        placement: '',
        duration: 0,
        price: 0,
        startDate: null
    })
    const {t} = useTranslation();
    const [loadingListAnnounces, setLoadingListAnnounces]=React.useState<boolean>(true);
    const [selectedDate, setSelectedDate] = React.useState<Moment | null>(
         moment(new Date())
      );
    
    let errors: string[] = [];
    const [errorMessages, setErrorMessages] = React.useState<string>("");
    const [snackValues, setSnackValues] = React.useState<ISnackbar>({
        type: "success",
        open: false,
        message: "",
    });
    const [updateTable, setUpdateTable]= React.useState<boolean>(false);
    const [loading, ]= React.useState<boolean>(false);
    const [updateAnnounceSelectList, setUpdateAnnounceSelectList]= React.useState<boolean>(false);
    const [step, setStep]= React.useState<number>(1)

    const [adsParams, setAdsParams] = React.useState<IAdParam[]>([])

    const [placementQuery, setPlacementQuery]=React.useState({
        field: 'placement',
        value: ''
    });
    


    React.useEffect(()=>{
        announceAdService.getSettingAdsQuery(placementQuery).then(response=>{
            if(response.success){
                setAdsParams(response.data)
            }
        })
    }, [placementQuery])

    React.useEffect(()=>{
        if (!user.email) {
            isConnected()
                .then((response: any) => {
                    if (response.success) {
                        setUser(response.data);
                    }
                })
                .catch((err) => {
                    history.push("/");
                });
        } else {
            propertyService.getListAnnounces({ owner: user._id /*, promotion: null*/, state: EAnnounceStatus.Published}).then(response=>{
                if(response.success){
                    setAnnouncesList(response.data.map((announce: IAnnounce, index:number) => {
                        return {
                                id: announce._id || index.toString(),
                                name: announce?.property?.name,
                                price: announce?.price
                            }
                        })
                    )  
                    setLoadingListAnnounces(false)
                }
            }).catch(error=>{
                setLoadingListAnnounces(false)
                console.log('userAnnounceAd component error', error)
            })            
        }
        
    },[user, history, isConnected, setUser])

  React.useEffect(()=>{
      if(updateAnnounceSelectList){
        setLoadingListAnnounces(true);
        propertyService.getListAnnounces({ owner: user._id, promotion: null, state: EAnnounceStatus.Published}).then(response=>{
            if(response.success){
                setAnnouncesList(response.data.map((announce: IAnnounce, index:number) => {
                    return {
                            id: announce._id || index.toString(),
                            name: announce?.property?.name,
                            price: announce?.price
                        }
                    })
                );  
                
                setLoadingListAnnounces(false)
                setUpdateAnnounceSelectList(false);
            }
        }).catch(error=>{
            setLoadingListAnnounces(false)
            setUpdateAnnounceSelectList(false)
            console.log('userAnnounceAd component error', error)
        })
      }
   
   },[updateAnnounceSelectList, user._id])
    
   React.useEffect(()=>{
        setAnnounceAd((prev)=>{
            return {
                ...prev,
            startAt: selectedDate ?? moment(new Date())
            }
        })
        setRecapData({
            ...recapData,
            startDate: selectedDate,
        })
   },[selectedDate])

    const handleSelectAnnounce=(v: ISelectProps)=>{
        if (v !== null){
        setSelectedAnnounce(v);
            setAnnounceAd((prev)=>{
                return {
                    ...prev,
                    announce: v.id
                }
            })
            setPaymentData((prev)=>{
                return {
                    ...prev,
                    transaction:{
                        items:{
                            announce: v.id,
                            adParam: announceAd.adParam
                        },
                        amount: {
                            discount: 0,
                            total: 0,
                        }
                    }
                }
            })
            setRecapData({
                ...recapData,
                title: v.name,
            })
        }
    }

    //Reset snackbar
    const handleCloseSnackbar = () => {
        setSnackValues({ ...snackValues, open: false })
    };


    const isFromValidate = (values: IAnnounceAd): boolean => {
        const oldAnnonceBoosted=announcesList.filter(el=>el.id===values.announce)[0];
        const oldAnnonceAdBoosted:any= oldAnnonceBoosted && announcesAdsParent.length ? announcesAdsParent.filter((el2)=>(el2.announceConcerned===oldAnnonceBoosted.name))[0]: 0
        const startDate=new Date(moment(values.startAt).format('YYYY/MM/DD'))
        const endDate=new Date(moment(values.startAt).add(recapData.duration, 'weeks').format("YYYY/MM/DD"))
        if (values.announce==='') {
            if (!errors.includes("NO ANNOUNCE SELECTED"))
                errors = [...errors, "NO ANNOUNCE SELECTED"];
        }else if (values.adParam ==='') {
            if (!errors.includes("NO AD PARAM SELECTED"))
                errors = [...errors, "NO AD PARAM SELECTED"];
        }else if(!values.startAt){
            if (!errors.includes("NO START DATE SELECTED"))
                errors = [...errors, "NO START DATE SELECTED"];
        }else if((new Date(oldAnnonceAdBoosted?.endDate)) >= (startDate) && ((new Date(oldAnnonceAdBoosted?.startDate)) <= (startDate)) ){                    
            if (!errors.includes("SELECT OTHER DATE"))
            errors = [...errors, "SELECT OTHER DATE"];
        }
        else if((new Date(oldAnnonceAdBoosted?.endDate)) >= (endDate) && ((new Date(oldAnnonceAdBoosted?.startDate)) <= (endDate)) ){                    
            if (!errors.includes("SELECT OTHER DATE"))
            errors = [...errors, "SELECT OTHER DATE"];
        }
        return !Boolean(errors.length);
    };

    const showErrorsMessages = () => {
        let allErrorMessages = "";
        errors.forEach((error) => {
            if (error === "NO ANNOUNCE SELECTED") {
                allErrorMessages += "," + getErrorLabel(AdErrorMessage.NoAnnounceSelected);
            }else if (error === "NO AD PARAM SELECTED") {
                allErrorMessages += "," + getErrorLabel(AdErrorMessage.NoAdParamSelected);
            } else if (error === "NO START DATE SELECTED") {
                allErrorMessages += "," + getErrorLabel(AdErrorMessage.NoStartDateSelected);
            } else if (error==='SELECT OTHER DATE'){
                allErrorMessages += "," + getErrorLabel(AdErrorMessage.StartingDateWrong);
            }
        });
        setErrorMessages(
            allErrorMessages.substring(1, allErrorMessages.length)
        );
    };
    
    const handlePreviousStep=()=>{
        setStep(prev=>--prev)
    }

    const handleNextStep=()=>{        
        if(isFromValidate(announceAd)){           
            setPaymentData((prev)=>{
                    return {
                    ...prev,
                    transaction:{
                        items:{
                            announce: selectedAnnounce.id,
                            adParam: announceAd.adParam
                        },
                        amount: {
                            discount: 0,
                            total: 0,
                        }
                    }
                }
            })
            setErrorMessages('');
            errors=[];
            setStep(prev=> ++prev)
        }else{         
            showErrorsMessages();   
        }
    }

    return (
        <div className={classes.promotionContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography
                            variant="h5"
                            gutterBottom
                            className={classes.promotionTitle}
                        >
                            {t("label.promouvoir-l-annonce")}
                        </Typography>
                        <Typography variant='subtitle2'>{t('label.boost-announce-subtitle')}</Typography>
                    </Grid>
                    {
                        step===1 &&
                        <>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                        {
                                            loadingListAnnounces && (<CircularProgress size={32} style={{color: '#890608'}} />)
                                        }
                                        {
                                            !loadingListAnnounces && <AfriqAnnouncesSelectList selectedAnnounce={selectedAnnounce} handleSelectAnnounce={handleSelectAnnounce} listAnnounces={announcesList} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                                <SelectAdsOffer announceAd={announceAd} setAnnounceAd={setAnnounceAd} setRecapData={setRecapData} setPlacementQuery={setPlacementQuery} placementQuery={placementQuery} adsParams={adsParams}/>
                            </Grid>
                            <Grid item md={6}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    className={classes.containerLabel}
                                >
                                    <label className={classes.inputLabel}>
                                        {t("label.date-debut")}
                                    </label>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={12}>
                                    <AfriqDatePicker setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                                </Grid>

                            </Grid>
                        {errorMessages && (
                            <Grid item xs={12} md={12} lg={12}>
                                <div className={classes.errors}>
                                    <Typography>
                                        <Error />
                                        <br />
                                        <span>{errorMessages}</span>
                                    </Typography>
                                </div>

                            </Grid>
                        )}
                            <Grid item xs={12} md={12} lg={12}>
                            <div className={classes.promotionBtns}>
                                <Button
                                    className={classes.enregistrer}
                                    endIcon={
                                        loading ? <Spin /> : null
                                    }
                                    onClick={handleNextStep}
                                >
                                {t('label.suivant')}
                                </Button>
                            </div>
                        </Grid>  
                        </>
                    }
                         <Grid>

                         </Grid>
                    {
                        step===2 &&
                        <>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Button
                                            className={classes.updateButton}
                                            onClick={handlePreviousStep}
                                        >
                                        {t('label.precedent')}
                                    </Button>

                                </Grid>
                                
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <RecapAnnounceAd announceAd={announceAd} paymentData={paymentData} setPaymentData={setPaymentData} recapData={recapData}/>
                                </Grid>
                            </Grid>

                        </Grid>
                        </>
                    }  
                    <AfriqSnackbar
                        {...snackValues}
                        handleClose={handleCloseSnackbar}
                    />
                </Grid>
            
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <ListAnnouncesAds updateTable={updateTable} setUpdateTable={setUpdateTable} setAnnouncesAdsParent={setAnnouncesAdsParent} setUpdateAnnounceSelectList={setUpdateAnnounceSelectList} />
                </Grid>
            </Grid>
        </div>
    )
}
