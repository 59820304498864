export enum ERoles {
    SUPER_ADMIN = "super admin",
    ADMIN_SUPPORT= "admin support",
    HOTEL_ADMIN = "hotel admin",
    COUNTRY_ADMIN = "country admin",
    GUEST = "guest",
    SALES_AGENT="sales agent",
    HOST = "host",
    CLERK="clerk",
    TRAVELLER = "traveller",
    FAKE = "fake"
}
