import Api from './Api';
import { paypalConfig } from '../configs/Paypal';
import { orangeConfig } from '../configs/Orange';
class SessionHandler {
    saveUserInfo(userConnected) {
        localStorage.setItem('user', JSON.stringify(userConnected));
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem('user'));
    }
    
    removeUserInfo() {
        return localStorage.getItem('user');
    }

    isConnected() {
        var token = localStorage.getItem('access_token');
        if (token === null || token.length === 0)
            return Promise.reject(false);
        let api = new Api();
        let res = api.post("users/getCurrentUser/").then((response) => {
            return Promise.resolve(response);
        }).catch((err) => {
                return Promise.reject(err);
        });
        return res;
    }

    isRefreshed() {
        var refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken === null || refreshToken.length === 0)
            return Promise.reject(false);
        let api = new Api();
        let res = api.post("users/refresh-token").then((response) =>{
            localStorage.setItem('access_token', response.user.token)
            localStorage.setItem('refresh_token', response.user.refreshToken)
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
        return res;
    }
    
    //PAYPAL ------------------------------------------------------------
    refreshPaypalAccessToken() {
        let loginData={
            username:paypalConfig.cliendID,
            password: paypalConfig.secret
          }          
        let api = new Api();
        api.postPaypalRequestToken('https://api-m.sandbox.paypal.com/v1/oauth2/token', loginData).then(response=>{
            localStorage.setItem('access_paypal_token', response.access_token)
        })
        return this.getPaypalAccessToken();
    }
    savePaypalAccessToken(token) {
        localStorage.setItem('access_paypal_token', token);
    }

    getPaypalAccessToken() {
        return localStorage.getItem('access_paypal_token');
    }

    //--------------------------------------------
    //ORANGE ---------------------------------------
    refreshOrangeAccesToken(){
        let api = new Api();
        api.postOrangeRequestToken('https://api.orange.com/oauth/v2/token', orangeConfig.authorization_token).then(response=>{
            this.saveOrangeAccessToken( response.access_token);
        })
        return this.getOrangeAccessToken();
    }
    
    saveOrangeAccessToken(token) {
        localStorage.setItem('access_orange_token', token);
    }

    getOrangeAccessToken() {
        return localStorage.getItem('access_orange_token');
    }

    //---------------------------------------------------------------

    saveAccessToken(token) {
        localStorage.setItem('access_token', token);
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    removeToken() {
        let keys = Object.keys(localStorage);
        keys.filter(k => k !== 'i18nextLng').forEach(key => {
            localStorage.removeItem(key);
        });
    }

    saveRefreshToken(refreshToken) {
        localStorage.setItem('refresh_token', refreshToken)
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token')
    }
}

export default SessionHandler;