/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Grid, Typography, 
  CircularProgress,
  } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { createData, Data, ListTable,  Data as DataRowTable } from "./List/ListTable";
import { ContextMenu } from "./ContextMenu";
import { UserPromotionService } from "../services/UserPromotionService";
import { UserContext } from "../../../../contexts/AppContext";
import SessionHandler from '../../../../https/SessionHandler';
import { IPromotion } from "../../../../utils/AnnounceInterfaces";
import { colors } from "../../../../styles/defaultColors";
import { ShowConfirmDialog } from "../../../components/ShowConfirmDialog";
import moment from 'moment';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    listProperty: {},
    properties: {
      width: "100%",
      minHeight: 1000,
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        minHeight: 1200,
      },
    },
    propertiesHeader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    propertiesTitle: {
      fontSize: 22,
      fontFamily: "Josefin Sans",
      color: "#890608",
      marginTop: 40,
      textAlign: "left",
      margin: theme.spacing(2, 0)
    },
    propertiesCheck: {},
    propertiesCheckbox: {
      maxWidth: "60%",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
    },
    showErrors: {},
    checkboxLabel: {
      "&:not(:last-child)": {
        marginBottom: 20,
      },
      "& span": {
        fontFamily: "Josefin Sans",
        color: "#5F5F5F",
      },
    },
    checkboxLegend: {
      textAlign: "left",
      fontSize: 18,
      fontFamily: "Josefin Sans",
      color: "#000000",
      marginBottom: 30,
    },
    checkboxGroup: {},
    propertiesBtn: {
      maxWidth: "60%",
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
    },
    Button: {
      fontFamily: "Josefin Sans",
      color: "#890608",
    },
    errors: {
      color: colors.danger,
      background: colors.backgroundDanger,
      width: "100%",
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: 5,
      "& p": {
        padding: 10,
      },
    },

    btn: {
      width: 180,
      height: 40,
      color: "#FFFFFF",
      textTransform: "initial",
      fontSize: 14,
      fontFamily: "Josefin Sans",
      marginBottom: 18,
      borderRadius: 100,
      background: "#890608",
      "&:hover": {
          background: "#EA7606"
      }
    },
    linkContainerButton:{
        textDecoration: 'none'
    },
    
  })
);

const userPromotionService = new UserPromotionService();
export const ListPromotions = (props:{ updateTable:boolean;setUpdateTable: any; setUpdateAnnounceSelectList:any}) => {
  const classes = styles();
  const sessionHandler = React.useMemo(() => new SessionHandler(), []);
  const isConnected = sessionHandler.isConnected;
  const userContext = useContext(UserContext);
  const { user, setUser } = userContext;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [currentRow, setCurrentRow] = React.useState<DataRowTable>();
  const [promotions, setPromotions] = React.useState<Data[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [deleteMultiple, setDeleteMultiple]= React.useState<boolean>(false)
  const history = useHistory();
  const {t} = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
        if (!user.email) {
        isConnected()
            .then((response: any) => {
                if (response.success) {
                    setUser(response.data);
                }
            })
            .catch((err) => {
                setLoading(false);
                history.push("/");
            });
    } else {  
        setLoading(true);
        userPromotionService
        .getAllPromotions({ 
          owner: user._id })
        .then((response) => {
            setPromotions(response.data.map((promotion: IPromotion) => {
              // console.log('promotion.startDate', moment(promotion.startDate).format("DD/MM/YYYY"))
              // console.log('promotion.endDate',  moment(promotion.endDate).format("DD/MM/YYYY"))
                return createData(
                    promotion._id ?? "",
                    promotion.title,
                    promotion.announce?.property?.name ?? "",
                    promotion.discount,
                    promotion.price,
                    promotion.startDate ? moment(promotion.startDate).format('DD/MM/YYYY') : "",
                    promotion.endDate ? moment(promotion.endDate).format("DD/MM/YYYY") : ""
                )
            })
            );
            setLoading(false);
        })
        .catch((error) => {
            console.log('list promotions component error ', error);
            setLoading(false);
        });      
        
    }
  }, [setPromotions, setLoading, user, history, isConnected, setUser]);


  React.useEffect(()=>{
      if(props.updateTable){
          setLoading(true);
          userPromotionService
          .getAllPromotions({ 
            owner: user._id })
          .then((response) => {
              setPromotions(response.data.map((promotion: IPromotion) => {
                  return createData(
                      promotion._id ?? "",
                      promotion.title,
                      promotion.announce?.property.name ?? "",
                      promotion.discount,
                      promotion.price,
                      promotion.startDate ? moment(promotion.startDate).format('DD-MM-YYYY') : "",
                      promotion.endDate ? moment(promotion.endDate).format("DD-MM-YYYY") : ""
                  )
              })
              );
              setLoading(false);
              props.setUpdateTable(false)
          })
          .catch((error) => {
              console.log('error properties', error);
              setLoading(false);
              props.setUpdateTable(false);
          });      
      }
   },[props])


  const handleClickOpenContextMenu = (
    event: React.MouseEvent<HTMLAnchorElement>,
    row: DataRowTable
  ) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const refreshPromotionsList = (id?: string) => {
    if(id){
        setPromotions(promotions.filter((promotion) => promotion.id !== id));
    }else{
        setLoading(true);
        userPromotionService
        .getAllPromotions({})
        .then((response) => {
            setPromotions(response.data.map((promotion: IPromotion) => {
                return createData(
                    promotion._id ?? "",
                    promotion.title,
                    promotion.announce?.property.name ?? "",
                    promotion.discount,
                    promotion.price,
                    promotion.startDate ?  moment(promotion.startDate).format('DD/MM/YYYY') : "",
                    promotion.endDate ? moment(promotion.endDate).format("DD/MM/YYYY") : ""
                )
            })
            );
            setLoading(false);
        })
        .catch((error) => {
            console.log('error properties', error);
            setLoading(false);
        });
    }
  };
  const handleCloseContextMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteSelectedRow = () => {
    handleClickOpenDialog();
    setDeleteMultiple(true);

  };

  const handleClickConfirmDialog = () => {
      if(deleteMultiple){
        if(selected.length)deleteMany();
      }else{
          if(currentRow) deleteOne(currentRow?.id)
      }
  };
  
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteOne = (id: string) => {
    userPromotionService
      .deletePromotion(id)
      .then((response) => {
        if (response.success) {
          setSelected(selected.filter((selId) => selId !== id));
          refreshPromotionsList(id);
          props.setUpdateAnnounceSelectList(true);
        }
        setDeleteMultiple(false);
      })
      .catch((error) => {
        console.log("list promotion component error", error);
        setDeleteMultiple(false);
      });
      handleCloseDialog();
  };

  const deleteMany = () => {
    userPromotionService  
      .deletesPromotion(selected)
      .then((response) => {
        if (response.success) {
            refreshPromotionsList()
            setSelected([]);
            props.setUpdateAnnounceSelectList(true);
        }
        setDeleteMultiple(false);

      })
      .catch((error) => {
        console.log("list promotion context component error", error);
        setDeleteMultiple(false);
      });
      handleCloseDialog();
  };

  const confirmDialog=ShowConfirmDialog({open: openDialog, handleClose: handleCloseDialog, handleConfirm: handleClickConfirmDialog, title: selected.length > 1 ? t('promotion.confirmDialog.title-pluriel'): t('promotion.confirmDialog.title'), description: selected.length >1 ? t('promotion.confirmDialog.description-pluriel'): t('promotion.confirmDialog.description')})
  return (

    <div className={classes.listProperty}>     
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.propertiesTitle}
            >
             {t("label.liste-des-promotions")}
            </Typography>
            {
              loading &&  (<CircularProgress size={32} style={{color: '#890608'}} />)
            }
            {
              !loading &&
                <ListTable
                  openContextMenu={handleClickOpenContextMenu}
                  promotions={promotions}
                  handleDeleteMultipleRow={handleDeleteSelectedRow}
                  selected={selected}
                  setSelected={setSelected}
                />
            }

            <ContextMenu
              handleClose={handleCloseContextMenu}
              handleDeleteRow={handleClickOpenDialog}
              open={open}
              anchorEl={anchorEl}
              row={currentRow}
              showEditButton={true}       
              showDeleteButton={true} 
            />
            {confirmDialog}

          </Grid>
        </Grid>
    </div>
  );
};
