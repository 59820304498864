import React from "react";
import { Stack, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { UserNotificationService } from "./services/UserNotificationService";
import { NotificationSwitch } from "./components/NotificationSwitch";
import { useTranslation } from "react-i18next";
import { INotification } from "../../../utils/NotificationInterface";
import { useUser } from "../../../Hooks/UserHooks";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";

const userNotificationService = new UserNotificationService();

export default function UserNotification() {

    const classes = useStyles();
    const { user, setUser } = useUser();
    const nList = user.notifications ? user.notifications.filter(el=>(el.key!=="PROPERTY_CREATED")): [];
    const [t] = useTranslation();
    const [loadingId, setLoadingId] = React.useState<string | null>(null);

    // console.log(nList);
    const handleNotifSwitch = (notif: INotification) => ((state: boolean) => {
        if(notif.key==="RECEIVE_OFFER_NOTIF") return
        setLoadingId(notif.key);
        userNotificationService.updateNotification({ key: notif.key, value: state }).then((response) => {
        if (response.success) {
            let udata = [...nList].map((no) => {
            if (no.key === notif.key) return { ...no, active: state }
            else return no
            })
            setLoadingId(null);
            setUser({ ...user, notifications: udata });
        }
        }).catch((err) => {
        setLoadingId(null);
        console.error(err);
        })
    })

  // console.log('nList', nList);
    return (
        <Box>
            <Stack direction="column">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h5" gutterBottom className={classes.h5Font}>
                    {t('label.paramaetre-de-notifications')}
                </Typography>
                <Typography variant='subtitle2'>{t('label.notifications-subtitle')}</Typography>
                </Grid>
            </Grid>
                {nList.map((notif, i) => (
                    <>
                        <NotificationSwitch
                            key={"user-notification-item-id" + i}
                            loading={loadingId === notif.key}
                            title={t([notif.key])}
                            subtitle={notif.description}
                            state={notif.active}
                            onSwitch={handleNotifSwitch(notif)}
                        />
                        <Divider />
                    </>
                ))}
            </Stack>
        </Box>
    );
}

const useStyles = makeStyles((theme) =>
    createStyles({
        h5Font: {
            fontSize: 22,
            fontFamily: "Josefin Sans",
            color: "#890608",
            marginTop: 5,
            marginBottom: 25,
        },
        
    })
);
