import 'date-fns';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Moment } from 'moment';

export default function RegisterDatePicker({selectedDate, setSelectedDate, label, startAdornment}: {selectedDate:Moment | null , setSelectedDate: (val:Moment | null)=>void, label?: String, startAdornment?:React.ReactNode}) {

  const handleDateChange = (dateVal: Moment | null) => {
    setSelectedDate(dateVal);
  };

  const minYear = (new Date()).getFullYear() - 70
  
  return (
      <MuiPickersUtilsProvider utils={MomentUtils} >
        <Grid container>
          {
              selectedDate &&
              <KeyboardDatePicker
                disableToolbar
                animateYearScrolling
                disableFuture
                variant="dialog"
                format="DD/MM/YYYY"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                label={label}
                InputProps={{
                    style: {
                        fontSize: 14,
                        height: 38,
                        width: 302
                    },
                    startAdornment,
                }}
                showTodayButton
                openTo='year'
                minDate={(new Date()).setFullYear(minYear)}
                maxDate={new Date()}
              />
          }
        </Grid>
      </MuiPickersUtilsProvider>
  );
}