import {makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles({
    active: {
        background: 'rgba(234, 118, 6, 0.06)',
        color: '#EA7606',
        border: '1px solid #EA7606',
        borderRadius: '5px',
        '&:hover': {
            cursor: "default !important",
            background: 'rgba(234, 118, 6, 0.06) !important',
        }
    },
});

export const CurrencyItem = (props: {
    currencyP: any,
    item: string,
    setItem: any,
    setCurrency: any,
    handleClose: any,
    handleClick?: any
}) => {
    const classes = useStyles()
    const {currencyP, setItem, handleClose, item, setCurrency} = props
    const isCurrencyActive = item?.toLowerCase() === currencyP?.code?.toLowerCase()

    const handleClicked = () => {
        setCurrency(currencyP.code);
        setItem(currencyP.code);
        handleClose()
    }
    return (
        <Grid item xs={6} sm={4} md={4} lg={3} onClick={handleClicked}>
            <Stack sx={{padding: '16px 8px 0 8px'}}>

                <Stack direction={'row'} justifyContent={'space-between'} sx={{
                    padding: "12px",
                    "&:hover": {backgroundColor: "#f3f3f3", cursor: "pointer", transition: "all 400ms ease-out"}
                }} className={isCurrencyActive ? classes.active : ''}>
                    <Stack>
                        <Typography component="div" textAlign="left" fontFamily="Josefin Sans" fontSize={14}
                                    fontWeight={400}
                                    color="#333"
                        >
                            {currencyP.name}
                        </Typography>
                        <Typography component="div" textAlign="left" fontFamily="Josefin Sans" fontSize={14}
                                    fontWeight={400} color="#6b6b6b" style={{textTransform: "uppercase"}}
                        >
                            {currencyP.code}
                        </Typography>
                    </Stack>
                    {isCurrencyActive &&
                      <Stack>
                        <CheckIcon/>
                      </Stack>

                    }
                </Stack>
            </Stack>
        </Grid>
    )
}