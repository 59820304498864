
import { makeStyles, createStyles, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import { FC, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>  createStyles({
    paper: {
        minWidth: 400,
        maxWidth: 500
    },
    title: {
        borderBottom: "1px solid #EBEBEB",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: 'center'
    },
    close: {
        padding: 5,
        position: "absolute",
        right: 20
    },
    subtitle:{
        fontSize: 14,
        display: 'block'
    }
  })
);

export interface IResetprops {
    handleClose: ()=>void; 
    open:boolean
    email: string
}

export const ResetPasswordDialog :FC<IResetprops> = ({handleClose, open, email}) => {
    const classes=useStyles();
    const [visible,setVisible]=useState<string>('');
    const { t } = useTranslation();
    
  return (   
      <> 
        { visible==="OK" ? "": 
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{paper: classes.paper}}
            >
                <DialogTitle classes={{root: classes.title}}>
                    <IconButton onClick={handleClose} className={classes.close}>
                        <CloseIcon />
                    </IconButton>
                    {t('SignIn.resetPassword.reset-password')}
                <span className={classes.subtitle}>
                    {t('SignIn.resetPassword.reset-password-subtitle')}
                </span>
                </DialogTitle>
                <DialogContent>
                <ResetPasswordForm email={email} setVisible={setVisible} handleCloseDialog={handleClose}/>
                </DialogContent>
            </Dialog>
        }
      </>
  );
}