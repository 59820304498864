import {
    makeStyles,
    createStyles,
    TextField,
    InputAdornment,
    Typography,
    IconButton,
} from "@material-ui/core";
import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import Spin from "../../../images/Spin";
import {
    IResetPasswordInput,
    ResetPasswordService,
} from "./services/ResetPasswordService";
import { useState } from "react";
import { colors } from "../../../styles/defaultColors";
import { Error } from "@material-ui/icons";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import HttpsIcon from "@material-ui/icons/Https";
import { Messages } from "../../../utils/enum";
import { useTranslation } from "react-i18next";
import { TestPasswordStrength } from "../../../utils/App";
import { getErrorLabel, UserErrorMessage } from "../../../utils/UserInterfaces";
import { AfriqSnackbar } from "../OverridingMaterial/AfriqSnackbar";
import { ISnackbar } from "../../../utils/enum/snackBarType";

const useStyles = makeStyles((theme) =>
    createStyles({
        formLoginContainer: {
            height: "40vh",
        },
        inputForm: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
        forgotPassword: {
            textAlign: "right",
            textDecoration: "none",
            marginBottom: theme.spacing(2),
        },
        register: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
        },
        registerLink: {
            color: "#EA7606",
        },
        link: {
            textAlign: "right",
            textDecoration: "none",
            color: "inherit",
            fontWeight: 600,
        },
        buttonLink: {
            textAlign: "right",
            textDecoration: "none",
            color: "inherit",
            fontWeight: 600,
            textTransform: "initial",
        },
        buttons: {
            marginBottom: theme.spacing(2),
            textAlign: 'center'
        },
        loginButton: {
            width: 250,
            background: "#f43636",
            color: "white",
            borderRadius: "50px",
            fontSize: 14,
            textTransform: "initial",
            "&:hover": {
                background: "#f52020"
            }
        },
        error: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        icon: {
            position: "relative",
            top: -4,
        },
        input: {
            "&::placeholder": {
                textOverflow: "ellipsis !important",
                color: "#000000",
                opacity: 10,
            },
        },
        passwordStrength: {
            width: '100%',
            height: 3,
            background: '#878787',
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
            marginBottom: 10,
            marginTop: -18,
        },
        passwordCriteria: {
            paddingLeft: 2,
            paddingTop: 0,
            marginBottom: 25,
            '& p': {
                margin: 0,
                fontSize: 11,
                marginBottom: 10
            }
        },
    })
);

const resetPasswordService = new ResetPasswordService();
export interface Iprops {
    email: string;
    setVisible: any;
    handleCloseDialog: () => void;
}

interface PasswordState {
    password: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
}

export const ResetPasswordForm: FC<Iprops> = ({ email, setVisible, handleCloseDialog }) => {
    const classes = useStyles();
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [resetInput, setResetInputs] = useState<IResetPasswordInput>({ email });
    const [colorPassword, setColorPassword] = React.useState<string>('#878787')

    const [values, setValues] = React.useState<PasswordState>({
        password: "",
        showPassword: false,
        showConfirmPassword: false,
    });
    const [snackValues, setSnackValues] = React.useState<ISnackbar>({
        type: "success",
        open: false,
        message: "",
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleCloseSnackbar = () => {
        setSnackValues({ ...snackValues, open: false });
    };

    const handleChange = (event: any) => {
        setError("");
        setResetInputs((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value,
        }));
        if (event.target.name === 'password') {
            setColorPassword(TestPasswordStrength(event.target.value))
        }
    };

    const handleResetPasswordSubmit = (evt: any) => {
        evt.preventDefault();
        setError("");
        const { email, confirmPassword, password, resetPasswordCode } = resetInput;
        if (!email) {
            setError("EMAIL");
            return;
        }
        if (!password) {
            setError("PASSWORD_AND_CONFIRM_PASSWORD_CANNOT_BE_NULL");
            return;
        }
        if (!confirmPassword) {
            setError("PASSWORD_AND_CONFIRM_PASSWORD_CANNOT_BE_NULL");
            return;
        }
        if (!resetPasswordCode) {
            setError("RESET_PASSWORD_CODE_REQUIRED");
            return;
        }
        if (confirmPassword !== password) {
            setError("PASSWORD DIFFERENT");
            return;
        }
        if (colorPassword !== 'green') {
            setError("PASSWORD WEAK");
            return;
        }

        setLoading(true);

        resetPasswordService
            .resetUserPassword(resetInput)
            .then((response: any) => {
                if (response?.success) {
                    
                    setSnackValues({
                        type: "success",
                        open: true,
                        message: t("common.alert.success"),
                    });
                    setTimeout(() => {
                        setVisible(response?.status)
                        handleCloseDialog();
                    }, 3000)
                }
            })
            .catch((err: Error) => {
                switch (err.message) {
                    case Messages.RESET_PASSWORD_CODE_REQUIRED:
                        setError("RESET_PASSWORD_CODE_REQUIRED");
                        break;
                    case Messages.PASSWORD_AND_CONFIRM_PASSWORD_CANNOT_BE_NULL:
                        setError("PASSWORD_AND_CONFIRM_PASSWORD_CANNOT_BE_NULL");
                        break;
                    case Messages.RESET_PASSWORD_CODE_NOT_FOUND:
                        setError("RESET_PASSWORD_CODE_NOT_FOUND");
                        break;
                    default:
                        setError("NETWORK_ERROR");
                        break;
                }
            }).finally(() => {
                setLoading(false);
            });
    };
    const { t } = useTranslation();

    return (
        <div>
        <form autoComplete="off" className={classes.formLoginContainer}>
            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    margin="dense"
                    disabled={true}
                    id="input-email-with-icon-textfield"
                    type="email"
                    value={email}
                    name="email"
                    fullWidth
                    placeholder={t('SignUp.email')}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailOutlinedIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="input-email-with-icon-textfield"
                    type="text"
                    name="resetPasswordCode"
                    value={resetInput.resetPasswordCode}
                    fullWidth
                    placeholder={t('SignIn.resetPassword.reset-code')}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HttpsIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className={classes.inputForm}>
                <TextField
                    autoComplete="off"
                    autoFocus
                    margin="dense"
                    id="input-email-with-icon-textfield"
                    type={values.showPassword ? "text" : "password"}
                    name="password"
                    value={resetInput.password}
                    fullWidth
                    placeholder={t("Profil.placeholder.newPassword")}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKeyOutlinedIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        classes: { input: classes["input"] },
                    }}
                    inputProps={{
                        autocomplete: 'new-password',
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                />
            </div>
            <div className={classes.passwordStrength} style={{ background: colorPassword }}></div>

            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="input-email-with-icon-textfield"
                    type={values.showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={resetInput.confirmPassword}
                    fullWidth
                    placeholder={t('Profil.placeholder.confirmPassword')}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKeyOutlinedIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showConfirmPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                        classes: { input: classes["input"] },
                    }}
                    inputProps={{
                        autocomplete: 'new-password',
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                />
            </div>
            <div className={classes.passwordCriteria}>
                <p>
                    {t('label.one-lowercase')}
                </p>
                <p>
                    {t('label.one-uppercase')}
                </p>
                <p>
                    {t('label.one-numeric-char')}
                </p>
                <p>
                    {t('label.one-special-char')}
                </p>
                <p>
                    {t('label.eight-char')}
                </p>
            </div>
            {error && (
                <div className={classes.error}>
                    <Typography>
                        <Error />
                        <br />
                        {error === "EMAIL" && t('SignIn.errors.emptyEmail')}
                        {error === "RESET_PASSWORD_CODE_REQUIRED" &&
                            t('SignIn.resetPassword.password-code-required')}
                        {error === "PASSWORD_AND_CONFIRM_PASSWORD_CANNOT_BE_NULL" &&
                            t('SignIn.errors.password-required')}
                        {error === "RESET_PASSWORD_CODE_NOT_FOUND" &&
                            t('SignIn.errors.invalid-code')}
                        {error === "NETWORK_ERROR" && Messages.NETWORK_ERROR}
                        {error === "PASSWORD DIFFERENT" && getErrorLabel(UserErrorMessage.PasswordDifferent)}
                        {error === "PASSWORD WEAK" && getErrorLabel(UserErrorMessage.PasswordWeak)}

                    </Typography>
                </div>
            )}
            <div className={classes.buttons}>
                <Button
                    type="button"
                    endIcon={loading ? <Spin /> : null}
                    disabled={loading}
                    className={classes.loginButton}
                    onClick={handleResetPasswordSubmit}
                >
                    {t('SignIn.resetPassword.reset-password')}
                </Button>
            </div>
        </form>
        <AfriqSnackbar
            {...snackValues}
            handleClose={handleCloseSnackbar}
        />
        </div>
    );
};
