import { InputAdornment, Select, MenuItem, makeStyles, createStyles, FormControl, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import React from 'react';
const useStyles = makeStyles((theme) =>
    createStyles({
        selectAdornment: {
        // marginRight: theme.spacing(3),
        },
        form: {
            marginTop: 15,
            marginBottom: 15,
        },
        selectField:{
            width: '100%',
            "& .MuiOutlinedInput-input": {
                padding:"9px 14px"
            },
            '&::placeholder': {
                color: "#000"
            },
            '&:focus':{
                borderRadius: 0,
                backgroundColor: 'inherit'
            }
        },
        shrink: {
            transform: "translate(14px, -5.5px) scale(0.75)!important"
        },
        icon: {
            position: "relative",
        },
        onFocus: {
            '&:focus': {
                backgroundColor: 'none'
            }
        },
    })
);

export function SelectEndornmentNation(props:any) {
    const classes= useStyles();
    const {t} = useTranslation();
    // const detailPays=(value:any)=>{

    // }
    // console.log(props.name==='country' );
    return (
        <FormControl fullWidth className={classes.form}>
            <InputLabel classes={{shrink: classes.shrink}}>{t("register.country")}{props.name==='country' ? '*': ''}</InputLabel>
            <Select
                labelId={props.id ?? ''}
                value={props.selectedValue}
                className={classes.selectField}
                name={props.name}
                label={t("register.country")}
                variant="outlined"
                onChange={e=>props.handleOnChange(e.target.name as string, e.target.value as string)}
                startAdornment={
                <InputAdornment className={classes.selectAdornment} position="start">
                    {props.IconElement}
                </InputAdornment>
                }
            >
                {
                    props.values.map((value:any, i:number) => {
                        return <MenuItem key={i} value={value.name} selected={value.selected} onClick={()=>{
                            props.handleOnChangeNation(value)
                        }}>{value.name}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );
}

