import { grey } from '@material-ui/core/colors';
import { Stack, Avatar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { colors } from '../../../styles/defaultColors';
import React from 'react';
import { FacebookCircularProgress } from '../UserNotification/components/NotificationSwitch';
import { DateToStringRefToday } from '.';

export const ChatItemMessage = (props: {
    avColor: string,
    avLetter: string,
    isResponse?: boolean,
    message: string,
    messageDate: Date,
    loading?: boolean,
}) => {
    const { avColor, avLetter, isResponse, message, messageDate, loading } = props;
    return (
        <Stack p={1} direction="row" alignItems="center" justifyContent="center" width="100%">
            {!isResponse && <Box flex={1} />}
            <Stack direction="row" maxWidth="300px">
                {isResponse && <Stack direction="column">
                    <Box flex={1} />
                    {!loading && <Avatar sx={{ bgcolor: avColor, color: "#fff", width: 24, height: 24, marginBottom: 4 }}>{avLetter[0] ?? 'U'}</Avatar>}
                </Stack>}
                <Stack direction="column" p={1} alignItems={isResponse ? "flex-start" : "flex-end"}>
                    <Box p={1} sx={{ backgroundColor: isResponse ? "#fff" : colors.orangePng, color: isResponse ? "#000" : "#fff" }} borderRadius={isResponse ? "6px 6px 6px 0px" : "6px 6px 0px 6px"}>
                        {message}
                    </Box>
                    <Typography p="2px" sx={{
                        color: grey[500],
                        fontSize: "12px !important",
                        fontWeight: 500,
                    }} component="div">
                        {DateToStringRefToday(messageDate)}
                    </Typography>
                </Stack>
                {!isResponse && <Stack direction="column">
                    <Box flex={1} sx={{ position: 'relative', }} />
                    {loading && <Box sx={{ paddingBottom: 4 }}><FacebookCircularProgress size={20} /></Box>}
                    {!loading && <Avatar sx={{ bgcolor: avColor, color: "#fff", width: 24, height: 24, marginBottom: 4 }}>{avLetter[0] ?? 'U'}</Avatar>}
                </Stack>}
            </Stack>
            {isResponse && <Box flex={1} />}
        </Stack>
    )
}