/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Switch, useRouteMatch } from "react-router";
// import UserDashboard from './UserDashboard';
import UserAdminContainer from "./UserAdminContainer";
import { RouteAdminUser } from "../Admin/constant/routesName";
import UserNotification from "./UserNotification";
import UserPaymentAccount from "./UserPaymentAccount";
import UserReceptionAccount from "./UserReceptionAccount";
// import UserProfile from './UserProfile';
// import UserSetting from "./UserSetting";
// import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import UserFavorite from "./UserFavorite";
import UserChat from "./UserChat";
import UserPromotion from "./UserPromotion/index";
import UserServices from "./userServices";
import UserAnnounceAd from "./UserAnnounceAd/index";
import { UserReservations } from "./UserReservations";
import React from "react";
import SessionHandler from "../../https/SessionHandler";
import { UserContext } from "../../contexts/AppContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import UserTransactionHistory from "./UserTransactionHistory";
import UserAdminCredit from "./UserAdminContainer/UserAdminCredit/UserAdminCredit";

export const UserAdminRoutes = () => {
    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const isConnected = sessionHandler.isConnected;
    const userContext = useContext(UserContext);
    const { user, setUser } = userContext;
    const history = useHistory();
    const { path } = useRouteMatch();

    React.useEffect(() => {
        if (!user.email) {
            isConnected()
                .then((response: any) => {
                    if (response.success) {
                        setUser(response.data);
                    }
                })
                .catch((err) => {
                    history.push("/");
                });
        }
    }, [user, history]);

    return (
        // <PerfectScrollbar>
        <UserAdminContainer>
            <Switch>
                {/* <Route exact path={path}>
                        <UserDashboard />
                    </Route> */}
                <Route exact path={path}>
                    <UserReservations />
                </Route>
                <Route path={RouteAdminUser.NOTIFICATION}>
                    <UserNotification />
                </Route>
                <Route path={RouteAdminUser.FAVORITE}>
                    <UserFavorite />
                </Route>
                <Route path={RouteAdminUser.SERVICES}>
                    <UserServices />
                </Route>
                <Route exact path={RouteAdminUser.PROMOTION}>
                    <UserPromotion />
                </Route>
                <Route exact path={`${RouteAdminUser.PROMOTION}/:id`}>
                    <UserPromotion />
                </Route>
                <Route path={RouteAdminUser.ANNOUNCES_ADS}>
                    <UserAnnounceAd />
                </Route>
                <Route path={RouteAdminUser.BOOKING}>
                    <UserReservations />
                </Route>
                <Route path={RouteAdminUser.CHATS}>
                    <UserChat />
                </Route>
                <Route path={RouteAdminUser.PAYMENT_ACCOUNT}>
                    <UserPaymentAccount />
                </Route>
                <Route path={RouteAdminUser.RECEPTION_ACCOUNT}>
                    <UserReceptionAccount />
                </Route>
                <Route path={RouteAdminUser.TRANSACTION_HISTORY}>
                    <UserTransactionHistory />
                </Route>
                <Route path={RouteAdminUser.CREDIT}>
                    <UserAdminCredit />
                </Route>
                {/* <Route path={RouteAdminUser.PROFILE}>
                        <UserProfile />
                    </Route> */}
                {/* <Route path={RouteAdminUser.SETTING}>
                    <UserSetting />
                </Route> */}
            </Switch>
        </UserAdminContainer>
        // </PerfectScrollbar>
    );
};
