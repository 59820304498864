import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FaCcPaypal } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdMobileFriendly } from 'react-icons/md';
// import { GoCreditCard } from 'react-icons/go';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import * as colors from '@mui/material/colors';
import { Box } from '@mui/system';
import { EPaymentMethod, EPaymentMethodCategory } from '../../../utils/enum/PaymentMethod';

const itemIcons = {
    [EPaymentMethodCategory.Paypal]: <FaCcPaypal size={64} color={colors.blue[600]} />,
    [EPaymentMethodCategory.MobileMoney]: <MdMobileFriendly size={64} color={colors.orange[600]} />,
}
const nameKey = {
    [EPaymentMethodCategory.Paypal]: 'recepient_email',
    [EPaymentMethodCategory.MobileMoney]: 'mobile_number',
}
const detailKey = {
    [EPaymentMethod.Paypal]: 'recepient_phone',
    [EPaymentMethodCategory.MobileMoney]: 'mobile_operator',
}

export const PayementItem = (props: {
    mode: EPaymentMethodCategory,
    onEdit: () => void,
    onDelete: () => void,
    item: any
}) => {
    const [mouseIn, setMouseIn] = React.useState(false);
    const { item, mode, onEdit, onDelete } = props;
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
            <div style={{ width: "100%" }} onMouseEnter={() => { setMouseIn(true) }} onMouseLeave={() => { setMouseIn(false) }}>
                <Stack p={2} direction="column" width="100%" height={200} justifyItems="space-between" position="relative">
                    <Stack direction="column" width="100%" alignItems="center" justifyContent="center" py={2}>
                        {itemIcons[mode]}
                    </Stack>
                    <Typography variant="h6" color={colors.red[900]}>
                        {item[nameKey[mode]]}
                    </Typography>
                    <Typography variant="h6" color={colors.grey[500]}>
                        {item[detailKey[mode]]}
                    </Typography>
                    <Grow style={{ position: "absolute", width: "100%", top: 0, left: 0 }} in={mouseIn}>
                        <Stack direction="row" width="100%" height={200} bgcolor={"rgba(174, 24, 24, 0.5)"} position="relative" >
                            <Stack direction="column" height="100%" flex={1} alignItems="center" justifyContent="center">
                                <IconButton onClick={onEdit} color="warning" aria-label="upload picture" component="span">
                                    <FiEdit size={54} color={colors.red[50]} />
                                </IconButton>
                            </Stack>
                            <Box position="absolute" right="8px" top="8px" bgcolor={colors.red[900]} p={1} borderRadius={100} >
                                <IconButton onClick={onDelete} color="warning" aria-label="upload picture" component="span">
                                    <RiDeleteBinLine size={22} color={colors.red[50]} />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Grow>
                </Stack>
            </div>
        </Paper>
    )
}