/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IReservationReq } from "../../../../utils/ReservationInterfaces";
import { dateToStringDDMMYYYY, getDayNumber } from "../../../../utils/App";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { serverConfig } from "../../../../utils/config";
import { BookingCardPhoto } from "./BookingCardPhoto";
import BookingCardListServices from "./BookingCardListServices";
import { RefundModal } from "./RefundModal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { EStatus } from "../../../../utils/enum";
// import { now } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primaryButton: {
            textAlign: "center",
            fontFamily: "Josefin Sans",
            background: "#890608",
            color: "#ffffff",
            padding: "8px 14px",
            marginRight: 10,
            "&:hover": {
                background: "#B5551B",
            },
        },
        secondaryButton: {
            textAlign: "center",
            fontFamily: "Josefin Sans",
            background: "#890608",
            color: "#ffffff",
            padding: "8px 14px",
            "&:hover": {
                background: "#B5551B",
            },
        },
        infoButton: {
            textAlign: "center",
            fontFamily: "Josefin Sans",
            background: "#890608",
            color: "#ffffff",
            padding: "8px 14px",
            width: "100%",
            "&:hover": {
                background: "#B5551B",
            },
        },
        body: {
            marginBottom: 16,
            display: "block",
        },
    })
);

export function BookingCard({
    reservation,
    setRefreshReservationList,
}: {
    reservation: IReservationReq;
    setRefreshReservationList: (val: boolean) => void;
}) {
    const classes = useStyles();
    const [prevewPhotosValid, setPreviewPhotosValid] = React.useState<any>([]);
    const [openRefundModal, setOpenRefundModal] = React.useState(false);

    React.useEffect(() => {
        const previewPhotos: any = reservation.announce?.property?.photos.map(
            (photo: any) => {
                if (photo.filename) {
                    return {
                        id: photo._id,
                        url: `${serverConfig.ApiRoot}/images/properties/${photo.filename}`,
                    };
                }
                return {
                    id: photo._id,
                    url: "",
                };
            }
        );
        setPreviewPhotosValid(previewPhotos);
    }, []);

    const handleClickOpen = () => {
        setOpenRefundModal(true);
    };

    const handleCancelBooking = () => {
        handleClickOpen();
    };

    const handleReservationRefunded = () => {
        //show dialog
    };

    const handleClose = () => {
        setOpenRefundModal(false);
    };
    const isBookingNotCancelable = (): boolean => {
        
        if(reservation.announce?.remboursable==="noremboursable"){
            if(getDayNumber(
                new Date().toISOString(),
                reservation.dateCheckIn,
            ) < reservation.announce?.notRemboursable1){
                return true
            }else{
                return false
            }
        }else{
            return moment(new Date()) > moment(reservation.dateCheckOut);
        }
       
    };
    const { t } = useTranslation();
    return (
        <>
            <Card>
                {prevewPhotosValid?.length > 0 && (
                    <BookingCardPhoto
                        photos={prevewPhotosValid}
                        width={184}
                        height={200}
                    />
                )}
                <Divider />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontSize: 20, marginBottom: 12 }}
                    >
                        {reservation.announce?.property?.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        className={classes.body}
                        style={{ marginBottom: 12 }}
                    >
                        {t("label.du")}{" "}
                        <span style={{ color: "#B5551B" }}>
                            {dateToStringDDMMYYYY(
                                new Date(reservation.dateCheckIn)
                            )}
                        </span>{" "}
                        {t("label.au")}{" "}
                        <span style={{ color: "#B5551B" }}>
                            {dateToStringDDMMYYYY(
                                new Date(reservation.dateCheckOut)
                            )}
                        </span>
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        className={classes.body}
                        style={{ marginBottom: 12 }}
                    >
                        {t("label.duree")}:{" "}
                        <span style={{ color: "#B5551B" }}>
                            {getDayNumber(
                                reservation.dateCheckIn,
                                reservation.dateCheckOut
                            )}{" "}
                            {t("label.jours")}
                        </span>
                    </Typography>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        className={classes.body}
                        style={{ marginBottom: 12 }}
                    >
                        {t("label.nombre-de-personnes")}:{" "}
                        <span style={{ color: "#B5551B" }}>{`${
                            reservation.travellerAdultTotal +
                            reservation.travellerChildTotal
                        } ${t("label.voyageurs")}`}</span>
                    </Typography>
                    {reservation.services?.length > 0 && (
                        <>
                            <Divider />
                            <BookingCardListServices
                                services={reservation.services}
                            />
                        </>
                    )}
                </CardContent>
                <Divider />
                <CardActions style={{ padding: 8 }}>
                    <Link
                        to={`/details-announce-${reservation.announce?._id}`}
                        target="_blank"
                        style={{
                            display: "block",
                            width: "50%",
                            marginRight: 10,
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.infoButton}
                            style={{ marginRight: 10, height: 53 }}
                        >
                            {t("label.voir-la-propriete")}
                        </Button>
                    </Link>
                    <Button
                        size="small"
                        style={{ width: "50%" }}
                        color={
                            reservation.canceled ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.CANCELLED ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.CANCELLED_BY_ADMIN ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.REJECTED
                                ? "success"
                                : "error"
                        }
                        variant={
                            reservation.canceled ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.CANCELLED ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.CANCELLED_BY_ADMIN ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.REJECTED
                                ? "contained"
                                : "text"
                        }
                        disabled={isBookingNotCancelable()}
                        onClick={
                            reservation.canceled ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.CANCELLED ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.CANCELLED_BY_ADMIN ||
                            reservation.status[reservation.status.length - 1]
                                .status === EStatus.REJECTED
                                ? handleReservationRefunded
                                : handleCancelBooking
                        }
                    >
                        {reservation.canceled ||
                        reservation.status[reservation.status.length - 1]
                            .status === EStatus.CANCELLED ||
                        reservation.status[reservation.status.length - 1]
                            .status === EStatus.CANCELLED_BY_ADMIN ||
                        reservation.status[reservation.status.length - 1]
                            .status === EStatus.REJECTED
                            ? t("reservation.button.annule-et-rembourse")
                            : t("reservation.button.annuler-cette-reservation")}
                    </Button>
                </CardActions>
                {reservation.canceled ||
                reservation.status[reservation.status.length - 1].status ===
                    EStatus.CANCELLED ||
                reservation.status[reservation.status.length - 1].status ===
                    EStatus.CANCELLED_BY_ADMIN ||
                reservation.status[reservation.status.length - 1]
                    .status === EStatus.REJECTED ? (
                    <>
                        <CardActions style={{ padding: 8, paddingTop: 0 }}>
                            <Link
                                to={`/booking-receipt-${reservation._id}`}
                                target="_blank"
                                style={{ display: "block", width: "50%" }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={classes.infoButton}
                                >
                                    {t("label.voir-mon-recu-de-paiement")}
                                </Button>
                            </Link>
                            <Link
                                to={`/cancellation-receipt-${reservation._id}`}
                                target="_blank"
                                style={{ display: "block", width: "50%" }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={classes.infoButton}
                                >
                                    {t("label.voir-mon-recu-d-annulation")}
                                </Button>
                            </Link>
                        </CardActions>
                    </>
                ) : (
                    <>
                        <CardActions style={{ padding: 8, paddingTop: 0 }}>
                            <Link
                                to={`/booking-receipt-${reservation._id}`}
                                target="_blank"
                                style={{ display: "block", width: "100%" }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={classes.infoButton}
                                >
                                    {t("label.voir-mon-recu-de-paiement")}
                                </Button>
                            </Link>
                        </CardActions>
                    </>
                )}
            </Card>
            <RefundModal
                openRefundModal={openRefundModal}
                handleClose={handleClose}
                reservation={reservation}
                setRefreshReservationList={setRefreshReservationList}
            />
        </>
    );
}
