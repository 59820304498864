import Api from "../../../../../https/Api";
import { ICurrency } from "../../../../../utils/CurrencyInterface";

export class CurrencyService {
    private api: Api;

    constructor() {
        this.api = new Api();
    }

    //get
    async getAllCurrency() {
        return this.api.get("currency");
    }

    async deleteCurrency(id: string) {
        return this.api.simpleDeleteById(`currency`, id);
    }

    async deleteCurrencies(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("currency", param);
    }

    async getCurrencyById(id: string) {
        return this.api.simpleGet("currency", id);
    }

    async createCurrency(currency: ICurrency) {
        return this.api.post("currency", currency);
    }

    async updateCurrency(id: string, currency: ICurrency) {
        return this.api.postById("currency", id, currency);
    }

    async selectCurrency(currencyID: string, superAdminID: string) {
        return this.api.postById("user/select-currency", currencyID, {
            idSuperUser: superAdminID,
        });
    }

    async getBaseCurrency() {
        return this.api.get("user/get-currency");
    }
}
