/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import React from "react";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
// import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import { Error, Visibility, VisibilityOff } from "@material-ui/icons";
import WcOutlinedIcon from "@material-ui/icons/WcOutlined";
import LanguageIcon from "@material-ui/icons/Language";
// import PublicIcon from '@material-ui/icons/Public';
import {
    IUserRegister,
    PasswordState,
    RegisterService,
    RoleService,
    typeGender,
    typeLang,
} from "./services/RegisterService";
import { SelectEndornment } from "../OverridingMaterial/SelectEndornment";
import {
    getErrorLabel,
    UserErrorMessage,
    UserErrorMessageFromServer,
} from "../../../utils/UserInterfaces";
import { colors } from "../../../styles/defaultColors";
import Spin from "../../../images/Spin";
import { useTranslation } from "react-i18next";
import { fileToBase64, TestPasswordStrength } from "../../../utils/App";
import { CountryService } from "../../Admin/Countries/services/EquipmentCountry";
import { AfriqSnackbar } from "../OverridingMaterial/AfriqSnackbar";
import { ISnackbar } from "../../../utils/enum/snackBarType";
import { ELinks, ERoles } from "../../../utils/enum";
import { Stack } from "@mui/material";
import { SelectEndornmentNation } from "./TextFieldNation";
import { SelectEndormentLang } from "./components/SelectEndormentLang";
import { RegisterValidationOTP } from "./components/RegisterValidationOTP";
import { CountryPhoneList } from "../../../utils/CountryList";
import { Link } from "react-router-dom";
import { linksStringToData, setRef } from "../../../utils/footerUtils";
import * as footerService from "../../../Services/footerServices";
import moment from "moment";
import RegisterDatePicker from "./components/RegisterDatePicker";
import { ELinksURL } from "../../../utils/enum/linksOnFooter";
import { Helmet } from "react-helmet-async";
import register from '../images/register.jpg';

const useStyles = makeStyles((theme) =>
    createStyles({
        inputForm: {
            display: "flex",
        },
        inputAvatar: {
            display: "none",
        },
        avatar: {
            display: "flex",
            width: 100,
            height: 100,
            border: "1px solid",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            backgroundSize: "cover",
        },
        avatarProfil: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 15,
        },
        isPreviewing: {
            color: "white",
            borderRadius: "50%",
            background: "#EA7606",
        },
        isNotPreviewing: {
            color: "#000000",
            borderRadius: "inherit",
            background: "inherit",
        },
        span: {
            display: "flex",
            justifyContent: "center",
            marginTop: 9,
            fontFamily: "Josefin Sans",
            fontSize: 14,
        },
        spanProfil: {
            fontFamily: "Josefin Sans",
            fontSize: 14,
            marginBottom: 20,
        },
        icon: {
            position: "relative",
        },
        textField: {
            "&::placeholder": {
                color: "#000",
            },
            marginTop: 15,
            marginBottom: 15,
        },
        input: {
            "&::placeholder": {
                textOverflow: "ellipsis !important",
                color: "#000000",

                opacity: 10,
            },
        },
        button: {
            // width: "100%",
            background: "#890608",
            color: "white",
            borderRadius: 100,
            fontFamily: "Josefin Sans",
            fontSize: 14,
            textTransform: "none",
            padding: "8px 14px",
            width: 175,
            "&:hover": {
                background: "#B5551B",
            },
        },
        buttonCancel: {
            // width: "100%",
            background: "#AAAAAA",
            color: "white",
            borderRadius: 100,
            fontFamily: "Josefin Sans",
            textTransform: "none",
            marginRight: 25,
            fontSize: 14,
            padding: "8px 14px",
            width: 175,
            "&:hover": {
                background: "#B5551B",
            },
            [theme.breakpoints.down("sm")]: {
                margin: "20px auto 0 auto",
            },
        },
        errors: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        boutonRegister: {
            margin: "auto",
            display: "flex",
            width: 400,
            marginTop: 40,
            marginBottom: 20,
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
            },
            [theme.breakpoints.down("xs")]: {
                width: "auto",
            },
        },
        btnCGu: {
            textTransform: "none",
            "&:hover": {
                background: "none",
                textDecoration: "underline",
            },
        },
        cguContainer: {
            marginLeft: -11,
            fontSize: "1rem",
        },
        passwordStrength: {
            width: "100%",
            height: 3,
            background: "#878787",
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
            marginBottom: 10,
            marginTop: -18,
        },
        passwordCriteria: {
            paddingLeft: 14,
            paddingTop: 5,
            "& p": {
                margin: 0,
                fontSize: 11,
                marginBottom: 10,
            },
        },
        inputLabel: {
            fontSize: 14,
            fontFamily: "Josefin Sans",
            color: "#000000",
        },
        containerLabel: {
            textAlign: "left",
            marginTop: 16
        },
        "@global": {
            ".MuiInputBase-input": {
                fontFamily: "Josefin Sans",
                fontSize: 14,
                color: "#000000",
            },
            ".MuiInputBase-input::placeholder": {
                opacity: 0.6,
                fontSize: 12,
            },
        },
    })
);

const registerService = new RegisterService();
const countryService = new CountryService();
const profilService = new RoleService();

export function Register(props: {
    handleOpenLoginDialog: () => void;
    handleClose: () => void;
}) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [fileURL, setFileURL] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    let errors: string[] = [];
    const [errorMessages, setErrorMessages] = React.useState<string>("");
    const [codephone, setCodePhone] = React.useState<string>("");
    const [logo, setLogoPays] = React.useState<string>("");
    const [codephoneurgent, setCodePhoneurgent] = React.useState<string>("");
    const [logourgent, setLogoPaysurgent] = React.useState<string>("");
    const [countriesList, setCountiresList] = React.useState<any>([]);
    const [values, setValues] = React.useState<IUserRegister>({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        confirmPassword: "",
        birthDate: "",
        numberPhone: "",
        phone: "",
        country: "",
        countryUrgent: "",
        numberPhoneUrgent: "",
        nomUrgent: "",
        relationUser: "",
        phoneUrgent: "",
        role: i18n.t("SignUp.traveller"),
        gender: i18n.t("SignUp.men"),
        avatar: null,
        lang: "FR",
    });

    // Test selected value
    const [state, setState] = React.useState({
        // defaultCountry: true,
        receiveProm: true,
        acceptPolitiq: false,
    });
    const { receiveProm, acceptPolitiq } = state;
    // const [lang, setLang] = React.useState("");
    const [role, setRoleUser] = React.useState<string>("");

    // const [paysSelected, setPaysSelected] = React.useState<any>([]);
    // const [roles, setRoles] = React.useState<any>([]);
    const [isSetRole, setIsSetRole] = React.useState<boolean>(false);
    const [validationCodeData, setValidationCodeData] = React.useState<{
        userId: string;
        userEmail: string;
        smsValidationCode: number;
        userPhone: string;
        userCountry: string;
        codePhone: string;
    }>({
        userId: "",
        userEmail: "",
        userPhone: "",
        userCountry: "",
        smsValidationCode: 0,
        codePhone: "",
    });
    const [validationCodeEntered, setValidationCodeEntered] = React.useState<
        number | null
    >(null);
    const [validationOTP, setValidationOTP] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(1);
    const [colorPassword, setColorPassword] = React.useState<string>("#878787");

    const [handlePassword, setHandlePassword] = React.useState<PasswordState>({
        password: "",
        showPassword: false,
        confirmPassword: "",
        showConfirmPassword: false,
    });
    const [snackValues, setSnackValues] = React.useState<ISnackbar>({
        type: "success",
        open: false,
        message: "",
    });

    const [open, setOpen] = React.useState(false);
    const [links, setLinks] = React.useState<any>({});
    const [, setGetingLinks] = React.useState(false);

    const handleChangeCheckecVallue = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    // const handleChangeLang = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setLang(event.target.value as string);
    // };

    const handleClickShowPassword = () => {
        setHandlePassword({
            ...handlePassword,
            showPassword: !handlePassword.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setHandlePassword({
            ...handlePassword,
            showConfirmPassword: !handlePassword.showConfirmPassword,
        });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handlChangeAvatar = async (event: any) => {
        const file = event.target.files[0];
        setFileURL(URL.createObjectURL(file));
        setValues({ ...values, avatar: await fileToBase64(file) });
    };

    const handleChange = (event: any) => {
        setValues({ ...values, [event.target.name]: event.target.value });
        if (event.target.name === "password") {
            setColorPassword(TestPasswordStrength(event.target.value));
        }
    };

    const handleChangeDate = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value as Date,
        });
    };

    const handleOnChangeSelect = (key: string, v: string) => {
        setValues({ ...values, [key]: v });
    };

    const handleCloseSnackbar = () => {
        setSnackValues({ ...snackValues, open: false });
    };

    const isFromValidate = (values: IUserRegister): boolean => {
        if (values.firstName === "" || values.lastName === "") {
            if (!errors.includes("EMPTY NAME"))
                errors = [...errors, "EMPTY NAME"];
        } else if (values.email === "" || values.password === "") {
            if (!errors.includes("EMAIL AND PASSWORD NULL"))
                errors = [...errors, "EMAIL AND PASSWORD NULL"];
        } else if (values.country === "") {
            if (!errors.includes("EMPTY COUNTRY"))
                errors = [...errors, "EMPTY COUNTRY"];
        } else if (values.phone === "") {
            if (!errors.includes("EMPTY PHONE"))
                errors = [...errors, "EMPTY PHONE"];
        } else if (acceptPolitiq === false) {
            if (!errors.includes("CGU NOT ACCEPTED"))
                errors = [...errors, "CGU NOT ACCEPTED"];
        } else if (values.password !== values.confirmPassword) {
            if (!errors.includes("PASSWORD DIFFERENT"))
                errors = [...errors, "PASSWORD DIFFERENT"];
        } else if (colorPassword !== "green") {
            if (!errors.includes("PASSWORD WEAK"))
                errors = [...errors, "PASSWORD WEAK"];
        }
        return !Boolean(errors.length);
    };

    const showErrorsMessages = () => {
        let allErrorMessages = "";
        errors.forEach((error) => {
            if (error === "EMPTY NAME") {
                allErrorMessages +=
                    "," +
                    getErrorLabel(UserErrorMessage.EmptyLastnameOrFirstname);
            } else if (error === "EMAIL USED") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.EmailUsed);
            } else if (error === "EMAIL AND PASSWORD NULL") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.EmailOrPasswordNull);
            } else if (error === "EMPTY COUNTRY") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.Emptypays);
            } else if (error === "EMPTY PHONE") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.Emptyphone);
            } else if (error === "CGU NOT ACCEPTED") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.CGUNOTACCEPTED);
            } else if (error === "SMS VALIDATION CODE WRONG") {
                allErrorMessages +=
                    "," +
                    getErrorLabel(UserErrorMessage.SmsValidationCodeWrong);
            } else if (error === "PASSWORD DIFFERENT") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.PasswordDifferent);
            } else if (error === "PASSWORD WEAK") {
                allErrorMessages +=
                    "," + getErrorLabel(UserErrorMessage.PasswordWeak);
            }
        });
        setErrorMessages(
            allErrorMessages.substring(1, allErrorMessages.length)
        );
    };

    React.useEffect(() => {
        if (!isSetRole) {
            profilService.getAllRole().then((response) => {
                if (response.success) {
                    const selectedRole = response.data.find(
                        (val: any) => val.name === ERoles.GUEST
                    );
                    setRoleUser(selectedRole._id);
                }
            });
        }
        setIsSetRole(true);
    });

    React.useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    React.useEffect(() => {
        countryService.getAllDestination().then((response) => {
            if (response.success) {
                setCountiresList(response.data);
            }
        });
    }, []);

    React.useEffect(() => {
        setGetingLinks(true);
        footerService
            .getFooterData()
            .then((resp) => {
                if (resp && resp.links) {
                    let upData = linksStringToData(resp.links);
                    setLinks(upData);
                }
                setGetingLinks(false);
            })
            .catch((err) => {
                console.error(err);
                setGetingLinks(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // React.useEffect(() => {
    //     profilService.getAllRole().then(response => {
    //         if (response.success) {
    //             setRoles(response.data.filter((val: any) => (val.name === "hotel admin" || val.name === "guest")))
    //         }
    //     })

    // }, [setRoles]);
    // let tab:any=[]
    // roles.map((val:any)=>{
    //     if(val.name=="hotel admin" || val.name=="guest"){
    //         tab.push(val)
    //     }

    // })

    // const onChangeCherchPays = (value: any): void => {
    //     setPaysSelected(value);
    //     if (value != null) {
    //         setRoleUser(value._id);
    //     }

    // };
    React.useEffect(() => {
        setValues({
            ...values,
            role,
        });
    }, [role]);
    const handleOnChangeNation = (val: any) => {
        setCodePhone(val.dial_code);
        setLogoPays(val.code);
    };
    const handleOnChangeNationUrgent = (val: any) => {
        setCodePhoneurgent(val.dial_code);
        setLogoPaysurgent(val.code);
    };
    React.useEffect(() => {
        let numphone;
        if (codephone !== undefined) {
            numphone = values.numberPhone;
        } else {
            numphone = values.numberPhone;
        }
        let numurgent;
        if (codephoneurgent !== undefined) {
            numurgent = values.numberPhoneUrgent;
        } else {
            numurgent = values.numberPhoneUrgent;
        }
        setValues({
            ...values,
            phone: numphone,
            phoneUrgent: numurgent,
        });
    }, [values.numberPhone, values.numberPhoneUrgent]);

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        if (isFromValidate(values)) {
            setLoading(true);
            registerService
                .register(values)
                .then((response) => {
                    if (response.success === true) {
                        const codePhone = CountryPhoneList.find(
                            (item: any) => item.name === response.user.country
                        );
                        if (step === 3) return;
                        setValidationCodeData({
                            userId: response.user._id,
                            userEmail: response.user.email,
                            userCountry: response.user.country,
                            smsValidationCode: parseInt(
                                response.user.registrationSmsToken
                            ),
                            userPhone: response.user.phone,
                            codePhone: codePhone.dial_code,
                        });
                        setErrorMessages("");
                        errors = [];
                        if (isFromValidate(values)) {
                            if (
                                values.country === "Cameroon" ||
                                values.country === "Ghana"
                            ) {
                                setStep((prev) => {
                                    return prev + 1;
                                });
                            } else {
                                handleValidationOTP();
                            }
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.error === UserErrorMessageFromServer.EmailUsed) {
                        errors = [...errors, "EMAIL USED"];
                    } else if (
                        error.error ===
                        getErrorLabel(
                            UserErrorMessageFromServer.EmailOrPasswordNull
                        )
                    ) {
                        errors = [...errors, "EMAIL AND PASSWORD NULL"];
                    }
                    setLoading(false);
                    showErrorsMessages();
                });
        } else {
            showErrorsMessages();
            return false;
        }
    };

    const handleCancel = () => {
        if (props.handleOpenLoginDialog) props.handleOpenLoginDialog();
        props.handleClose();
    };

    const handleValidationOTP = () => {
        if (step === 1) {
            setSnackValues({
                ...snackValues,
                open: true,
                message: t("common.alert.success"),
            });
            setOpen(true);
            setTimeout(() => {
                props.handleOpenLoginDialog();
            }, 4000);
        } else if (step === 2) {
            if (
                validationCodeData.smsValidationCode !== validationCodeEntered
            ) {
                if (!errors.includes("SMS VALIDATION CODE WRONG"))
                    errors = [...errors, "SMS VALIDATION CODE WRONG"];
                showErrorsMessages();
            } else {
                registerService
                    .confirmCodeValidation(validationCodeData.userId)
                    .then((response) => {
                        if (response.success) {
                            setSnackValues({
                                ...snackValues,
                                open: true,
                                message: t("common.alert.success"),
                            });
                            setOpen(true);
                            setTimeout(() => {
                                props.handleOpenLoginDialog();
                            }, 4000);
                        }
                    })
                    .catch((error) => {
                        registerService
                            .confirmCodeValidation(validationCodeData.userId)
                            .then((response) => {
                                if (response.success) {
                                    setSnackValues({
                                        ...snackValues,
                                        open: true,
                                        message: t("common.alert.success"),
                                    });
                                    setOpen(true);
                                    setTimeout(() => {
                                        props.handleOpenLoginDialog();
                                    }, 4000);
                                }
                            })
                            .catch((error) => {
                                console.log(
                                    "confirm code validation error",
                                    error
                                );
                            });
                    });
            }
        }
    };

    const handlePrevStep = () => {
        if (step === 1) return;
        setLoading(true);
        registerService
            .deleteUserById(validationCodeData.userId)
            .then((response) => {
                if (response.success) {
                    setStep((prev) => {
                        return prev - 1;
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const handleResendValdiationCode = (e: any) => {
        e.preventDefault();
        registerService
            .reSendValidationCode({
                userId: validationCodeData.userId,
                userPhone: validationCodeData.userPhone,
                userCountry: validationCodeData.userCountry,
            })
            .then((response) => {
                if (response.success) {
                    setValidationCodeData({
                        ...validationCodeData,
                        smsValidationCode: response.data.registrationSmsToken,
                    });
                }
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

        const title = `${t("Register.creer-un-compte")} | AfriqPnB`;
        const description = (t("Register.description"));
        const currentUrl = window.location.href;
        const ogImageUrl = "https://afriqpnb.com/api/images/articles/register.jpg";        
        const ogImageAlt = t("Register.altImageArt");


    return (
        <div>
            <Helmet>
                 {/* General Meta Tags */}
                 <title>{title}</title>
                <meta name="description" content={description} data-rh="true" />
                                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={title} data-rh="true" />                
                <meta property="og:url" content={currentUrl} data-rh="true" />
                <meta property="og:type" content="website" data-rh="true" />
                <meta property="og:site_name" content="AfriqPnB" data-rh="true" />
                <meta property="og:image" content={ogImageUrl} data-rh="true" />
                <meta property="og:image:secure_url" content={ogImageUrl} data-rh="true" />
                <meta property="og:image:type" content="image/webp" data-rh="true" />
                <meta property="og:image:width" content="300" data-rh="true" />
                <meta property="og:image:height" content="300" data-rh="true" />
                <meta property="og:image:alt" content={ogImageAlt} data-rh="true" />
                <link rel="canonical" href={currentUrl} /> 
            </Helmet>


            <h1 style={{ color: "#890608", fontSize: "16px", textAlign: "center", marginBottom: 25 }}>{t("Register.creer-un-compte")} </h1>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3} className={classes.inputForm}>
                    {step === 1 && (
                        <>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <div className={classes.avatarProfil}>
                                    <span className={classes.spanProfil}>
                                        {t("label.votre-profil")}
                                    </span>
                                    <div
                                        className={classes.avatar}
                                        style={{
                                            backgroundImage: `url(${fileURL})`,
                                        }}
                                    >
                                        <input
                                            accept="image/*"
                                            className={classes.inputAvatar}
                                            id="icon-button-file"
                                            type="file"
                                            onChange={handlChangeAvatar}
                                        />
                                        <label htmlFor="icon-button-file">
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="span"
                                            >
                                                <AccountCircleOutlinedIcon
                                                    className={
                                                        fileURL
                                                            ? classes.isPreviewing
                                                            : classes.isNotPreviewing
                                                    }
                                                />
                                            </IconButton>
                                        </label>
                                    </div>
                                    <span className={classes.span}>Avatar</span>
                                </div>
                            </Grid>
                            <Grid item container lg={9} md={9} sm={12} xs={12}>
                                <Grid container spacing={3} direction="row">
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="firstName"
                                            variant="outlined"
                                            name="firstName"
                                            type="text"
                                            fullWidth
                                            label={`${t("SignUp.firstname")}*`}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            onChange={handleChange}
                                            value={values.firstName}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />

                                        <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={values.lastName}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            fullWidth
                                            label={`${t("SignUp.lastname")}*`}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonAddOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                lg={4}
                                                md={4}
                                                sm={4}
                                                xs={4}
                                            >
                                                <SelectEndornmentNation
                                                    id="country"
                                                    name="country"
                                                    values={CountryPhoneList}
                                                    IconElement={
                                                        logo ? (
                                                            <img
                                                                style={{
                                                                    width: 32,
                                                                }}
                                                                src={`${logo}`}
                                                                alt={"Flag"}
                                                            />
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    selectedValue=""
                                                    handleOnChange={
                                                        handleOnChangeSelect
                                                    }
                                                    handleOnChangeNation={
                                                        handleOnChangeNation
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={8}
                                                md={8}
                                                sm={8}
                                                xs={8}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    display="flex"
                                                >
                                                    <TextField
                                                        // autoFocus
                                                        margin="dense"
                                                        id="phone"
                                                        name="numberPhone"
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        value={
                                                            values.numberPhone
                                                        }
                                                        type="text"
                                                        classes={{
                                                            root: classes.textField,
                                                        }}
                                                        fullWidth
                                                        label={`${t(
                                                            "SignUp.phone"
                                                        )}*`}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <div
                                                                    style={{
                                                                        marginRight: 10,
                                                                    }}
                                                                >
                                                                    {codephone}
                                                                </div>
                                                            ),
                                                            classes: {
                                                                input: classes[
                                                                    "input"
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid item xs={12} md={12} sm={12} lg={12}> */}
                                            {/* <AfriqDatePicker  setSelectedDate={(value) =>setValues({...values, birthDate: value!.toDate()})} selectedDate={moment(values.birthDate)} /> */}
                                        {/* </Grid> */}
                                        {/* <Grid item md={12}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    className={classes.containerLabel}
                                >
                                    <label className={classes.inputLabel}>
                                        {t("label.date-debut")}
                                    </label>
                                </Grid> */}
                                <Grid item xs={12} md={12} sm={12} lg={12}>
                                    <RegisterDatePicker setSelectedDate={(value) =>setValues({...values, birthDate: value?.toDate() ?? ''})} selectedDate={moment(values.birthDate !== "" ? values.birthDate : new Date())} label={`${t(
                                                "register.your-birthday"
                                            )}*`} startAdornment={(
                                                <InputAdornment position="start">
                                                    <DateRangeOutlinedIcon
                                                        className={
                                                            classes.icon
                                                        }
                                                    />
                                                </InputAdornment>
                                            )}/>
                                </Grid>

                            {/* </Grid> */}
                                        {/* <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="birthDate"
                                            name="birthDate"
                                            variant="outlined"
                                            type="date"
                                            onChange={handleChangeDate}
                                            value={values.birthDate as Date}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            fullWidth
                                            label={`${t(
                                                "register.your-birthday"
                                            )}*`}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <DateRangeOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        /> */}
                                        <SelectEndornment
                                            id="gender"
                                            name="gender"
                                            values={typeGender}
                                            IconElement={
                                                <WcOutlinedIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            selectedValue={values.gender}
                                            handleOnChange={
                                                handleOnChangeSelect
                                            }
                                        />

                                    {/*    <SelectEndormentLang
                                            id="lang"
                                            name="lang"
                                            values={typeLang}
                                            IconElement={
                                                <LanguageIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            selectedValue={values.lang}
                                            handleOnChange={
                                                handleOnChangeSelect
                                            }
                                        /> */}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="email"
                                            name="email"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                            type="email"
                                            fullWidth
                                            label={`${t("SignUp.email")}*`}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />
                                        <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="password"
                                            name="password"
                                            variant="outlined"
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            value={values.password}
                                            type={
                                                handlePassword.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            fullWidth
                                            label={`${t("SignUp.password")}*`}
                                            inputProps={{
                                                autocomplete: "new-password",
                                                form: {
                                                    autocomplete: "off",
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <VpnKeyOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {handlePassword.showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />
                                        <div
                                            className={classes.passwordStrength}
                                            style={{
                                                background: colorPassword,
                                            }}
                                        ></div>

                                        <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="password"
                                            name="confirmPassword"
                                            variant="outlined"
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            value={values.confirmPassword}
                                            type={
                                                handlePassword.showConfirmPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            fullWidth
                                            label={`${t(
                                                "label.confirmer-le-mot-de-passe"
                                            )}*`}
                                            inputProps={{
                                                autocomplete:
                                                    "confirm-password",
                                                form: {
                                                    autocomplete: "off",
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <VpnKeyOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirmPassword visibility"
                                                            onClick={
                                                                handleClickShowConfirmPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {handlePassword.showConfirmPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />
                                        <div
                                            className={classes.passwordCriteria}
                                        >
                                            <p>{t("label.one-lowercase")}</p>
                                            <p>{t("label.one-uppercase")}</p>
                                            <p>{t("label.one-numeric-char")}</p>
                                            <p>{t("label.one-special-char")}</p>
                                            <p>{t("label.eight-char")}</p>
                                        </div>
                                       {/* <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="NameUrgent"
                                            variant="outlined"
                                            name="nomUrgent"
                                            value={values.nomUrgent}
                                            type="text"
                                            fullWidth
                                            label={t("register.nom-urgent")}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />

                                        <TextField
                                            // autoFocus
                                            margin="dense"
                                            id="NameUrgent"
                                            variant="outlined"
                                            name="relationUser"
                                            value={values.relationUser}
                                            type="text"
                                            fullWidth
                                            label={t("register.relation")}
                                            classes={{
                                                root: classes.textField,
                                            }}
                                            placeholder={t(
                                                "register.placeholder-relation"
                                            )}
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlinedIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes["input"],
                                                },
                                            }}
                                        />
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                lg={4}
                                                md={4}
                                                sm={4}
                                                xs={4}
                                            >
                                                <SelectEndornmentNation
                                                    id="country"
                                                    name="countryUrgent"
                                                    values={CountryPhoneList}
                                                    IconElement={
                                                        logourgent ? (
                                                            <img
                                                                style={{
                                                                    width: 32,
                                                                }}
                                                                src={`${logourgent}`}
                                                                alt={"Flag"}
                                                            />
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    selectedValue=""
                                                    handleOnChange={
                                                        handleOnChangeSelect
                                                    }
                                                    handleOnChangeNation={
                                                        handleOnChangeNationUrgent
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={8}
                                                md={8}
                                                sm={8}
                                                xs={8}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    display="flex"
                                                >
                                                    <TextField
                                                        // autoFocus
                                                        margin="dense"
                                                        id="phoneUrgent"
                                                        name="numberPhoneUrgent"
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        value={
                                                            values.numberPhoneUrgent
                                                        }
                                                        type="text"
                                                        classes={{
                                                            root: classes.textField,
                                                        }}
                                                        fullWidth
                                                        label={t(
                                                            "register.contact-urgent"
                                                        )}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <div
                                                                    style={{
                                                                        marginRight: 10,
                                                                    }}
                                                                >
                                                                    {
                                                                        codephoneurgent
                                                                    }
                                                                </div>
                                                            ),
                                                            classes: {
                                                                input: classes[
                                                                    "input"
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                                    </Grid>*/}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction={"column"}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <FormControlLabel
                                        style={{ marginRight: 16 }}
                                        control={
                                            <Checkbox
                                                checked={receiveProm}
                                                onChange={
                                                    handleChangeCheckecVallue
                                                }
                                                name="receiveProm"
                                                style={{ color: "#890608" }}
                                            />
                                        }
                                        label={t("SignUp.promotion")}
                                    />
                                    <div className={classes.cguContainer}>
                                        <Checkbox
                                            checked={acceptPolitiq}
                                            onChange={handleChangeCheckecVallue}
                                            name="acceptPolitiq"
                                            style={{ color: "#890608" }}
                                        />
                                        <span
                                            style={{
                                                display: "inline-block",
                                                transform: " translateY(2px)",
                                            }}
                                        >
                                            {t("SignUp.politique-1")}{" "}
                                        </span>
                                        <Link
                                            to={{
                                                pathname: `${ELinksURL.CGU}`,
                                                state: links[
                                                    setRef(ELinks.CGU).key
                                                ]?.state,
                                            }}
                                            target="_blank"
                                            style={{
                                                display: "inline-block",
                                                transform: " translateY(2px)",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {" "}
                                            {t("SignUp.politique-2")}{" "}
                                        </Link>
                                    </div>
                                    {/* {acceptPolitiq &&
                            {t('label.voir-cgu')} */}
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <RegisterValidationOTP
                                validationCodeEntered={validationCodeEntered}
                                setValidationCodeEntered={
                                    setValidationCodeEntered
                                }
                                validationCodeData={validationCodeData}
                            />
                        </>
                    )}

                    {errorMessages && (
                        <div
                            className={classes.errors}
                            style={{
                                marginBottom: step === 2 ? 50 : 0,
                                marginTop: step === 2 ? 0 : 16,
                            }}
                        >
                            <Typography>
                                <Error />
                                <br />
                                <span>{errorMessages}</span>
                            </Typography>
                        </div>
                    )}
                </Grid>
                <div
                    className={classes.boutonRegister}
                    style={{ marginTop: step === 2 ? 0 : 40 }}
                >
                    {step === 1 && (
                        <Button
                            size="small"
                            className={classes.buttonCancel}
                            onClick={handleCancel}
                        >
                            {t("label.annuler")}
                        </Button>
                    )}

                    <Button
                        size="small"
                        type={step === 1 ? "submit" : "button"}
                        className={
                            !acceptPolitiq
                                ? classes.buttonCancel
                                : classes.button
                        }
                        style={{
                            display: step === 2 ? "block" : "flex",
                            margin: "0 auto",
                        }}
                        endIcon={step === 1 && loading ? <Spin /> : null}
                        disabled={(step === 1 && loading) || !acceptPolitiq}
                        onClick={
                            step === 1 ? handleSubmit : handleValidationOTP
                        }
                    >
                        {step === 1 ? t("label.s-inscrire") : "Confirmer"}
                    </Button>
                </div>
                {step === 2 && (
                    <div style={{ textAlign: "right", marginTop: 50 }}>
                        <Link
                            to={""}
                            onClick={handleResendValdiationCode}
                            style={{ display: "block" }}
                        >
                            {t("label.renvoyer-le-code")}
                        </Link>
                        {/*
                        t('label.s-inscrire')
                        <Button
                        type="button"
                        onClick={handleResendValdiationCode}
                        >
                        {t('label.renvoyer-le-code')}
                        </Button> */}
                    </div>
                )}
            </form>
            <AfriqSnackbar {...snackValues} handleClose={handleCloseSnackbar} />
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("dialogRegister.confirm")}{" "}
                        <span style={{ color: "blue" }}>{values.email}</span>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}
