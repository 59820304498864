import React from 'react'
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer:{

    },
    buttonDeleteImage:{
      fill: 'rgb(208, 67, 67)',
      color: 'rgb(208, 67, 67)',
      background: 'white',
      position: 'absolute',
      top: 5,
      right: 5,
      minWidth: 30,
      height: 30,
      margin: 0,
      padding: 0,
      '&:hover':{
         background: 'white',

      }
    },
    imgThumb:{
        width: '100%'
    },
    imgContainer:{
        background:'#5e5e5e',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: -20,
        position: 'relative',
        maxHeight: 200
    },
  })
);
export const BookingCardPhotoSlide = (props:{ filename: string; fileURL: string; handleOpenImageLightBox: ()=>void;}) => {
    const classes=styles();
    return (
      <div className={classes.imgContainer}>
        <div className="image-container"  >
          <img className={classes.imgThumb} src={props.fileURL} alt={props.filename ?? 'Sans photo'} onClick={props.handleOpenImageLightBox} style={{cursor: 'pointer'}} />
        </div> 
    </div>
    )
}
