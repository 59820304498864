import { INotification } from "./NotificationInterface";
import { IMobileMoney, IPaypal } from "./PaymentInterfaces";
import { ICurrency } from "./CurrencyInterface";
import i18n from "../i18n/config";

export enum UserErrorMessageFromServer {
    NotFound = "User not found",
    WrongPassword = "Wrong password",
    EmailUsed = "Email already used",
    EmailOrPasswordNull = "Mail or password cannot be null",
    WrongActualPasswordReceived = "Wrong actual password received",
    NewAndOrConfirmPasswordNull = "Password and confirm password cannot be null",
    UnConfirmedAccount = "Unconfirmed Account",
}

export enum UserErrorMessage {
    CGUNOTACCEPTED = "CGU NOT ACCEPTED",
    NotFound = "Incorrect email or password",
    WrongPassword = "Incorrect password",
    EmptyLastnameOrFirstname = "Enter your first and last name",
    EmptyPassword = "Enter your password",
    EmptyEmail = "Enter your e-mail address",
    Emptypays = "Enter your country",
    Emptyphone = "Enter your phone number",
    EmailUsed = "Your email has already been used",
    EmailOrPasswordNull = "Email or password can not be null",
    NewPasswordDifferent = "The two new passwords are different",
    PasswordDifferent = "The two passwords are different",
    PasswordTooSmall = "The password must contain at least 8 characters",
    WrongActualPassword = "Old password incorrect",
    EmptyRoleAccount = "Please select a role for the account!",
    EmptyNewOrConfirmPassword = "Enter the new password or leave all password fields blank if you do not want to change it",
    UnConfrimAccount = "Account not confirmed! Please confirm via the link sent to you by email",
    SmsValidationCodeWrong = "Sms validation code wrong",
    PasswordWeak = "Your password is too weak",
}

export enum PromotionErrorMessage {
    EmptyPromotionTitle = "Enter the title of the promotion",
    EmptyQuartie = "Veuillez renseigner quartier",
    NoAnnounceSelected = "Please select an announcement",
    EmptyDiscount = "Please fill in the discount to be offered",
    EmptyExpirationDate = "Enter the promotion expiration date",
    DateNotSelectable = "Select an other range of dates please",
}

export enum AdErrorMessage {
    NoAdParamSelected = "Select the sponsor's location for the ad ",
    NoAnnounceSelected = "Select the ad to be boosted",
    NoStartDateSelected = "Select a starting date for the boosting please",
    StartingDateWrong = "Select an other starting date that is above of the ends of the other boost announce you've created",
}

export interface IPaymentDetail {
    type: string;
    details: string;
}

export interface IUser {
    _id: string;
    token: string;
    accountType: string;
    firstName: string;
    lastName: string;
    country?: string;
    gender: string;
    birthDate: Date | string;
    properties: Array<string>;
    phone: string;
    notifications?: INotification[];
    avatar: any;
    avatarURL?: string;
    email: string;
    password: string;
    newPassword?: string;
    favorites: string[];
    confirmPassword?: string;
    confirmedRegistration: boolean;
    confirmedCodeValidaton: boolean;
    registrationSmsToken: string;
    role: IRole;
    paymentDetail?: Array<IPaymentDetail>;
    createdAt?: string;
    updatedAt?: string;
    devenirHotel?: boolean;
    currency?: ICurrency;
    relationUser: string;
    phoneUrgent: string;
    nomUrgent: string;
    payments: {
        paypal: Array<IPaypal>;
        mobileMoney: Array<IMobileMoney>;
    };
    receptions: {
        paypal: Array<IPaypal>;
        mobileMoney: Array<IMobileMoney>;
    };
    lang: string;
    userID: string;
    credits: {
        paypal: number;
        mobileMoney: number;
    };
}

export interface IUserPost {
    _id: string;
    accountType: string;
    token: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthDate: Date | string;
    properties: Array<string>;
    phone: string;
    email: string;
    password: string;
    newPassword?: string;
    confirmPassword?: string;
    confirmedRegistration: boolean;
    confirmedCodeValidaton: boolean;
    registrationSmsToken: string;
    role: IRole;
    paymentDetail?: Array<IPaymentDetail>;
    relationUser: string;
    phoneUrgent: string;
    nomUrgent: string;
    payments?: {
        paypal: Array<IPaypal>;
        mobileMoney: Array<IMobileMoney>;
    };
    receptions?: {
        paypal: Array<IPaypal>;
        mobileMoney: Array<IMobileMoney>;
    };
    lang: string;
}

export interface IRole {
    _id: string;
    name: string;
    rights?: Array<IRight>;
}

export interface IRight {
    _id: string;
    name: string;
}

export type RoleInputs = Omit<IRole, "_id"> & {
    _id?: string;
};

export function getErrorLabel(name: string) {
    if (name === UserErrorMessageFromServer.NotFound)
        return i18n.t("error.userErrorMessageFromServer.notFound");
    if (name === UserErrorMessageFromServer.WrongPassword)
        return i18n.t("error.userErrorMessageFromServer.wrongPassword");
    if (name === UserErrorMessageFromServer.EmailUsed)
        return i18n.t("error.userErrorMessageFromServer.emailUsed");
    if (name === UserErrorMessageFromServer.EmailOrPasswordNull)
        return i18n.t("error.userErrorMessageFromServer.emailOrPasswordNull");
    if (name === UserErrorMessageFromServer.WrongActualPasswordReceived)
        return i18n.t(
            "error.userErrorMessageFromServer.wrongActualPasswordReceived"
        );
    if (name === UserErrorMessageFromServer.NewAndOrConfirmPasswordNull)
        return i18n.t(
            "error.userErrorMessageFromServer.newAndOrConfirmPasswordNull"
        );
    if (name === UserErrorMessageFromServer.UnConfirmedAccount)
        return i18n.t("error.userErrorMessageFromServer.unConfirmedAccount");
    if (name === UserErrorMessage.NotFound)
        return i18n.t("error.userError.notFound");
    if (name === UserErrorMessage.WrongPassword)
        return i18n.t("error.userError.wrongPassword");
    if (name === UserErrorMessage.EmptyLastnameOrFirstname)
        return i18n.t("error.userError.emptyLastnameOrFirstname");
    if (name === UserErrorMessage.EmptyPassword)
        return i18n.t("error.userError.emptyPassword");
    if (name === UserErrorMessage.EmptyEmail)
        return i18n.t("error.userError.emptyEmail");
    if (name === UserErrorMessage.Emptypays)
        return i18n.t("error.userError.emptypays");
    if (name === UserErrorMessage.Emptyphone)
        return i18n.t("error.userError.emptyphone");
    if (name === UserErrorMessage.EmailUsed)
        return i18n.t("error.userError.emailUsed");
    if (name === UserErrorMessage.EmailOrPasswordNull)
        return i18n.t("error.userError.emailOrPasswordNull");
    if (name === UserErrorMessage.NewPasswordDifferent)
        return i18n.t("error.userError.newPasswordDifferent");
    if (name === UserErrorMessage.PasswordDifferent)
        return i18n.t("error.userError.passwordDifferent");
    if (name === UserErrorMessage.PasswordTooSmall)
        return i18n.t("error.userError.passwordTooSmall");
    if (name === UserErrorMessage.WrongActualPassword)
        return i18n.t("error.userError.wrongActualPassword");
    if (name === UserErrorMessage.EmptyRoleAccount)
        return i18n.t("error.userError.emptyRoleAccount");
    if (name === UserErrorMessage.EmptyNewOrConfirmPassword)
        return i18n.t("error.userError.emptyNewOrConfirmPassword");
    if (name === UserErrorMessage.CGUNOTACCEPTED)
        return i18n.t("error.userError.cguNotAccepted");
    if (name === UserErrorMessage.UnConfrimAccount)
        return i18n.t("error.userError.unConfrimAccount");
    if (name === UserErrorMessage.SmsValidationCodeWrong)
        return i18n.t("error.userError.smsValidationCodeWrong");
    if (name === UserErrorMessage.PasswordDifferent)
        return i18n.t("error.userError.passwordDifferent");
    if (name === UserErrorMessage.PasswordWeak)
        return i18n.t("error.userError.passwordWeak");
    if (name === PromotionErrorMessage.EmptyPromotionTitle)
        return i18n.t("error.promotionErrorMessage.emptyPromotionTitle");
    if (name === PromotionErrorMessage.NoAnnounceSelected)
        return i18n.t("error.promotionErrorMessage.noAnnounceSelected");
    if (name === PromotionErrorMessage.EmptyDiscount)
        return i18n.t("error.promotionErrorMessage.emptyDiscount");
    if (name === PromotionErrorMessage.EmptyExpirationDate)
        return i18n.t("error.promotionErrorMessage.emptyExpirationDate");
    if (name === AdErrorMessage.NoAdParamSelected)
        return i18n.t("error.adErrorMessage.noAdParamSelected");
    if (name === AdErrorMessage.NoAnnounceSelected)
        return i18n.t("error.adErrorMessage.noAnnounceSelected");
    if (name === AdErrorMessage.NoStartDateSelected)
        return i18n.t("error.adErrorMessage.noStartDateSelected");
    if (name === AdErrorMessage.StartingDateWrong)
        return i18n.t("error.adErrorMessage.startingDateWrong");
    return name;
}
