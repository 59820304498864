import { IUserService } from "../Services/userServecesServices";
import { IAnnounce } from "../utils/AnnounceInterfaces";
import { IUser } from "../utils/UserInterfaces";
import { IAnnounceItem } from "./announces";
import { IBaseData } from "./global";

export interface IReservation extends IBaseData {
    dateCheckIn: Date;
    dateCheckOut: Date;
    status: Array<ReservationStatus>;
    travellerChildTotal: number;
    travellerAdultTotal: number;
    announce: IAnnounceItem;
    traveller: IUser;
    attachments: Array<AttachmentInputs>;
    services: string[];
}

export interface IReservationReq extends IBaseData {
    dateCheckIn: Date;
    dateCheckOut: Date;
    status: Array<ReservationStatus>;
    travellerChildTotal: number;
    travellerAdultTotal: number;
    announce: IAnnounce;
    traveller: IUser;
    attachments: Array<AttachmentInputs>;
    services: IUserService[];
    isPaymentPaused: Boolean;
    incrimentationJour: Number;
}

export type ReservationStatus = {
    date: Date;
    status: string;
};

export type AttachmentInputs = {
    _id?: string;
    filename: string;
    type: string;
    fileURL?: string;
};

export interface DataReservation {
    _id: string;
    announceId: string;
    property: string;
    travellerNumber: number;
    bookingDate: Date;
    dateCheckIn: Date;
    dateCheckOut: Date;
    requestAge: number;
    status: string;
    updatedAt: Date;
    country: string;
    city: string;
    services: IUserService[];
    isPaymentPaused: Boolean;
    remboursable: String;
    incrimentationJour: Number;
}

export function createData(
    _id: string,
    announceId: string,
    property: string,
    travellerNumber: number,
    bookingDate: Date,
    dateCheckIn: Date,
    dateCheckOut: Date,
    requestAge: number,
    status: string,
    updatedAt: Date,
    country: string,
    city: string,
    services: IUserService[],
    isPaymentPaused: Boolean,
    remboursable: String,
    incrimentationJour: Number
): DataReservation {
    return {
        _id,
        announceId,
        property,
        travellerNumber,
        bookingDate,
        dateCheckIn,
        dateCheckOut,
        requestAge,
        status,
        updatedAt,
        country,
        city,
        services,
        isPaymentPaused,
        remboursable,
        incrimentationJour,
    };
}
