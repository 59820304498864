/* eslint-disable jsx-a11y/anchor-is-valid */

import { makeStyles, createStyles, } from '@material-ui/core';
import React, { useContext } from "react";
import Isalo_National_Park from '../../images/Isalo_National_Park.webp';
import { CountryService } from '../Admin/Countries/services/EquipmentCountry';
import { serverConfig } from '../../utils/config';
import { useQuery } from '../../utils/App';
import { useHistory } from 'react-router-dom';
import { PropertyService } from '../Properties/services/PropertyService';
import { CountryPhoneList } from '../../utils/CountryList';
import { CurrencyContext } from '../../contexts/AppContext';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) =>
    createStyles({
        section: {
            background: "#ffffff",
            height: "auto",
            width: "100%",
            position: "absolute",
            top: 100,
            boxShadow: "0px 6px 4px rgba(0, 0, 0, 0.16)",
            zIndex: 100,
            borderRadius: "0px 0px 5px 5px",
            padding: "3.5rem 1.5rem 2rem"
        },
        mwidth: {
            maxWidth: "75rem",
            margin: " 0 auto"
        },
        gridCol: {
            flexFlow: "row wrap",
            justifyContent: "flex-start",
            display: "flex"
        },
        image: {
            width: "100px",
            height: "75px",
            cursor: "pointer",
        },
        card: {
            width: "18%",
            margin: "0 1.5rem 1.5rem 0",
            [theme.breakpoints.down("xs")]: {
                width: "auto",
                marginRight: 0,
                [theme.breakpoints.down("xs")]: {
                    maxWidth: 150
                },
            },
        },
        contentCard: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        titleContent: {
            color: "#000000",
            fontSize: 16,
            fontWeight: "bold",
            marginBottom: 0,
        },
        ul: {
            display: "list-item",
            listStyle: "none",
            padding: 0
        },
        li: {
            marginBottom: 8,
            cursor: "pointer"
        },
        linkCard: {
            fontSize: 16,
            color: "#000000",
            [theme.breakpoints.down("sm")]: {
                color: "rgba(0,0,0,0.6)",
            },
        }
    })
);

const countryService = new CountryService();
const Env = serverConfig;
const propertyService = new PropertyService();
export function LinkOurDestination(props: { closeAllDialogsOpen: () => void; closeMobileMenu: (val: boolean) => void; toggleMenuClose?: () => void }) {
    const classes = useStyles();
    const [, i18n] = useTranslation();
    const [listCountry, setListCountry] = React.useState([])
    React.useEffect(() => {
        countryService.getAllDestination().then(response => {
            if (response.success) {
                setListCountry(response.data)
            }
        })
    }, [setListCountry])
    const history = useHistory();
    let query = useQuery();
    const [, setPropertyTypeList] = React.useState([])
    const [, setEquipement] = React.useState([])
    const currencyContext = useContext(CurrencyContext);
    const { currency, } = currencyContext;

    React.useEffect(() => {
        propertyService
            .getAllPropertyTypes()
            .then((response) => {
                if (response.success) {
                    setPropertyTypeList(response.data);
                }
            })
            .catch((err) => {
                console.log(err);

            });
        propertyService
            .getAllEquipement()
            .then((response) => {

                if (response.success) {
                    setEquipement(response.data);
                }
            })
            .catch((err) => {
                console.log(err);

            });
    }, [])

    // let tabEqui: any = []
    // equipement && equipement.forEach((aqui: any) => {
    //     tabEqui.push(aqui.name.split(",").join("-"))
    // })
    const clickCountry = (pays: any) => {
        let super_offer = 0

        query.set('search_pays', pays.toString());
        query.set('search_adresse', '');
        query.set('offer', super_offer.toString());
        query.set('currency', currency.toString())
        query.set('currencyBase', CountryPhoneList.find((devi: any) => devi.name?.toLowerCase() === pays?.toLowerCase())?.currencyCode)
        query.set('travellerChildTotal', '0')
        query.set('travellerAdultTotal', '2')
        query.set(`eqt`, [""].toString());
        query.set(`page`, "1");
        //query.set('click', [''].toString());
        query.set('click', [",Maison","Appartement","Hôtel","Chambre","Suite"].toString());
        query.set('serchex', '');
        query.set('prefill', 'true');
        query.set(`rooms`, [1, 2, 3].toString());
        props.closeAllDialogsOpen();
        props.closeMobileMenu(false);
        if (props.toggleMenuClose) props.toggleMenuClose()
        history.push({ pathname: "/search", search: query.toString() });
    }

    const clickVille = (pays: any, ville: any) => {
        let super_offer = 0

        query.set('search_pays', pays.toString());
        query.set('search_adresse', ville.toString());
        query.set('offer', super_offer.toString());
        query.set('currency', currency.toString())
        query.set('currencyBase', CountryPhoneList.find((devi: any) => devi.name?.toLowerCase() === pays?.toLowerCase())?.currencyCode)
        query.set('travellerChildTotal', '0')
        query.set('travellerAdultTotal', '2')
        query.set(`eqt`, [""].toString());
        query.set(`page`, "1");
        //query.set('click', [''].toString());
        query.set('click', [",Maison","Appartement","Hôtel","Chambre","Suite"].toString());
        query.set('serchex', '');
        query.set('prefill', 'true');
        query.set(`rooms`, [1, 2, 3].toString());
        query.set(`luxury`, "false");
        query.set(`perle`, "false");
        query.set(`business`, "false");
        props.closeAllDialogsOpen();
        props.closeMobileMenu(false);
        if (props.toggleMenuClose) props.toggleMenuClose()
        history.push({ pathname: "/search", search: query.toString() });
    }
    return (
        <>
            {
                listCountry && listCountry.map((country: any) =>
                (<div className={classes.card}>
                    <a onClick={() => { clickCountry(country.name) }}>
                        <img className={classes.image}
                            src={country.photo ? `${Env.ApiRoot}/images/destination/${country.photo}` : Isalo_National_Park}
                            alt={country.photo ? country.photo : "Isalo_National_Park"}
                        />
                    </a>
                    <div className={classes.contentCard}>
                        <h3 className={classes.titleContent} >
                            {i18n.language === 'fr' ? country.nomFR : country.nomEN}
                        </h3>
                        <ul className={classes.ul}>
                            {
                                country && country.villes.map((ville: any) => (
                                    <li className={classes.li}>
                                        <a onClick={() => { clickVille(country.name, ville.name) }} className={classes.linkCard}>
                                            {ville.name}
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                ))
            }
        </>
    );
}


