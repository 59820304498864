/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Select } from '@material-ui/core';
import { paymentMethodMobileList } from '../../../utils/App';
import { MenuItem } from '@material-ui/core';

export interface IpForm { recepient_email?: string, recepient_paypal_id?: string, recepient_phone?: string }
export interface ImForm { mobile_operator?: string, mobile_number?: string }
export interface IbForm { bank_name?: string, bank_rib?: string, bank_bic?: string, bank_iban?: string, bank_swift?: string }

export const PypalForm = (props: {
    formData: IpForm,
    onChange: (form: IpForm) => void
}) => {
    const { formData, onChange } = props;
    const emailOK = (formData && formData.recepient_email && formData.recepient_email.length > 2);
    const payidOk = (formData && formData.recepient_paypal_id && formData.recepient_paypal_id.length > 2);
    const numOk = (formData && formData.recepient_phone && formData.recepient_phone.length > 2);
    const { t } = useTranslation();

    return (
        <Stack direction="column" width="100%" spacing={2} >
            <TextField
                onChange={(e) => { onChange({ ...formData, recepient_email: e.target.value }) }}
                id="outlined-error-helper-text-1-pay"
                label="Email"
                helperText={t('label.champ-requis')}
                value={formData.recepient_email}
            />
            <TextField
                onChange={(e) => { onChange({ ...formData, recepient_paypal_id: e.target.value }) }}
                id="outlined-error-helper-text-2-pay"
                label="Pypal Id"
                value={formData.recepient_paypal_id}
            />
            <TextField
                onChange={(e) => { onChange({ ...formData, recepient_phone: e.target.value }) }}
                id="outlined-error-helper-text-3-pay"
                label="Phone"
                helperText={t('label.champ-requis')}
                value={formData.recepient_phone}
            />
            {!(emailOK || payidOk || numOk) && <Stack direction="column" p={2} alignItems="center" color="red">
                {t('label.remplir-les-champs-requis')}
            </Stack>}
        </Stack>
    )
}

export const MobileForms = (props: {
    formData: ImForm,
    onChange: (form: ImForm) => void
}) => {
    const { formData, onChange } = props;
    const numOk = (formData && formData.mobile_number && formData.mobile_number.length > 2);

    const { t } = useTranslation();

    const onChangePaymentOperator = (event: any) => {
        onChange({ ...formData, mobile_operator: event.target.value }) 
    }
    return (
        <Stack direction="column" width="50%" spacing={2} >
            {/* <TextField
                onChange={ }
                id="outlined-error-helper-text-2-pay"
                label={t('label.operateur')}
                value={formData.mobile_operator}
            /> */}
            <Select
                id="mobile_operator_selection"
                name="mobile_operator"
                variant="outlined"
                label={t('label.operateur')}
                fullWidth
                value={formData.mobile_operator}
                onChange={onChangePaymentOperator}
            >
                    {
                    paymentMethodMobileList.map(method=><MenuItem
                        key={method.label+method.value}
                        value={method.value}
                    >
                        {method.label}
                    </MenuItem>)
                }
            </Select>
            <TextField
                onChange={(e) => { onChange({ ...formData, mobile_number: e.target.value }) }}
                error={!numOk}
                id="outlined-error-helper-text-3-pay"
                label="Phone"
                helperText={t('label.champ-requis')}
                value={formData.mobile_number}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            +237
                        </InputAdornment>
                    ),
                }}
            />
        </Stack>
    )
}


export const BanqueForms = (props: {
    formData: IbForm,
    onChange: (form: IbForm) => void
}) => {
    const { formData, onChange } = props;
    const bankNameOk = (formData && formData.bank_name && formData.bank_name.length > 2);
    const bankRibOk = (formData && formData.bank_rib && formData.bank_rib.length > 2);
    const bankBicOk = (formData && formData.bank_bic && formData.bank_bic.length > 2);
    const bankIbanOk = (formData && formData.bank_iban && formData.bank_iban.length > 2);
    const bankSwiftOk = (formData && formData.bank_swift && formData.bank_swift.length > 2);
    const { t } = useTranslation();

    return (
        <Stack direction="column" width="100%" spacing={2} >
            <TextField
                onChange={(e) => { onChange({ ...formData, bank_name: e.target.value }) }}
                error={!bankNameOk}
                id="outlined-error-helper-text-3-pay"
                label={t('label.nom')}
                helperText={t('label.champ-requis')}
                value={formData.bank_name}
            />
            <TextField
                onChange={(e) => { onChange({ ...formData, bank_rib: e.target.value }) }}
                error={!bankRibOk}
                id="outlined-error-helper-text-3-pay"
                label={t('label.rib')}
                helperText={t('label.champ-requis')}
                value={formData.bank_rib}
            />
            <TextField
                onChange={(e) => { onChange({ ...formData, bank_bic: e.target.value }) }}
                error={!bankBicOk}
                id="outlined-error-helper-text-3-pay"
                label="BIC"
                helperText={t('label.champ-requis')}
                value={formData.bank_bic}
            />
            <TextField
                onChange={(e) => { onChange({ ...formData, bank_iban: e.target.value }) }}
                error={!bankIbanOk}
                id="outlined-error-helper-text-3-pay"
                label="IBAN"
                helperText={t('label.champ-requis')}
                value={formData.bank_iban}
            />
            <TextField
                onChange={(e) => { onChange({ ...formData, bank_swift: e.target.value }) }}
                error={!bankSwiftOk}
                id="outlined-error-helper-text-3-pay"
                label="SWIFT"
                helperText={t('label.champ-requis')}
                value={formData.bank_swift}
            />
        </Stack>
    )
}