import * as React from "react";
import Box from "@mui/material/Box";
import { PaymentSetting } from "./PaymentSetting";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

/** MAIN COMPONENT */
export default function UserPaymentAccount() {
    const { t } = useTranslation();
    const classes = useStyle();
    return (
        <>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        className={classes.reservationTittle}
                        variant="h5"
                        gutterBottom
                    >
                        {t("label.payment-account-title")}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t("label.payment-account-subtitle")}
                    </Typography>
                </Grid>
            </Grid>
            <Box>
                <PaymentSetting />
            </Box>
        </>
    );
}

const useStyle = makeStyles((theme: Theme) => ({
    reservationTittle: {
        fontSize: 22,
        fontFamily: "Josefin Sans",
        color: "#890608",
        marginTop: 5,
        marginBottom: 25,
    },
}));
