
import { makeStyles, createStyles, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { GeneratePasswordCodeForm } from './GeneratePasswordCodeForm';
import { ResetPasswordDialog } from './ResetPasswordDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>  createStyles({
    paper: {
        minWidth: 400,
        maxWidth: 500
    },
    title: {
        borderBottom: "1px solid #EBEBEB",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: 'center'
    },
    close: {
        padding: 5,
        position: "absolute",
        right: 20
    },
    subtitle:{
        fontSize: 14,
        display: 'block'
    }
  })
);

export function GeneratePasswordCodeDialog(props:{handleClose: ()=>void; open:boolean}) {
    const classes=useStyles();
    const [code, setCode] = useState<string>('');
    const { t } = useTranslation();
    
  return (
      <> 
       {!code ?   
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            classes={{paper: classes.paper}}
        >
            <DialogTitle classes={{root: classes.title}}>
                <IconButton onClick={props.handleClose} className={classes.close}>
                    <CloseIcon />
                </IconButton>
                {t('SignIn.resetPassword.generate')}
                <span className={classes.subtitle}>
                    {t('SignIn.resetPassword.generate-subtitle')}
                </span>
            </DialogTitle>
            <DialogContent>
              <GeneratePasswordCodeForm setCode={setCode}/>
            </DialogContent>
        </Dialog>: <ResetPasswordDialog handleClose={props.handleClose} open={props.open} email={code}/>}
     </>
  );
}