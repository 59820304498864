import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function PnBDialogue(props: {
    open: boolean,
    setOpen: (state: boolean) => void,
    title: string,
    children?: JSX.Element | any,
    action?: JSX.Element,
    fullWidth?: boolean,
    mw?: Breakpoint
}) {
    const { mw, open, setOpen, title, children, action, fullWidth } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title-pnb"
            open={open}
            fullWidth={fullWidth}
            maxWidth={mw ?? "md"}
        >
            <BootstrapDialogTitle id="customized-dialog-title-pnb" onClose={handleClose}>
                {title}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {action && <DialogActions>
                {action}
            </DialogActions>}
        </BootstrapDialog>
    );
}
