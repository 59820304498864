export const CountryPhoneList:any=[
    {
    "name": "Afghanistan",
    "dial_code": "+93",
    "currencyCode": "AFN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/af.png",
    "nameFR": "Afghanistan",
    "nameEN": "Afghanistan",
    },
    {
    "name": "Aland Islands",
    "dial_code": "+358",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ax.png",
    "nameFR": "Afghanistan",
    "nameEN": "Afghanistan",
    },
    {
    "name": "Albania",
    "dial_code": "+355",
    "currencyCode": "ALL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/al.png",
    "nameFR": "Albania",
    "nameEN": "Albanie",
    },
    {
    "name": "Algeria",
    "dial_code": "+213",
    "currencyCode": "DZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/dz.png",
    "nameFR": "Algérie",
    "nameEN": "Algeria",
    },
    {
    "name": "AmericanSamoa",
    "dial_code": "+1684",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/as.png"
    },
    {
    "name": "Andorra",
    "dial_code": "+376",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ad.png",
    "nameFR": "Andorre",
    "nameEN": "Andorra",
    },
    {
    "name": "Angola",
    "dial_code": "+244",
    "currencyCode": "AOA",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ao.png",
    "nameFR": "Angola",
    "nameEN": "Angola",
    },
    {
    "name": "Anguilla",
    "dial_code": "+1264",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ai.png"
    },
    {
    "name": "Antarctica",
    "dial_code": "+672",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/aq.png"
    },
    {
    "name": "Antigua and Barbuda",
    "dial_code": "+1268",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ag.png"
    },
    {
    "name": "Argentina",
    "dial_code": "+54",
    "currencyCode": "ARS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ar.png"
    },
    {
    "name": "Armenia",
    "dial_code": "+374",
    "currencyCode": "AMD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/am.png",
    "nameFR": "Arménie",
    "nameEN": "Armenia",
    },
    {
    "name": "Aruba",
    "dial_code": "+297",
    "currencyCode": "AWG",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/aw.png"
    },
    {
    "name": "Australia",
    "dial_code": "+61",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/au.png"
    },
    {
    "name": "Austria",
    "dial_code": "+43",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/at.png",
    "nameFR": "Autriche",
    "nameEN": "Austria",
    },
    {
    "name": "Azerbaijan",
    "dial_code": "+994",
    "currencyCode": "AZN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/az.png",
    "nameFR": "Azerbaijan",
    "nameEN": "Azerbaijan",
    },
    {
    "name": "Bahamas",
    "dial_code": "+1242",
    "currencyCode": "BSD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bs.png"
    },
    {
    "name": "Bahrain",
    "dial_code": "+973",
    "currencyCode": "BHD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bh.png"
    },
    {
    "name": "Bangladesh",
    "dial_code": "+880",
    "currencyCode": "BDT",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bd.png"
    },
    {
    "name": "Barbados",
    "dial_code": "+1246",
    "currencyCode": "BBD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bb.png"
    },
    {
    "name": "Belarus",
    "dial_code": "+375",
    "currencyCode": "BYR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/by.png",
    "nameFR": "Biélorussie",
    "nameEN": "Belarus",
    },
    {
    "name": "Belgium",
    "dial_code": "+32",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/be.png",
    "nameFR": "Belgique",
    "nameEN": "Belgium",
    },
    {
    "name": "Belize",
    "dial_code": "+501",
    "currencyCode": "BZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bz.png"
    },
    {
    "name": "Benin",
    "dial_code": "+229",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bj.png",
    "nameFR": "Bénin",
    "nameEN": "Benin",
    },
    {
    "name": "Bermuda",
    "dial_code": "+1441",
    "currencyCode": "BMD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bm.png"
    },
    {
    "name": "Bhutan",
    "dial_code": "+975",
    "currencyCode": "BTN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bt.png"
    },
    {
    "name": "Bolivia, Plurinational State of",
    "dial_code": "+591",
    "currencyCode": "BOB",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bo.png"
    },
    {
    "name": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "currencyCode": "BAM",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ba.png",
    "nameFR": "Bosnie-Herzégovine",
    "nameEN": "Bosnia and Herzegovina",
    },
    {
    "name": "Botswana",
    "dial_code": "+267",
    "currencyCode": "BWP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bw.png",
    "nameFR": "Botswana",
    "nameEN": "Botswana",
    },
    {
    "name": "Brazil",
    "dial_code": "+55",
    "currencyCode": "BRL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/br.png"
    },
    {
    "name": "British Indian Ocean Territory",
    "dial_code": "+246",
    "currencyCode": "BRL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/io.png"
    },
    {
    "name": "Brunei Darussalam",
    "dial_code": "+673",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bn.png"
    },
    {
    "name": "Bulgaria",
    "dial_code": "+359",
    "currencyCode": "BGN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bg.png",
    "nameFR": "Bulgarie",
    "nameEN": "Bulgaria",
    },
    {
    "name": "Burkina Faso",
    "dial_code": "+226",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bf.png",
    "nameFR": "Burkina Faso",
    "nameEN": "Burkina"
    },
    {
    "name": "Burundi",
    "dial_code": "+257",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bi.png",
    "nameFR": "Burundi",
    "nameEN": "Burundi",
    },
    {
    "name": "Cambodia",
    "dial_code": "+855",
    "currencyCode": "KHR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kh.png"
    },
    {
    "name": "Cameroon",
    "dial_code": "+237",
    "currencyCode": "XAF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cm.png",
    "nameFR": "Cameroun",
    "nameEN": "Cameroon",
    },
    {
    "name": "Canada",
    "dial_code": "+1",
    "currencyCode": "CAD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ca.png"
    },
    {
    "name": "Cape Verde",
    "dial_code": "+238",
    "currencyCode": "CVE",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cv.png",
    "nameFR": "Cap vert",
    "nameEN": "Cape Verde",
    },
    {
    "name": "Cayman Islands",
    "dial_code": "+ 345",
    "currencyCode": "KYD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ky.png"
    },
    {
    "name": "Central African Republic",
    "dial_code": "+236",
    "currencyCode": "XAF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cf.png",
    "nameFR": "République centrafricaine",
    "nameEN": "Central African Republic",
    },
    {
    "name": "Chad",
    "dial_code": "+235",
    "currencyCode": "XAF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/td.png",
    "nameFR": "Tchad",
    "nameEN": "Chad",
    },
    {
    "name": "Chile",
    "dial_code": "+56",
    "currencyCode": "CLP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cl.png"
    },
    {
    "name": "China",
    "dial_code": "+86",
    "currencyCode": "CNY",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cn.png"  
    },
    {
    "name": "Christmas Island",
    "dial_code": "+61",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cx.png"   
    },
    {
    "name": "Cocos (Keeling) Islands",
    "dial_code": "+61",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cc.png"   
    },
    {
    "name": "Colombia",
    "dial_code": "+57",
    "currencyCode": "COP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/co.png"   
    },
    {
    "name": "Comoros",
    "dial_code": "+269",
    "currencyCode": "KMF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/km.png" ,
    "nameFR": "Comores",
    "nameEN": "Comoros",  
    },
    {
    "name": "Congo",
    "dial_code": "+242",
    "currencyCode": "XAF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cg.png",
    "nameFR": "Congo",
    "nameEN": "Congo",   
    },
    {
    "name": "Congo, The Democratic Republic of the Congo",
    "dial_code": "+243",
    "currencyCode": "CDF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cd.png" ,
    "nameFR": "République démocratique du Congo",
    "nameEN": "Congo, The Democratic Republic of the Congo",   
    },
    {
    "name": "Cook Islands",
    "dial_code": "+682",
    "currencyCode": "KYD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ck.png"  
    },
    {
    "name": "Costa Rica",
    "dial_code": "+506",
    "currencyCode": "NZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cr.png"   
    },
    {
    "name": "Cote d'Ivoire",
    "dial_code": "+225",
    "currencyCode": "CFA",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ci.png" ,
    "nameFR": "Côte d’Ivoire",
    "nameEN": "Ivory Coast", 
      
    },
    {
    "name": "Croatia",
    "dial_code": "+385",
    "currencyCode": "HRK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/hr.png" ,
    "nameFR": "Croatie",
    "nameEN": "Croatia",  
    },
    {
    "name": "Cuba",
    "dial_code": "+53",
    "currencyCode": "CUP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cu.png"   
    },
    {
    "name": "Cyprus",
    "dial_code": "+357",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cy.png",
    "nameFR": "Chypre",
    "nameEN": "Cyprus",    
    },
    {
    "name": "Czech Republic",
    "dial_code": "+420",
    "currencyCode": "Couronne tchèque",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/cz.png" ,
    "nameFR": "République Tchèque",
    "nameEN": "Czech Republic",  
    },
    {
    "name": "Denmark",
    "dial_code": "+45",
    "currencyCode": "DKK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/dk.png" ,
    "nameFR": "Danemark",
    "nameEN": "Denmark",  
    },







    
    {
    "name": "Djibouti",
    "dial_code": "+253",
    "currencyCode": "DJF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/dj.png",
    "nameFR": "Djibouti",
    "nameEN": "Djibouti",   
    },
    {
    "name": "Dominica",
    "dial_code": "+1767",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/dm.png" 
    },
    {
    "name": "Dominican Republic",
    "dial_code": "+1849",
    "currencyCode": "DOP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/do.png"   
    },
    {
    "name": "Ecuador",
    "dial_code": "+593",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ec.png"  
    },
    {
    "name": "Egypt",
    "dial_code": "+20",
    "currencyCode": "EGP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/eg.png"  
    },
    {
    "name": "El Salvador",
    "dial_code": "+503",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sv.png"  
    },
    {
    "name": "Equatorial Guinea",
    "dial_code": "+240",
    "currencyCode": "XAF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gq.png",
    "nameFR": "Guinée équatoriale",
    "nameEN": "Equatorial Guinea",   
    },
    {
    "name": "Eritrea",
    "dial_code": "+291",
    "currencyCode": "ERN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/er.png",
    "nameFR": "Eritrea",
    "nameEN": "Erythree",    
    },
    {
    "name": "Estonia",
    "dial_code": "+372",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ee.png",
    "nameFR": "Estonie",
    "nameEN": "Estonia",   
    },
    {
    "name": "Ethiopia",
    "dial_code": "+251",
    "currencyCode": "ETB",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/et.png",
    "nameFR": "Ethiopie",
    "nameEN": "Ethiopia",  
    },
    {
    "name": "Falkland Islands (Malvinas)",
    "dial_code": "+500",
    "currencyCode": "FKP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/fk.png"   
    },
    {
    "name": "Faroe Islands",
    "dial_code": "+298",
    "currencyCode": "DKK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/fo.png"   
    },
    {
    "name": "Fiji",
    "dial_code": "+679",
    "currencyCode": "DJF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/fj.png"  
    },
    {
    "name": "Finland",
    "dial_code": "+358",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/fi.png",
    "nameFR": "Finlande",
    "nameEN": "Finland",      
    },
    {
    "name": "France",
    "dial_code": "+33",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/fr.png"  ,
    "nameFR": "France",
    "nameEN": "France",
    },
    {
    "name": "French Guiana",
    "dial_code": "+594",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gf.png"  
    },
    {
    "name": "French Polynesia",
    "dial_code": "+689",
    "currencyCode": "XPF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pf.png"   
    },
    {
    "name": "Gabon",
    "dial_code": "+241",
    "currencyCode": "XAF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ga.png" ,
    "nameFR": "Gabon",
    "nameEN": "Gabon",   
    },
    {
    "name": "Gambia",
    "dial_code": "+220",
    "currencyCode": "GMD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gm.png"  ,
    "nameFR": "Gambia",
    "nameEN": "Gambia",
    },
    {
    "name": "Georgia",
    "dial_code": "+995",
    "currencyCode": "GEL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ge.png" 
    },
    {
    "name": "Germany",
    "dial_code": "+49",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/de.png" ,
    "nameFR": "Allemagne",
    "nameEN": "Germany" 
    },
    {
    "name": "Ghana",
    "dial_code": "+233",
    "currencyCode": "GHS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gh.png" ,
    "nameFR": "Ghana",
    "nameEN": "Ghana",  
    },








    {
    "name": "Gibraltar",
    "dial_code": "+350",
    "currencyCode": "GIP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gi.png"  
    },
    {
    "name": "Greece",
    "dial_code": "+30",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gr.png" ,
    "nameFR": "Grèce",
    "nameEN": "Greece"  
    },
    {
    "name": "Greenland",
    "dial_code": "+299",
    "currencyCode": "DKK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gl.png"   
    },
    {
    "name": "Grenada",
    "dial_code": "+1473",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gd.png"   
    },
    {
    "name": "Guadeloupe",
    "dial_code": "+590",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gp.png" 
    },
    {
    "name": "Guam",
    "dial_code": "+1671",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gu.png"  
    },
    {
    "name": "Guatemala",
    "dial_code": "+502",
    "currencyCode": "GTQ",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gt.png"   
    },
    {
    "name": "Guernsey",
    "dial_code": "+44",
    "currencyCode": "GBP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gg.png"   
    },
    {
    "name": "Guinea",
    "dial_code": "+224",
    "currencyCode": "PGK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gn.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea", 
    },


    {
    "name": "Guinea-Bissau",
    "dial_code": "+245",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gw.png"  ,
    "nameFR": "Guinée-Bissau",
    "nameEN": "Guinea-Bissau", 
    },
    {
    "name": "Guyana",
    "dial_code": "+595",
    "currencyCode": "GYD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gy.png"   ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Haiti",
    "dial_code": "+509",
    "currencyCode": "HTG",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ht.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea", 
    },
    {
    "name": "Holy See (Vatican City State)",
    "dial_code": "+379",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/va.png"   ,
    "nameFR": "Vatican",
    "nameEN": "Vatican City",
    },
    {
    "name": "Honduras",
    "dial_code": "+504",
    "currencyCode": "HNL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/hn.png"  ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Hong Kong",
    "dial_code": "+852",
    "currencyCode": "HKD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/hk.png",
    "nameFR": "Guinée",
    "nameEN": "Guinea",  
    },
    {
    "name": "Hungary",
    "dial_code": "+36",
    "currencyCode": "HUF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/hu.png"   ,
    "nameFR": "Hongrie",
    "nameEN": "Hungary",
    },
    {
    "name": "Iceland",
    "dial_code": "+354",
    "currencyCode": "ISK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/is.png" ,
    "nameFR": "Islande",
    "nameEN": "Iceland", 
    },
    {
    "name": "India",
    "dial_code": "+91",
    "currencyCode": "INR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/in.png"  ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Indonesia",
    "dial_code": "+62",
    "currencyCode": "IDR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/id.png"  ,
    "nameFR": "Guinée",
    "nameEN": "Guinea", 
    },
    {
    "name": "Iran, Islamic Republic of Persian Gulf",
    "dial_code": "+98",
    "currencyCode": "IRR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ir.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Iraq",
    "dial_code": "+964",
    "currencyCode": "IQD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/iq.png",
    "nameFR": "Guinée",
    "nameEN": "Guinea",   
    },
    {
    "name": "Ireland",
    "dial_code": "+353",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ie.png"  ,
    "nameFR": "Irelande",
    "nameEN": "Ireland",
    },
    {
    "name": "Isle of Man",
    "dial_code": "+44",
    "currencyCode": "GBP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/im.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Israel",
    "dial_code": "+972",
    "currencyCode": "ILS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/il.png"  ,
    "nameFR": "Guinée",
    "nameEN": "Guinea", 
    },
    {
    "name": "Italy",
    "dial_code": "+39",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/it.png"  ,
    "nameFR": "Italie",
    "nameEN": "Italy", 
    },
    {
    "name": "Jamaica",
    "dial_code": "+1876",
    "currencyCode": "JMD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/jm.png"   ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Japan",
    "dial_code": "+81",
    "currencyCode": "JPY",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/jp.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea", 
    },
    {
    "name": "Jersey",
    "dial_code": "+44",
    "currencyCode": "GBP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/je.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",  
    },
    {
    "name": "Jordan",
    "dial_code": "+962",
    "currencyCode": "JOD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/jo.png" ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },


    
    {
    "name": "Kazakhstan",
    "dial_code": "+77",
    "currencyCode": "KZT",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kz.png"  ,
    "nameFR": "Guinée",
    "nameEN": "Guinea",
    },
    {
    "name": "Kenya",
    "dial_code": "+254",
    "currencyCode": "KES",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ke.png" ,
    "nameFR": "Kenya",
    "nameEN": "Kenya", 
    },
    {
    "name": "Kiribati",
    "dial_code": "+686",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ki.png"  
    },
    {
    "name": "Korea, Democratic People's Republic of Korea",
    "dial_code": "+850",
    "currencyCode": "KPW",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kp.png"  
    },
    {
    "name": "Korea, Republic of South Korea",
    "dial_code": "+82",
    "currencyCode": "KRW",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kr.png"   
    },
    {
    "name": "Kuwait",
    "dial_code": "+965",
    "currencyCode": "KWD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kw.png"  
    },
    {
    "name": "Kyrgyzstan",
    "dial_code": "+996",
    "currencyCode": "KGS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kg.png"   
    },
    {
    "name": "Laos",
    "dial_code": "+856",
    "currencyCode": "LAK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/la.png"   
    },
    {
    "name": "Latvia",
    "dial_code": "+371",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lv.png" ,
    "nameFR": "Lettonie",
    "nameEN": "Latvia",  
    },
    {
    "name": "Lebanon",
    "dial_code": "+961",
    "currencyCode": "LBP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lb.png"  
    },
    {
    "name": "Lesotho",
    "dial_code": "+266",
    "currencyCode": "LSL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ls.png"  ,
    "nameFR": "Lesotho",
    "nameEN": "Lesotho",
    },
    {
    "name": "Liberia",
    "dial_code": "+231",
    "currencyCode": "LRD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lr.png"  ,
    "nameFR": "Liberia",
    "nameEN": "Liberia", 
    },
    {
    "name": "Libyan Arab Jamahiriya",
    "dial_code": "+218",
    "currencyCode": "LYD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ly.png"  
    },
    {
    "name": "Liechtenstein",
    "dial_code": "+423",
    "currencyCode": "CHF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/li.png"  ,
    "nameFR": "Liechtenstein",
    "nameEN": "Liechtenstein", 
    },
    {
    "name": "Lithuania",
    "dial_code": "+370",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lt.png",
    "nameFR": "Lituanie",
    "nameEN": "Lithuania",   
    },
    {
    "name": "Luxembourg",
    "dial_code": "+352",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lu.png" ,
    "nameFR": "Luxembourg",
    "nameEN": "Luxembourg",  
    },
    {
    "name": "Macao",
    "dial_code": "+853",
    "currencyCode": "MOP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mo.png"  
    },
    {
    "name": "Macedonia",
    "dial_code": "+389",
    "currencyCode": "MKD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mk.png" ,
    "nameFR": "Macédoine",
    "nameEN": "Macedonia", 
    },
    {
    "name": "Madagascar",
    "dial_code": "+261",
    "currencyCode": "MGA",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mg.png"  ,
    "nameFR": "Madagascar",
    "nameEN": "Madagascar",

    },
    {
    "name": "Malawi",
    "dial_code": "+265",
    "currencyCode": "MWK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mw.png"   ,
    "nameFR": "Malawi",
    "nameEN": "Malawi",
    },
    {
    "name": "Malaysia",
    "dial_code": "+60",
    "currencyCode": "MYR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/my.png"  
    },
    {
    "name": "Maldives",
    "dial_code": "+960",
    "currencyCode": "MVR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mv.png"   
    },
    {
    "name": "Mali",
    "dial_code": "+223",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ml.png"  ,
    "nameFR": "Mali",
    "nameEN": "Mali",
    },
    {
    "name": "Malta",
    "dial_code": "+356",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mt.png" ,
    "nameFR": "Malte",
    "nameEN": "Malta",   
    },
    {
    "name": "Marshall Islands",
    "dial_code": "+692",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mh.png"  
    },
    {
    "name": "Martinique",
    "dial_code": "+596",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mq.png"   
    },
    {
    "name": "Mauritania",
    "dial_code": "+222",
    "currencyCode": "MRO",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mr.png" ,
    "nameFR": "Mauritanie",
    "nameEN": "Mauritania",  
    },
    {
    "name": "Mauritius",
    "dial_code": "+230",
    "currencyCode": "MUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mu.png" ,
    "nameFR": "Ile Maurice",
    "nameEN": "Mauritius", 
    },
    {
    "name": "Mayotte",
    "dial_code": "+262",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/yt.png"   
    },
    {
    "name": "Mexico",
    "dial_code": "+52",
    "currencyCode": "MXN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mx.png"   
    },
    {
    "name": "Micronesia, Federated States of Micronesia",
    "dial_code": "+691",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/fm.png"   
    },
    {
    "name": "Moldova",
    "dial_code": "+373",
    "currencyCode": "MDL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/md.png",
    "nameFR": "Moldavie",
    "nameEN": "Moldova",  
    },
    {
    "name": "Monaco",
    "dial_code": "+377",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mc.png"  ,
    "nameFR": "Monaco",
    "nameEN": "Monaco", 
    },
    {
    "name": "Mongolia",
    "dial_code": "+976",
    "currencyCode": "MNT",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mn.png"   
    },
    {
    "name": "Montenegro",
    "dial_code": "+382",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/me.png",
    "nameFR": "Montenegro",
    "nameEN": "Montenegro",  
    },
    {
    "name": "Montserrat",
    "dial_code": "+1664",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ms.png"  
    },
    {
    "name": "Morocco",
    "dial_code": "+212",
    "currencyCode": "MAD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ma.png" ,
    "nameFR": "Maroc",
    "nameEN": "Morocco", 
    },
    {
    "name": "Mozambique",
    "dial_code": "+258",
    "currencyCode": "MZN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mz.png"  ,
    "nameFR": "Mozambique",
    "nameEN": "Mozambique", 
    },
    {
    "name": "Myanmar",
    "dial_code": "+95",
    "currencyCode": "MMK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mm.png"  
    },
    {
    "name": "Namibia",
    "dial_code": "+264",
    "currencyCode": "NAD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/na.png"  ,
    "nameFR": "Namibie",
    "nameEN": "Namibia", 
    },
    {
    "name": "Nauru",
    "dial_code": "+674",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/nr.png"  
    },
    {
    "name": "Nepal",
    "dial_code": "+977",
    "currencyCode": "NPR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/np.png"   
    },
    {
    "name": "Netherlands",
    "dial_code": "+31",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/nl.png",
    "nameFR": "Pays-Bas",
    "nameEN": "Netherlands",  
    },
    {
    "name": "New Caledonia",
    "dial_code": "+687",
    "currencyCode": "XPF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/nc.png"  
    },
    {
    "name": "New Zealand",
    "dial_code": "+64",
    "currencyCode": "NZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/nz.png"   
    },
    {
    "name": "Nicaragua",
    "dial_code": "+505",
    "currencyCode": "NIO",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ni.png"   
    },
    {
    "name": "Niger",
    "dial_code": "+227",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ne.png"  ,
    "nameFR": "Niger",
    "nameEN": "Niger",
    },
    {
    "name": "Nigeria",
    "dial_code": "+234",
    "currencyCode": "NGN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ng.png",
    "nameFR": "Nigeria",
    "nameEN": "Nigeria", 
    },
    {
    "name": "Niue",
    "dial_code": "+683",
    "currencyCode": "NZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/nu.png"   
    },
    {
    "name": "Norfolk Island",
    "dial_code": "+672",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/nf.png" 
    },
    {
    "name": "Northern Mariana Islands",
    "dial_code": "+1670",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mp.png"   
    },
    {
    "name": "Norway",
    "dial_code": "+47",
    "currencyCode": "NOK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/no.png" ,
    "nameFR": "Norvège",
    "nameEN": "Norway",   
    },
    {
    "name": "Oman",
    "dial_code": "+968",
    "currencyCode": "OMR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pm.png"   
    },
    {
    "name": "Pakistan",
    "dial_code": "+92",
    "currencyCode": "PKR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pk.png"  
    },
    {
    "name": "Palau",
    "dial_code": "+680",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pw.png"   
    },
    {
    "name": "Palestinian Territory, Occupied",
    "dial_code": "+970",
    "currencyCode": "ILS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ps.png"  
    },
    {
    "name": "Panama",
    "dial_code": "+507",
    "currencyCode": "PAB",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pa.png"  
    },
    {
    "name": "Papua New Guinea",
    "dial_code": "+675",
    "currencyCode": "PGK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pg.png" 
    },
    {
    "name": "Paraguay",
    "dial_code": "+595",
    "currencyCode": "PYG",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/py.png"  
    },
    {
    "name": "Peru",
    "dial_code": "+51",
    "currencyCode": "PEN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pe.png"   
    },
    {
    "name": "Philippines",
    "dial_code": "+63",
    "currencyCode": "PHP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ph.png"   
    },
    {
    "name": "Pitcairn",
    "dial_code": "+872",
    "currencyCode": "NZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pn.png"   
    },
    {
    "name": "Poland",
    "dial_code": "+48",
    "currencyCode": "PLN",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pl.png"  ,
    "nameFR": "Pologne",
    "nameEN": "Poland", 
    },
    {
    "name": "Portugal",
    "dial_code": "+351",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pt.png",
    "nameFR": "Portugal",
    "nameEN": "Portugal",  
    },
    {
    "name": "Puerto Rico",
    "dial_code": "+1939",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pr.png"  
    },
    {
    "name": "Qatar",
    "dial_code": "+974",
    "currencyCode": "QAR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/qa.png"  
    },
    {
    "name": "Romania",
    "dial_code": "+40",
    "currencyCode": "RON",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ro.png",
    "nameFR": "Romanie",
    "nameEN": "Romania",   
    },
    {
    "name": "Russia",
    "dial_code": "+7",
    "currencyCode": "RUB",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ru.png"   
    },
    {
    "name": "Rwanda",
    "dial_code": "+250",
    "currencyCode": "RWF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/rw.png" ,
    "nameFR": "Rwanda",
    "nameEN": "Rwanda",  
    },
    {
    "name": "Reunion",
    "dial_code": "+262",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/re.png"  
    },
    {
    "name": "Saint Barthelemy",
    "dial_code": "+590",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/bl.png"  
    },
    {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "dial_code": "+290",
    "currencyCode": "SHP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sh.png"   
    },
    {
    "name": "Saint Kitts and Nevis",
    "dial_code": "+1869",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/kn.png"   
    },
    {
    "name": "Saint Lucia",
    "dial_code": "+1758",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lc.png"   
    },
    {
    "name": "Saint Martin",
    "dial_code": "+590",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/mf.png"   
    },
    {
    "name": "Saint Pierre and Miquelon",
    "dial_code": "+508",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/pm.png"  
    },
    {
    "name": "Saint Vincent and the Grenadines",
    "dial_code": "+1784",
    "currencyCode": "XCD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/vc.png"  
    },
    {
    "name": "Samoa",
    "dial_code": "+685",
    "currencyCode": "WST",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ws.png"  
    },
    {
    "name": "San Marino",
    "dial_code": "+378",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sm.png",
    "nameFR": "Saint-Marin",
    "nameEN": "San Marino",     
    },
    {
    "name": "Sao Tome and Principe",
    "dial_code": "+239",
    "currencyCode": "STD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/st.png",
    "nameFR": "Sao Tomé-et-Principe",
    "nameEN": "Sao Tome and Principe",   
    },
    {
    "name": "Saudi Arabia",
    "dial_code": "+966",
    "currencyCode": "SAR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sa.png"  ,
    "nameFR": "Sao Tomé-et-Principe",
    "nameEN": "Sao Tome and Principe",
    },
    {
    "name": "Senegal",
    "dial_code": "+221",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sn.png",
    "nameFR": "Senegal",
    "nameEN": "Sénégal",   
    },
    {
    "name": "Serbia",
    "dial_code": "+381",
    "currencyCode": "RSD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/rs.png",
    "nameFR": "Serbie",
    "nameEN": "Serbia",    
    },
    {
    "name": "Seychelles",
    "dial_code": "+248",
    "currencyCode": "SCR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sc.png" ,
    "nameFR": "Seychelles",
    "nameEN": "Seychelles",  
    },
    {
    "name": "Sierra Leone",
    "dial_code": "+232",
    "currencyCode": "SLL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sl.png" ,
    "nameFR": "Sierra Leone",
    "nameEN": "Sierra Leone" 
    },
    {
    "name": "Singapore",
    "dial_code": "+65",
    "currencyCode": "SGD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sg.png"   
    },
    {
    "name": "Slovakia",
    "dial_code": "+421",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sk.png",
    "nameFR": "Slovakie",
    "nameEN": "Slovakia",   
    },
    {
    "name": "Slovenia",
    "dial_code": "+386",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/si.png" ,
    "nameFR": "Slovenie",
    "nameEN": "Slovenia" 
    },
    {
    "name": "Solomon Islands",
    "dial_code": "+677",
    "currencyCode": "SBD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sb.png"  
    },
    {
    "name": "Somalia",
    "dial_code": "+252",
    "currencyCode": "SOS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/so.png" ,
    "nameFR": "Somalie",
    "nameEN": "Somalia"   
    },
    {
    "name": "South Africa",
    "dial_code": "+27",
    "currencyCode": "ZAR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/za.png"  ,
    "nameFR": "Afrique du Sud",
    "nameEN": "South Africa" 
    },
    {
    "name": "South Sudan",
    "dial_code": "+211",
    "currencyCode": "SSP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ss.png" ,
    "nameFR": "Soudan du Sud",
    "nameEN": "South Sudan"   
    },
    {
    "name": "South Georgia and the South Sandwich Islands",
    "dial_code": "+500",
    "currencyCode": "GBP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gs.png" ,
    "nameFR": "Georgie",
    "nameEN": "Georgia"  
    },
    {
    "name": "Spain",
    "dial_code": "+34",
    "currencyCode": "EUR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/es.png",
    "nameFR": "Espagne",
    "nameEN": "Spain",      
    },
    {
    "name": "Sri Lanka",
    "dial_code": "+94",
    "currencyCode": "LKR",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/lk.png"   
    },
    {
    "name": "Sudan",
    "dial_code": "+249",
    "currencyCode": "SSP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sd.png" ,
    "nameFR": "Soudan",
    "nameEN": "Sudan"  
    },
    {
    "name": "Suriname",
    "dial_code": "+597",
    "currencyCode": "SRD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sr.png"   
    },
    {
    "name": "Svalbard and Jan Mayen",
    "dial_code": "+47",
    "currencyCode": "NOK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sj.png"   
    },
    {
    "name": "Swaziland",
    "dial_code": "+268",
    "currencyCode": "SZL",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sz.png" ,
    "nameFR": "Swaziland",
    "nameEN": "Swaziland"   
    },
    {
    "name": "Sweden",
    "dial_code": "+46",
    "currencyCode": "SEK",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/se.png"  ,
    "nameFR": "Suède",
    "nameEN": "Sweden", 
    },
    {
    "name": "Switzerland",
    "dial_code": "+41",
    "currencyCode": "CHF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ch.png" ,
    "nameFR": "Suisse",
    "nameEN": "Switzerland",  
    },
    {
    "name": "Syrian Arab Republic",
    "dial_code": "+963",
    "currencyCode": "LS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/sy.png"   
    },
    {
    "name": "Taiwan",
    "dial_code": "+886",
    "currencyCode": "TWD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tw.png"  
    },
    {
    "name": "Tajikistan",
    "dial_code": "+992",
    "currencyCode": "TJS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tj.png"   
    },
    {
    "name": "Tanzania, United Republic of Tanzania",
    "dial_code": "+255",
    "currencyCode": "TZS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tz.png" ,
    "nameFR": "Tanzanie",
    "nameEN": "Tanzania"  
    },
    {
    "name": "Thailand",
    "dial_code": "+66",
    "currencyCode": "THB",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/th.png"  
    },
    {
    "name": "Timor-Leste",
    "dial_code": "+670",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tl.png"  
    },
    {
    "name": "Togo",
    "dial_code": "+228",
    "currencyCode": "XOF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tg.png" ,
    "nameFR": "Togo",
    "nameEN": "Togo"  
    },
    {
    "name": "Tokelau",
    "dial_code": "+690",
    "currencyCode": "NZD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tk.png"   
    },
    {
    "name": "Tonga",
    "dial_code": "+676",
    "currencyCode": "TOP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/to.png"  
    },
    {
    "name": "Trinidad and Tobago",
    "dial_code": "+1868",
    "currencyCode": "TTD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tt.png"   
    },
    {
    "name": "Tunisia",
    "dial_code": "+216",
    "currencyCode": "TND",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tn.png",
    "nameFR": "Tunisie",
    "nameEN": "Tunisia"   
    },
    {
    "name": "Turkey",
    "dial_code": "+90",
    "currencyCode": "TRY",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tr.png"   
    },
    {
    "name": "Turkmenistan",
    "dial_code": "+993",
    "currencyCode": "TMT",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tm.png" 
    },
    {
    "name": "Turks and Caicos Islands",
    "dial_code": "+1649",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tc.png"  
    },
    {
    "name": "Tuvalu",
    "dial_code": "+688",
    "currencyCode": "AUD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/tv.png"  
    },
    {
    "name": "Uganda",
    "dial_code": "+256",
    "currencyCode": "UGX",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ug.png"  ,
    "nameFR": "Ouganda",
    "nameEN": "Uganda" 
    },
    {
    "name": "Ukraine",
    "dial_code": "+380",
    "currencyCode": "UAH",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ua.png",
    "nameFR": "Ukraine",
    "nameEN": "Ukraine",  
    },
    {
    "name": "United Arab Emirates",
    "dial_code": "+971",
    "currencyCode": "AED",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ae.png"   
    },
    {
    "name": "United Kingdom",
    "dial_code": "+44",
    "currencyCode": "GBP",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/gb.png"  ,
    "nameFR": "Royaume-Uni",
    "nameEN": "United Kingdom", 
    },
    {
    "name": "United States",
    "dial_code": "+1",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/us.png"  
    },
    {
    "name": "Uruguay",
    "dial_code": "+598",
    "currencyCode": "UYU",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/uy.png"   
    },
    {
    "name": "Uzbekistan",
    "dial_code": "+998",
    "currencyCode": "UZS",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/uz.png"   
    },
    {
    "name": "Vanuatu",
    "dial_code": "+678",
    "currencyCode": "VUV",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/vu.png"  
    },
    {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "dial_code": "+58",
    "currencyCode": "VEF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ve.png"   
    },
    {
    "name": "Vietnam",
    "dial_code": "+84",
    "currencyCode": "VND",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/vn.png"  
    },
    {
    "name": "Virgin Islands, British",
    "dial_code": "+1284",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/vg.png"  
    },
    {
    "name": "Virgin Islands, U.S.",
    "dial_code": "+1340",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/vi.png"  
    },
    {
    "name": "Wallis and Futuna",
    "dial_code": "+681",
    "currencyCode": "XPF",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/wf.png"   
    },
    {
    "name": "Yemen",
    "dial_code": "+967",
    "currencyCode": "YER",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/ye.png" 
    },
    {
    "name": "Zambia",
    "dial_code": "+260",
    "currencyCode": "ZMW",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/zm.png"  ,
    "nameFR": "Zambie",
    "nameEN": "Zambia"  
    },
    {
    "name": "Zimbabwe",
    "dial_code": "+263",
    "currencyCode": "USD",
    "code": "https://www.drapeauxdespays.fr/data/flags/w580/zw.png" ,
    "nameFR": "Zimbabwe",
    "nameEN": "Zimbabwe" 
    }
    ]