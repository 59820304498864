import Api from "../https/Api";

const http = new Api();

export const AddToFav = (uid: string, fid: string): Promise<any> => http
    .post('favorites/add', { uid, fid })
    .then((response) => response.data)

export const RemFromFav = (uid: string, fid: string): Promise<any> => http
    .get(`favorites/remove/${uid}/${fid}`)
    .then((response) => response.data)

export const RemFromFavMult = (uid: string, fids: string[]): Promise<any> => http
    .post(`favorites/remove-multiple`, { uid, fids })
    .then((response) => response.data)

export const GetFavList = (uid: string) => http
    .get('favorites/' + uid)
    .then((response) => response.data)

export function getAnnounces() {
    throw new Error('Function not implemented.');
}
