import {
    AppBar,
    Button,
    createStyles,
    Drawer,
    Hidden,
    makeStyles,
    Toolbar,
    useMediaQuery,
    useTheme,
    useScrollTrigger,
    Slide,
    IconButton,
    Theme
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Menu as MenuIcon } from "@material-ui/icons";
import africa_pnb_logo2 from "../../images/africa_pnb_logo2.png";
import rockybgfooter from "../../images/rockybgfooter.webp";
import MenuUser from "./MenuUser";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MenuItemsMobile } from "./MenuItemsMobile";
import { LangSelection } from "./LangSelection";
import { LinkOurDestination } from "./LinkOurDestination";
import { LinkTypeAccomodation } from "./LinkTypeAccomodation";
import { LinkExeptionalDeal } from "./LinkExeptionalDeal";
import React from "react";
import { a, useTrail } from "@react-spring/web";
import { useHistory } from "react-router";
import { UserContext } from "../../contexts/AppContext";
import { CurrencySelection } from "./CurrencySelection";
import { SortArray, useQuery } from "../../utils/App";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import PushPinIcon from '@mui/icons-material/PushPin';
import CloseIcon from '@mui/icons-material/Close';
import { IAnnounce } from "../../utils/AnnounceInterfaces";
import { HomeService } from "../Home/services/HomeService";
import { CountryService } from "../Admin/Countries/services/EquipmentCountry";
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { LinkExeptionalDealMobile } from "./LinkExeptionalDealMobile";

const drawerWidth = 257;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            backgroundColor: "rgba(255,255,255,0.99)",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
            height: 100,
            zIndex: 1000,
            borderRadius: "0px 0px 5px 5px",
        },
        scrollStyle: {
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
            height: 100,
            zIndex: 1000,
            borderRadius: "0px 0px 5px 5px",
        },
        header: {},
        appBarMobile: {
            background: "white",
            height: 75,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        toolbar: {
            maxWidth: 1400,
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        menuButton: {
            color: "white",
            border: "1px solid  #890608",
            borderRadius: 100,
            width: 100,
            height: 50,
            display: "flex",
            justifyContent: "space-around",
        },
        links: {
            display: "flex",
            alignItems: "center",
            margin: "0 30px",
        },
        displayMenu: {
            display: "none"
        },
        link: {
            color: "#000000",
            textDecoration: "none",
            marginRight: 20,
            fontSize: 16,
            textTransform: "capitalize",
            position: "relative",
            cursor: "pointer",
            "&:hover": {
                color: "#890608",
                transition: "all 0.3s ease-in-out ",
                "&::after": {
                    content: "close-quote",
                    position: "absolute",
                    left: 0,
                    top: 30,
                    display: "block",
                    width: "100%",
                    height: "0.25rem",
                    borderRadius: "0.125rem",
                    backgroundColor: "#890608",
                },
            },
        },
        title: {
            flexGrow: 1,
        },

        navLinks: {
            marginLeft: 'auto',
        },
        navList: {
            display: 'flex',
            listStyleType: 'none',
            margin: 0,
            padding: 0,
        },
        navItem: {
            marginRight: theme.spacing(2),
        },

        
        logo: {
            width: "auto",
            height: 100,
        },
        logoMobile: {
            width: "auto",
            height: 75,
        },
        paper: {
            minWidth: 200,
            maxWidth: 450,
            borderRadius: 10,
        },
        navRight: {
            display: "flex",
            alignItems: "center",
        },
        titleDialog: {
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #EBEBEB",
            alignItems: "center",
            position: "relative",
        },
        close: {
            position: "absolute",
            right: 20,
            top: 15,
            padding: 5,
        },
        content: {
            padding: "20px 40px",
        },
        divider: {
            minHeight: 28,
            backgroundColor: "#EBEBF2",
            padding: "20px 40px",
        },

        buttons: {
            display: "flex",
            alignItems: "center",
        },
        rightButtons: {
            flexBasis: 0,
            paddingRight: 16,
            justifyContent: "flex-end",
        },
        rightButtonsClosed: {
            borderTopRightRadius: 10,
        },

        drawerPaper: {
            backgroundColor: "white",
            width: "100%",
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            padding: 30,
            maxHeight: "80%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
            overflowX: "hidden",
            overflowY: "auto",
            zIndex: 1401
        },
        drawerPaperDeal: {
            backgroundColor: "white",
            width: "100%",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            padding: 0,
            maxHeight: "60%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
            overflowX: "hidden",
            overflowY: "auto",
            zIndex: 1401
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.short,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.short,
            }),
            overflowX: "hidden",
            width: 50,
        },
        host: {
            border: "1px solid #890608",
            boxSizing: "border-box",
            borderRadius: 150,
            textTransform: "capitalize",
            marginRight: 11,
            paddingRight: 30,
            paddingLeft: 30,
            fontSize: 16,
        },

        margin: {
            margin: theme.spacing(1),
        },
        section: {
            backgroundImage: `url(${rockybgfooter})`,
            height: "auto",
            width: "100%",
            position: "absolute",
            top: 100,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
            zIndex: 100,
            borderRadius: "0px 0px 5px 5px",
            padding: "3.5rem 1.5rem 2rem",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        mwidth: {
            maxWidth: "75rem",
            margin: " 0 auto",
        },
        gridCol: {
            flexFlow: "row wrap",
            justifyContent: "flex-start",
            display: "flex",
        },
        trail: {
            flexFlow: "row wrap",
            justifyContent: "flex-start",
            display: "flex",
        },
        card: {
            width: "18%",
            margin: "0 1.5rem 1.5rem 0",
            animation:
                "vertical-content-in-top .3s cubic-bezier(.34,1.61,.7,1) forwards",
            "&:first-child": {
                animationDelay: ".05s",
            },
            "&:nth-child(2)": {
                animationDelay: ".1s",
            },
            "&:nth-child(3)": {
                animationDelay: ".15s",
            },
            "&:nth-child(4)": {
                animationDelay: ".2s",
            },
            "&:nth-child(5)": {
                animationDelay: ".25s",
                marginRight: 0,
            },
        },
        contentCard: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        titleContent: {
            color: "#000000",
            fontSize: 16,
            fontWeight: "bold",
            marginBottom: 0,
        },
        ul: {
            display: "list-item",
            listStyle: "none",
            padding: 0,
        },
        li: {
            marginBottom: 8,
        },
        linkCard: {
            fontSize: 16,
            fontWeight: 300,
            color: "#000000",
        },
        w100: {
            width: "100%",
        },
        mobile: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%"
        },
        searchHide: {
            display: "none"
        },
        searchButton: {
            border: "1px solid #DDDDDD !important",
            boxShadow: "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
            borderRadius: 150,
            padding: "9px 14px",
            color: "#222222 !important",
            textTransform: "capitalize",
            display: "flex"
        },
        searchSpan: {
            marginLeft: 20
        },
        searchIcon: {
            padding: 5,
            borderRadius: "50%",
            background: "#890608",
            display: "flex",
            marginLeft: 20
        },
        searchNormal: {
            border: "1px solid #890608",
            boxShadow: "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
            borderRadius: 150,
            color: "#222222 !important",
            textTransform: "capitalize",
            display: "flex",
            padding: "9px 14px"
        },
        navigation: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: "none",
            top: "auto",
            bottom: 0,
        },
        selectedMobile: {
            color: "#890608 !important"
        },
        "@global": {
            ".css-imwso6-MuiBottomNavigationAction-label": {
                fontFamily: "Josefin Sans !important"
            }
        }
    })
);
const homeService = new HomeService();
const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
    const classes = useStyles();
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 10, tension: 500, friction: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : "100%",
        height: open ? "auto" : 0,
        from: { opacity: 0, x: 20, height: 0 },
    });
    return (
        <div className={classes.w100}>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div className={classes.trail} style={{ height }}>
                        {items[index]}
                    </a.div>
                </a.div>
            ))}
        </div>
    );
};


const countryService = new CountryService();
export function Navbar(props: {
    handleClickOpenLoginDialog?: () => void;
    handleClickOpenRegister?: () => void;
    setDeal: any;
    deal: boolean;
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const userContext = React.useContext(UserContext);
    const { user } = userContext;

    const [destination, setDestination] = React.useState(false);
    const [type, setType] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [value, setValue] = React.useState('home');
    const [menuMobile, setMenuMobile] = React.useState(false);
    const [, setAnnounces] = React.useState<[IAnnounce]>();
    const [paysList, setPaysList] = React.useState<any[]>()
    const [openDrawerDeal, setOpenDrawerDeal] = React.useState(false)

    React.useEffect(() => {
        homeService
            .getListAnnounces()
            .then((response) => {
                if (response.success) {
                    setAnnounces(response.data);
                }
            })
            .catch(() => {

            });
    }, []);

    const handleChangeMobile = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const [, setClickedOutside] = React.useState(false);
    const myRef = React.useRef<HTMLDivElement>(null);

    const handleChangeActive = () => {
        if (window.scrollY >= 50) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleChangeActive);
    }, []);


    let query = useQuery();

    const handleClickOutside = (e: any) => {
        if (!myRef.current?.contains(e.target)) {
            closeAllDialogsOpen();
        }
    };
    const handleClickInside = () => {
        setClickedOutside(false);
    };

    const theme = useTheme();
    const bp = useMediaQuery(theme.breakpoints.down(1025));

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    });



    const closeAllDialogsOpen = () => {
        setDestination(false);
        setType(false);
    };
    const handleChange = () => {
        props.setDeal(false)
        setDestination((prev) => !prev);
    };
    const handleChangeType = () => {
        props.setDeal(false)
        setType((prev) => !prev);
    };
    const handleChangeDeal = () => {
        props.setDeal((prev: any) => !prev);
    };
    const handleChangeCloseDeal = () => {
        props.setDeal(false)
    }
    const handleChangeCloseDealBar = () => {
        if (props.deal) {
            props.setDeal(false)
        }
    }
    const DrawerDeal = () => {
        setOpenDrawerDeal(true)
    }
    const CloseDrawerDeal = () => {
        setOpenDrawerDeal(false)
        setDestination(false);
        setType(false);
    }

    const handleMenuClick = () => {
        setOpen(false);
    };

    const handlePublishAnnounce = () => {
        if (user._id !== "") {
            history.push("/category")
        } else {
            query.set('redirectPage', 'category');
            history.push({ search: query.toString() });
            if (props.handleClickOpenLoginDialog) props.handleClickOpenLoginDialog();
        }
    }

    const handleClickMobile = () => {
        history.push("/")
    }
    React.useEffect(() => {
        countryService.getAllDestination().then(response => {
            if (response.success) {
                if (response.data !== undefined) {
                    setPaysList(response.data.sort(SortArray))
                }

            }
        })
    }, [setPaysList])

    const trigger = useScrollTrigger();
    React.useEffect(() => {
        if (trigger) setOpen(false);
        return () => { };
    }, [trigger]);

    const toggleMenuMobile = () => {
        setMenuMobile(true)
    }
    const toggleMenuClose = () => {
        setMenuMobile(false)
    }

    return (
        <>
            {!bp ? (
                <Hidden xsDown>
                    <AppBar position="fixed" className={clsx(classes.appBar, { [classes.scrollStyle]: active })} onScroll={handleChangeActive} >
                        <div className={classes.header} onClick={handleChangeCloseDealBar}>
                            <Toolbar className={classes.toolbar} style={{ padding: 0 }} >
                                <Link to="/" style={{ margin: 0 }} onClick={handleChangeCloseDeal}>
                                    <img
                                        src={africa_pnb_logo2}
                                        className={classes.logo}
                                        alt="AfriqPnB Logo"
                                    />
                                </Link>
                                <nav className={classes.navLinks}>
                                    <ul className={classes.navList}>
                                        {/*<li className={classes.navItem}>
                                            <Link to="/" className={classes.link} onClick={handleChangeCloseDeal}>
                                                {t("label.accueil")}
                                            </Link>
                                        </li>*/}
                                        <li className={classes.navItem}>
                                            <span className={classes.link} onClick={handleChange}>
                                                {t("label.nos-destinations")}
                                            </span>
                                        </li>
                                        <li className={classes.navItem}>
                                            <span className={classes.link} onClick={handleChangeType}>
                                                {t("label.types-d-hebergements")}
                                            </span>
                                        </li>
                                        <li className={classes.navItem}>
                                            <span className={classes.link} onClick={handleChangeDeal}>
                                                {t("label.offres-exceptionnelles")}
                                            </span>
                                        </li>
                                        <li className={classes.navItem}>
                                            <Link to="/blog" className={classes.link} onClick={handleChangeCloseDeal}>
                                                {t("label.blog")}
                                            </Link>
                                        </li>
                                        <li className={classes.navItem}>
                                            <Link to="/guide-touristique" className={classes.link} onClick={handleChangeCloseDeal}>
                                                {t("label.guide-touristique")}
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                                {/*<div className={classes.links}>
                                    <span className={classes.link} onClick={handleChange} >
                                        {t("label.nos-destinations")}
                                    </span>

                                    <span
                                        className={classes.link}
                                        onClick={handleChangeType}
                                    >
                                        {t("label.types-d-hebergements")}
                                    </span>
                                    <span
                                        className={classes.link}
                                        onClick={handleChangeDeal}
                                    >
                                        {t("label.offres-exceptionnelles")}
                                    </span>
                                    <Link to="/blog" className={classes.link} onClick={handleChangeCloseDeal}>
                                        {t("label.blog")}
                                    </Link>
                                    <Link to="/guide-touristique" className={classes.link} onClick={handleChangeCloseDeal}>
                                        {t("label.guide-touristique")}
                                    </Link>
                                </div> */}

                                <div className={classes.navRight}>
                                    <Button className={classes.searchButton} onClick={handlePublishAnnounce}>
                                        {t("label.publier-une-annonce")}                                        
                                    </Button>

                                    <CurrencySelection />

                                    <LangSelection />

                                    <MenuUser
                                        handleClickOpenLoginDialog={
                                            props.handleClickOpenLoginDialog
                                        }
                                    />
                                </div>
                            </Toolbar>
                        </div>
                        {destination && (
                            <div ref={myRef} onClick={handleClickInside}>
                                <section className={classes.section}>
                                    <div className={classes.mwidth}>
                                        <div className={classes.gridCol}>
                                            <Trail open={destination}>
                                                <LinkOurDestination closeAllDialogsOpen={closeAllDialogsOpen}
                                                    closeMobileMenu={setOpen} />
                                            </Trail>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                        {type && (
                            <div ref={myRef} onClick={handleClickInside}>
                                <section className={classes.section}>
                                    <div className={classes.mwidth}>
                                        <div className={classes.gridCol}>
                                            <Trail open={type}>
                                                <LinkTypeAccomodation closeAllDialogsOpen={closeAllDialogsOpen} />
                                            </Trail>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                        {props.deal && (
                            <div ref={myRef} onClick={closeAllDialogsOpen}>
                                <LinkExeptionalDeal closeAllDialogsOpen={closeAllDialogsOpen} setDeal={props.setDeal} paysList={paysList} />
                            </div>
                        )}
                    </AppBar>
                </Hidden>
            ) : (
                <>
                    <AppBar position="fixed" className={classes.appBarMobile}>
                        <Toolbar style={{ paddingLeft: 6, paddingRight: 6 }}>
                            <div className={classes.mobile}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <IconButton
                                        edge="start"
                                        aria-label="open drawer"
                                        onClick={toggleMenuMobile}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Link to="/">
                                        <img
                                            src={africa_pnb_logo2}
                                            className={classes.logoMobile}
                                            alt="AfriqPnB Logo"
                                        />
                                    </Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <CurrencySelection />
                                    <LangSelection />
                                    <MenuUser handleClickOpenLoginDialog={props.handleClickOpenLoginDialog} />
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {/* </Slide> */}
                    <Slide in={!trigger} direction="up">
                        <BottomNavigation classes={{ root: classes.navigation }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1300 }} showLabels value={value} onChange={handleChangeMobile}>
                            <BottomNavigationAction
                                label={t('label.accueil')}
                                value="home"
                                icon={<HomeIcon />}
                                onClick={handleClickMobile}
                                classes={{ selected: classes.selectedMobile }}
                            />
                            <BottomNavigationAction
                                label={t('label.offres-exceptionnelles')}
                                value="offre"
                                icon={open ? <CloseIcon /> : <StarHalfIcon />}
                                onClick={DrawerDeal}
                                // onClick={toggleDrawer(true)}
                                classes={{ selected: classes.selectedMobile }}
                            />
                            <BottomNavigationAction
                                label={t('label.publier-une-annonce')}
                                value="annonce"
                                icon={<PushPinIcon />}
                                classes={{ selected: classes.selectedMobile }}
                                onClick={handlePublishAnnounce}
                            />

                        </BottomNavigation>
                    </Slide>
                    <Drawer
                        anchor="top"
                        open={menuMobile}
                        onClose={toggleMenuClose}
                        classes={{ paper: clsx(classes.drawerPaper) }}
                    >
                        <MenuItemsMobile
                            onMenuClick={handleMenuClick}
                            closeAllDialogsOpen={closeAllDialogsOpen}
                            closeMobileMenu={setOpen}
                            setDeal={props.setDeal}
                            toggleMenuClose={toggleMenuClose}
                            paysList={paysList}
                        />
                    </Drawer>
                    <Drawer
                        anchor="bottom"
                        open={openDrawerDeal}
                        classes={{ paper: classes.drawerPaperDeal }}
                        onClose={CloseDrawerDeal}
                    >
                        <div ref={myRef} onClick={closeAllDialogsOpen}>
                            <LinkExeptionalDealMobile setOpenDrawerDeal={() => setOpenDrawerDeal(false)} closeAllDialogsOpen={closeAllDialogsOpen} setDeal={props.setDeal} paysList={paysList} />
                        </div>
                    </Drawer>
                </>
            )}
        </>
    );
}