import Api from "../../../../https/Api";
import i18n from "../../../../i18n/config";
export interface IUserRegister {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    birthDate: Date | string;
    phone: string;
    role: string;
    gender: string;
    avatar: any | null;
    country: string;
    numberPhone:string;
    numberPhoneUrgent:string;
    countryUrgent:string;
    relationUser:string;
    nomUrgent:string;
    phoneUrgent:string;
    lang: string;
}

export interface PasswordState {
    password: string;
    showPassword: boolean;
    confirmPassword: string;
    showConfirmPassword: boolean;
}
export interface PasswordEditState {
    password: string;
    showPassword: boolean;
    newPassword: string;
    showNewPassword: boolean;
    confirmPassword: string;
    showConfirmPassword: boolean;
}

export let typeRole = [
    // { name: 'traveller', label: i18n.t("SignUp.traveller"), selected: true },
    // { name: 'host', label: i18n.t("SignUp.host") },
    // { name: 'country admin', label: i18n.t("SignUp.hotel-admin") },
    { name: 'guest', label: "Guest" },
    
];

export let typeGender = [
    { name: i18n.t("SignUp.men"), selected: true },
    { name: i18n.t("SignUp.women") },
    { name: i18n.t("SignUp.other") },
];

export let typeLang = [
    { name: i18n.t("SignUp.lang-fr"), value: 'FR', selected: true },
    { name: i18n.t("SignUp.lang-en"), value: 'EN' }, 
];

// reset translations to new values on language change
i18n.on("languageChanged", function (lng) {
    typeRole = [];
    typeGender = [];
    typeRole = [
        // { name: 'traveller', label: i18n.t("SignUp.traveller"), selected: true },
        // { name: 'host', label: i18n.t("SignUp.host") },
        // { name: 'country admin', label: i18n.t("SignUp.hotel-admin") },
        { name: 'guest', label: "Guest" },
    ];
    typeGender = [
        { name: i18n.t("SignUp.men"), selected: true },
        { name: i18n.t("SignUp.women") },
        { name: i18n.t("SignUp.other") },
    ];

    typeLang = [
        { name: i18n.t("SignUp.lang-fr"), value: 'FR', selected: true },
        { name: i18n.t("SignUp.lang-en"), value: 'EN' }, 
    ];
});

export interface RoleOptionType {
    role: string;
}

export interface GenderOptionType {
    gender: string;
}
export class RegisterService {
    private api: Api;

    constructor() {
        this.api = new Api();
    }

    register(user: any): Promise<any> {
        return this.api.post("users/create", user);
    }

    reSendValidationCode(data:{userId:string, userPhone:string, userCountry: string}){
        return this.api.put('user/resend-sms-code-validation',data)
    }
    
    confirmCodeValidation(userId:string){
        const data={
            userId
        }
        return this.api.put('user/code-validation-update',data)
    }

    deleteUserById(id:string){
        return this.api.simpleDeleteById('users/delete',id)
    }
}


export class RoleService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    //get
    async getAllRole(){
        return this.api.get('roles');
    }

}

