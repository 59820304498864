import { Grid, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { IAnnounceAd } from '../../../../utils/AnnounceInterfaces'
import { dateToStringDDMMYYYY, getPlacementAdsLabel, paymentMethodList } from '../../../../utils/App'
import { EPaymentMethod } from '../../../../utils/enum/PaymentMethod'
import { IAnnounceAdPayment } from '../../../../utils/PaymentInterfaces'
import { PaypalAnnounceAdPaymentButtons } from '../../../components/Payments/PaypalAnnounceAdPaymentButtons'
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DohoneAnnounceAdPaymentButtons } from '../../../components/Payments/DohoneAnnounceAdPaymentButtons'

const styles = makeStyles((theme: Theme) =>
createStyles({
    paymentChoice:{
        marginBottom: 16
    },
    span: {
        fontSize: 30
    },
    text: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    spanNext: {
        fontSize: 14,
        marginBottom: 20
    },
    flex: {
        display: "flex",
        flexDirection: "column",
        marginTop: 20
    }
  })
)

export const RecapAnnounceAd = ({announceAd, paymentData, recapData, setPaymentData}:{announceAd: IAnnounceAd, paymentData:IAnnounceAdPayment, setPaymentData: (val: any)=>void, recapData:any }) => {
    const [paymentMethod, setPaymentMethod] = React.useState(EPaymentMethod.Paypal);
    const classes= styles();

    const onChangePaymentMethod = (event: any) => {
        setPaymentMethod(event.target.value)        
        setPaymentData((prev:any)=>{
            return {
                ...prev,
                method: event.target.value
            }
        })
    }
    

    const { t } = useTranslation();

    return (
        <div>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <span className={classes.span}>
                    {t('label.petit-recapitulatif')}
                </span>

                <div className={classes.flex}>
                    <span className={classes.spanNext}>
                        {t('label.intitule-de-la-propriete')} <span style={{color: "#B5551B"}}> {recapData.title}</span>
                    </span>
                    <span className={classes.spanNext}>
                        {t('label.emplacement')} : <span style={{color: "#B5551B"}}> {getPlacementAdsLabel(recapData.placement)}</span>
                    </span>
                    <span className={classes.spanNext}>
                        {t('label.duree')} : <span style={{color: "#B5551B"}}> {recapData.duration} {t('label.semaines')} </span>
                    </span>

                    <span className={classes.spanNext}>
                        {t('label.price')} : <span style={{color: "#B5551B"}}> {recapData.price} XAF</span>
                    </span>
                    <span className={classes.spanNext}>
                        {t('label.date-debut')} : <span style={{color: "#B5551B"}}> { recapData.startDate ? dateToStringDDMMYYYY(new Date(recapData.startDate)): dateToStringDDMMYYYY(new Date())}</span>
                    </span>

                    <span className={classes.spanNext}>
                        {t('label.date-fin')} : <span style={{color: "#B5551B"}}>{ moment(recapData.startDate ? recapData.startDate : new Date()).add(recapData.duration, 'weeks').format("DD/MM/YYYY")} </span>
                    </span>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.paymentChoice}>
                <Select
                    id="paymentMethodSelection"
                    name="paymentMethod"
                    variant="outlined"
                    fullWidth
                    value={paymentMethod}
                    onChange={onChangePaymentMethod}
                >
                        {
                        paymentMethodList.map(method=><MenuItem
                            key={method.label+method.value}
                            value={method.value}
                        >
                            {method.label}
                        </MenuItem>)
                    }
                </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {
                    paymentMethod=== EPaymentMethod.Paypal &&
                    <PaypalAnnounceAdPaymentButtons announceAd={announceAd} paymentData={paymentData} />
                }
                {
                    paymentMethod!== EPaymentMethod.Paypal &&
                    <DohoneAnnounceAdPaymentButtons announceAd={announceAd} paymentData={paymentData} price={recapData.price}/>
                }
            </Grid>
        </div>
    )
}
