import Api from "../../../../https/Api";
import { IAnnounceAd } from "../../../../utils/AnnounceInterfaces";
import { IAnnounceAdPayment, ITransaction } from "../../../../utils/PaymentInterfaces";

export class AnnounceAdService {
  private api: Api;
  constructor() {
    this.api = new Api();
  }

    //Get method
    async getAllSettingAds(){
      return this.api.get('settings/ads');
    }

    async getSettingAdsQuery(params:any){
      return this.api.getQuery('settings/ads/q',params);
    }

    async getAnnounceAdById(id: string) {
        return this.api.simpleGet("announces-ads/get", id);
    }

    async getAllAnnouncesAds(filter:any, page: number = 1, limit: number = 10) {
      return this.api.simpleGetWithFilter(`announces-ads`,filter);
    }

    //post
    async saveTransaction(transaction: ITransaction){
      return this.api.post('transactions/create', transaction)
    }
  
    async saveAnnounceAd(announceAd: IAnnounceAd) {
        return this.api.post("announces-ads/create", announceAd);
    }

    async updateAnnounceAdById(announceAd: Partial<IAnnounceAd>){
        if(announceAd._id) return this.api.postById('settings/ads/update', announceAd._id, announceAd);
    }

    async createAnnounceAdPayment(paymentData: IAnnounceAdPayment){
      return this.api.post('payments/v2/create/announce-ad',paymentData)
    }

    async allowOrBlockAnnoncePublishing(id: string, state:boolean){  
      const params={
        state

      }    
      if(id) return this.api.postById('announces-ads/update',id, params);
    }

    //delete
    async deleteAnnounceAd(id: string) {
        return this.api.simpleDeleteById(`settings/ads/delete`, id);
    }

    async deletesAnnouncesAds(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("settings/ads/deleteMany", param);
    }
}
