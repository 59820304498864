import Api from "../../../../https/Api";
import { EPaymentMethod } from "../../../../utils/enum/PaymentMethod";
import {
    IRefund,
    IRefundCredit,
    IRefundDohone,
    ITransaction,
} from "../../../../utils/PaymentInterfaces";

export class UserReservationService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    async getAllReservations(
        filter: any,
        page: number = 1,
        limit: number = 10
    ) {
        return this.api.simpleGetWithFilter(`reservations`, filter);
    }

    async getReservationFeeByCountryId(countryId: string) {
        return this.api.simpleGet(
            "settings/reservations-fees/country",
            countryId
        );
    }

    async getReservationFeeByCountryName(countryName: string) {
        return this.api.simpleGet(
            "settings/reservations-fees/country-name",
            countryName
        );
    }

    async getCommissionFeeByCountryAndCityId(
        countryName: string,
        cityName: string
    ) {
        return this.api.simpleGetWithTwoParams(
            "settings/commissions-fees-country-city",
            countryName,
            cityName
        );
    }

    async getHostFeeByCountryAndCityId(countryName: string, cityName: string) {
        return this.api.simpleGetWithTwoParams(
            "settings/hosts-fees-country-city",
            countryName,
            cityName
        );
    }

    async getSpecialFeeByPropertyType(propertyType: string) {
        return this.api.simpleGet(
            "settings/specials-fees/property-type",
            propertyType
        );
    }

    async getTransactionDetailByReservationId(id: string) {
        return this.api.simpleGet("transactions-details/reservation", id);
    }

    async getPaymentMethodFees(method: string) {
        let type = "";
        if (method === EPaymentMethod.Paypal) {
            type = EPaymentMethod.Paypal;
        }
        // if(method===EPaymentMethod.BankTransfer){
        //   type=EPaymentMethod.BankTransfer
        // }
        if (method === EPaymentMethod.Orange) {
            type = EPaymentMethod.Orange;
        }
        if (method === EPaymentMethod.MTN) {
            type = EPaymentMethod.MTN;
        }
        if (method === EPaymentMethod.Nexttel) {
            type = EPaymentMethod.Nexttel;
        }
        if (method === EPaymentMethod.ExpressUnion) {
            type = EPaymentMethod.ExpressUnion;
        }
        if (method === EPaymentMethod.YUP) {
            type = EPaymentMethod.YUP;
        }
        return this.api.simpleGet("settings/payments-fees/getByType", type);
    }

    async saveTransaction(transaction: ITransaction) {
        return this.api.post("transactions/create", transaction);
    }
    async getPaypalDataById(id: string) {
        return this.api.simpleGet("user/pmode/paypal", id);
    }
    async createBookingRefund(data: IRefund) {
        return this.api.post("payments/refund/create", data);
    }
    async createBookingRefundDohone(data: IRefundDohone) {
        return this.api.post("dohone/refund/create", data);
    }
    async createBookingRefundDohoneHost(data: IRefundDohone) {
        return this.api.post("dohone/refundHost/create", data);
    }
    async createBookingRefundCredit(data: IRefundCredit) {
        return this.api.post("payments/refundCredit/create", data);
    }
    async getPendingReservations(id: string) {
        return this.api.get(`booking/getPendingReservations/${id}`);
    }
    async getUser(id: string) {
        return this.api.simpleGet("users/get", id);
    }
    async changePaymentPauseStatus(id: string, data: any) {
        return this.api.put(`booking/changePaymentPauseStatus/${id}`, data)
    }
}
