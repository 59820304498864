
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { createStyles, Dialog, Divider, Hidden, IconButton, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../Home/react_dates_overrides.css";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import { PropertyService } from "../Properties/services/PropertyService";
import { colors } from "../../styles/defaultColors";
import { useHistory } from "react-router-dom";
import { PnbPlaceSelector } from "../components/PnbPlaceSelector";
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import { ESearchErrorMessages } from "../../utils/enum/properties";
import { useQuery } from "../../utils/App";
import { CurrencyContext } from "../../contexts/AppContext";
import { useContext } from 'react';
import { SearchLocation } from "../Home/SearchLocation";
import ItemPopover from "../Home/ItemPopover";
import { CountryPhoneList } from "../../utils/CountryList";
import { PnbPlaceSelectorMobile } from "./PnbSelectMobile";
const useStyles = makeStyles((theme) =>
    createStyles({

        // Search Components
        searchContainer: {
            display: "flex",
            justifyContent: "center",
            border: "1px solid #DDDDDD !important",
            alignItems: "center",
            position: "relative",
            width: "100%",
            maxWidth: 1100,
            height: 66,
            background: "#ffffff",
            color: "#222222 !important",
            outline: "none !important",
            boxShadow: "0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important",
            borderRadius: 32,
            [theme.breakpoints.down(1025)]: {
                maxWidth: 1000
            },
            [theme.breakpoints.down("sm")]: {
                top: "30%",
                flexDirection: "column",
                borderRadius: 10,
                paddingBottom: 40,
                height: "auto",
                background: "rgba(255, 255, 255, 0.7)",
                border: "none!important",
                margin: "auto !important"
            },
            [theme.breakpoints.down("xs")]: {
                top: "15%",
                flexDirection: "column",
                borderRadius: 10,
                maxWidth: 300
            },

            "& > div": {
                marginRight: 8,
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    marginRight: 0
                },
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                    marginRight: 0
                },
            },
        },
        label: {
            cursor: "pointer !important",
            border: "1px solid transparent !important",
            borderRadius: "32px !important",
            padding: "14px 32px !important",
            width: 300,
            "&:hover": {
                background: "#e1e1e1"
            },
        },
        selectedLabel: {
            backgroundClip: "padding-box !important",
            border: "1px solid rgb(255, 255, 255) !important",
            borderRadius: "32px !important",
            backgroundColor: "rgb(255, 255, 255) !important",
            boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
            [theme.breakpoints.down("sm")]: {
                borderRadius: "10px !important"
            },
        },
        searchLocation: {
            display: "flex",
            justifyContent: "center",
            width: 344,
            height: 37,
            padding: "9px 15px",
            background: "#FFFFFF",
            border: "1px solid #890608",
            borderRadius: 150,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
                border: "none",
                borderRadius: 0
            },
            [theme.breakpoints.down("xs")]: {
                border: "none",
                borderRadius: 0
            },
        },
        searchDateContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            border: 0,
            [theme.breakpoints.down("sm")]: {
                border: "none",
                borderRadius: 0
            },
            [theme.breakpoints.down("xs")]: {
                border: "none",
                borderRadius: 0
            },
        },
        searchTraveller: {
            display: "flex",
            alignItems: "center",
            border: 0,
            [theme.breakpoints.down("sm")]: {
                border: "none",
                borderRadius: 0
            },
            [theme.breakpoints.down("xs")]: {
                border: "none",
                borderRadius: 0
            },
        },
        btnTraveller: {
            fontFamily: "Josefin Sans",
            fontSize: 14,
            opacity: 0.7,
            textTransform: "capitalize",
            padding: 0,
            "&  p": {
                marginLeft: 5,
                marginTop: 0,
                marginBottom: 0
            },
        },
        popContainer: {
            padding: "16px 32px",
            background: "#ffffff",
            overflowX: "hidden",
            overflowY: "auto",
        },
        popItem: {
            minWidth: 286,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 16,
            paddingRight: 4,
            paddingBottom: 16,
            color: "rgb(34, 34, 34)",

            "&:not(:last-child)": {
                borderBottom: "1px solid rgb(235, 235, 235)",
            },
        },
        popItemLeft: {},
        popItemRight: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 104,
            height: 32,
            color: "rbg(34,34,34)",
            fontSize: 16,
            fontWeight: 400,
        },
        popTextAdultTitle: {
            fontSize: 16,
            fontWeight: 600,
        },
        popTextAdultSubTitle: {
            fontSize: 14,
            fontWeight: 400,
            color: "rgb(113, 113, 113)",
        },
        popItemIcon: {
            width: 32,
            height: 32,
            cursor: "pointer",
            display: "inline-flex",
            margin: 0,
            padding: 0,
            textAlign: "center",
            border: "1px solid rgb(176, 176, 176)",
            color: "rgb(113, 113, 113)",
            outline: "none",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(255, 255, 255)",
            borderRadius: "50%",
        },
        popRadius: {
            marginTop: 10,
            background: "#ffffff !important",
            borderRadius: "32px !important",
            boxShadow: "0px 6px 20px rgb(0 0 0 / 20%) !important",
        },
        popItemNbr: {
            color: "#222222",
            fontSize: 16,
        },
        reactDates: {
            width: "100%",
        },
        showErrors: {},
        searchButton: {
            padding: 13,
            fontSize: 14,
            color: "white",
            textTransform: "capitalize",
            background: "#890608 !important",
            borderRadius: "50%",
            fontFamily: "Josefin Sans",
            position: "absolute",
            right: 8,
            "&:hover": {
                background: "#B5551B !important",
            },
            [theme.breakpoints.down("sm")]: {
                position: "absolute",
                bottom: -22,
                right: "auto"
            },
        },
        errors: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        loading: {
            width: "100%",
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        formSearch: {
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
                top: "20%",
            },
            [theme.breakpoints.down("xs")]: {
                top: "20%",
                alignItems: "center"
            },
        },
        feel: {
            color: "#ffffff",
            fontSize: 65,
            width: 446,
            textTransform: "capitalize",
            marginBottom: 70,
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
                marginBottom: 30,
                fontSize: 46
            },
            [theme.breakpoints.down("xs")]: {
                marginBottom: 20,
                fontSize: 30,
                width: 350
            },
        },
        iconRoom: {
            [theme.breakpoints.down("sm")]: {
                display: "none!important"
            },
            [theme.breakpoints.down("xs")]: {
                display: "none!important"
            },
        },
        paper: {
            padding: 20
        },
        formSm: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            [theme.breakpoints.down("xs")]: {
                display: "none!important"
            },
        },
        mobileForm: {
            display: "flex"
        },
        formXs: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
                display: "none!important"
            },
        },
        "@global": {
            ".DateInput_input": {
                fontFamily: "Josefin Sans",
                fontSize: 14,
                color: "#000000",
            },
        },
    })
);
const propertyService = new PropertyService();
export function SearchExceptionalLink(props: any) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const currencyContext = useContext(CurrencyContext);
    const { currency, } = currencyContext;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    let [nbrTraveller, setnbrTraveller] = React.useState<number>(2);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    let [nbrAdult, setnbrAdult] = React.useState<number>(2);
    let [nbrEnfant, setnbrEnfant] = React.useState<number>(0);
    let [nbrBebe,] = React.useState<number>(0);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Initialize Language of calendar in SearchBar
    React.useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);
    const [active, setActive] = React.useState(false);
    const [activeCity, setActiveCity] = React.useState(false);
    const [activeDate, setActiveDate] = React.useState(false);
    const [activeTraveler, setActiveTraveler] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState<string>("");
    const [openError, setOpenError] = React.useState(false);
    const [, setPropertyTypeList] = React.useState([])
    const [, setEquipement] = React.useState([])
    const [, setCountryInput] = React.useState<String>('');
    let errors: string[] = [];
    React.useEffect(() => {
        propertyService
            .getAllPropertyTypes()
            .then((response) => {
                if (response.success) {
                    setPropertyTypeList(response.data);
                }
            })
            .catch((err) => {
                console.log(err);

            });
        propertyService
            .getAllEquipement()
            .then((response) => {

                if (response.success) {
                    setEquipement(response.data);
                }
            })
            .catch((err) => {
                console.log(err);

            });
    }, [])

    // let tabEqui: any = []
    // equipement && equipement.map((aqui: any) => {
    //     return tabEqui.push(aqui.name.split(",").join("-"))
    // })
    const handleClickActive = () => {
        setActive(true)
    };
    const handleClickActiveCity = () => {
        setActiveCity(true)
    };

    React.useEffect(() => {
        window.addEventListener('mousedown', () => setActiveCity(false));

        // cleanup this component
        return () => {
            window.removeEventListener('mousedown', handleClickActiveCity);
        };
    }, [activeCity]);
    const handleClickActiveDate = () => {
        setActiveDate(true)
    };
    React.useEffect(() => {
        setnbrTraveller(nbrAdult + nbrEnfant + nbrBebe);
    }, [nbrAdult, nbrEnfant, nbrBebe]);
    const handleClickActiveTraveler = () => {
        setActiveTraveler(true)
    };

    React.useEffect(() => {
        window.addEventListener('mousedown', () => setActiveTraveler(false));

        // cleanup this component
        return () => {
            window.removeEventListener('mousedown', handleClickActiveTraveler);
        };
    }, [activeTraveler]);

    React.useEffect(() => {
        window.addEventListener('mousedown', () => setActiveDate(false));

        // cleanup this component
        return () => {
            window.removeEventListener('mousedown', handleClickActiveDate);
        };
    }, [activeDate]);
    const bp = useMediaQuery(theme.breakpoints.down("xs"));
    React.useEffect(() => {
        window.addEventListener('mousedown', () => setActive(false));

        // cleanup this component
        return () => {
            window.removeEventListener('mousedown', handleClickActive);
        };
    }, [active]);
    const isSearchValidate = (): boolean => {
        if (props.paysSelected === undefined) {
            if (!errors.includes("EMPTY COUNTRY"))
                errors = [...errors, "EMPTY COUNTRY"];
        } else if (props.startDate != null && props.endDate != null) {

        }
        return !Boolean(errors.length);
    };
    const showErrorsMessages = () => {
        let allErrorMessages = "";
        errors.forEach((error) => {
            if (error === "EMPTY COUNTRY") {
                allErrorMessages += "," + t(`label.error.${ESearchErrorMessages.EmptyName}`);
            }
        });
        setErrorMessages(
            allErrorMessages.substring(1, allErrorMessages.length)
        );
        setOpenError(true);
    };
    const query = useQuery()

    const handleClickSearch = () => {
        if (props.setOpenDrawerDeal) props.setOpenDrawerDeal(false)
        props.closeAllDialogsOpen()
        let super_offer = 1
        if (isSearchValidate()) {
            setErrorMessages("");
            if (props.startDate != null && props.endDate != null) {
                query.set('search_pays', props.search.country.toString());
                query.set('search_adresse', props.search.city.toString());
                query.set('offer', super_offer.toString());
                query.set('currencyBase', CountryPhoneList.filter((devi: any) => devi.name?.toLowerCase() === props.search.country?.toLowerCase())[0]?.currencyCode)
                query.set('currency', currency.toString())
                query.set('travellerChildTotal', nbrEnfant.toString());
                query.set('travellerAdultTotal', nbrAdult.toString());
                query.set('dci', moment(props.startDate).format('YYYY/MM/DD'));
                query.set('dco', moment(props.endDate).format('YYYY/MM/DD'));
                query.set(`eqt`, [""].toString());
                query.set(`rooms`, [1, 2, 3].toString());
                query.set(`click`, [''].toString());
                query.set('serchex', "exceptionnal");
                query.set(`page`, "1");
                query.set(`luxury`, "false");
                query.set(`perle`, "false");
                query.set(`business`, "false");
                history.push({ pathname: "/search", search: query.toString() });
            } else {
                query.set('search_pays', props.search.country.toString());
                query.set('search_adresse', props.search.city.toString());
                query.set('offer', super_offer.toString());
                query.set('currencyBase', CountryPhoneList.filter((devi: any) => devi.name?.toLowerCase() === props.search.country?.toLowerCase())[0]?.currencyCode)
                query.set('currency', currency.toString())
                query.set('travellerChildTotal', nbrEnfant.toString());
                query.set('travellerAdultTotal', nbrAdult.toString());
                query.set(`eqt`, [""].toString());
                query.set(`click`, [''].toString());
                query.set('serchex', "exceptionnal");
                query.set(`rooms`, [1, 2, 3].toString());
                query.set(`page`, "1");
                query.set(`luxury`, "false");
                query.set(`perle`, "false");
                query.set(`business`, "false");
                history.push({ pathname: "/search", search: query.toString() });
            }
            props.setDeal(false)
        } else {
            showErrorsMessages();
        }
    }
    const handleCloseError = () => {
        setOpenError(false);
    };

    const bpSm = useMediaQuery(theme.breakpoints.down("sm"));
    const bpXs = useMediaQuery(theme.breakpoints.down("sm"));


    return (
        <>
            {
                props.paysList &&
                <>
                    <form className={classes.searchContainer}>
                        <Hidden smDown>
                            <label className={clsx(classes.label, { [classes.selectedLabel]: active })} onClick={handleClickActive}>
                                <div style={{ padding: "1px 0", }}>
                                    <span>
                                        {t('home.destination')}
                                    </span>
                                    <PnbPlaceSelector
                                        noHeading
                                        secondStyle
                                        width="100%"
                                        title={t("reservationCard.label.ou-voulez-vous-aller")}
                                        placeHolder={t('label.dans-quel-pays-allez-vous')}
                                        id="propertyEquipments" name="name"
                                        selectedValues={props.paysSelected}
                                        values={props.paysList}
                                        onChangeSelect={props.onChangeCherchPays}
                                        setCountryInput={setCountryInput}
                                    />
                                </div>
                            </label>

                            <Divider style={{ height: 32, margin: "auto" }} orientation="vertical" flexItem></Divider>

                            <label className={clsx(classes.label, { [classes.selectedLabel]: activeCity })} onClick={handleClickActiveCity} >
                                <div style={{ lineHeight: 1.3 }} >
                                    <span>
                                        {t('home.ville')}
                                    </span>
                                    <SearchLocation handleChange={props.onChangeHandler} />
                                </div>
                            </label>

                            <Divider style={{ height: 32, margin: "auto" }} orientation="vertical" flexItem></Divider>

                            <label className={clsx(classes.label, { [classes.selectedLabel]: activeDate })} onClick={handleClickActiveDate} style={{ padding: "13px 32px!important" }} >
                                <div style={{ lineHeight: 0.8, marginTop: 1 }}>
                                    <span>
                                        {t('home.quand')}
                                    </span>
                                    <div className={classes.searchDateContainer}>
                                        <div className={classes.reactDates}>
                                            <DateRangePicker
                                                orientation={bp ? "vertical" : "horizontal"}
                                                startDate={props.startDate}
                                                startDateId="your_unique_start_date_id"
                                                endDate={props.endDate}
                                                endDateId="your_unique_end_date_id"
                                                onDatesChange={props.handlendDatesChange}
                                                focusedInput={props.focusedInput}
                                                onFocusChange={props.handleFocusChange}
                                                startDatePlaceholderText={t(
                                                    "searchBar.checkIn"
                                                )}
                                                endDatePlaceholderText={t("searchBar.checkOut")}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </label>

                            <Divider style={{ height: 32, margin: "auto" }} orientation="vertical" flexItem></Divider>

                            <label className={clsx(classes.label, { [classes.selectedLabel]: activeTraveler })} onClick={handleClickActiveTraveler} style={{ padding: "13px 32px!important" }}>
                                <div style={{ lineHeight: 0.6, marginTop: 2 }} >
                                    <span>
                                        {t('home.voyaguers')}
                                    </span>
                                    <div className={classes.searchTraveller}>
                                        <Button
                                            className={classes.btnTraveller}
                                            onClick={handleClick}
                                        >
                                            {t("searchBar.guest")} : <p>{nbrTraveller}</p>
                                        </Button>
                                        <Popover
                                            classes={{ paper: classes.popRadius }}
                                            style={{ borderRadius: "32px" }}
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                        >
                                            <div className={classes.popContainer}>
                                                <ItemPopover
                                                    title={t("searchBar.adult")}
                                                    subtitle={t("searchBar.adultSub")}
                                                    value={nbrAdult}
                                                    increment={() => setnbrAdult(++nbrAdult)}
                                                    decrement={() => setnbrAdult(--nbrAdult)}
                                                />

                                                <ItemPopover
                                                    title={t("searchBar.children")}
                                                    subtitle={t("searchBar.childrenSub")}
                                                    value={nbrEnfant}
                                                    increment={() => setnbrEnfant(++nbrEnfant)}
                                                    decrement={() => setnbrEnfant(--nbrEnfant)}
                                                />
                                            </div>
                                        </Popover>
                                    </div>
                                </div>
                            </label>
                        </Hidden>
                        {
                            (bpSm && window.screen.width > 600) &&
                            <div className={classes.formSm}>
                                <div className={classes.mobileForm}>
                                    <label className={clsx(classes.label, { [classes.selectedLabel]: active })} onClick={handleClickActive}>
                                        <div style={{ padding: "1px 0", }}>
                                            <span>
                                                {t('home.destination')}
                                            </span>
                                            <PnbPlaceSelectorMobile
                                                noHeading
                                                secondStyle
                                                width="100%"
                                                title={t("reservationCard.label.ou-voulez-vous-aller")}
                                                // placeHolder={t("reservationCard.label.pays-ville-ou")}
                                                placeHolder={t('label.dans-quel-pays-allez-vous')}
                                                // preIcon={<RoomIcon sx={{color: "#890608"}} className={classes.iconRoom}/>}
                                                id="propertyEquipments" name="name"
                                                selectedValues={props.paysSelected}
                                                values={props.paysList}
                                                onChangeSelect={props.onChangeCherchPays}
                                            />
                                        </div>
                                    </label>

                                    <label className={clsx(classes.label, { [classes.selectedLabel]: activeCity })} onClick={handleClickActiveCity} >
                                        <div style={{ lineHeight: 1.3 }} >
                                            <span>
                                                {t('home.ville')}
                                            </span>
                                            <SearchLocation handleChange={props.onChangeHandler} />
                                        </div>
                                    </label>
                                </div>
                                <div className={classes.mobileForm}>
                                    <label className={clsx(classes.label, { [classes.selectedLabel]: activeDate })} onClick={handleClickActiveDate} style={{ padding: "13px 32px!important" }} >
                                        <div style={{ lineHeight: 0.8, marginTop: 1 }}>
                                            <span>
                                                {t('home.quand')}
                                            </span>
                                            <div className={classes.searchDateContainer}>
                                                <div className={classes.reactDates}>
                                                    <DateRangePicker
                                                        orientation={bp ? "vertical" : "horizontal"}
                                                        startDate={props.startDate}
                                                        startDateId="your_unique_start_date_id"
                                                        endDate={props.endDate}
                                                        endDateId="your_unique_end_date_id"
                                                        onDatesChange={props.handlendDatesChange}
                                                        focusedInput={props.focusedInput}
                                                        onFocusChange={props.handleFocusChange}
                                                        startDatePlaceholderText={t(
                                                            "searchBar.checkIn"
                                                        )}
                                                        endDatePlaceholderText={t("searchBar.checkOut")}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </label>

                                    <label className={clsx(classes.label, { [classes.selectedLabel]: activeTraveler })} onClick={handleClickActiveTraveler} style={{ padding: "13px 32px!important" }}>
                                        <div style={{ lineHeight: 0.6, marginTop: 2 }} >
                                            <span>
                                                {t('home.voyaguers')}
                                            </span>
                                            <div className={classes.searchTraveller}>
                                                <Button
                                                    className={classes.btnTraveller}
                                                    onClick={handleClick}
                                                >
                                                    {t("searchBar.guest")} : <p>{nbrTraveller}</p>
                                                </Button>
                                                <Popover
                                                    classes={{ paper: classes.popRadius }}
                                                    style={{ borderRadius: "32px" }}
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                >
                                                    <div className={classes.popContainer}>
                                                        <ItemPopover
                                                            title={t("searchBar.adult")}
                                                            subtitle={t("searchBar.adultSub")}
                                                            value={nbrAdult}
                                                            increment={() => setnbrAdult(++nbrAdult)}
                                                            decrement={() => setnbrAdult(--nbrAdult)}
                                                        />

                                                        <ItemPopover
                                                            title={t("searchBar.children")}
                                                            subtitle={t("searchBar.childrenSub")}
                                                            value={nbrEnfant}
                                                            increment={() => setnbrEnfant(++nbrEnfant)}
                                                            decrement={() => setnbrEnfant(--nbrEnfant)}
                                                        />
                                                    </div>
                                                </Popover>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            (bpXs && window.screen.width < 600) &&
                            <div className={classes.formXs}>
                                <label className={clsx(classes.label, { [classes.selectedLabel]: active })} onClick={handleClickActive}>
                                    <div style={{ padding: "1px 0", }}>
                                        <span>
                                            {t('home.destination')}
                                        </span>
                                        <PnbPlaceSelectorMobile
                                            noHeading
                                            secondStyle
                                            width="100%"
                                            title={t("reservationCard.label.ou-voulez-vous-aller")}
                                            // placeHolder={t("reservationCard.label.pays-ville-ou")}
                                            placeHolder={t('label.dans-quel-pays-allez-vous')}
                                            // preIcon={<RoomIcon sx={{color: "#890608"}} className={classes.iconRoom}/>}
                                            id="propertyEquipments" name="name"
                                            selectedValues={props.paysSelected}
                                            values={props.paysList}
                                            onChangeSelect={props.onChangeCherchPays}
                                        />
                                    </div>
                                </label>

                                <label className={clsx(classes.label, { [classes.selectedLabel]: activeCity })} onClick={handleClickActiveCity} >
                                    <div style={{ lineHeight: 1.3 }} >
                                        <span>
                                            {t('home.ville')}
                                        </span>
                                        <SearchLocation handleChange={props.onChangeHandler} />
                                    </div>
                                </label>

                                <label className={clsx(classes.label, { [classes.selectedLabel]: activeDate })} onClick={handleClickActiveDate} style={{ padding: "13px 32px!important" }} >
                                    <div style={{ lineHeight: 0.8, marginTop: 1 }}>
                                        <span>
                                            {t('home.quand')}
                                        </span>
                                        <div className={classes.searchDateContainer}>
                                            <div className={classes.reactDates}>
                                                <DateRangePicker
                                                    orientation={bp ? "vertical" : "horizontal"}
                                                    startDate={props.startDate}
                                                    startDateId="your_unique_start_date_id"
                                                    endDate={props.endDate}
                                                    endDateId="your_unique_end_date_id"
                                                    onDatesChange={props.handlendDatesChange}
                                                    focusedInput={props.focusedInput}
                                                    onFocusChange={props.handleFocusChange}
                                                    startDatePlaceholderText={t(
                                                        "searchBar.checkIn"
                                                    )}
                                                    endDatePlaceholderText={t("searchBar.checkOut")}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </label>

                                <label className={clsx(classes.label, { [classes.selectedLabel]: activeTraveler })} onClick={handleClickActiveTraveler} style={{ padding: "13px 32px!important" }}>
                                    <div style={{ lineHeight: 0.6, marginTop: 2 }} >
                                        <span>
                                            {t('home.voyaguers')}
                                        </span>
                                        <div className={classes.searchTraveller}>
                                            <Button
                                                className={classes.btnTraveller}
                                                onClick={handleClick}
                                            >
                                                {t("searchBar.guest")} : <p>{nbrTraveller}</p>
                                            </Button>
                                            <Popover
                                                classes={{ paper: classes.popRadius }}
                                                style={{ borderRadius: "32px" }}
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                            >
                                                <div className={classes.popContainer}>
                                                    <ItemPopover
                                                        title={t("searchBar.adult")}
                                                        subtitle={t("searchBar.adultSub")}
                                                        value={nbrAdult}
                                                        increment={() => setnbrAdult(++nbrAdult)}
                                                        decrement={() => setnbrAdult(--nbrAdult)}
                                                    />

                                                    <ItemPopover
                                                        title={t("searchBar.children")}
                                                        subtitle={t("searchBar.childrenSub")}
                                                        value={nbrEnfant}
                                                        increment={() => setnbrEnfant(++nbrEnfant)}
                                                        decrement={() => setnbrEnfant(--nbrEnfant)}
                                                    />
                                                </div>
                                            </Popover>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        }
                        <IconButton className={classes.searchButton} onClick={handleClickSearch}>
                            <SearchIcon style={{ color: "#ffffff" }} /> {/* {t("searchBar.search")} */}
                        </IconButton>
                    </form>
                    <div>
                        {errorMessages && (
                            <Dialog
                                classes={{ paper: classes.paper }}
                                onClose={handleCloseError}
                                aria-labelledby="simple-dialog-title"
                                open={openError}
                            >
                                {t("searchBar.selectPays")}
                            </Dialog>
                        )}
                    </div>
                </>

            }

        </>
    );
}













