import i18n from "../../i18n/config"

export enum ETarifs {
    JOURNALIER = "journalier",
    HEBDOMADAIRE = "hebdomadaire",
    QUINZAINE = "quinzaine",
    MENSUEL = "mensuel",
}

export function getTarifLabel(name: string):string {
    switch (name){
        case ETarifs.JOURNALIER:
            return i18n.t('annonce.labels.tarif-journalier')
        case ETarifs.HEBDOMADAIRE:
            return i18n.t('annonce.labels.tarif-hebdomadaire')
        case ETarifs.QUINZAINE:
            return i18n.t('annonce.labels.tarif-de-quinzaine')
        case ETarifs.MENSUEL:
            return i18n.t('annonce.labels.tarif-mensuel')
        default: 
            return name
    }
}