import Api from '../https/Api';

export class CurrencyConvert {
  private api: Api;
  constructor() {
    this.api = new Api();
  }

  async convert(from:string, to:string, amount:number) {
    let info:any = [];

    //await fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`, 
    await fetch(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${from}.json`,
    { method: 'GET', mode: 'cors',
      cache: 'default'
    }).then(response => response.json())
      .then(function (response) {
        info = (response[from]);
      }).catch(error => console.log(error))

    return amount * info[to]
  }

  getConvertedValue(from:string, to:string, amount:number) {
    return this.convert(from, to, amount).then(value => value)
  }

  async convertDevise(from:string, to:string){
    // console.log("conversion convertDevise front")
    return this.api.getWithURL(`https://api.fastforex.io/fetch-one?from=${from.toUpperCase()}&to=${to.toUpperCase()}&api_key=378c5bcdaf-880c4acd49-r7aijm`)
    .then((response) => {
        if (response.result) return response.result;
        else return null
    }).catch((err) => {
        console.log('CurrencyConverterService', err);
        throw err
    });
  }
}
