import * as React from 'react';
import { Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as colors from '@material-ui/core/colors';

export interface IgButton {
    label: string;
    onClick: () => void;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    isActive?: boolean;
}

export const GroupeButton = (props: { actions: IgButton[] }) => {
    return (
        <Stack direction="row" width="100%" >
            {props.actions.map((itm, i) => (
                <MenuItem
                    sx={{ backgroundColor: itm.isActive ? colors.blueGrey[50] : undefined, borderRadius: "6px 6px 0px 0px" }}
                    key={"switch-item-button-id-" + i} onClick={itm.onClick}>
                    {itm.startIcon && <ListItemIcon color={itm.isActive ? colors.red[900] : undefined} >
                        {itm.startIcon}
                    </ListItemIcon>}
                    <ListItemText color={itm.isActive ? colors.red[900] : undefined} >{itm.label}</ListItemText>
                    {itm.endIcon && <ListItemIcon>
                        {itm.endIcon}
                    </ListItemIcon>}
                </MenuItem>
            ))}
        </Stack>
    )
}