import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import MenuItem from '@mui/material/MenuItem';
import * as colors from '@mui/material/colors';

export const AddPayementItem = (props: {
    label: string,
    onClick: () => void
}) => {
    const { label, onClick } = props;

    return (
        <Paper sx={{ width: "100%", backgroundColor: colors.blueGrey[50], borderColor: colors.blueGrey[500] }} variant="outlined"
        >
            <MenuItem sx={{ padding: 0 }} onClick={onClick}>
                <Stack direction="column" width="100%" height={200} alignItems="center" justifyContent="center" py={2}>
                    <MdOutlineAddCircleOutline size={64} color={colors.blueGrey[500]} />
                    <Typography variant="h6" color={colors.blueGrey[500]}>
                        {label}
                    </Typography>
                </Stack>
            </MenuItem>
        </Paper>
    )
}