import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { snackBarType } from "../../../utils/enum/snackBarType";

export function AfriqSnackbar(props: {
    open: boolean;
    type: snackBarType;
    message: string;
    handleClose: () => void;
}) {
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={4000}
            onClose={props.handleClose}
            style={{ top: 0 }}
        >
            <Alert onClose={props.handleClose} severity={props.type}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}
