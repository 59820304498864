import React from 'react'
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import DialpadIcon from '@material-ui/icons/Dialpad';
import { InputAdornment } from '@material-ui/core';
import { TextField } from '@material-ui/core';
// import { RegisterService } from '../services/RegisterService';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    inputForm: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    forgotPassword: {
      textAlign: "right",
      textDecoration: "none",
      marginBottom: theme.spacing(2),
    },
    register: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    buttons: {
      marginBottom: theme.spacing(2),
    },
    textField: {
        "&::placeholder": {
            color: "#000",
        },
        marginTop: 15,
        marginBottom: 15,
    },
    loginButton: {
      width: "150px",
      background: "linear-gradient(to right, #877979, #af9b9b);",
      color: "white",
      borderRadius: "50px",
      textTransform: "capitalize",
    },
    icon: {
      position: "relative",
      top: 0,
    },   
    titlForm:{
        fontFamily: "Josefin Sans",
        fontSize: 20,
        marginBottom: 20,
        color: '#890608',
        display: 'block'

    },
    spanProfil: {
        fontFamily: "Josefin Sans",
        fontSize: 14,
        marginBottom: 20,
        display: 'block'
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "#000000",
        opacity: 10,
      },
    },
  })
);

// const registerService = new RegisterService();
export const RegisterValidationOTP = ({validationCodeEntered, setValidationCodeEntered, validationCodeData}: {validationCodeEntered:number | null, setValidationCodeEntered:(val:number)=>void, validationCodeData: any}) => {

    const classes= useStyles();
    const { t } = useTranslation()

    return (
        <Grid item container lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={3} direction="row">
                <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                    <span className={classes.titlForm}>{t('label.validation-otp')}</span>
                    <span className={classes.spanProfil}>{t('label.un-code-de-validation-a-ete-envoye')}{validationCodeData.userPhone}</span>
                    <div className={classes.inputForm}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="validationCode"
                            required
                            variant='outlined'
                            name="validationCode"
                            type="number"
                            fullWidth
                            label={t('label.entrer-le-code')}
                            classes={{root: classes.textField}}
                            onChange={e=>setValidationCodeEntered(parseInt(e.target.value))}
                            value={validationCodeEntered || null}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DialpadIcon
                                            className={classes.icon}
                                        />
                                    </InputAdornment>
                                ),
                                classes: {input: classes["input"]},
                            }}
                        />
                    </div>
                  
                </Grid>
            </Grid>
        </Grid>
    )
}
