import {CurrencyConvert} from "./CurrencyConverterService";

const converter = new CurrencyConvert();

export function ConvertPrice(base: string, to: string, amount: number) {
    return new CurrencyConvert().convert(base?.toLowerCase(), to?.toLowerCase(), amount).then((value: any): any => value).catch(error => console.log(error))
}

export function ConvertPriceWithFormat(base: string = "eur", to: string, amount: number) {
    return new CurrencyConvert().convert(base?.toLowerCase(), to?.toLowerCase(), amount).then((value: any): any => new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: to,
        maximumSignificantDigits: 3
    }).format(value)).catch(error => console.log(error))
}

export async function ConvertedDeviseWithoutMoneySign(from: string = "xaf", to: string, amount:number){
    try {
        if (from.toUpperCase() === to.toUpperCase()) return to.toLocaleLowerCase() === 'xaf' ? Math.ceil(amount).toString() : amount.toString()
        const rate:any= await converter.convertDevise(from.toUpperCase(),to.toUpperCase())
        const convertedValue= (rate[to.toUpperCase()] * amount).toFixed(2) 
        return to.toLocaleLowerCase() === 'xaf' ? Math.ceil(Number(convertedValue)).toString() : convertedValue.toString()
    } catch (error) {
        return amount.toString();
        
    }
}


export async function ConvertedDeviseWithoutMoneySignPromise(from: string = "xaf", to: string, amount:number){
    try {
        if ((from.toUpperCase() === to.toUpperCase())) return to.toLocaleLowerCase() === 'xaf' ? Math.ceil(amount).toString() : amount.toString()
        const rate:any= await converter.convertDevise(from.toUpperCase(),to.toUpperCase())
        const convertedValue=(rate[to.toUpperCase()] * amount).toFixed(2) 
        return Promise.resolve(to.toLocaleLowerCase() === 'xaf' ? Math.ceil(Number(convertedValue)).toString() : convertedValue.toString());
    } catch (error) {
        return amount.toString();
        
    }
}


export async function ConvertedDeviseWithMoneySign(from: string = "xaf", to: string, amount:number){
    try {
        const rate:any= await converter.convertDevise(from.toUpperCase(),to.toUpperCase())
        const convertedValue=(rate[to.toUpperCase()] * amount).toFixed(2)
        const prixArrondi = to.toUpperCase() === 'XAF' ? Math.ceil(Number(convertedValue)) : convertedValue
        return prixArrondi.toLocaleString()+' '+ to.toUpperCase();
    } catch (error) {
        console.log('CurrencyConvertFunction error', error)
        return amount.toString()+' '+ from.toUpperCase();
    }
}