/* eslint-disable react-hooks/exhaustive-deps */
import {
    brown,
    deepPurple,
    green,
    grey,
    indigo,
    orange,
    pink,
    red,
    teal,
} from "@material-ui/core/colors";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { colors } from "../../../styles/defaultColors";
import PerfectScrollbar from "react-perfect-scrollbar";
import IconButton from "@mui/material/IconButton";
import { PnbStyledCustomTextField } from "../../components/PnbPlaceSelector";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import {
    getAdminListMessagable,
    GetMessageFromTo,
    SendMessage,
} from "../../../Services/ChatService";
import { useUser } from "../../../Hooks/UserHooks";
import {
    IMessageData,
    IUserMessegerData,
} from "../../../Models/messagesModels";
import { ChatItemCorespendant } from "./ChatItemCorespendant";
import { ChatItemMessage } from "./ChatItemMessage";
import { FacebookCircularProgress } from "../UserNotification/components/NotificationSwitch";
import { IUser } from "../../../utils/UserInterfaces";
import { GetUserByArrayOfIds } from "../../../Services/UserServices";
import { useWS } from "../../../Hooks/Sockets";
import { ESocketRoutes } from "../../../utils/enum/sockets";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

export const MOUTH_LISTS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export default function UserChat(props: { onRow?: boolean; rowId?: string }) {
    const { t } = useTranslation();

    const { onRow, rowId } = props;

    const [messengerId, setCurrentMessegerId] = React.useState("");
    const [messengerCurent, setMessengerCurent] =
        React.useState<IUserMessegerData>();
    const [currentMessageList, setCurrentMessageList] = React.useState<
        IMessageData[]
    >([]);
    const [currentSendingMessage, setcurrentSendingMessage] = React.useState<
        IMessageData[]
    >([]);
    const [discutList, setDiscutList] = React.useState<IUserMessegerData[]>([]);
    const [discutListByMsg, setdiscutListByMsg] = React.useState<
        IUserMessegerData[]
    >([]);
    const [getingMessage, setGettingMessage] = React.useState(false);
    const [getingInterl, setGetingInterl] = React.useState(false);
    const [userMessage, setUserMessage] = React.useState("");
    const [, setSendingMessage] = React.useState(false);
    // const scroolRef = React.useRef<HTMLDivElement | null>(null);

    const { user } = useUser();
    const { wsSend, lastMessage, tMsg } = useWS();
    const classes = useStyles();
    React.useEffect(() => {
        const msg = tMsg(lastMessage);
        // console.log("last message +++++ >", msg);
        if (msg && msg.from) {
            if (msg.from === messengerId) {
                setCurrentMessageList([
                    ...currentMessageList,
                    {
                        ...(currentMessageList.find((m) => m.isResponse) ??
                            currentMessageList[0]),
                        messageId: messengerId + "to" + msg.to,
                        between: {
                            from: msg.from,
                            to: msg.to,
                        },
                        isResponse: true,
                        message: msg.message,
                        messageDate: new Date(),
                        refIds: msg?.refIds ?? [],
                    },
                ]);
            } else {
                setDiscutList(addNewMessage(discutList, msg.from));
                setdiscutListByMsg(addNewMessage(discutListByMsg, msg.from));
            }
        }
    }, [lastMessage]);

    const handleAdminClick = (uM: IUserMessegerData) => {
        if (messengerId !== uM.adminId) {
            setCurrentMessegerId(uM.adminId);
            setMessengerCurent(uM);
            setDiscutList(clearNewMessage(discutList, uM.adminId));
            setdiscutListByMsg(clearNewMessage(discutListByMsg, uM.adminId));
        }
    };

    const getMessage = (noLoad?: boolean) => {
        if (messengerId.length > 0) {
            !noLoad && setGettingMessage(true);
            GetMessageFromTo(messengerId, user._id)
                .then((resp) => {
                    if (resp !== null) {
                        setCurrentMessageList(
                            resp.map((ms) => ({
                                messageId: ms._id,
                                between: {
                                    from: ms.from,
                                    to: ms.to,
                                },
                                avColor:
                                    ms.from === user._id
                                        ? orange[400]
                                        : messengerCurent?.avColor ?? grey[400],
                                avLetter:
                                    ms.from === user._id
                                        ? (user.lastName ?? user.firstName)[0]
                                        : messengerCurent?.avLetter ?? "Un",
                                isResponse: !(ms.from === user._id),
                                message: ms.message,
                                messageDate: new Date(ms.createdAt),
                                refIds: ms?.refIds ?? [],
                            }))
                        );
                    }
                    !noLoad && setGettingMessage(false);
                    setcurrentSendingMessage([]);
                })
                .catch((err) => {
                    console.log(err);
                    !noLoad && setGettingMessage(false);
                });
        }
    };

    const scroolMessage = () => {
        let divElement: HTMLDivElement | null = document.querySelector(
            "#perfect-scroolbar-doom-element-id"
        );
        if (divElement) {
            divElement.scrollTo(0, divElement.scrollHeight);
        }
    };

    const messageFilter = (msg: IMessageData): boolean => {
        if (onRow && rowId) {
            if (msg.refIds) {
                if (msg.refIds.findIndex((ref) => ref === rowId) >= 0)
                    return true;
                else return false;
            } else return false;
        } else return true;
    };

    React.useEffect(() => {
        setGetingInterl(true);
        getAdminListMessagable(onRow).then((lists) => {
            // console.log(lists);
            if (lists && lists.length > 0)
                setDiscutList(UserToMessageData(lists));
            GetMessageFromTo(user._id)
                .then((resp) => {
                    // console.log(resp)
                    if (resp && resp.length > 0) {
                        GetUserByArrayOfIds(resp.map((r) => r.from)).then(
                            (usrs) => {
                                if (usrs && usrs.length > 0) {
                                    let filteredUser = usrs.filter(
                                        (uf) =>
                                            !lists
                                                .map((l) => l._id)
                                                .join(",")
                                                .includes(uf._id)
                                    );
                                    setdiscutListByMsg(
                                        UserToMessageData(filteredUser)
                                    );
                                    setGetingInterl(false);
                                } else {
                                    setGetingInterl(false);
                                }
                            }
                        );
                    } else {
                        setGetingInterl(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setGetingInterl(false);
                });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (discutList.length > 0) {
            let userList = discutList[0];
            if (userList && !(messengerId.length > 0)) {
                setMessengerCurent(userList);
                setCurrentMessegerId(discutList[0].adminId);
            }
        }
    }, [discutList]);

    React.useEffect(() => {
        getMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messengerId]);

    React.useEffect(() => {
        scroolMessage();
    }, [currentMessageList, currentSendingMessage]);

    const orderMessages = (mA: IMessageData, mB: IMessageData) => {
        return mA.messageDate.getTime() - mB.messageDate.getTime();
    };

    const handleOnSend = () => {
        if (messengerId.length > 0 && userMessage.length > 0) {
            setcurrentSendingMessage([
                ...currentSendingMessage,
                {
                    messageId: "user-message-ref-id-" + messengerId,
                    between: {
                        from: user._id,
                        to: messengerId,
                    },
                    avColor: orange[400],
                    avLetter: (user.lastName ?? user.firstName)[0],
                    isResponse: false,
                    message: userMessage,
                    messageDate: new Date(),
                    sending: true,
                    refIds: rowId ? [rowId] : [],
                },
            ]);

            setUserMessage("");
            setSendingMessage(true);

            SendMessage({
                message: userMessage,
                to: messengerId,
                from: user._id,
                sender: {
                    idSender: user._id,
                    lastName: user.lastName,
                    firstName: user.firstName,
                    role: user.role._id,
                },
                refIds: rowId ? [rowId] : [],
            })
                .then((resp) => {
                    /** Sending with socket */
                    wsSend(ESocketRoutes.Message, {
                        message: userMessage,
                        to: messengerId,
                        from: user._id,
                        sender: {
                            idSender: user._id,
                            lastName: user.lastName,
                            firstName: user.firstName,
                            role: user.role._id,
                        },
                        refIds: rowId ? [rowId] : [],
                    });
                    //console.log(resp);
                    getMessage();
                    setSendingMessage(false);
                })
                .catch((err) => {
                    console.log(err);
                    setSendingMessage(false);
                });
        }
    };

    return (
        <>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        className={classes.reservationTittle}
                        variant="h5"
                        gutterBottom
                    >
                        {t("label.chat-title")}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t("label.chat-subtitle")}
                    </Typography>
                </Grid>
            </Grid>
            <Box
                borderRadius={3}
                bgcolor={grey[200]}
                flex={1}
                height={onRow ? "300px" : "100%"}
                overflow="hidden"
            >
                <Grid container flex={1} height="100%">
                    <Grid item xs={4} height="100%">
                        <PerfectScrollbar id="messages-scrool-container-id-1">
                            <Box p={2} flex={1}>
                                <Stack spacing={1}>
                                    {getingInterl && (
                                        <Box
                                            p={4}
                                            flex={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ display: "flex" }}
                                        >
                                            <FacebookCircularProgress
                                                size={26}
                                            />
                                        </Box>
                                    )}
                                    {!getingInterl &&
                                        [...discutList, ...discutListByMsg]
                                            .filter(
                                                (m) => m.adminId !== user._id
                                            )
                                            .map((ad, i) => (
                                                <ChatItemCorespendant
                                                    key={
                                                        "senders-item-user-id-" +
                                                        ad.adminId +
                                                        i
                                                    }
                                                    onClick={() => {
                                                        handleAdminClick(ad);
                                                    }}
                                                    adminId={ad.adminId}
                                                    avLetter={ad.avLetter}
                                                    name={ad.name}
                                                    ufunction={ad.ufunction}
                                                    lastMessageDate={
                                                        ad.lastMessageDate
                                                    }
                                                    newMessage={ad.newMessage}
                                                    avColor={ad.avColor}
                                                />
                                            ))}
                                </Stack>
                            </Box>
                        </PerfectScrollbar>
                    </Grid>
                    <Grid bgcolor={grey[100]} item xs={8} height="100%">
                        <Stack direction="column" width="100%" height="100%">
                            <Box flex={1} maxHeight={onRow ? "196px" : "53vh"}>
                                <PerfectScrollbar id="perfect-scroolbar-doom-element-id">
                                    <Box p={2} flex={1}>
                                        <Stack>
                                            {getingMessage && (
                                                <Box
                                                    p={4}
                                                    flex={1}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ display: "flex" }}
                                                >
                                                    <FacebookCircularProgress
                                                        size={26}
                                                    />
                                                </Box>
                                            )}
                                            {[
                                                ...currentMessageList,
                                                ...currentSendingMessage,
                                            ]
                                                .filter(messageFilter)
                                                .sort(orderMessages)
                                                .map((cMsg, i) => (
                                                    <ChatItemMessage
                                                        key={
                                                            "message-item-user-id-" +
                                                            i
                                                        }
                                                        avColor={cMsg.avColor}
                                                        avLetter={cMsg.avLetter}
                                                        isResponse={
                                                            cMsg.isResponse
                                                        }
                                                        message={cMsg.message}
                                                        messageDate={
                                                            cMsg.messageDate
                                                        }
                                                        loading={cMsg.sending}
                                                    />
                                                ))}
                                            {[
                                                ...currentMessageList,
                                                ...currentSendingMessage,
                                            ].filter(messageFilter).length <=
                                                0 && (
                                                <Box
                                                    p={4}
                                                    flex={1}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ display: "flex" }}
                                                >
                                                    <Typography
                                                        style={{
                                                            color: "#9e9e9e",
                                                            fontFamily:
                                                                "inherit",
                                                        }}
                                                    >
                                                        {t(
                                                            "label.pas-de-conversation"
                                                        )}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Stack>
                                    </Box>
                                </PerfectScrollbar>
                            </Box>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                m={2}
                                spacing={1}
                                borderRadius={1}
                                p={2}
                                bgcolor="#fff"
                            >
                                <PnbStyledCustomTextField
                                    disabled={messengerId.length <= 0}
                                    multiline
                                    fullWidth
                                    placeholder="Message"
                                    size="small"
                                    variant="outlined"
                                    value={userMessage}
                                    onChange={(e) => {
                                        setUserMessage(e?.target?.value ?? "");
                                    }}
                                />
                                <IconButton
                                    onClick={handleOnSend}
                                    sx={{ color: colors.orangePng }}
                                    aria-label="upload picture"
                                    component="span"
                                >
                                    <SendIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

/** MOCK DATA */
const COLORS = [
    deepPurple[500],
    pink[500],
    grey[500],
    red[500],
    teal[500],
    green[500],
    brown[500],
    indigo[500],
    orange[500],
];

/** UTIL FUNCTIONS */
// const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

const isToday = (someDate: Date) => {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

const sumDigit = (num: number, sum = 0): number => {
    if (num) {
        return sumDigit(Math.floor(num / 10), sum + (num % 10));
    }
    return sum;
};

const sumRepeatedly = (num: number) => {
    while (num > 9) {
        num = sumDigit(num);
    }
    return num;
};

const UserToMessageData = (users: IUser[]): IUserMessegerData[] =>
    users.map((u, i) => ({
        adminId: u._id,
        avLetter: (u.lastName ?? u.firstName)[0],
        name: u.firstName,
        ufunction: u.role.name,
        lastMessageDate: new Date(),
        newMessage: 0,
        avColor: COLORS[sumRepeatedly(i)],
        messages: [],
    }));

export function DateToStringRefToday(mDate: Date) {
    let day, mouth, years, hours, mins;
    day = mDate.getDate();
    mouth = MOUTH_LISTS[mDate.getMonth()];
    years = (mDate.getFullYear() + "").slice(2, 4);
    hours = mDate.getHours();
    mins = mDate.getMinutes();

    let fDate = `${day + 1}, ${mouth} ${years}`;
    let fTime = `${hours < 10 ? "0" + hours : hours}:${
        mins < 10 ? "0" + mins : mins
    }`;
    return isToday(mDate) ? fTime : `${fDate} - ${fTime}`;
}

const addNewMessage = (list: IUserMessegerData[], msengerId: string) => {
    return list.map((l) => {
        if (l.adminId === msengerId) {
            return { ...l, newMessage: l.newMessage + 1 };
        } else return l;
    });
};
const clearNewMessage = (list: IUserMessegerData[], msengerId: string) => {
    return list.map((l) => {
        if (l.adminId === msengerId) {
            return { ...l, newMessage: 0 };
        } else return l;
    });
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        reservationTittle: {
            fontSize: 22,
            fontFamily: "Josefin Sans",
            color: "#890608",
            marginTop: 5,
            marginBottom: 25,
        },
    })
);
