import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { EAdsPlacement } from '../../utils/enum/AdsPlacement';
import { getPlacementAdsLabel } from '../../utils/App';
// import { AnnounceAdService } from '../UserAdmin/UserAnnounceAd/services/AnnounceAdService';
import { IAdParam } from '../../utils/SettingIntefaces';
import { Grid, MenuItem, Select } from '@material-ui/core';
import { Stack } from '@mui/material';
import { IAnnounceAd } from '../../utils/AnnounceInterfaces';
import { useTranslation } from 'react-i18next';

// const announceAdAservice= new AnnounceAdService();
const SelectAdsOffer=({announceAd, setAnnounceAd, setRecapData, placementQuery, setPlacementQuery, adsParams}: {announceAd: IAnnounceAd, setAnnounceAd:(val: any)=>void; setRecapData:(val: any)=>void; placementQuery: any, setPlacementQuery:(val:any)=>void;adsParams: IAdParam[]})=> {
  const handleChangePlacement=(e: any)=>{
    setPlacementQuery({
      ...placementQuery,
      value: e.target.value});
  }

  const handleChangeAdParam=(e:any)=>{
    setAnnounceAd((prev:any)=>{
        return {
            ...prev,
            adParam: e.target.value
        }
    });
    setRecapData((prev:any)=>{
      return {
        ...prev,
        duration: adsParams.filter(adParam=> (adParam._id ===e.target.value))[0].duration,
        placement: adsParams.filter(adParam=> (adParam._id ===e.target.value))[0].placement,
        price: adsParams.filter(adParam=> (adParam._id ===e.target.value))[0].price
      }
    })
  }

  // React.useEffect(() => {
  //   console.log("PlacementQuery", placementQuery)
  // },[placementQuery])

  const { t } = useTranslation();
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("label.emplacement-de-la-promotion")}</FormLabel>
          <RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleChangePlacement} value={placementQuery.value}>
            <FormControlLabel value={EAdsPlacement.HomePage} control={<Radio />} label={getPlacementAdsLabel(EAdsPlacement.HomePage)}/>
            <FormControlLabel value={EAdsPlacement.SearchPage} control={<Radio />} label={getPlacementAdsLabel(EAdsPlacement.SearchPage)} />
          </RadioGroup>        
        </FormControl>
      </Grid>
      {(placementQuery.value === EAdsPlacement.HomePage) && 
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {t('label.emplacement-de-la-promotion-homePage')}
        </Grid>
      }
      {(placementQuery.value === EAdsPlacement.SearchPage) && 
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {t('label.emplacement-de-la-promotion-searchPage')}
        </Grid>
      }
      {
        (adsParams.length >0 || announceAd.adParam!=='') && 
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Select
              id="placementAdSelection"
              name="placement"
              variant="outlined"
              fullWidth
              value={announceAd.adParam}
              onChange={handleChangeAdParam}
            >
              {/* <MenuItem value='' disabled selected={true}>Choisir...</MenuItem> */}
                  {
                  adsParams.map(adParam=><MenuItem
                      key={adParam._id}
                      value={adParam._id}
                      selected={announceAd.adParam===adParam._id}
                  >
                      <Stack display="flex" width="100%" direction="row" justifyContent="space-between" alignItems="center">
                      <span>
                        {`${getPlacementAdsLabel(adParam.placement)} - ${adParam.duration} ${t('label.semaines')} `}
                      </span>
                      <span>
                        {adParam.price} XAF
                      </span>
                    </Stack>
                  </MenuItem>)
              }
          </Select>
        </Grid>
      }

    </Grid>
  );
}

export default React.memo(SelectAdsOffer)