import { IAnnounce, IAnnounceAd, IAnnounceAdReq } from "./AnnounceInterfaces";
import { IReservation, IReservationReq } from "./ReservationInterfaces";
import { IAdParam } from "./SettingIntefaces";
import { IUser } from "./UserInterfaces";

export interface IBookingPayment {
    id?: string;
    method: string;
    providerIdentifier?: string;
    accountIdentifier?: string;
    transaction: {
        items: {
            booking: IReservation;
            services: string[]; //IUserService[] |
        };
        amount: {
            discount: number;
            total: number;
        };
    };
    description: string;
}

export interface IBookingPaymentOrange extends IBookingPayment {
    access_token: string;
    lang: string;
    return_url: string;
    cancel_url: string;
    notif_url: string;
}
export interface IAnnounceAdPaymentReq {
    id?: string;
    method: string;
    transaction: {
        items: {
            announce: IAnnounce;
            adParam: IAdParam;
        };
        amount: {
            discount: number;
            total: number;
        };
    };
    description: string;
}

export interface IAnnounceAdPayment {
    id?: string;
    method: string;
    transaction: {
        items: {
            announce: string;
            adParam: string;
        };
        amount: {
            discount: number;
            total: number;
        };
    };
    description: string;
}

export interface ITransactionRaw {
    method: string;
    billingToken: string | null;
    facilitatorAccessToken: string;
    orderID: string;
    payerID: string;
    paymentID: string | null;
    link: string;
    paymentEmail: string;
    announceAd: string | IAnnounceAd | null;
    payout_batch_id?: string;
    recipient_type?: string;
    batch_status?: string;
    sender_batch_id?: string;
    amount?: number;
    reservation: string | IReservation;
    type: string;
    amountCredit?: number;
}
export interface ITransaction {
    method: string;
    billingToken?: string | null;
    facilitatorAccessToken?: string;
    orderID?: string;
    payerID?: string;
    paymentID?: string | null;
    link: string;
    paymentEmail: string;
    reservation: string | IReservation | null;
    announceAd: string | IAnnounceAd | null;
    payout_batch_id?: string;
    recipient_type?: string;
    batch_status?: string;
    sender_batch_id?: string;
    amount?: number;
    type: string;
    userId?: IUser | string | null;
    amountCredit?: number;
}

export interface ITransactionReq {
    _id: string;
    method: string;
    billingToken: string | null;
    facilitatorAccessToken: string;
    orderID: string;
    payerID: string;
    paymentID: string | null;
    link: string;
    paymentEmail: string;
    reservation: IReservationReq | null;
    announceAd: IAnnounceAdReq | null;
    payout_batch_id?: string;
    recipient_type?: string;
    batch_status?: string;
    sender_batch_id?: string;
    amount?: number;
    type: string;
    userId?: IUser | string | null;
    createdAt?: Date;
    updatedAt?: Date;
    amountCredit?: number;
}

export interface IPaypalReq {
    _id?: string;
    user: IUser;
    recepient_email: string;
    recepient_paypal_id: string;
    recepient_phone: string;
}
export interface IPaypal {
    _id?: string;
    user: string;
    recepient_email: string;
    recepient_paypal_id: string;
    recepient_phone: string;
}

export interface IMobileMoneyReq {
    _id: string;
    user: IUser;
    mobile_operator: string;
    mobile_number: string;
}

export interface IMobileMoney {
    _id: string;
    user: string;
    mobile_operator: string;
    mobile_number: string;
}
export interface IBanckAccountReq {
    _id: string;
    user: IUser;
    bank_name: string;
    bank_rib: string;
    bank_bic: string;
    bank_iban: string;
    bank_swift: string;
}
export interface IBanckAccount {
    _id: string;
    user: string;
    bank_name: string;
    bank_rib: string;
    bank_bic: string;
    bank_iban: string;
    bank_swift: string;
}

export interface IPaymentMethodSelectProps {
    label: string;
    value: string;
}

export interface IRefund {
    email_subject: string;
    email_message: string;
    recepient_type: string;
    amount: {
        value: number;
        currency: string;
    };
    note: string;
    reservation: string;
    userId: string | IUser | null;
    receiver: string;
}

export interface IRefundDohone {
    destination: string;
    mode: DohonePayoutMode;
    amount: number;
    devise: string;
    nameDest: string;
    ville: string;
    pays: string;
    rUserId: string;
    reservation: string;
    userId: string | IUser | null;
}

export interface IRefundCredit {
    amount: {
        value: number;
        currency: string;
    };
    reservation: string;
    method: string;
    isCancellation: boolean;
}

export interface IDioolMobileTransaction {
    _id?: string;
    reservation: IReservation;
    date: string;
    amount: number;
    accountIdentifier: string;
    lang: string;
    state: string;
    currency: string;
    providerIdentifier: string;
    referenceOrder: string;
}

export enum EDohoneMobileType {
    MTN = 1,
    ORANGE = 2,
    EXPRESS_UNION = 3,
    VISA_UBA = 5,
    DOHONE = 10,
    VISA_WARI = 14,
    WARI_CARD = 15,
    VISA_MASTERCARD = 16,
    YUP = 17,
}

export enum DohonePayoutMode {
    paymentDohoneAccount = 1,
    expressExchange = 2,
    expressUnion = 3,
    moneyGram = 4,
    mtnMobileMoney = 5,
    orangeMoney = 6,
    rechargeCarteVisaOrDohone = 7,
    paymentBank = 11,
    yup = 12,
}

export interface IDohonePayement {
    nom: string;
    telephone: string;
    mailAdress: string;
    montant: number;
    mobileType: EDohoneMobileType;
}

export interface IDohoneCommande {
    commandeId: string;
}
