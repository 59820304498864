import Api from "../../../../https/Api";

export class CountryService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    //get
    async getAllDestination() {
        return this.api.get("destinations");
    }

    async getCommissionFeeByCountryAndCityId(
        countryName: string,
        cityName: string
    ) {
        return this.api.simpleGetWithTwoParams(
            "settings/commissions-fees-country-city",
            countryName,
            cityName
        );
    }

    async getSpecialFeeByPropertyType(propertyType: string) {
        return this.api.simpleGet(
            "settings/specials-fees/property-type",
            propertyType
        );
    }

    async getAllEquipments() {
        return this.api.get("equipments");
    }

    async getDestinationById(id: string) {
        return this.api.simpleGet("destinations/get", id);
    }

    //post

    async saveDestination(destination: any) {
        return this.api.post("destinations/create/", destination);
    }

    //delete
    async deleteDestination(id: string) {
        return this.api.simpleDeleteById(`destinations/delete`, id);
    }

    async deleteCategories(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("categories/deleteMany", param);
    }

    async deleteEquipments(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("equipments/deleteMany", param);
    }
    async saveDestinationPhotos(Photo: any) {
        return this.api.postFileupload("destinations/upload", Photo);
    }
}
