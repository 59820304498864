export enum EPaymentMethod {
    Paypal = "Paypal",
    Orange = "Orange",
    MTN = "MTN",
    Nexttel = "Nexttel",
    ExpressUnion = "ExpressUnion",
    YUP = "YUP",
    Credit = "Credit",
    // BankTransfer='BanckAccount',
}
export enum EPaymentMethodCategory {
    Paypal = "Paypal",
    MobileMoney = "MobileMoney",
}

export enum ETransactionType {
    Sending = "Sending",
    Receiving = "Receiving",
    OwnerPay = "OwnerPay",
    Cancelling = "Cancelling",
}
