import Api from "../../../../https/Api";
import { PromotionInputs } from "../../../../utils/AnnounceInterfaces";

export class UserPromotionService {
  private api: Api;
  constructor() {
    this.api = new Api();
  }

  //Get method
  async getPromotionById(id: string) {
      return this.api.simpleGet("promotions", id);
  }

  async getAllPromotions(filter:any, page: number = 1, limit: number = 10) {
    return this.api.simpleGetWithFilter(`promotions`,filter);
  }
    
    async getUserById(userId: string) {
      return this.api.simpleGet("users/get", userId);
    }

    //post
    async savePromotion(announcePromotion: PromotionInputs) {
        return this.api.post("promotions", announcePromotion);
    }

    async updatePromotionById(announcePromotion: Partial<PromotionInputs>){
        if(announcePromotion._id) return this.api.postById('promotions', announcePromotion._id, announcePromotion);
    }

  //delete
  async deletePromotion(id: string) {
      return this.api.simpleDeleteById(`promotions/delete`, id);
  }

  async deletesPromotion(ids: string[]) {
      const param = {
          ids: ids,
      };
      return this.api.deleteMany("promotions/deleteMany", param);
  }
}
