import i18n from "../../i18n/config";

export enum ETransactionMode {
    Rent = "Rent",
    Booking = "Booking",
    SearchPage = "SearchPage",
    HomePage = "HomePage",
    Refund = "Refund",
    Cancellation = "Cancellation",
}

export function getTransactionLabel(name: string): string {
    switch (name) {
        case ETransactionMode.Booking:
            return i18n.t("label.transactionType.booking");
        case ETransactionMode.SearchPage:
            return i18n.t("label.transactionType.searchPage");
        case ETransactionMode.HomePage:
            return i18n.t("label.transactionType.homePage");
        case ETransactionMode.Refund:
            return i18n.t("label.transactionType.refund");
        case ETransactionMode.Rent:
            return i18n.t("label.transactionType.rent");
        case ETransactionMode.Cancellation:
            return i18n.t("label.transactionType.cancellation");
        default:
            return name;
    }
}
