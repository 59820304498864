import { makeStyles, createStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import iconLocation from "../../images/icons/Location.png";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) =>
    createStyles({
        searchLocation: {
            width: "100%",
            border: 0,
            fontFamily: "Josefin Sans",
            "& input, .MuiInputBase-input": {
                padding: 0,
                fontSize: 14
            },
            [theme.breakpoints.down("sm")]: {
                border: "none",
                borderRadius: 0
            },
            [theme.breakpoints.down("xs")]: {
                border: "none",
                borderRadius: 0
            },
        },
        // icon: {
        //     [theme.breakpoints.down("sm")]: {
        //         display: "none"
        //     },
        //     [theme.breakpoints.down("xs")]: {
        //         display: "none"
        //     },
        // },
        "@global": {
            ".MuiInput-underline:before, .MuiInput-underline:after": {
                display: "none"
            },
        },
    })
);

export function SearchLocation(props: {
    handleChange: (e: any) => void;
    defaultValue?: string;
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    return ( 
        <>
             
            <TextField
                placeholder={t("searchBar.location")}
                className={classes.searchLocation}
                id="input-with-icon-textfield"
                onChange={props.handleChange}
                defaultValue={props.defaultValue}
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">
                //             <img
                //                 src={iconLocation}
                //                 alt=""
                //                 width="24px"
                //                 height="24px"
                //                 className={classes.icon}
                //             />
                //         </InputAdornment>
                //     ),
                // }}
            />
                    
        </>
    );
}