import React, { useContext } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import credit_svg from "../../../svg/credit_svg.svg";
import { colors } from "@material-ui/core";
import { MdMobileFriendly } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CurrencyContext } from "../../../../../contexts/AppContext";
import { ConvertedDeviseWithMoneySign } from "../../../../../utils/CurrencyConvertFunction";
import Spin from "../../../../../images/Spin";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "space-between",
        },
        details: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        content: {
            flex: "1 0 auto",
        },
        cover: {
            width: "50%",
            backgroundSize: "contain",
        },
        controls: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        solde: {
            marginLeft: 20,
            fontSize: 24,
        },
    })
);

export default function CardCreditMobileMoney(props: { credit: number }) {
    const [priceConverted, setPriceConverted] = React.useState<string | null>(
        null
    );
    const currencyContext = useContext(CurrencyContext);
    const { currency } = currencyContext;
    const classes = useStyles();
    const { t } = useTranslation();

    React.useEffect(() => {
        ConvertedDeviseWithMoneySign("XAF", currency, Number(props.credit))
            .then((value: any): any => setPriceConverted(value))
            .catch((error) => console.log(error));
    }, [currency, props.credit]);

    return (
        <Card className={classes.root}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        Mobile money
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {t("label.vos-soldes-sur-mobile-money")}
                    </Typography>
                </CardContent>
                <div className={classes.controls}>
                    <MdMobileFriendly size={64} color={colors.orange[600]} />
                    <span className={classes.solde}>
                        {priceConverted !== null ? priceConverted : <Spin />}
                    </span>
                </div>
            </div>
            <CardMedia
                className={classes.cover}
                image={credit_svg}
                title="Crédit"
            />
        </Card>
    );
}
