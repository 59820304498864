// export const paypalConfig = {
//     mode: process.env.PAYPAL_ENV || "sandbox",
//     email: process.env.PAYPAL_EMAIL || "sb-mghhp7700851@business.example.com",
//     cliendID:
//         process.env.PAYPAL_CLIENT_ID ||
//         "ASaN-AqhGYtNb_JnxIrnwpF_FlJkheqwFaSPM71UNNtc0w9XP5fqXRSn8jWCs8eqaSOTbrYp9iXRE6rU",
//     secret:
//         process.env.PAYPAL_SECRET ||
//         "EBqj8X91cpmB_wun8YWI3yoOXr9mQeL1h5T5cLJbTuaIHuarCUfJCOnijQ4Q62qqN9bhCMsrswXSzPl-",
// };

export const paypalConfig = {
    mode: "live",
    email: "Paypal-cameroun@afriqpnb.com",
    cliendID:
        "ASAYqJ5HHdYSwpP7MZzYu3BzA0HZ-ESDQmXdMVTj5I2KqSGKvXGpDz2FItXYhx7P-o4NuG1JSDflPfAN",
    secret: "EC_cdD7u0Y8er342qEN8RGNSQAZ2YjDL2OeeRy3T05oRmby1-coxol1Fkj9gfeTqXghNyIfCUXbTYLGj",
};
