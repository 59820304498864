import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from "react-i18next";

export default function PnbAlertDialog(props: {
    open: boolean,
    setOpen: (state: boolean) => void,
    onValidate: () => void,
    title: string,
    children?: JSX.Element,
    warning?: boolean,
    loading?: boolean
}) {
    const {loading, warning, open, setOpen, onValidate, title, children} = props;

    const handleClose = () => {
        setOpen(false);
    };
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} startIcon={loading ? <CircularProgress size={16}/> : undefined}
                        onClick={handleClose}>{t('label.non')}</Button>
                <Button disabled={loading} color={warning ? "error" : "primary"} onClick={onValidate}
                        autoFocus> {t('label.oui')} </Button>
            </DialogActions>
        </Dialog>
    );
}
