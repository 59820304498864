export const SAVE_SEARCH = "location/save";
export const TYPE_SEARCH = "sercloc/save";
export const BILAN_SEARCH = "bilan_result/save";
const initialState = { search: [], location: [], bilan: [] };

export const localRed = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_SEARCH:
            return {
                ...state,
                search: action.payload,
            };
        case BILAN_SEARCH:
            return {
                ...state,
                bilan: action.payload,
            };
        default:
            return state;
    }
};
