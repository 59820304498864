export const SAVE_TRI = "search/save";
const initialState = { tri: [] };

export const seach = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_TRI:
            return {
                ...state,
                tri: action.res,
            };
        default:
            return state;
    }
};
