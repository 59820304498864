import { makeStyles, createStyles } from "@material-ui/core"

export const gStyles = makeStyles(() => createStyles({
	'@global': {
		'input::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
		'input::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
		'input[type=number]': {
			'-moz-appearance': 'textfield'
		},
		".MuiSelect-select:focus, input:-internal-autofill-selected": {
			background: "none!important"
		},
		'input, .MuiInputBase-input': {
			fontWeight: 400
		},
		".css-f01iyf-MuiInputBase-input-MuiOutlinedInput-input::placeholder":{
			fontFamily: "Josefin Sans!important",
		},
		".css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
			fontFamily: "Josefin Sans!important",
			fontSize: "14px!important"
		}
	}
}))
