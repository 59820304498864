export const colors = {
    backgroundMenu: "#890608",
    textFieldBackground: "#E4E5EB",
    textFieldColor: "#4D4F5C",
    textFieldBorderColor: "#C4C4C4",
    labelColor: "#727999",
    labelHoverColor: "#7F7F7F",
    primary: "#890608",
    secondary: "#F92D5B",
    danger: "#FF6E6E",
    dangerHover: "#f56969",
    backgroundDanger: "#FF6E6E33",
    success: "rgb(81 149 84)",
    backgroundDashboard: "#F4F6FB",
    backgroundButtonToolbar: "#2F45B5",
    buttonMenuTextColor: "#4D4F5C",
    buttonTextColor: "#FFF",
    menuIconColor: "#A5A6AD",
    buttonMenuHoverBackgroundColor: "#EFEFEF",
    gray: "#C9C9C9",
    darkerGrey: "#7F7F7F",
    greyBlack: "#55555",
    suspendu: "#535353",
    info: " #FFA500",
    orangePng: "#EA7606",
    orangeDark: "#bf6004",
    black: "#000000",
    white: "#fff",
    red:"#890608",
    whiteSmock: "#F2F2F2"
}