/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Stack, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { colors } from "../../../styles/defaultColors";
import { BalanceItem } from "./BalanceItem";
import { ITransactionReq } from "../../../utils/PaymentInterfaces";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ETransactionType } from "../../../utils/enum";
import { CurrencyContext } from "../../../contexts/AppContext";
import { ConvertedDeviseWithoutMoneySignPromise } from "../../../utils/CurrencyConvertFunction";
import { makeStyles } from "@material-ui/core";

export const WalletHistorique = (props: { hLists: ITransactionReq[] }) => {
    const currencyContext = React.useContext(CurrencyContext);
    const classes = useStyle();
    const { currency } = currencyContext;
    const [receivedPriceConverted, setReceivedPriceConverted] =
        React.useState<number>(0);
    const [sendedPriceConverted, setSendedPriceConverted] =
        React.useState<number>(0);

    React.useEffect(() => {
        const hListsReceiving = props.hLists.filter(
            (el) =>
                el.type === ETransactionType.Receiving ||
                el.type === ETransactionType.OwnerPay ||
                el.type === ETransactionType.Cancelling
        );
        const hListsSending = props.hLists.filter(
            (el) => el.type === ETransactionType.Sending
        );

        setReceivedPriceConverted(0);
        const receivedValueConverted: any = [];
        hListsReceiving.forEach(async (el, index) => {
            receivedValueConverted.push(
                await ConvertedDeviseWithoutMoneySignPromise(
                    el.reservation
                        ? el.reservation?.announce?.devise ?? "XAF"
                        : "XAF",
                    currency,
                    Number(el?.amount || 0)
                )
            );
            if (index === hListsReceiving.length - 1) {
                setReceivedPriceConverted(
                    receivedValueConverted.reduce(
                        (prev: number, val: number) => {
                            return Number(prev) + Number(val);
                        },
                        0
                    )
                );
            }
        }, 0);

        setSendedPriceConverted(0);
        const sendedValueConverted: any = [];
        hListsSending.forEach(async (el, index) => {
            const elAmount = el?.amount || 0;
            const elCredit = el?.amountCredit || 0;
            const elTotal = elAmount + elCredit;
            sendedValueConverted.push(
                await ConvertedDeviseWithoutMoneySignPromise(
                    el.reservation
                        ? (elCredit === 0) ? el.reservation?.announce?.devise ?? "XAF" : "XAF"
                        : "XAF",
                    currency,
                    Number(elTotal)
                )
            );
            if (
                index === sendedValueConverted.length - 1 &&
                sendedValueConverted.length > 0
            ) {
                setSendedPriceConverted(
                    sendedValueConverted.reduce((prev: number, val: number) => {
                        return Number(prev) + Number(val);
                    }, 0)
                );
            }
        }, 0);
    }, [props]);

    React.useEffect(() => {
        const hListsReceiving = props.hLists.filter(
            (el) =>
                el.type === ETransactionType.Receiving ||
                el.type === ETransactionType.OwnerPay ||
                el.type === ETransactionType.Cancelling
        );
        const hListsSending = props.hLists.filter(
            (el) => el.type === ETransactionType.Sending
        );

        setReceivedPriceConverted(0);
        const receivedValueConverted: any = [];
        hListsReceiving.forEach(async (el, index) => {
            receivedValueConverted.push(
                await ConvertedDeviseWithoutMoneySignPromise(
                    el.reservation
                        ? el.reservation?.announce?.devise ?? "XAF"
                        : "XAF",
                    currency,
                    Number(el?.amount || 0)
                )
            );
            if (index === hListsReceiving.length - 1) {
                setReceivedPriceConverted(
                    receivedValueConverted.reduce(
                        (prev: number, val: number) => {
                            return Number(prev) + Number(val);
                        },
                        0
                    )
                );
            }
        }, 0);

        setSendedPriceConverted(0);
        const sendedValueConverted: any = [];
        hListsSending.forEach(async (el, index) => {
            sendedValueConverted.push(
                await ConvertedDeviseWithoutMoneySignPromise(
                    el.reservation
                        ? el.reservation?.announce?.devise ?? "XAF"
                        : "XAF",
                    currency,
                    Number(el?.amount || 0)
                )
            );
            if (
                index === sendedValueConverted.length - 1 &&
                sendedValueConverted.length > 0
            ) {
                setSendedPriceConverted(
                    sendedValueConverted.reduce((prev: number, val: number) => {
                        return Number(prev) + Number(val);
                    }, 0)
                );
            }
        }, 0);
    }, [currency]);

    // const hListsReceiving= props.hLists.filter(el=>el.type===ETransactionType.Receiving);
    // const hListsSending= props.hLists.filter(el=>el.type===ETransactionType.Sending);
    // const totalReceiveing=hListsReceiving.reduce((prev, val)=>prev + Number(val?.amount || 0) ,0)
    // const totalSending=hListsSending.reduce((prev, val)=>prev + Number(val?.amount || 0) ,0)

    return (
        <Stack direction="column">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={3}
            >
                <Typography
                    fontSize={20}
                    fontWeight={400}
                    sx={{ color: colors.greyBlack }}
                >
                    {"Balance"}
                </Typography>
                <div style={{ display: "flex" }}>
                    <Typography
                        fontSize={22}
                        fontWeight={600}
                        sx={{ color: colors.success, marginRight: 16 }}
                        className={classes.marginRightMobile}
                    >
                        {" "}
                        <AddCircleOutlineIcon
                            sx={{ color: colors.success }}
                        />{" "}
                        {currency.toUpperCase() +
                            " " +
                            receivedPriceConverted.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize={22}
                        fontWeight={600}
                        sx={{ color: colors.danger }}
                    >
                        <RemoveCircleOutlineIcon
                            sx={{ color: colors.danger }}
                        />{" "}
                        {currency.toUpperCase() +
                            " " +
                            sendedPriceConverted.toLocaleString()}
                    </Typography>
                </div>
            </Stack>
            <Stack direction="column">
                <Typography
                    marginBottom={1}
                    fontSize={14}
                    fontWeight={400}
                    sx={{ color: colors.greyBlack }}
                >
                    {"Transactions"}
                </Typography>
                <Divider />
            </Stack>
            {props.hLists.map((l) => (
                <BalanceItem data={l} key={l._id} />
            ))}
        </Stack>
    );
}; /** STYLES */
const useStyle = makeStyles((theme) => ({
    marginRightMobile: {
        [theme.breakpoints.down("xs")]: {
            marginRight: "0!important",
        },
    },
}));
