interface IEnv {
    ApiRoot: string;
    SockRoot: string;
}

export interface IServerConfig {
    ApiRoot: string;
    ApiTranslation?: string;
    SockRoot: string;
    MapBoxApikey: string;
}

const env: IEnv = (window as any).runConfig as IEnv;

export const serverConfig: IServerConfig = {
    ApiRoot: env?.ApiRoot ?? "https://afriqpnb.com/api", //"http://localhost:5000",
    SockRoot: env?.SockRoot ?? "ws://afriqpnb.com/api",  //"ws://127.0.0.1:5000",
    MapBoxApikey:
        "pk.eyJ1IjoiY2hyaXN0ZXBvIiwiYSI6ImNrdThhajAzYTA2M3kybm16azRrMHhsd2UifQ.6y9WOE4E2CB-5-IR9Pa4LA",
};

export interface IPaypalConfig {
    env: string;
    email: string;
    client_id: string;
    secret: string;
}

// export const paypalConfig: IPaypalConfig = {
//     env: "sandbox",
//     email: "sb-mghhp7700851@business.example.com",
//     client_id:
//         "ASaN-AqhGYtNb_JnxIrnwpF_FlJkheqwFaSPM71UNNtc0w9XP5fqXRSn8jWCs8eqaSOTbrYp9iXRE6rU",
//     secret: "EBqj8X91cpmB_wun8YWI3yoOXr9mQeL1h5T5cLJbTuaIHuarCUfJCOnijQ4Q62qqN9bhCMsrswXSzPl-",
// };

export const paypalConfig: IPaypalConfig = {
    env: "live",
    email: "Paypal-cameroun@afriqpnb.com",
    client_id:
        "ASAYqJ5HHdYSwpP7MZzYu3BzA0HZ-ESDQmXdMVTj5I2KqSGKvXGpDz2FItXYhx7P-o4NuG1JSDflPfAN",
    secret: "EC_cdD7u0Y8er342qEN8RGNSQAZ2YjDL2OeeRy3T05oRmby1-coxol1Fkj9gfeTqXghNyIfCUXbTYLGj",
};
