import React from 'react';
import { SvgIcon, makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
    "@keyframes rotate": {
        from: {
            transform: "rotate(0deg)"
        },
        to: {
            transform: "rotate(360deg)"
        }
    },
    animationRotate: {
        animation: "$rotate",
        animationDuration: "1s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear"
    },
}));

/**
 * 
 * @param {{stroke?: string, style?: React.CSSProperties}} props 
 */
export default function Spin(props) {
    const classes = styles();
    return (
        <SvgIcon className={classes.animationRotate} {...props} width="26" height="26" viewBox="0 0 26 26">
            <g id="Circle" fill="none" stroke={props.stroke ? props.stroke : "#FFF"} strokeLinecap="round" strokeWidth="3" strokeDasharray="20 653.12">
                <circle cx="13" cy="13" r="13" stroke="none" />
                <circle cx="13" cy="13" r="11.5" fill="none" />
            </g>
        </SvgIcon>
    );
};