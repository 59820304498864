import { createStyles, Hidden, IconButton, makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Menu as MenuIcon} from "@material-ui/icons";
import { useTranslation } from 'react-i18next';

export default function UserTitleCard(props: {
    title: string,
    descComponents?: JSX.Element[],
    toggleMenu: () => void
}) {
    const classes = useStyles();
    const { t } = useTranslation()
    return (
        <>
            <Hidden xsDown>
                <div className={classes.fixed}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={8} xs={12}>
                            <Paper elevation={2} sx={{padding: 3}} className={classes.paper}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" component="div" className={classes.title}>
                                        {props.title}
                                    </Typography>
                                    {props.descComponents}
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                    <IconButton size="small" onClick={props.toggleMenu} className={classes.boutonMenu}>
                        <MenuIcon style={{width: "1,8em", height: "1,8em"}}/>
                    </IconButton>
                </div>
            </Hidden>
            <Hidden smUp>
                <div className={classes.fixed}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={8} xs={12}>
                            <Paper elevation={2} sx={{padding: 3}} className={classes.paper}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" component="div" className={classes.title}>
                                        {t('menuUser.dashboard')}
                                    </Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                    <IconButton size="small" onClick={props.toggleMenu} className={classes.boutonMenu}>
                        <MenuIcon style={{width: "1,8em", height: "1,8em"}}/>
                    </IconButton>
                </div>
            </Hidden>
        </>    
    )
}
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            [theme.breakpoints.down("xs")]: {
                   fontSize: "18px !important",
                   fontWeight: "bold !important"
            },
        },
        paper: {
            [theme.breakpoints.down("xs")]: {
                padding: "10px !important",
                justifyContent: "flex-end",
                display: "flex"
            },
        },
        
        boutonMenu: {
            [theme.breakpoints.up("sm")]: {
                display: "none"
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                position: "absolute",
                left: 3,
                top: 7
            },
        },
        fixed: {
            [theme.breakpoints.down("xs")]: {
                position: "fixed",
                zIndex: 100,
                width: "90%",
                left: "5%",
                top: 66
            },
        }
    })
);