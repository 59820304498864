/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import CardCreditMobileMoney from "./components/CardCreditMobileMoney";
import CardCreditPaypal from "./components/CardCreditPaypal";
import CardCreditTotal from "./components/CardCreditTotal";
import { UserContext } from "../../../../contexts/AppContext";
import { useTranslation } from "react-i18next";
import { RetrieveCreditModal } from "./components/RetrieveCreditModal";
import { Accountservice } from "../../../Admin/Accounts/services/AccountService";

/** MAIN COMPONENT */
const accountService = new Accountservice();
export default function UserAdminCredit() {
    const [openRefundModal, setOpenRefundModal] = React.useState(false);
    const classes = useStyle();
    const userContext = React.useContext(UserContext);
    const { user, setUser } = userContext;
    const { t } = useTranslation();
    React.useEffect(() => {
        if (user._id !== "") {
            accountService
                .getUserById(user._id)
                .then((res) => {
                    if (res.success) {
                        setUser(res.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleClose = () => {
        setOpenRefundModal(false);
    };

    const handleClickOpen = () => {
        setOpenRefundModal(true);
    };

    return (
        <>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        className={classes.reservationTittle}
                        variant="h5"
                        gutterBottom
                    >
                        {t("label.vos-credits")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <CardCreditPaypal credit={user.credits.paypal} />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <CardCreditMobileMoney credit={user.credits.mobileMoney} />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <CardCreditTotal
                        credit={user.credits.paypal + user.credits.mobileMoney}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.button}>
                        <Button
                            variant="contained"
                            className={classes.buttonStyle}
                            onClick={handleClickOpen}
                        >
                            {t("label.-recuperer-mes-credits")}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <RetrieveCreditModal
                openRefundModal={openRefundModal}
                handleClose={handleClose}
            />
        </>
    );
}

const useStyle = makeStyles(() => ({
    reservationTittle: {
        fontSize: 22,
        fontFamily: "Josefin Sans",
        color: "#890608",
        marginTop: 5,
        marginBottom: 25,
    },
    button: {
        display: "flex",
        justifyContent: "center",
    },
    buttonStyle: {
        textTransform: "none",
        background: "#890608",
        color: "#ffffff",
        fontFamily: "Josefin Sans",
        borderRadius: 100,
        padding: "8px 14px",
        "&:hover": {
            background: "#B5551B",
        },
    },
}));
