/* eslint-disable react-hooks/exhaustive-deps */
// import { deepOrange, grey, orange } from '@material-ui/core/colors';
import { Stack, IconButton, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import * as Annouce from "../../../Services/AnnounceService";
import { useUser } from "../../../Hooks/UserHooks";
import { IAnnounceItem } from "../../../Models/announces";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
// import moment from 'moment'
import { Link } from "react-router-dom";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TableBody,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";

export const FavoriteTableHeader = (props: {
    onCheckAll: (state: boolean) => void;
    onDeleteCheked: () => void;
    checkedAll: boolean;
    title: string;
    disableAction: boolean;
}) => {
    const { onCheckAll, checkedAll, disableAction, onDeleteCheked } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { t } = useTranslation();

    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell align="center" padding="checkbox">
                        <Checkbox
                            checked={checkedAll}
                            inputProps={{ "aria-label": "check all chekckbox" }}
                            onChange={(e) => onCheckAll(e.target.checked)}
                        />
                    </TableCell>

                    <TableCell
                        align="center"
                        style={{
                            fontSize: 18,
                            fontFamily: "Josefin Sans",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {t("favorite.title")}
                    </TableCell>

                    <TableCell
                        align="center"
                        style={{
                            fontSize: 18,
                            fontFamily: "Josefin Sans",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {t("favorite.type-propriete")}
                    </TableCell>

                    <TableCell
                        align="center"
                        style={{
                            fontSize: 18,
                            fontFamily: "Josefin Sans",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {t("favorite.pays")}
                    </TableCell>

                    <TableCell
                        align="center"
                        style={{
                            fontSize: 18,
                            fontFamily: "Josefin Sans",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {t("favorite.ville")}
                    </TableCell>

                    <TableCell align="center">
                        <IconButton
                            id="selected-all-action-menu-button"
                            disabled={disableAction}
                            onClick={handleClick}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id="selected-all-action-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby":
                                    "selected-all-action-menu-button",
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    onDeleteCheked();
                                }}
                            >
                                <Box p={1}>{t("label.supprimer")}</Box>
                            </MenuItem>
                        </Menu>
                    </TableCell>
                </TableRow>
            </TableHead>
        </>
    );
};

export const ListItemFavorite = (props: {
    onCheck: (state: boolean) => void;
    onDelete: () => void;
    checked: boolean;
    title: string;
    type: string;
    city: string;
    country: string;
    description: string;
    _id: string;
    isLoading?: boolean;
}) => {
    const {
        onCheck,
        checked,
        title,
        type,
        city,
        country,
        onDelete,
        _id,
        isLoading,
    } = props;
    const { t } = useTranslation();
    return (
        <TableRow>
            <TableCell align="center">
                <Checkbox
                    checked={checked}
                    inputProps={{ "aria-label": "check-item-chekckbox" }}
                    onChange={(e) => onCheck(e.target.checked)}
                />
            </TableCell>
            <TableCell align="center">
                <Typography
                    style={{
                        fontFamily: "inherit",
                        maxWidth: 300,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: 14,
                    }}
                >
                    <Link to={`/details-announce-${_id}`}>{title}</Link>
                </Typography>
            </TableCell>
            <TableCell align="center">{type}</TableCell>
            <TableCell align="center">{country}</TableCell>
            <TableCell align="center">{city}</TableCell>
            <TableCell align="center">
                <Button
                    disabled={isLoading}
                    color="secondary"
                    sx={{ borderRadius: 99 }}
                    onClick={onDelete}
                    variant="outlined"
                    size="small"
                >
                    {t("label.supprimer")}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default function UserFavorite() {
    const [chekeAll, setCheckAll] = React.useState(false);
    const [allCheckBoxState, setAllCheckBoxstate] = React.useState<string[]>(
        []
    );
    const [isLoadingList, setIsLoadingList] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const { user } = useUser();
    const [favList, setFavlist] = React.useState<IAnnounceItem[]>([]);
    const [dense] = React.useState(false);
    const classes = useStyle();

    const handleDeletChecked = () => {
        if (allCheckBoxState.length > 0) {
            setIsLoadingList([...isLoadingList, ...allCheckBoxState]);
            Annouce.RemFromFavMult(user._id, allCheckBoxState)
                .then((response) => {
                    setIsLoadingList(
                        isLoadingList.filter(
                            (f) => !allCheckBoxState.includes(f)
                        )
                    );
                    refetchLst();
                })
                .catch((err) => {
                    setIsLoadingList(
                        isLoadingList.filter(
                            (f) => !allCheckBoxState.includes(f)
                        )
                    );
                    console.log(err);
                });
        }
    };

    const handleCheckAll = (state: boolean) => {
        setCheckAll(state);
        if (state) setAllCheckBoxstate(favList.map((f) => f._id));
        else setAllCheckBoxstate([]);
    };

    const handleSetCheckbox = (elId: string) => (state: boolean) => {
        if (state) setAllCheckBoxstate([...allCheckBoxState, elId]);
        else setAllCheckBoxstate(allCheckBoxState.filter((f) => f !== elId));
    };

    const refetchLst = () => {
        setIsLoading(true);
        Annouce.GetFavList(user._id)
            .then((lists) => {
                setFavlist(lists);
                setAllCheckBoxstate(
                    lists.map((itm: IAnnounceItem) => ({
                        id: itm._id,
                        state: false,
                    }))
                );
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const handleDeletByElement = (id: string) => {
        setIsLoadingList([...isLoadingList, id]);
        Annouce.RemFromFav(user._id, id)
            .then((res) => {
                setIsLoadingList(isLoadingList.filter((l) => l !== id));
                refetchLst();
            })
            .catch((err) => {
                setIsLoadingList(isLoadingList.filter((l) => l !== id));
                console.log(err);
            });
    };

    const { t } = useTranslation();

    React.useEffect(() => {
        refetchLst();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.promotionTitle}
                    >
                        {t("label.favorites-title")}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t("label.favorites-subtitle")}
                    </Typography>
                </Grid>
            </Grid>
            <Box flex={1} height="100%">
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                        style={{ minWidth: 750 }}
                    >
                        <FavoriteTableHeader
                            onCheckAll={handleCheckAll}
                            checkedAll={chekeAll}
                            title={t("label.liste-des-favoris")}
                            disableAction={!(allCheckBoxState.length > 0)}
                            onDeleteCheked={handleDeletChecked}
                        />
                        <TableBody>
                            {isLoading && (
                                <Stack
                                    direction="column"
                                    width="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    p={8}
                                >
                                    <CircularProgress color="primary" />
                                </Stack>
                            )}
                            {!isLoading &&
                                favList.map((itm) => (
                                    <ListItemFavorite
                                        isLoading={isLoadingList.includes(
                                            itm._id
                                        )}
                                        onCheck={handleSetCheckbox(itm._id)}
                                        onDelete={() => {
                                            handleDeletByElement(itm._id);
                                        }}
                                        checked={allCheckBoxState.includes(
                                            itm._id
                                        )}
                                        title={itm.property.name}
                                        type={itm.property.type.name}
                                        country={itm.property.address.country}
                                        city={itm.property.address.city}
                                        description={itm.publishDate.toString()}
                                        key={itm._id}
                                        _id={itm._id}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}

const useStyle = makeStyles((theme) => ({
    deleteMobile: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0!important",
        },
    },
    gridMobile: {
        [theme.breakpoints.down("xs")]: {
            padding: "10px !important",
        },
    },
    promotionTitle: {
        fontSize: 22,
        fontFamily: "Josefin Sans",
        color: "#890608",
        marginTop: 5,
        marginBottom: 25,
    },
}));
