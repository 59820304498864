import Api from "../../../../https/Api";
import { IUser } from "../../../../utils/UserInterfaces";

export class UserNotificationService {
  private api: Api;
  constructor() {
    this.api = new Api();
  }

  async getUserById(userId: string) {
    return this.api.simpleGet("users/get", userId);
  }
  async updateUser(id: string, user: IUser) {
    return this.api.postById("users", id, user);
  }

  updateNotification(data: { key: string, value: boolean }): Promise<{
    user: IUser,
    success: boolean,
    message: string,
  }> {
    return this.api.put("users/notifs/update", data);
  }
}
