import Api from "../https/Api";

const http = new Api();

//PAYMENT METHOD
export const getUserPModeByUserId = (userId: string): Promise<any[] | null> => http
    .get(`user/pmode/${userId}/all/`)
    .then((respoonse) => {
        if (respoonse && respoonse.success) return respoonse.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    })

export const addPypalMethode = (data: any): Promise<{ user: any, list: any[] } | null> => http
.post(`user/pmode/add/paypal/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const addMobileMethode = (data: any): Promise<{ user: any, list: any[] } | null> => http
.post(`user/pmode/add/mobile/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const addBankMethode = (data: any): Promise<{ user: any, list: any[] } | null> => http
.post(`user/pmode/add/bank/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const updatePypalMethode = (data: any): Promise<any[] | null> => http
.put(`user/pmode/update/paypal/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const updateMobileMethode = (data: any): Promise<any[] | null> => http
.put(`user/pmode/update/mobile/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const updateBankMethode = (data: any): Promise<any[] | null> => http
.put(`user/pmode/update/bank/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const deletePypalMethode = (id: string, uid: string): Promise<{ user: any, list: any[] } | null> => http
.delete(`user/pmode/delete/paypal/${id}/${uid}/`)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const deleteMobileMethode = (id: string, uid: string): Promise<{ user: any, list: any[] } | null> => http
.delete(`user/pmode/delete/mobile/${id}/${uid}/`)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

//RECEPTION METHOD
export const getUserRModeByUserId = (userId: string): Promise<any[] | null> => http
    .get(`user/rmode/${userId}/all/`)
    .then((respoonse) => {
        if (respoonse && respoonse.success) return respoonse.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    })

export const addReceptPypalMethode = (data: any): Promise<{ user: any, list: any[] } | null> => http
.post(`user/rmode/add/paypal/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const addReceptMobileMethode = (data: any): Promise<{ user: any, list: any[] } | null> => http
.post(`user/rmode/add/mobile/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const updateReceptPypalMethode = (data: any): Promise<any[] | null> => http
.put(`user/rmode/update/paypal/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const updateReceptMobileMethode = (data: any): Promise<any[] | null> => http
.put(`user/rmode/update/mobile/`, data)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const deleteReceptPypalMethode = (id: string, uid: string): Promise<{ user: any, list: any[] } | null> => http
.delete(`user/rmode/delete/paypal/${id}/${uid}/`)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})

export const deleteReceptMobileMethode = (id: string, uid: string): Promise<{ user: any, list: any[] } | null> => http
.delete(`user/rmode/delete/mobile/${id}/${uid}/`)
.then((respoonse) => {
    if (respoonse && respoonse.success) return respoonse.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
})
