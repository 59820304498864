import Api from "../https/Api";
import { IReservationReq } from "../Models/reservationModels";
import {
    IRefund,
    IRefundCredit,
    IRefundDohone,
    ITransaction,
    ITransactionReq,
} from "../utils/PaymentInterfaces";

const http = new Api();

export const getAllReservation = (
    page?: number,
    size?: number
): Promise<IReservationReq[] | null> =>
    http
        .get(page && size ? `reservations/${page}/${size}` : "reservations")
        .then((response) => {
            if (response.success) return response.data;
            else return null;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });

export const getAllHostReservation = (
    hostId: string,
    page?: number,
    size?: number
): Promise<IReservationReq[] | null> =>
    http
        .simpleGet(
            page && size
                ? `host-reservations/${hostId}/${page}/${size}`
                : "host-reservations",
            hostId
        )
        .then((response) => {
            if (response.success) return response.data;
            else return null;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });

export const getOneReservation = (id: string): Promise<IReservationReq> =>
    http
        .simpleGet(`reservations/get/`, id)
        .then((response) => {
            if (response.success) return response.data;
        })
        .catch((err) => {
            console.log(err);
        });

export const getTransactionDetailByReservationId = (
    id: string
): Promise<ITransactionReq> =>
    http
        .simpleGet("transactions-details/reservation", id)
        .then((response) => {
            if (response.success) return response.data;
        })
        .catch((err) => {
            console.log(err);
        });

export const getPaymentMethodFees = (method: string): Promise<any> =>
    http
        .simpleGet("settings/payments-fees/getByType", method)
        .then((response) => {
            if (response.success) return response.data;
        })
        .catch((err) => {
            console.log(err);
        });

export const createBookingRefund = (data: IRefund): Promise<any> =>
    http
        .post("payments/refund/create", data)
        .then((response) => {
            if (response.success) return response.data.result;
        })
        .catch((err) => {
            console.log(err);
        });

export const createBookingRefundDohone = (data: IRefundDohone): Promise<any> =>
    http
        .post("dohone/refund/create", data)
        .then((response) => {
            if (response.success) return response;
        })
        .catch((err) => {
            console.log(err);
        });

export const createBookingRefundCredit = (data: IRefundCredit): Promise<any> =>
    http
        .post("payments/refundCredit/create", data)
        .then((response) => {
            if (response.success) return response;
        })
        .catch((err) => {
            console.log(err);
        });

export const saveTransaction = (transaction: ITransaction): Promise<any> =>
    http
        .post("transactions/create", transaction)
        .then((response) => {
            if (response.success) return response;
        })
        .catch((err) => {
            console.log(err);
        });

export const deleteResevationByIds = (ids: string[]): Promise<any | null> =>
    http
        .delete("reservations/deleteMany", { ids })
        .then((response) => {
            if (response.success) return response;
            else return null;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });

export const updateReservationState = (
    id: string,
    data: any
): Promise<any | null> =>
    http
        .put(`reservations/updatestate/${id}`, data)
        .then((response) => {
            if (response.success) return response;
            else return null;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
