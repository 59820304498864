import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinkOurDestination } from './LinkOurDestination';
import { LinkTypeAccomodation } from './LinkTypeAccomodation';
import { LinkExeptionalDeal } from './LinkExeptionalDeal';
import { Link } from 'react-router-dom';
import React from "react";
import { CountryService } from '../Admin/Countries/services/EquipmentCountry';
import { SortArray } from '../../utils/App';

const localStyles = makeStyles(() => ({
    drawerItem: {
        padding: 16
    },
    root: {
        background: "transparent !important",
        boxShadow: "none!important",
        color: "rgba(0, 0, 0, 0.6)",
        fontFamily: "Josefin Sans",
    },
    typo: {
        fontFamily: "Josefin Sans",
        color: "rgba(0, 0, 0, 0.6)",
    },
    details: {
        flexWrap: "wrap",
        justifyContent: "space-between",
        display: "flex"
    },
    link: {
        fontFamily: "Josefin Sans",
        color: "rgba(0, 0, 0, 0.6)",
        textDecoration: "none",
        
    },
    lien: {
        display: "flex",
        alignItems: "center",
        padding: "0 16px",
        height: 48
    },
    "@global": {
        ".css-1elwnq4-MuiPaper-root-MuiAccordion-root:before": {
            display: "none"
        }
    }
}));
const countryService= new CountryService();
export function MenuItemsMobile(props: {onMenuClick: ()=>void; closeAllDialogsOpen: ()=>void;closeMobileMenu:(val:boolean)=>void;setDeal:any,paysList:any; toggleMenuClose: () => void}) {
    const classes = localStyles();
    const { t, } = useTranslation();
    const [ , setPaysList] = React.useState<any[]>()
    React.useEffect(() => {
        countryService.getAllDestination().then(response => {
            if (response.success) {
                if(response.data!==undefined){
                    setPaysList(response.data.sort(SortArray))
                }
                
            }
        })
    }, [setPaysList])

    return (
        <>
            <Accordion classes={{root: classes.root}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#F92D5B"}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <span className={classes.typo}>{t("label.nos-destinations")}</span>
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.details}}>
                    <LinkOurDestination 
                        closeAllDialogsOpen={props.closeAllDialogsOpen} 
                        closeMobileMenu={props.closeMobileMenu} 
                        toggleMenuClose={props.toggleMenuClose}
                    />
                </AccordionDetails>
            </Accordion>      

            <Accordion classes={{root: classes.root}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#F92D5B"}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <span className={classes.typo}>{t("label.types-d-hebergements")}</span>
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.details}}>
                    <LinkTypeAccomodation  closeAllDialogsOpen={props.closeAllDialogsOpen} 
                    toggleMenuClose={props.toggleMenuClose}
                    />
                </AccordionDetails>
            </Accordion>     

            <Accordion classes={{root: classes.root}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#F92D5B"}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <span className={classes.typo}>{t("label.offres-exceptionnelles")}</span>
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.details}}>
                    <LinkExeptionalDeal closeAllDialogsOpen={props.closeAllDialogsOpen} setDeal={props.setDeal} paysList={props.paysList}
                    toggleMenuClose={props.toggleMenuClose}
                    />
                </AccordionDetails>
            </Accordion> 

            <div className={classes.lien}>
                <Link to="/blog" className={classes.link} onClick={props.toggleMenuClose}>
                    {t("label.blog")}
                </Link>
            </div>

            <div className={classes.lien}>
                <Link to="/guide-touristique" className={classes.link} onClick={props.toggleMenuClose}>
                    {t("label.guide-touristique")}
                </Link>
            </div>
        </>
    )
}
