import React, { useContext } from "react";
import {
  Button,
  Menu,
  MenuItem,
  makeStyles,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
// import { UserContext } from '../AppContext';
import Api from "../../https/Api";
import SessionHandler from "../../https/SessionHandler";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { colors } from "../../styles/defaultColors";
import { UserContext } from "../../contexts/AppContext";
import { ExpandMore } from "@material-ui/icons";
import { IUser } from "../../utils/UserInterfaces";
import { useTranslation } from "react-i18next";
import { defaultUserValue } from "../../utils/defaultNullValues";
import { ERoles } from "../../utils/enum";
import { RouteAdmin } from "../Admin/constant/routesName";
import LoginIcon from '@mui/icons-material/Login';
import EmailIcon from '@mui/icons-material/Email';
import { UserReservationService } from "../UserAdmin/UserReservations/services/UserReservationService";

let api = new Api();

const localStyles = makeStyles((theme) => ({
    expandMore: {
        width: 24,
        color: "#222222",
        opacity: "50%",
        marginLeft: 10,
        [theme.breakpoints.down(1025)]: {
            color: "rgba(0, 0, 0, 0.6)",
            opacity: 1
        },
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
    },
    menuButton: {
        backgroundColor: "transparent",
        border: "1px solid #DDDDDD !important",
        borderRadius: 150,
        maxWidth: 210,
        padding: "9px 14px",
        display: "flex",
        justifyContent: "space-around",
        boxShadow: "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        "&:hover": {
            background: "rgba(0, 0, 0, 0.04)"
        },
        [theme.breakpoints.down(1025)]: {
            color: "rgba(0, 0, 0, 0.6)",
            background: "transparent",
            margin: "20px auto",
            border: "1px solid #DDDDDD !important",
            boxShadow: "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
            // "&:hover": {
            //     backgroundColor: "transparent!important",
            // }
        },
        [theme.breakpoints.down("xs")]: {
            borderRadius: "50%",
            padding: 5,
            minWidth: "auto",
            width: 40,
            height: 40
        },
    },
    seConnecter: {
        background: "#890608",
        textTransform: "none",
        borderRadius: 100,
        padding: "9px 14px",
        "&:hover": {
            background: "#B5551B"
        },
        [theme.breakpoints.down(1025)]: {
            color: "rgba(0, 0, 0, 0.6)",
            background: "white",
            margin: "10px auto",
            border: "1px solid #DDDDDD !important",
            boxShadow: "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        },
    },
    
    seConnecterMobile: {
        background: "#890608",
        textTransform: "none",
        borderRadius: 200,
        padding: "9px 14px",
        "&:hover": {
            background: "#B5551B"
        },
        [theme.breakpoints.down(1025)]: {
            color: "rgba(0, 0, 0, 0.6)",
            background: "white",
            margin: "10px auto",
            border: "1px solid #DDDDDD !important",
            boxShadow: "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        },
    },

    menuIcons: {
        color: "#880608",
        [theme.breakpoints.down(1025)]: {
            color: "rgba(0, 0, 0, 0.6)",
        },
    },
    error: {
        color: colors.danger,
        background: colors.backgroundDanger,
        width: "100%",
        textAlign: "center",
        marginTop: 26,
        borderRadius: 5,
        "& p": {
        padding: 12,
        },
    },
    iconNotification: {
        position: "absolute", 
        top: -10, 
        background: "blue", 
        padding: 5, 
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        width: 20,
        height: 20,
        right: -8,
        color: "#ffffff",
        fontSize: 12,
        fontWeight: "bold",
        alignItems: "center",
    }
}));

const userReservationService = new UserReservationService();

function MenuUser(props: { handleClickOpenLoginDialog?: () => void }) {
    const [anchorElement, setAnchorElement] = React.useState(null);
    // const [paperWidth, setPaperWidth] = React.useState(0);
    const history = useHistory();
    const classes = localStyles();
    // const [loading, setLoading] = React.useState(true);

    // const theme = useTheme();

    const { t } = useTranslation();

    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const userContext = useContext(UserContext);
    const [data, setData] = React.useState(0);
    const { user, setUser } = userContext;
    const isConnected = sessionHandler.isConnected;
    React.useEffect(() => {
        if (!user.email) {
        isConnected()
            .then((response: any) => {
            if (response.success) {
                setUser(response.data);
            }
            })
            .catch((err) => {
            // history.push(history.location.pathname);
            });
        }
        else {
            userReservationService
            .getPendingReservations(user._id)
            .then((response) => {
                if (response.success){
                    setData(response.data)
                }
            })
            .catch(err => console.log(err))
        }
    }, [user, history, isConnected, setUser, data]);

    const handleClick = (event: any) => {
        setAnchorElement(event.currentTarget);
        // setPaperWidth(event.currentTarget.clientWidth);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const handleClickOpen = () => {
        if (props.handleClickOpenLoginDialog) props.handleClickOpenLoginDialog();
    };

    const myAccount = () => {
        setAnchorElement(null);
    };

    const logout = () => {
        // console.log('window.location.href',window.location.href)
        sessionHandler.removeToken();
        api.abort();
        setAnchorElement(null);
        const setUserValueToNull: IUser = defaultUserValue;
        const recentRoleUser = user.role.name;
        setUser(setUserValueToNull);
        if (
            recentRoleUser === ERoles.SUPER_ADMIN ||
            recentRoleUser === ERoles.ADMIN_SUPPORT ||
            recentRoleUser === ERoles.COUNTRY_ADMIN ||
            // recentRoleUser === ERoles.GUEST ||
            recentRoleUser === ERoles.SALES_AGENT
        ) {
            history.push(RouteAdmin.ACCOUNT_LOGIN);
        } else {
            if (history.location.pathname !== '/search') {
                history.replace(history.location.pathname);
            }
        }
    };
    const localClasses = localStyles();
  return (
    <>
        {user._id ? (
            <>
            <Button
                variant="contained"
                onClick={handleClick}
                className={classes.menuButton}
            >
                <AccountCircleIcon className={classes.menuIcons} />
                
                <ExpandMore className={localClasses.expandMore} />
            </Button>

            <Menu
                open={Boolean(anchorElement)}
                anchorEl={anchorElement}
                onClose={handleClose}
                // PaperProps={{ style: { minWidth: paperWidth } }}
                PaperProps={{ style: { minWidth: 180 } }}
            >
                {/* <Link style={{textDecoration: "none", color: "inherit"}} to="/announces"><MenuItem onClick={myAccount}>{t('menuUser.mesAnnonces')}</MenuItem></Link> */}
                {
                     ((user.role.name===ERoles.GUEST && user.accountType==='host') || (user.role.name===ERoles.GUEST && user.accountType==='traveller') || (user.role.name === ERoles.SUPER_ADMIN) || (user.role.name === ERoles.HOTEL_ADMIN)) &&
                    <>
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/profil"
                        >
                            <MenuItem onClick={myAccount}>{t("menuUser.monProfil")}</MenuItem>
                        </Link>
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/announces"
                        >
                            <MenuItem onClick={myAccount}>
                                {t("menuUser.mesAnnonces")}
                            </MenuItem>
                        </Link>
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/credit"
                        >
                            <MenuItem onClick={myAccount}>
                                Mes crédits
                            </MenuItem>
                        </Link>
                        
                        {
                            user.role.name === ERoles.HOTEL_ADMIN && 
                            <Link
                                style={{ textDecoration: "none", color: "inherit" }}
                                to="/hotel"
                            >
                                <MenuItem onClick={myAccount}>{t("menuUser.mesHotels")}</MenuItem>
                            </Link>
                        }
                    </>
                }
                    <Link
                        style={{ textDecoration: "none", color: "inherit", position: "relative",  display: "flex", alignItems: "center" }}
                        to="/users/admin"
                    >
                        <MenuItem onClick={myAccount} style={{width: "100%",}}>
                            {t("menuUser.dashboard")}
                        </MenuItem>
                        <div style={{position: "absolute", display: "flex", right: 15}}>
                            <EmailIcon/>
                            <span className={classes.iconNotification}>{ data }</span>
                        </div>
                    </Link>
                     
                    
                
                {   
                    user.role.name === ERoles.SUPER_ADMIN ||
                    user.role.name === ERoles.ADMIN_SUPPORT ||
                    user.role.name === ERoles.COUNTRY_ADMIN ||
                    // user.role.name === ERoles.GUEST ||
                    user.role.name === ERoles.SALES_AGENT  ? (
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/admin/activity/announces"
                        >
                            <MenuItem onClick={myAccount}>
                                {t("menuUser.adminDashboard")}
                            </MenuItem>
                        </Link>
                        ) : (
                        ""
                    )
                }
                <MenuItem onClick={logout}>{t("menuUser.deconnexion")}</MenuItem>
            </Menu>
            </>
        ) : (<>
                <Hidden xsDown>
                    <Button
                        // edge="end"
                        className={classes.seConnecter}
                        color="inherit"
                        onClick={handleClickOpen}
                    >
                        {/* <AccountCircleIcon className={classes.menuIcons} />
                        <MenuIcon style={{ color: "#880608" }} aria-label="menu" /> */}
                        {/* {t("SignIn.title")} */}
                        {t('label.connexion')}
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <IconButton 
                        className={classes.seConnecterMobile}
                        color="inherit"
                        onClick={handleClickOpen}
                    >
                        <LoginIcon />
                    </IconButton>
                </Hidden>
            </>
        )}
    </>
  );
}

export default MenuUser;
