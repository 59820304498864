/* eslint-disable no-self-assign */
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

export const linksDataToString = (datas: any) => {
    let result: any = {}
    try {
        for (let key of Object.keys(datas)) {
            if (!datas[key].isLink) {
                result[key] = { state: convertToRaw(datas[key]?.state?.getCurrentContent()), isLink: datas[key].isLink };
                if (!result[key].state || result[key].state === '') delete result[key];
            } else result[key] = { ...datas[key] };
        }
    }
    catch (e) {
        console.log(e)
    }
    return result;
}

export const linksStringToData = (datas: any) => {
    let result: any = { ...datas }
    try {
        for (let key of Object.keys(result)) {
            if (!result[key].isLink && result[key].state) {
                result[key].state = EditorState.createWithContent(convertFromRaw({ ...result[key]?.state, entityMap: { ...result[key]?.state?.entityMap } }))
            } else {
                result[key].state = "https://" + result[key].state
            }
        }
    }
    catch (e) {
        console.log(e)
    }
    return result;
}

export const linksStringToDataEdit = (datas: any) => {
    let result: any = { ...datas }
    try {
        for (let key of Object.keys(result)) {
            if (!result[key].isLink && result[key].state) {
                result[key].state = EditorState.createWithContent(convertFromRaw({ ...result[key]?.state, entityMap: { ...result[key]?.state?.entityMap } }))
            } else {
                result[key].state = result[key].state
            }
        }
    }
    catch (e) {
        console.log(e)
    }
    return result;
}

export const setRef = (name: string) => {
    const key = name.replace(/ /g, '_').replace(/'/g, '_');
    return { name, key }
}