import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next'

interface IAfriqDateRangeProps{
    startDate: Moment | null;
    endDate: Moment | null;
    handleDateSelection: (values: {startDate: Moment | null; endDate: Moment | null;})=>void;
    handleFocusChange: (value: FocusedInputShape | null)=>void;
    focusedInput: FocusedInputShape | null;
}
export const AfriqDateRange = ({startDate, endDate, focusedInput, handleFocusChange, handleDateSelection}: IAfriqDateRangeProps) => {
    const { t } = useTranslation();
    return (
        <>
            <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => handleDateSelection({ startDate, endDate })}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                startDatePlaceholderText={t("label.date-debut")}
                endDatePlaceholderText={t("label.date-fin")}
                readOnly
            />
        </>
    )
}
