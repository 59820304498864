import { snackBarType } from "../../utils/enum/snackBarType";
import ReactDOM from "react-dom";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

export function FlashMessage(params: {
    type: snackBarType; message: string; handleClose?: ()=>void;
}) {
    const container = document.createElement("div")
    document.body.appendChild(container)

    const onClose = () => {
        ReactDOM.unmountComponentAtNode(container)
    }

    ReactDOM.render(
        <>
             <Snackbar open={true} autoHideDuration={6000} onClose={onClose}>
            <Alert onClose={params.handleClose} severity={params.type}>
                {params.message}
            </Alert>
            </Snackbar>
        </>,
        
        container
    )

}

export function FlashErrorOccured() {
    FlashMessage({
        type: 'error',
        message: 'Une erreur est survenue'
    })
}