/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Grid, Typography, 
  CircularProgress,
  withStyles,
  } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { createDataAnnounceAd, DataAnnounceAd, DataAnnounceAd as DataRowTable, ListTableAnnounceAd } from "./List/ListTableAnnounceAd";
import { ContextMenu } from "./ContextMenu";
import { AnnounceAdService } from "../services/AnnounceAdService";
import { UserContext } from "../../../../contexts/AppContext";
import SessionHandler from '../../../../https/SessionHandler';
import { IAnnounceAdReq } from "../../../../utils/AnnounceInterfaces";
import Switch from "@material-ui/core/Switch";
import { colors } from "../../../../styles/defaultColors";
import { ShowConfirmDialog } from "../../../components/ShowConfirmDialog";
import moment from 'moment';
import 'moment/locale/fr'
import 'moment/locale/en-ca'
import { green } from "@material-ui/core/colors";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    listProperty: {},
    properties: {
      width: "100%",
      minHeight: 1000,
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        minHeight: 1200,
      },
    },
    propertiesHeader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    propertiesTitle: {
      fontSize: 22,
      fontFamily: "Josefin Sans",
      color: "#890608",
      marginTop: 40,
      textAlign: "left",
      margin: theme.spacing(2, 0)
    },
    propertiesCheck: {},
    propertiesCheckbox: {
      maxWidth: "60%",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
    },
    showErrors: {},
    checkboxLabel: {
      "&:not(:last-child)": {
        marginBottom: 20,
      },
      "& span": {
        fontFamily: "Josefin Sans",
        color: "#5F5F5F",
      },
    },
    checkboxLegend: {
      textAlign: "left",
      fontSize: 18,
      fontFamily: "Josefin Sans",
      color: "#000000",
      marginBottom: 30,
    },
    checkboxGroup: {},
    propertiesBtn: {
      maxWidth: "60%",
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
    },
    Button: {
      fontFamily: "Josefin Sans",
      color: "#890608",
    },
    errors: {
      color: colors.danger,
      background: colors.backgroundDanger,
      width: "100%",
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: 5,
      "& p": {
        padding: 10,
      },
    },

    btn: {
      width: 180,
      height: 40,
      color: "#FFFFFF",
      textTransform: "initial",
      fontSize: 14,
      fontFamily: "Josefin Sans",
      marginBottom: 18,
      borderRadius: 100,
      background: "#890608",
      "&:hover": {
          background: "#EA7606"
      }
    },
    linkContainerButton:{
        textDecoration: 'none'
    },
    
  })
);

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[600],
    },
    '&$checked + $track':{
      backgroundColor: green[600],
    },
  },
  checked: {},
  track: {},
})(Switch);

const announceAdService= new AnnounceAdService()
export const ListAnnouncesAds = (props:{ updateTable:boolean;setUpdateTable: any; setUpdateAnnounceSelectList:any;setAnnouncesAdsParent:any}) => {
  const classes = styles();
  const sessionHandler = React.useMemo(() => new SessionHandler(), []);
  const isConnected = sessionHandler.isConnected;
  const userContext = useContext(UserContext);
  const { user, setUser } = userContext;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [currentRow, setCurrentRow] = React.useState<DataRowTable>();
  const [announcesAds, setAnnouncesAds] = React.useState<DataAnnounceAd[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [deleteMultiple, setDeleteMultiple]= React.useState<boolean>(false)
  const history = useHistory();
  const {t, i18n} = useTranslation();
  i18n.on("languageChanged", function (lng) {
    moment.locale(lng)
  })

  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(()=> {
    moment.locale(i18n.language)
  }, [i18n.language])

  React.useEffect(() => {
        if (!user.email) {
        isConnected()
            .then((response: any) => {
                if (response.success) {
                    setUser(response.data);
                }
            })
            .catch((err) => {
                setLoading(false);
                history.push("/");
            });
    } else {  
        setLoading(true);
        announceAdService
        .getAllAnnouncesAds({ 
          owner: user._id })
        .then((response) => {
          const filteredList = response.data.filter((item: any) => item.announce.owner === user._id)
          

          setAnnouncesAds(filteredList.map((announceAd: IAnnounceAdReq) => {
            let count : any
            //let messageCounter : any 
            const DateDuJour = new Date ()
            
            if (moment(DateDuJour).isBetween(moment(announceAd.startAt), moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))){
              count = `${t("label.boost-will-end")} ${moment(DateDuJour).to(moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))}`
            }
            else if (moment(DateDuJour).isBefore(moment(announceAd.startAt))) {
              count = `${t("label.boost-will-begin")} ${moment(DateDuJour).to(moment(announceAd.startAt))}`
            }
            else {
              count = `${t("label.boost-ended")} ${moment(DateDuJour).to(moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))}`
            }

              const allowPublishing=<GreenSwitch
                      checked={announceAd.state}
                      onChange={()=> handleAllowPublishing(announceAd._id || '', !announceAd.state)}
                      name="anounceAdState"
                      inputProps={{ 'aria-label': 'isSubmitted checkbox' }}
                    />
                return createDataAnnounceAd(
                  announceAd._id ?? "",
                  announceAd.announce?.property.name ?? "",
                  announceAd.adParam?.placement,
                  announceAd.adParam?.duration,
                  announceAd.startAt ? moment(announceAd.startAt).format('DD/MM/YYYY') : "",
                  announceAd.startAt ? moment(announceAd.startAt).add(announceAd.adParam?.duration, 'weeks').format("DD/MM/YYYY") : "",
                  announceAd.adParam?.price,
                  <span>{count}</span>,
                  allowPublishing
                )
              })
            );

            // console.log("response", response.data)
            
          props.setAnnouncesAdsParent(response.data.map((announceAd: IAnnounceAdReq) => {
            let count : any
            //let messageCounter : any 
            const DateDuJour = new Date ()
            
            if (moment(DateDuJour).isBetween(moment(announceAd.startAt), moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))){
              count = `${t("label.boost-will-end")} ${moment(DateDuJour).to(moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))}`
            }
            else if (moment(DateDuJour).isBefore(moment(announceAd.startAt))) {
              count = `${t("label.boost-will-begin")} ${moment(DateDuJour).to(moment(announceAd.startAt))}`
            }
            else {
              count = `${t("label.boost-ended")} ${moment(DateDuJour).to(moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))}`
            }
              return createDataAnnounceAd(
                announceAd._id ?? "",
                announceAd.announce?.property.name ?? "",
                announceAd.adParam?.placement,
                announceAd.adParam?.duration,
                announceAd.startAt ? moment(announceAd.startAt).format('MM/DD/YYYY') : "",
                announceAd.startAt ? moment(announceAd.startAt).add(announceAd.adParam?.duration, 'weeks').format("MM/DD/YYYY") : "",
                announceAd.adParam?.price,
                <span>{count}</span>,
                <span>{announceAd.state}</span>
              )
            }))
            setLoading(false);
        })
        .catch((error) => {
          console.log('list announces ads component error ', error);
            setLoading(false);
        });      
        
    }
  }, [setAnnouncesAds, setLoading, user, history, isConnected, setUser]);

   const handleAllowPublishing=(announceAdId:string, state:boolean)=>{
      announceAdService.allowOrBlockAnnoncePublishing(announceAdId, state).then(response=>{
        if(response.success){
          refreshAnnounceAdsList();
        }
      }).catch(error=>{
        console.log(error)
      })
    }


  const handleClickOpenContextMenu = (
    event: React.MouseEvent<HTMLAnchorElement>,
    row: DataRowTable
  ) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const refreshAnnounceAdsList = (id?: string) => {
    if(id){
      setAnnouncesAds(announcesAds.filter((announceAd) => announceAd.id !== id));
    }else{
        setLoading(true);
        announceAdService
          .getAllAnnouncesAds({ 
            owner: user._id })
          .then((response) => {
            const filteredList = response.data.filter((item: any) => item.announce.owner === user._id)

            setAnnouncesAds(filteredList.map((announceAd: IAnnounceAdReq) => {
                  let count : any
                //let messageCounter : any 
                const DateDuJour = new Date ()
                
                if (moment(DateDuJour).isBetween(moment(announceAd.startAt), moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))){
                  count = `${t("label.boost-will-end")} ${moment(DateDuJour).to(moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))}`
                }
                else if (moment(DateDuJour).isBefore(moment(announceAd.startAt))) {
                  count = `${t("label.boost-will-begin")} ${moment(DateDuJour).to(moment(announceAd.startAt))}`
                }
                else {
                  count = `${t("label.boost-ended")} ${moment(DateDuJour).to(moment(announceAd.startAt).add(announceAd.adParam.duration, 'weeks'))}`
                }
              const allowPublishing=<GreenSwitch
                        checked={announceAd.state}
                        onChange={()=> handleAllowPublishing(announceAd._id || '', !announceAd.state)}
                        name="anounceAdState"
                        inputProps={{ 'aria-label': 'isSubmitted checkbox' }}
                      />
                  return createDataAnnounceAd(
                    announceAd._id ?? "",
                    announceAd.announce?.property.name ?? "",
                    announceAd.adParam?.placement,
                    announceAd.adParam?.duration,
                    announceAd.startAt ? moment(announceAd.startAt).format('DD/MM/YYYY') : "",
                    announceAd.startAt ? moment(announceAd.startAt).add(announceAd.adParam?.duration, 'weeks').format("DD/MM/YYYY") : "",
                    announceAd.adParam?.price,
                    <span>{count}</span>,
                    allowPublishing
                  )
                })
              );
              setLoading(false);
          })
          .catch((error) => {
              console.log('list announces ads component error ', error);
              setLoading(false);
          });    
    }
  };
  const handleCloseContextMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteSelectedRow = () => {
    handleClickOpenDialog();
    setDeleteMultiple(true);

  };

  const handleClickConfirmDialog = () => {
      if(deleteMultiple){
        if(selected.length)deleteMany();
      }else{
          if(currentRow) deleteOne(currentRow?.id)
      }
  };
  
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteOne = (id: string) => {
    announceAdService
      .deleteAnnounceAd(id)
      .then((response) => {
        if (response.success) {
          setSelected(selected.filter((selId) => selId !== id));
          refreshAnnounceAdsList(id);
          props.setUpdateAnnounceSelectList(true);
        }
        setDeleteMultiple(false);
      })
      .catch((error) => {
        console.log("list promotion component error", error);
        setDeleteMultiple(false);
      });
      handleCloseDialog();
  };

  const deleteMany = () => {
    // console.log("selected selected",selected)
    announceAdService
      .deletesAnnouncesAds(selected)
      .then((response) => {
        if (response.success) {
            refreshAnnounceAdsList()
            setSelected([]);
            props.setUpdateAnnounceSelectList(true);
        }
        setDeleteMultiple(false);

      })
      .catch((error) => {
        console.log("list promotion context component error", error);
        setDeleteMultiple(false);
      });
      handleCloseDialog();
  };
  
  const confirmDialog=ShowConfirmDialog({open: openDialog, handleClose: handleCloseDialog, handleConfirm: handleClickConfirmDialog, title: selected.length > 1 ? t('boost.confirmDialog.title-pluriel'): t('boost.confirmDialog.title'), description: selected.length >1 ? t('promotion.confirmDialog.description-pluriel'): t('promotion.confirmDialog.description')})
  return (

    <div className={classes.listProperty}>     
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.propertiesTitle}
            >
             {t('label.mes-annonces-boostes')}
            </Typography>
            {
              loading &&  (<CircularProgress size={32} style={{color: '#890608'}} />)
            }
            {
              !loading &&
                <ListTableAnnounceAd
                  openContextMenu={handleClickOpenContextMenu}
                  announceAds={announcesAds}
                  handleDeleteMultipleRow={handleDeleteSelectedRow}
                  selected={selected}
                  setSelected={setSelected}
                />
            }

            <ContextMenu
              handleClose={handleCloseContextMenu}
              handleDeleteRow={handleClickOpenDialog}
              open={open}
              anchorEl={anchorEl}
              row={currentRow}
              showEditButton={false}       
              showDeleteButton={false} 
            />
            {confirmDialog}

          </Grid>
        </Grid>
    </div>
  );
};
