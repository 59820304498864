/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, createStyles, } from '@material-ui/core';
import React from 'react';
import { CountryService } from '../Admin/Countries/services/EquipmentCountry';
import { SearchInputs } from '../../utils/AnnounceInterfaces';
import { searchDefaultValue } from '../../utils/defaultNullValues';
import { useTranslation } from 'react-i18next'
import { PropertyService } from '../Properties/services/PropertyService';
import { SearchExceptionalLink } from './SearchExceptionalLink';
import moment, {Moment} from "moment";
import {FocusedInputShape} from "react-dates";
import { SortArray } from '../../utils/App';
const useStyles = makeStyles(() =>
    createStyles({
        section: {
            background: "#ffffff", 
            height: "auto", 
            width: "100%", 
            zIndex: 100,
            borderRadius: "0px 0px 5px 5px",
            padding: "2rem 1.5rem 2rem",
            marginTop: -5
        },
        mwidth: {
            maxWidth: "75rem",
            margin: " 0 auto",
            
        },
    })
);
const countryService= new CountryService();
const propertyService = new PropertyService();
export function LinkExeptionalDealMobile(props: { closeAllDialogsOpen: ()=>void; setDeal:any, paysList:any, setOpenDrawerDeal: any }) {
    const { i18n} = useTranslation();
    const classes = useStyles();
    // const { t } = useTranslation();
    const [focusedInput, setFocusedInput] =
        React.useState<FocusedInputShape | null>(null);
    i18n.on("languageChanged", function (lng) {
        moment.locale(lng);
    });
    const [paysSelected, setPaysSelected] = React.useState<any>();
    const [country, setPays] = React.useState<string>("");
    const [paysList, setPaysList] = React.useState<any[]>()
    const [search, setSearch] = React.useState<SearchInputs>(searchDefaultValue);
    const [startDate, setStartDate] = React.useState<Moment | null>(null);
    const [endDate, setEndDate] = React.useState<Moment | null>(null);
    const [ , setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const onChangeHandler = (event: any): void => {
        setSearch({ ...search, city: event.target.value });
        //setEquipmentsSelected(value);
    };
    const [ ,setPropertyTypeList]=React.useState([])
    const [equipement,setEquipement]=React.useState([])
    
    props.setDeal(true)
    const onChangeCherchPays = (value: any): void => {
        props.setDeal(true)
        setPaysSelected(value);
        if (value != null) {
            setPays(value.name);
        }
    };
    const handlendDatesChange = (arg: {
        startDate: Moment | null;
        endDate: Moment | null;
    }) => {
        setStartDate(arg.startDate);
        setEndDate(arg.endDate);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFocusChange = (arg: FocusedInputShape | null) => {
        setFocusedInput(arg);
    };
    React.useEffect(()=>{
        propertyService
            .getAllPropertyTypes()
            .then((response) => {
                if (response.success) {
                    setPropertyTypeList(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
                
            });
        propertyService
            .getAllEquipement()
            .then((response) => {
                
                if (response.success) {
                    setEquipement(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
                
            });
    },[])
    let tabEqui:any=[]
    equipement && equipement.forEach((aqui:any)=>{
        tabEqui.push(aqui.name.split(",").join("-"))
    })
    // const onChangeCherchPays = (value: any): void => {
    //     setPaysSelected(value);
    //     if(value!=null){
    //         setPays(value.name);
    //     }
        
        
    // };
    React.useEffect(() => {
        countryService.getAllDestination().then(response => {
            if (response.success) {
                if(response.data!==undefined){
                    setPaysList(response.data.sort(SortArray))
                }
                
            }
        })
    }, [setPaysList])
    React.useEffect(() => {
        
        setSearch({
            ...search,
            country
        });
    }, [country])
    return (
        <section className={classes.section}>
            <div className={classes.mwidth}>
                <SearchExceptionalLink 
                    search={search}
                    paysList={paysList}
                    paysSelected={paysSelected}
                    onChangeCherchPays={onChangeCherchPays}
                    onChangeHandler={onChangeHandler}
                    startDate={startDate}
                    endDate={endDate}
                    handlendDatesChange={handlendDatesChange}
                    focusedInput={focusedInput}
                    handleFocusChange={handleFocusChange}
                    handleClick={handleClick}
                    closeAllDialogsOpen={props.closeAllDialogsOpen}
                    setDeal={props.setDeal}
                    setOpenDrawerDeal={props.setOpenDrawerDeal}
                />
            </div>
        </section>
    );
}