import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Box, Stack, Typography } from "@mui/material";
// import { colors } from "../../../../styles/defaultColors";
import AnnounceSwitch from "../../../Announces/components/AnnounceSwitch";
import { createStyles, makeStyles } from '@material-ui/core';

export function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => ("#890608"),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export const NotificationSwitch = (props: {
  title: string;
  subtitle: string;
  onSwitch: (state: boolean) => void;
  state: boolean;
  loading?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Stack direction="row" alignItems="center">
      <Stack direction="column" padding={2} flex={"1"}>
        <Typography variant="h6" className={classes.h6Font}>{props.title}</Typography>
        {/* <Typography style={{ color: colors.gray }}>{props.subtitle}</Typography> */}
      </Stack>
      <Box alignItems="center" justifyContent="center" padding={2}>
        {props.loading && <FacebookCircularProgress />}
        {!props.loading && <AnnounceSwitch
          noTitle
          id="announceSwitch"
          label={""}
          name="superOffer"
          value={props.state ? 1 : 0}
          handleChange={() => {
            /** */
          }}
          onChekedChange={props.onSwitch}
        />}
      </Box>
    </Stack>
  );
};
const useStyles = makeStyles((theme) =>
    createStyles({
      h6Font: {
            [theme.breakpoints.down("xs")]: {
                   fontSize: "14px !important"
            },
        },
        
    })
);
