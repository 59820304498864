/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles, createStyles, CircularProgress, } from '@material-ui/core';
import React, { useContext } from "react";
import { IAnnounce } from '../../utils/AnnounceInterfaces';
import { CountryService } from '../Admin/Countries/services/EquipmentCountry';
import { HomeService } from '../Home/services/HomeService';
import { PropertyService } from '../Properties/services/PropertyService';
import { useQuery } from '../../utils/App';
import { useHistory } from 'react-router-dom';
import { CountryPhoneList } from '../../utils/CountryList';
import { CurrencyContext } from '../../contexts/AppContext';
import { useTranslation } from 'react-i18next';

const propertyService = new PropertyService();
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            width: "18%",
            margin: "0 1.5rem 1.5rem 0",
            [theme.breakpoints.down("xs")]: {
                width: "auto",
                marginRight: 0,
                [theme.breakpoints.down("xs")]: {
                    maxWidth: 150
                },
            },
        },
        contentCard: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        titleContent: {
            color: "#000000",
            fontSize: 16,
            fontWeight: "bold",
            marginBottom: 0,
        },
        ul: {
            display: "list-item",
            listStyle: "none",
            padding: 0,
            marginBottom: 0,
            marginTop: 10
        },
        li: {
            marginBottom: 2,
            cursor: "pointer"
        },
        linkCard: {
            fontSize: 16,
            fontWeight: 300,
            color: "#000000",
            [theme.breakpoints.down("sm")]: {
                color: "rgba(0,0,0,0.6)",
            },
        },
        image: {
            width: "150px",
            height: "100px",
        },
        slideLocation: {
            "& > .slick-list": {
                height: 350,
                width: 1200
            },
        },
        "@global": {
            ".slick-slide": {
                width: "250px !important"
            }
        }
    })
);
const countryService = new CountryService();
const homeService = new HomeService();

export function LinkTypeAccomodation(props: { closeAllDialogsOpen: () => void; toggleMenuClose?: () => void }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [typeLogement, setPropertyTypeList] = React.useState([])
    const [listCountry, setListCountry] = React.useState([])
    const [announces, setAnnounces] = React.useState<IAnnounce[]>();
    const [loading, setLoading] = React.useState<boolean>(true);
    let query = useQuery();
    const history = useHistory();
    const currencyContext = useContext(CurrencyContext);
    const { currency, } = currencyContext;

    React.useEffect(() => {
        propertyService
            .getAllPropertyTypes()
            .then((response) => {
                if (response.success) {
                    setPropertyTypeList(response.data);
                }
            })
            .catch((err) => {
                console.log(err);

            });
    }, [])

    // let tabEqui: any = []
    // equipement && equipement.forEach((aqui: any) => {
    //     tabEqui.push(aqui.name.split(",").join("-"))
    // })
    React.useEffect(() => {
        countryService.getAllDestination().then(response => {
            if (response.success) {
                setListCountry(response.data)
            }
        })
    }, [setListCountry])

    React.useEffect(() => {
        homeService
            .getListAnnounces()
            .then((response) => {
                if (response.success) {

                    setAnnounces(response.data.filter((published: any) => published.state === "Published"));
                }
            })
            .catch(console.log).finally(() => setLoading(false));
    }, []);
    let tab: any = []

    typeLogement && typeLogement.forEach((typeLogement: any) => {
        let announce = announces && announces.filter(anonce => (anonce.property?.type.name === typeLogement.name))
        let county = announce?.map((country: any) => country.property?.address.country)
        tab.push({ type: typeLogement.name, announce: county, label: typeLogement.label ? t(typeLogement.label) : typeLogement.name })
    })




    const clickType = (type: any, country: any) => {
        let super_offer = 0
        query.set('search_adresse', '');
        query.set('offer', super_offer.toString());
        query.set('currency', currency.toString())
        query.set('currencyBase', CountryPhoneList.filter((devi: any) => devi.name?.toLowerCase() === country?.toLowerCase())[0]?.currencyCode)
        query.set(`eqt`, [""].toString());
        query.set('serchex', '');
        query.set(`page`, "1");
        query.set('click', type.toString());
        query.set(`rooms`, [1, 2, 3].toString());
        query.set('search_pays', country.toString());
        query.set(`luxury`, "false");
        query.set(`perle`, "false");
        query.set(`business`, "false");
        props.closeAllDialogsOpen();
        if (props.toggleMenuClose) props.toggleMenuClose()

        history.push({ pathname: "/search", search: query.toString() });
    }
    return (
        <>
            {
                tab.length > 0 && !loading ? tab.map((list: any) => {
                    return (
                        <>
                            {
                                (list.announce !== undefined &&
                                    list.announce.length > 0) ?
                                    <div className={classes.card}>
                                        <div className={classes.contentCard}>
                                            <h3 className={classes.titleContent}>
                                                {list.label}
                                            </h3>
                                            {
                                                listCountry.length > 0 && listCountry.map((country: any) => {
                                                    if (list.announce !== undefined && list.announce.includes(country.name)) {
                                                        return (
                                                            <>
                                                                <ul className={classes.ul}>
                                                                    <li className={classes.li}>
                                                                        <a onClick={() => clickType(list.type, country.name)} className={classes.linkCard} >
                                                                            {country.name}
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }

                                        </div>
                                    </div> : ''
                            }
                        </>

                    )
                }
                ): 
                <div style={{display: 'flex', width: '100%', justifyContent:'center'}}>
                    <CircularProgress size={25}
                            style={{ color: "#890608" }}/>
                </div>
            }
        </>
    );
}
