import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { IUserService } from '../../../../Services/userServecesServices';
import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles= makeStyles((theme: Theme)=>createStyles({
    listItemText: {
        fontSize: '0.875rem',
        paddingTop: '0px!important' as any,
        '& .css-10hburv-MuiTypography-root':{
            fontSize: '0.875rem',
            paddingTop: 0
        }
    },
    body:{
        marginBottom: 16,
        display: 'block'
    }
})
)
export default function BookingCardListServices({services}:{services:IUserService[]}) {
    const classes=useStyles();
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 350,
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
        <li>
          <ul>
            <ListSubheader style={{padding: 0, textDecoration: 'underline'}} >Services: </ListSubheader>
            {services.map((service) => (
              <ListItem key={`item-${service.name}-${service._id}`} className={classes.listItemText}>
                <ArrowRightAltIcon/> <ListItemText primary={`Item ${service.name}`} className={classes.listItemText}/>
              </ListItem>
            ))}
          </ul>
        </li>
    </List>
  );
}