import useWebSocket, { ReadyState } from "react-use-websocket";
import SessionHandler from "../https/SessionHandler";
import { serverConfig } from "../utils/config";
import { useUser } from "./UserHooks";

const Env = serverConfig;
let token = new SessionHandler().getAccessToken();

const treatMessageData = (e: MessageEvent<any> | null) => {
    if (e) {
        let result;
        try {
            result = JSON.parse(e.data);
        } catch (err) {
            result = e.data;
        }
        return result;
    } else return null;
};

export const useWS = () => {
    const { user } = useUser();

    const queryParams = {
        user: user._id,
        token: token ?? "",
    };

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        Env.SockRoot,
        { queryParams }
    );

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    const wsSend = (path: string, data: any) => {
        sendMessage(JSON.stringify({ path, data, ...queryParams }));
    };

    const tMsg = treatMessageData;

    return { lastMessage, wsSend, connectionStatus, tMsg };
};
