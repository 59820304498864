import Api from "../https/Api";

const http = new Api();
export interface IUserService {
    _id: string;
    name: string;
    userId: string;
    description: string;
    price: number
}
type tUserServiceData = Omit<IUserService, '_id'>;

export const AddUserService = (newUs: tUserServiceData): Promise<IUserService[] | null> => http
    .post('userservices/add', newUs)
    .then((respoonse) => {
        if (respoonse && respoonse.success) return respoonse.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    })

export const getUserServiceByUserId = (userId: string): Promise<IUserService[] | null> => http
    .get('userservices/' + userId)
    .then((respoonse) => {
        if (respoonse && respoonse.success) return respoonse.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    })

export const DeleteServiceById = (id: string): Promise<IUserService[] | null> => http
    .delete('userservices/remove/' + id).then((respoonse) => {
        if (respoonse && respoonse.success) return respoonse.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    })

export const UpdateService = (id: string, service: tUserServiceData): Promise<IUserService[] | null> => http
    .put('userservices/update', { id, service }).then((respoonse) => {
        if (respoonse && respoonse.success) return respoonse.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    })