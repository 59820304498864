import { makeStyles, createStyles, TextField, InputAdornment, Typography } from '@material-ui/core';
import React from 'react';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import Button from '@material-ui/core/Button';
import Spin from '../../../images/Spin';
import { ResetPasswordService } from './services/ResetPasswordService';
import { useState } from 'react';
import { Error } from '@material-ui/icons';
import { colors } from '../../../styles/defaultColors';
import { Messages } from '../../../utils/enum';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>  createStyles({
    formLoginContainer:{
        height: '30vh'
    },
    inputForm: {
        display: "flex",
        marginBottom: theme.spacing(2)
    },
    forgotPassword:{
        textAlign: 'right',
        textDecoration: 'none',
        marginBottom: theme.spacing(2)
    },
    register:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },
    registerLink:{
        color: '#EA7606'
    },
    link:{
        textAlign: 'right',
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 600
    },
    buttonLink:{
        textAlign: 'right',
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 600,
        textTransform: "initial"
    },
    buttons:{
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    loginButton:{
        width: 200,
        background: "#f43636",
        color: "white",
        // borderRadius: 100,
        borderRadius: 5,
        fontFamily: 'Josefin Sans',
        fontSize: 14,
        textTransform: "none",
        padding: "8px 14px",
        "&:hover": {
            background: "#f52020"
        },
    },
    error: {
        color: colors.danger,
        background: colors.backgroundDanger,
        width: "100%",
        textAlign: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: 5,
        "& p": {
            padding: 10
        }
    },
  })
);

const resetPasswordService =new ResetPasswordService();


export const GeneratePasswordCodeForm = (props:any) => {
    const classes = useStyles();

    const [email, setEmail] = useState<string>("")
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false); 
    // const [open, setOpen] = React.useState(false);   
       
    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleEmailChange = (event:any) => {       
        setError("");
        setEmail(event.target.value.toLowerCase());
    };
   
    const handleResetPasswordSubmit = (evt:any) => {
        evt.preventDefault();
        setError("");

        if (!email) {            
            setError('EMAIL');
            return;
        }
       
        setLoading(true);

        resetPasswordService.generatePasswordResetCode({email }).then((response:any)=>{                     
            setLoading(false); 
            
            if (response?.success) {
                setLoading(false)
                props.setCode(response.code)
            }
            // if(response.message=== Messages.RESET_PASSWORD_CODE_SENT) {
            //     setOpen(true); 
            // }                               
        }).catch( ( err:Error) => { 
            if (err.message === Messages.USER_NOT_FOUND) {
                setError('USER_NOT_FOUND'); 
            } else {
                setError('NETWORK_ERROR');
            }                    
            setLoading(false);
        });
    }

    const { t } = useTranslation();
    
    return (
        
        <form autoComplete="off" className={classes.formLoginContainer}>
            <div className={classes.inputForm}>
            <TextField
                autoFocus
                margin="dense"
                id="input-email-with-icon-textfield"
                type="email"
                fullWidth
                placeholder="Email"
                onChange={handleEmailChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlinedIcon />
                        </InputAdornment>
                    ),
                  }}
            />
        </div>  
                     
        <div className={classes.buttons}>            
            <Button 
                type="button"
                endIcon={loading ? <Spin /> : null}
                disabled={loading}
                className={classes.loginButton}
                onClick={handleResetPasswordSubmit}
            >
                {t('SignIn.resetPassword.generateButton')}
            </Button>
        </div>  

        {error && (
            <div className={classes.error}>
                <Typography>
                    <Error /><br />
                    {error === "EMAIL" && t('SignIn.errors.emptyEmail')}
                    {error === 'USER_NOT_FOUND' && t('SignIn.errors.mailNotFound')}                    
                    {error === 'NETWORK_ERROR' && Messages.NETWORK_ERROR}                    
                </Typography>
            </div>
        )} 

        
    </form>
    );
}