/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import { Stack, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import { blueGrey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import PnBDialogue from "./AddDlg";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FiEdit, FiTrash } from "react-icons/fi";
import PnbAlertDialog from "./AlertDlg";
import {
    AddUserService,
    DeleteServiceById,
    getUserServiceByUserId,
    IUserService,
    UpdateService,
} from "../../../Services/userServecesServices";
import { useUser } from "../../../Hooks/UserHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";

export interface IServiceItem {
    itemId: string;
    name: string;
    description: string;
    price: number;
    isPair?: boolean;
}

export const ServiceHeader = (props: { title: string; onAdd: () => void }) => {
    const { title, onAdd } = props;
    const { t } = useTranslation();
    const classes = useStyle();
    return (
        <Stack direction="row" alignItems="center">
            <Typography
                variant="h5"
                gutterBottom
                className={classes.promotionTitle}
            >
                {title}
            </Typography>
            <Box flex={1}></Box>
            <Button onClick={onAdd} startIcon={<AddIcon />} variant="outlined">
                {t("label.button-ajouter")}
            </Button>
        </Stack>
    );
};

export const ServiceUserItem = (props: {
    itemId: string;
    isPair?: boolean;
    name: string;
    description: string;
    price: number;
    onEdit: () => void;
    onDelete: () => void;
}) => {
    const { isPair, name, description, price, onEdit, onDelete } = props;
    const classes = useStyle();
    return (
        <Grid
            bgcolor={isPair ? blueGrey[50] : "#fff"}
            container
            p={3}
            alignItems="center"
            className={classes.gridMobile}
        >
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                    style={{
                        fontFamily: "inherit",
                        fontSize: 16,
                        color: "#37474f",
                    }}
                >
                    {name}
                </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={5}>
                <Typography
                    style={{
                        fontFamily: "inherit",
                        fontSize: 14,
                        color: "#37474f",
                    }}
                >
                    {description}
                </Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={1}>
                <Typography
                    style={{
                        fontFamily: "inherit",
                        fontSize: 16,
                        color: "#37474f",
                    }}
                >
                    {price.toLocaleString()} XAF
                </Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={3}
                >
                    <IconButton
                        onClick={onEdit}
                        color="primary"
                        component="span"
                    >
                        <FiEdit />
                    </IconButton>
                    <IconButton
                        onClick={onDelete}
                        color="warning"
                        component="span"
                        className={classes.deleteMobile}
                    >
                        <FiTrash />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default function UserServices() {
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [getingList, setGetingList] = React.useState(false);
    const { user } = useUser();
    const [servicesList, setServicesList] = React.useState<IUserService[]>([]);
    const [newService, setNewService] = React.useState<{
        name: string;
        price: number;
        description: string;
    }>({
        name: "",
        price: 100,
        description: "",
    });
    const [toModify, setToModify] = React.useState<IUserService | null>(null);

    const handleCloseDlg = () => {
        setOpen(false);
    };
    const handleAddService = () => {
        setIsAdding(true);
        if (!toModify) {
            AddUserService({ ...newService, userId: user._id })
                .then((data) => {
                    // console.log(data);
                    if (data) setServicesList(data);
                    setIsAdding(false);
                    setOpen(false);
                })
                .catch((err) => {
                    setIsAdding(false);
                    setOpen(false);
                });
        } else {
            UpdateService(toModify._id, { ...newService, userId: user._id })
                .then((data) => {
                    // console.log(data);
                    if (data) setServicesList(data);
                    setIsAdding(false);
                    setOpen(false);
                })
                .catch((err) => {
                    setIsAdding(false);
                    setOpen(false);
                });
        }
    };

    const handleShowAddNewService = () => {
        setToModify(null);
        setNewService({
            name: "",
            price: 100,
            description: "",
        });
        setOpen(true);
    };

    const handleModifyItem = (toModify: IUserService) => {
        setToModify(toModify);
        setNewService({
            name: toModify.name,
            price: toModify.price,
            description: toModify.description,
        });
        setOpen(true);
    };

    const handleDeleteItem = (toModify: IUserService) => {
        setToModify(toModify);
        setOpenAlert(true);
    };

    const handleDeleteItemValidate = () => {
        setIsDeleting(true);
        DeleteServiceById(toModify?._id ?? "unkown")
            .then((data) => {
                // console.log(data);
                if (data) setServicesList(data);
                setOpenAlert(false);
                setIsDeleting(false);
            })
            .catch((err) => {
                setOpenAlert(false);
                setIsDeleting(false);
            });
    };

    React.useEffect(() => {
        if (user && user._id) {
            setGetingList(true);
            getUserServiceByUserId(user._id)
                .then((data) => {
                    // console.log(data);
                    if (data) setServicesList(data);
                    setGetingList(false);
                })
                .catch((err) => {
                    setGetingList(false);
                });
        }
    }, [user]);

    const { t } = useTranslation();

    const AddDialgAction = (
        <Stack direction="row" spacing={2} p={2}>
            <Button
                disabled={isAdding}
                onClick={handleAddService}
                startIcon={
                    isAdding ? (
                        <CircularProgress size={16} />
                    ) : toModify ? (
                        <FiEdit />
                    ) : (
                        <AddIcon />
                    )
                }
                variant="contained"
            >
                {toModify ? t("label.modifier") : t("label.ajouter")}
            </Button>
            <Button
                disabled={isAdding}
                color="error"
                onClick={handleCloseDlg}
                startIcon={<CloseIcon />}
                variant="contained"
            >
                {t("label.annuler")}
            </Button>
        </Stack>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ServiceHeader
                title={t("label.liste-des-services")}
                onAdd={handleShowAddNewService}
            />
            <Stack p={1}>
                <Typography variant="subtitle2">
                    {t("label.liste-des-services-subtitle")}
                </Typography>
            </Stack>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {getingList && (
                    <Stack direction="column" alignItems="center" p={4}>
                        <CircularProgress />
                    </Stack>
                )}
                {!getingList &&
                    servicesList.map((item, idx) => (
                        <ServiceUserItem
                            key={item._id}
                            itemId={item._id}
                            isPair={idx % 2 === 0}
                            name={item.name}
                            description={item.description}
                            price={item.price}
                            onEdit={() => {
                                handleModifyItem(item);
                            }}
                            onDelete={() => {
                                handleDeleteItem(item);
                            }}
                        />
                    ))}
            </Paper>
            <PnBDialogue
                mw={"sm"}
                open={open}
                setOpen={setOpen}
                title={
                    toModify
                        ? t("label.modifier-service")
                        : t("label.ajouter-service")
                }
                action={AddDialgAction}
            >
                <Stack direction="column" minWidth={440} spacing={3} p={2}>
                    <TextField
                        value={newService.name}
                        onChange={(e) => {
                            setNewService({
                                ...newService,
                                name: e.target.value ?? "",
                            });
                        }}
                        color="secondary"
                        id="dlg-add-name-text-field"
                        label={t("label.nom")}
                        variant="outlined"
                    />
                    <TextField
                        value={newService.price}
                        onChange={(e) => {
                            setNewService({
                                ...newService,
                                price: parseInt(e.target.value) ?? "",
                            });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    XAF
                                </InputAdornment>
                            ),
                        }}
                        type="number"
                        color="secondary"
                        id="dlg-add-name-text-field"
                        label={t("label.prix")}
                        variant="outlined"
                    />
                    <TextField
                        value={newService.description}
                        onChange={(e) => {
                            setNewService({
                                ...newService,
                                description: e.target.value ?? "",
                            });
                        }}
                        color="secondary"
                        id="outlined-multiline-static-description"
                        label="Description"
                        multiline
                        rows={4}
                    />
                </Stack>
            </PnBDialogue>
            <PnbAlertDialog
                title={`Delete ${toModify ? toModify.name : ""}?`}
                open={openAlert}
                setOpen={setOpenAlert}
                onValidate={handleDeleteItemValidate}
                warning
                loading={isDeleting}
            >
                <Typography>
                    {`${t("label.voulez-vous-vraiment")} ${
                        toModify ? toModify.name : ""
                    } ${t("label.de-la-liste")} ?`}
                </Typography>
            </PnbAlertDialog>
        </Box>
    );
}

/** STYLES */
const useStyle = makeStyles((theme) => ({
    deleteMobile: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0!important",
        },
    },
    gridMobile: {
        [theme.breakpoints.down("xs")]: {
            padding: "10px !important",
        },
    },
    promotionTitle: {
        fontSize: 22,
        fontFamily: "Josefin Sans",
        color: "#890608",
        marginTop: 5,
        marginBottom: 25,
    },
}));
