import React, { useContext } from 'react'
import Box from '@mui/material/Box';
// import { ITransactionData } from './BalanceItem';
import { WalletHistorique } from './Historiques';
import { ITransactionReq} from '../../../utils/PaymentInterfaces';
import { UserTransactionService } from '../../../Services/UserTransactionService';
import { UserContext } from '../../../contexts/AppContext';
import SessionHandler from '../../../https/SessionHandler';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


/** MAIN COMPONENT */
const userTransactionService= new UserTransactionService();
export default function UserTransactionHistory() {
    const [transactions, setTransactions]=React.useState<ITransactionReq[]>([])
    // const [gettingList, setGettingList] = React.useState(false);
    const userContext = useContext(UserContext);
    const { user, setUser } = userContext;
    const [ , setIsLoadingPage]= React.useState<boolean>(true);
    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const isConnected = sessionHandler.isConnected;
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyle();

    React.useEffect(()=>{
        if (!user.email) {
            isConnected()
                .then((response: any) => {
                    if (response.success) {
                        setUser(response.data);
                    }
                })
                .catch((err) => {
                    history.push("/");
                });
        } else {
            userTransactionService.getAllTransactionsByUserId(user._id).then(response=>{ 
                if(response.success){
                    // console.log('all transactions', response.data);
                    setTransactions(response.data)
                }
                setIsLoadingPage(false);  
            }).catch(error=>{
                console.log('userReservations error component', error)
            })
        }
    },[user, history, isConnected, setUser])

    
    return (
        <>
        <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        className={classes.reservationTittle}
                        variant="h5"
                        gutterBottom
                    >
                        {t("label.transactions-title")}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t("label.transactions-subtitle")}
                    </Typography>
                </Grid>
            </Grid>
        <Box>
            <WalletHistorique hLists={transactions} />
        </Box>
        </>
    )
}

// const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

/** MOCKUP DATA */

// const DEFAUTL_LIST: ITransactionData[] = Array.from(Array(18).keys()).map((i) => ({
//     id: "id-balence-transaction-item-" + i,
//     name: "Transaction item " + i,
//     date: new Date(),
//     price: random(75, 120),
//     description: "transaction description " + i,
//     type: i % 2 === 0 ? '+' : '-'
// }))

const useStyle = makeStyles((theme: Theme) => ({
    reservationTittle: {
        fontSize: 22,
        fontFamily: "Josefin Sans",
        color: "#890608",
        marginTop: 5,
        marginBottom: 25,
    },
}));