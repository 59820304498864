import Api from "../../../../https/Api";
export interface IUserAuth {
    email: string;
    password: string;
}

export interface IUserAuthResponse {
    email: string;
    password: string;
    role: string;
    token: string;
}

export class LoginService {
    private api;
    constructor() {
        this.api = new Api();
    }
    async authentificate(user: IUserAuth) {
        return this.api.post("users/authenticates", user);
    }
}
