import {
    Grid,
    makeStyles,
    TextField,
    createStyles,
    Theme,
} from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inpuText: {
            marginBottom: 14,
            marginTop: 6
        },
        inputLabel: {
            fontSize: 14,
            fontFamily: "Josefin Sans",
            color: "#000000",
        },
        containerLabel: {
            textAlign: "left",
        },
    })
);
export function PropertyTextField(props: {
    id: string;
    label: string;
    name: string;
    value: string | number | Array<string>;
    variant?: "filled" | "outlined" | "standard",
    handleChange: (e: any) => void;
}) {
    const classes = styles();
    const { id, label, name, value, handleChange } = props;
    return (
        <>
            <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                className={classes.containerLabel}
            >
                <label htmlFor={id} className={classes.inputLabel}>
                    {label}
                </label>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} className={classes.inpuText}>
                <TextField
                    id={id}
                    fullWidth
                    name={name}
                    value={value}
                    onChange={handleChange}
                    variant={props.variant ?? "outlined"}
                />
            </Grid>
        </>
    );
}
