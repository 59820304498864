/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { IUserMenuElement } from "../UAHeader";
import { colors } from "../../../../styles/defaultColors";
import { Box } from "@mui/system";
import SessionHandler from "../../../../https/SessionHandler";
import { UserContext } from "../../../../contexts/AppContext";
import React, { useContext } from 'react';
import { getDashboardListMenusForHost, getDashboardListMenusForTraveller } from "../../../../utils/App";
import { ERoles } from "../../../../utils/enum";
import { useHistory } from 'react-router-dom';


export default function UserAdminMenu(props: {
    menuLIst: IUserMenuElement[]
    onMenuChange: (currentMenue: number) => void,
    currentMenu: number,
}) {    
    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const userContext = useContext(UserContext);
    const { user, setUser } = userContext;
    const [menusList, setMenuList]= React.useState<any>([])
    const classes = useStyle();
    const isConnected = sessionHandler.isConnected;
    const isRefreshed = sessionHandler.isRefreshed;
    const history = useHistory();

    React.useEffect(() => {
        if (!user.email) {
        isConnected()
            .then((response: any) => {
            if (response.success) {
                setUser(response.data);
            }
            })
            .catch((err) => {
                isRefreshed()
                .then((response: any) => {
                if (response.success) {
                    setUser(response.user.data);
                    // setLoadingPage(false);
                }
                })
                .catch((error) => {
                    history.push(history.location.pathname);
                    // setLoadingPage(false);
                });
            
            });
        }
    }, [user, history, isConnected, setUser]);

    React.useEffect(()=>{
        let selectedMenus:React.ReactNode[]=[];
        props.menuLIst.forEach((m, i) => {
            if(user.accountType==='host'){
                if(getDashboardListMenusForHost().includes(m.id)){
                    selectedMenus.push((
                        <Box key={"menu-item-navigation-user-admin-" + i} width="100%">
                            <MenuItem onClick={() => { m.onClick(i) }}>
                                {props.currentMenu === i && <div className={classes.activeIndicator}></div>}
                                <ListItemIcon sx={{ padding: 1, color: props.currentMenu === i ? colors.primary : undefined }}>
                                    {m.icon}
                                </ListItemIcon>
                                <ListItemText sx={{ color: props.currentMenu === i ? colors.primary : undefined }}>
                                    {m.title}
                                </ListItemText>
                                <ListItemText>
                                    {m.notification}
                                </ListItemText>
                            </MenuItem>
                            {(i < (props.menuLIst.length - 1)) && <div className={classes.divider} />}
                        </Box>
                    ))
                }
            }else if(user.accountType==='traveller'){
                if(getDashboardListMenusForTraveller().includes(m.id)){
                    selectedMenus.push((
                        <Box key={"menu-item-navigation-user-admin-" + i} width="100%">
                            <MenuItem onClick={() => { m.onClick(i) }}>
                                {props.currentMenu === i && <div className={classes.activeIndicator}></div>}
                                <ListItemIcon sx={{ padding: 1, color: props.currentMenu === i ? colors.primary : undefined }}>
                                    {m.icon}
                                </ListItemIcon>
                                <ListItemText sx={{ color: props.currentMenu === i ? colors.primary : undefined }}>
                                    {m.title}
                                </ListItemText>
                                <ListItemText>
                                    {m.notification}
                                </ListItemText>
                            </MenuItem>
                            {(i < (props.menuLIst.length - 1)) && <div className={classes.divider} />}
                        </Box>
                    ))
                }
            }else if(user.role.name===ERoles.SUPER_ADMIN){
                selectedMenus.push((
                    <Box key={"menu-item-navigation-user-admin-" + i} width="100%">
                        <MenuItem onClick={() => { m.onClick(i) }}>
                            {props.currentMenu === i && <div className={classes.activeIndicator}></div>}
                            <ListItemIcon sx={{ padding: 1, color: props.currentMenu === i ? colors.primary : undefined }}>
                                {m.icon}
                            </ListItemIcon>
                            <ListItemText sx={{ color: props.currentMenu === i ? colors.primary : undefined }}>
                                {m.title}
                            </ListItemText>
                            <ListItemText>
                                {m.notification}
                            </ListItemText>
                        </MenuItem>
                        {(i < (props.menuLIst.length - 1)) && <div className={classes.divider} />}
                    </Box>
                ))
            }
        })
        
        setMenuList(selectedMenus)
    },[props.menuLIst])

    return (
        <Stack className={classes.menuContainer} direction="column" >
            <MenuList disablePadding>
                {
                    menusList.length> 0 &&
                    menusList.map((el:any)=>(el))
                }
            </MenuList>
        </Stack>
    )
}

/** STYLES */
const useStyle = makeStyles(() => ({
    menuContainer: {
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#cccccc",
        overflow: "hidden"
    },
    activeIndicator: {
        backgroundColor: colors.primary,
        width: 4,
        height: "100%",
        position: "absolute",
        left: 0,
    },
    divider: {
        width: "100%",
        height: 1,
        backgroundColor: "#cccccc",
    }
}));