import { Button, makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import React, {
    useRef,
    useState,
} from "react";
import { SyntheticEvent } from "react-draft-wysiwyg";
import { useDohone } from "../../../Hooks/useDohone";
import { useUser } from "../../../Hooks/UserHooks";
import Spin from "../../../images/Spin";
import { serverConfig } from "../../../utils/config";
import {
    IAnnounceAdPayment,
} from "../../../utils/PaymentInterfaces";
import { ConfirmationReservationService } from "../../Reservation/services/ConfirmationReservationService";
import { FlashErrorOccured } from "../FlashMessage";
import { useTranslation } from "react-i18next";
import { IAnnounceAd } from "../../../utils/AnnounceInterfaces";

const styles = makeStyles(() => ({
    btn: {
        width: 180,
        height: 40,
        color: "#FFFFFF",
        textTransform: "initial",
        fontSize: 14,
        fontFamily: "Josefin Sans",
        marginBottom: 18,
        borderRadius: 100,
        background: "#890608",
        "&:hover": {
            background: "#EA7606",
        },
    },
}));

const reservationService = new ConfirmationReservationService();

export function DohoneAnnounceAdPaymentButtons(props: {
    paymentData: IAnnounceAdPayment;
    announceAd: IAnnounceAd;
    price: number;
}) {
    const classes = styles();
    const dohone = useDohone();
    const formRef = useRef<HTMLFormElement | null>(null);
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [montant, setMontant] = React.useState(0);
    const { t } = useTranslation();

    React.useEffect(() => {
        setMontant(props.price);
    }, [props.price]);

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        if (!formRef.current || montant === 0) return;

        const clonePaymentData = cloneDeep(props.paymentData);
        clonePaymentData.transaction.amount.total = montant;

        setLoading(true);
        reservationService
            .createTemporaryAnnounceAd(props.announceAd)
            .then((response) => {
                if (!formRef.current) return;
                (formRef.current.elements as any)["rI"].value = response.tempId;
                formRef.current.submit();
            })
            .catch((err) => {
                FlashErrorOccured();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return dohone.info === null ? (
        <Button
            variant="text"
            type="submit"
            className={classes.btn}
            onClick={() => dohone.refresh()}
        >
            {t("label.rafraichir")}
        </Button>
    ) : (
        <form
            ref={formRef}
            action="https://www.my-dohone.com/dohone/pay"
            method="post"
            onSubmit={handleSubmit}
        >
            <input type="hidden" name="cmd" value="start" />
            <input
                type="hidden"
                name="rN"
                value={user.firstName + " " + user.lastName}
            />
            <input
                type="hidden"
                name="rT"
                value={user.phone || "237699982782"}
            />
            <input type="hidden" name="rH" value={dohone.info.codeMarchand} />
            <input type="hidden" name="rI" value="xxx" />
            <input type="hidden" name="rMt" value={montant} />
            <input type="hidden" name="rDvs" value="XAF" />
            <input
                type="hidden"
                name="source"
                value={dohone.info.commercialName}
            />
            <input
                type="hidden"
                name="endPage"
                value={`${window.location.origin}/announce-ad/payment_success`}
            />
            <input
                type="hidden"
                name="notifyPage"
                value={`${serverConfig.ApiRoot}/dohone/notificationAnnounceAd`}
            />
            <input
                type="hidden"
                name="cancelPage"
                value={`${window.location.href}&failed=true`}
            />
            <Button
                variant="text"
                type="submit"
                className={classes.btn}
                disabled={loading || montant === 0}
            >
                {loading && (
                    <Spin style={{ height: 16, width: 16, marginRight: 8 }} />
                )}{" "}
                {t("label.payer-maintenant")}
            </Button>
        </form>
    );
}
