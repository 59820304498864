export enum EAnnounceStatus {
    Submitted = "Submitted",
    PendingUser = "Pending User",
    UserAccept = "Task Accepted",
    UserRefuse = "Task Refused",
    VerificationStarted = "Verification Started",
    PendingValidation = "Pending Validation",
    // Rejected='Rejected',
    Published = "Published",
    Suspended = "Suspended",
    SuspendedByUser = "SuspendedByUser",
    Drafted = "Drafted",
}
export enum EAnnounceFieldStatus {
    Correct = "Correct",
    Incorrect = "Incorrect",
    Pending = "Pending",
}
