/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { paypalConfig } from '../../../configs/Paypal';
import { IAnnounceAdPayment } from "../../../utils/PaymentInterfaces";
// import { ReservationContext } from "../../../contexts/AppContext";
import { useHistory } from 'react-router-dom';
import { AnnounceAdService } from "../../UserAdmin/UserAnnounceAd/services/AnnounceAdService";
import { IAnnounceAd } from "../../../utils/AnnounceInterfaces";
import { EPaymentMethod } from "../../../utils/enum/PaymentMethod";
import { ETransactionType } from '../../../utils/enum';


const amount = "2";
const currency = "EUR";
const style = {"layout":"vertical"};

const announceAdService=new AnnounceAdService();
const ButtonWrapper = ({ currency, showSpinner, paymentData, announceAd }:{currency:string, showSpinner:boolean, paymentData:IAnnounceAdPayment, announceAd:IAnnounceAd}) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner, paymentData]);

    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style as any}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return announceAdService.createAnnounceAdPayment({...paymentData, method: EPaymentMethod.Paypal}).then(response=>{
                        if(response.success){
                            return response.data.orderId
                        }
                    }).catch(error=>{
                        console.log('Paypal buttons error', error);
                    })
                   
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function (responseOrder) {
                        // Your code here after capture the order
                        if(responseOrder.status==='COMPLETED'){
                            announceAdService.saveAnnounceAd(announceAd).then(response=>{
                                announceAdService.saveTransaction({
                                    method: paymentData.method,
                                    billingToken: data.facilitatorAccessToken,
                                    facilitatorAccessToken: '',
                                    orderID: responseOrder.id,
                                    payerID: responseOrder.payer.payer_id,
                                    paymentID: null,
                                    link: responseOrder.links[0].href,
                                    paymentEmail: responseOrder.payer.email_address,
                                    reservation: null,
                                    announceAd: response.data,
                                    amount: response.data.adParam.price,
                                    type: ETransactionType.Sending
                                }).then(resp=>{
                                    if(resp.success){
                                        history.push(`/sponsor-ads/payment-done-successfully-${resp.data._id}`)
                                    }
                                })
                            });
                        }
                    });
                }}
                onCancel={function(data, actions){
                    console.log('canceld payment')
                }}
            />
        </>
    );
}

export function PaypalAnnounceAdPaymentButtons({paymentData, announceAd}:{paymentData:IAnnounceAdPayment, announceAd: IAnnounceAd}) {
	return (
		<div style={{ maxWidth: "750px", minHeight: "200px" }}>
            <PayPalScriptProvider
                options={{
                    "client-id": paypalConfig.cliendID,
                    components: "buttons",
                    currency: "EUR"
                }}
            >
				<ButtonWrapper
                    currency={currency}
                    showSpinner={false}
                    paymentData={paymentData}
                    announceAd={announceAd}
                />
			</PayPalScriptProvider>
		</div>
	);
}