/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Stack, Grid, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AddReceptionItem } from './AddReceptionItem';
import { ReceptionItem } from './ReceptionItem';
import PnBDialogue from "../userServices/AddDlg"
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { FiEdit } from 'react-icons/fi';
import CloseIcon from '@mui/icons-material/Close';
import PnbAlertDialog from '../userServices/AlertDlg';
import { useUser } from '../../../Hooks/UserHooks';
import * as modePayementService from '../../../Services/modePayementService';
import { GroupeButton } from './groupButton';
import { EPaymentMethodCategory } from '../../../utils/enum/PaymentMethod';
import { FaCcPaypal } from 'react-icons/fa';
import { MdMobileFriendly } from 'react-icons/md';
import * as colors from '@material-ui/core/colors';
import { IbForm, ImForm, IpForm, MobileForms, PypalForm } from './ModalForms';
import { useTranslation } from 'react-i18next';

const nameKey: any = {
    [EPaymentMethodCategory.Paypal]: 'recepient_email',
    [EPaymentMethodCategory.MobileMoney]: 'mobile_number',
    // [EPaymentMethod.Orange]: 'mobile_number',
    // [EPaymentMethod.MTN]: 'mobile_number',
    // [EPaymentMethod.Nexttel]: 'mobile_number',
    // [EPaymentMethod.ExpressUnion]: 'mobile_number',
    // [EPaymentMethod.YUP]: 'mobile_number',
    // [EPaymentMethod.BankTransfer]: 'bank_name'
}

export const ReceptionSetting = () => {
    const [showDlg, setShowDlg] = React.useState(false);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [toModify, setToModify] = React.useState<any | null>(null);
    const [getingList, setGetingList] = React.useState(false);
    const [pModeLists, setPModeList] = React.useState<any[]>([]);
    const [addCurrentTab, setAddCurrentTab] = React.useState<EPaymentMethodCategory>(EPaymentMethodCategory.Paypal);
    const [formData, setFormeData] = React.useState<IpForm>({});
    const [formDataMob, setFormeDataMob] = React.useState<ImForm>({});
    const [ , setFormeDataBa] = React.useState<IbForm>({});
    const { user, setUser } = useUser();

    const paypalOk = (addCurrentTab === EPaymentMethodCategory.Paypal && (formData?.recepient_email || formData?.recepient_phone || formData?.recepient_paypal_id));
    const mobileOk = (addCurrentTab === EPaymentMethodCategory.MobileMoney && formDataMob?.mobile_number);
    // const banquelOk = (addCurrentTab === EPaymentMethod.BankTransfer && formDataBa?.bank_name && formDataBa?.bank_rib && formDataBa?.bank_bic && formDataBa?.bank_iban && formDataBa?.bank_swift);

    const handleAddReception = () => {
        setToModify(null);
        setAddCurrentTab(EPaymentMethodCategory.Paypal);
        setFormeData({});
        setFormeDataMob({});
        setFormeDataBa({});
        setShowDlg(true);
    }

    const handleCloseDlg = () => {
        setShowDlg(false);
    }

    const handleAddService = () => {
        setIsAdding(true);
        if (paypalOk) {
            modePayementService.addReceptPypalMethode({ user: user._id, ...formData }).then((result) => {
                setIsAdding(false);
                setShowDlg(false);
                if (result?.list) setPModeList(result.list);
                if (result?.user) setUser(result.user);

            }).catch((err) => {
                setShowDlg(false);
                setIsAdding(false);
            })
        } else if (mobileOk) {
            modePayementService.addReceptMobileMethode({ user: user._id, ...formDataMob }).then((result) => {
                setIsAdding(false);
                setShowDlg(false);
                if (result?.list) setPModeList(result.list);
                if (result?.user) setUser(result.user);

            }).catch((err) => {
                setShowDlg(false);
                setIsAdding(false);
                console.log(err);
            })
        }
        //  else if (banquelOk) {
        //     modePayementService.addBankMethode({ user: user._id, ...formDataBa }).then((result) => {
        //         setIsAdding(false);
        //         setShowDlg(false);
        //         if (result?.list) setPModeList(result.list);
        //         if (result?.user) setUser(result.user);

        //     }).catch((err) => {
        //         setShowDlg(false);
        //         setIsAdding(false);
        //         console.log(err);
        //     })
        // } 
        else {
            setIsAdding(false);
        }
    }

    const handleModifieSubmit = () => {
        setIsAdding(true);
        if (paypalOk) {
            modePayementService.updateReceptPypalMethode({ user: user._id, ...formData }).then((result) => {
                setIsAdding(false);
                setShowDlg(false);
                if (result) setPModeList(result);
            }).catch((err) => {
                setShowDlg(false);
                setIsAdding(false);
                console.log(err);
            })
        } else if (mobileOk) {
            modePayementService.updateReceptMobileMethode({ user: user._id, ...formDataMob }).then((result) => {
                setIsAdding(false);
                setShowDlg(false);
                if (result) setPModeList(result);
            }).catch((err) => {
                setShowDlg(false);
                setIsAdding(false);
                console.log(err);
            })
        } 
        // else if (banquelOk) {
        //     modePayementService.updateBankMethode({ user: user._id, ...formDataBa }).then((result) => {
        //         setIsAdding(false);
        //         setShowDlg(false);
        //         if (result) setPModeList(result);

        //     }).catch((err) => {
        //         setShowDlg(false);
        //         setIsAdding(false);
        //         console.log(err);
        //     })
        // } 
        else {
            setIsAdding(false);
        }
    }

    const handleDeleteItemValidate = () => {
        setIsDeleting(true);
        switch (toModify.mode) {
            case EPaymentMethodCategory.Paypal:
                modePayementService.deleteReceptPypalMethode(toModify._id, user._id).then((result) => {
                    setIsDeleting(false);
                    setOpenAlert(false);
                    if (result?.list) setPModeList(result.list);
                    if (result?.user) setUser(result.user);

                }).catch((err) => {
                    setOpenAlert(false);
                    setIsDeleting(false);
                    console.log(err);
                })
                break;
            case EPaymentMethodCategory.MobileMoney:
                modePayementService.deleteReceptMobileMethode(toModify._id, user._id).then((result) => {
                    setIsDeleting(false);
                    setOpenAlert(false);
                    if (result?.list) setPModeList(result.list);
                    if (result?.user) setUser(result.user);

                }).catch((err) => {
                    setOpenAlert(false);
                    setIsDeleting(false);
                    console.log(err);
                })
                break;
            // case EPaymentMethod.BankTransfer:
            //     modePayementService.deleteBankMethode(toModify._id, user._id).then((result) => {
            //         setIsDeleting(false);
            //         setOpenAlert(false);
            //         if (result?.list) setPModeList(result.list);
            //         if (result?.user) setUser(result.user);

            //     }).catch((err) => {
            //         setOpenAlert(false);
            //         setIsDeleting(false);
            //         console.log(err);
            //     })
            //     break;

            default:
                setIsDeleting(false);
                break;
        }
    }

    const handleModifie = (itm: any) => {
        setToModify(itm);
        setFormeData(omitedBase(itm));
        setFormeDataMob(omitedBase(itm));
        setFormeDataBa(omitedBase(itm));
        setShowDlg(true);
        setAddCurrentTab(itm.mode);
    }

    const { t } = useTranslation();

    React.useEffect(() => {
        if (user && user._id) {
            setGetingList(true);
            modePayementService.getUserRModeByUserId(user._id).then((data) => {
                if (data) setPModeList(data);
                setGetingList(false);
            }).catch((err) => {
                setGetingList(false);
            })
        }
    }, [])

    const AddDialgAction = (
        <Stack direction="row" spacing={2} p={2}>
            <Button disabled={isAdding || !(paypalOk || mobileOk /*|| banquelOk*/)}
                onClick={toModify ? handleModifieSubmit : handleAddService} startIcon={isAdding ? <CircularProgress size={16} /> : (toModify ? <FiEdit /> : <AddIcon />)}
                variant="contained">{toModify ? t('label.modifier') : t('label.ajouter')}</Button>
            <Button disabled={isAdding} color="error" onClick={handleCloseDlg} startIcon={<CloseIcon />} variant="contained">{t('label.annuler')}</Button>
        </Stack>
    )

    const addSwitchActions = [
        {
            label: "Paypal",
            onClick: () => { setAddCurrentTab(EPaymentMethodCategory.Paypal) },
            startIcon: <FaCcPaypal size={16} />,
            isActive: addCurrentTab === EPaymentMethodCategory.Paypal
        },
        {
            label: "Mobile Money",
            onClick: () => { setAddCurrentTab(EPaymentMethodCategory.MobileMoney)},
            startIcon: <MdMobileFriendly size={16} />,
            isActive: addCurrentTab === EPaymentMethodCategory.MobileMoney
        },
        // {
        //     label: "Orange",
        //     onClick: () => { setAddCurrentTab(EPaymentMethod.Orange) },
        //     startIcon: <MdMobileFriendly size={16} />,
        //     isActive: addCurrentTab === EPaymentMethod.Orange
        // },
        // {
        //     label: "MTN",
        //     onClick: () => { setAddCurrentTab(EPaymentMethod.MTN) },
        //     startIcon: <MdMobileFriendly size={16} />,
        //     isActive: addCurrentTab === EPaymentMethod.MTN
        // },
        // {
        //     label: "Nexttel",
        //     onClick: () => { setAddCurrentTab(EPaymentMethod.Nexttel) },
        //     startIcon: <MdMobileFriendly size={16} />,
        //     isActive: addCurrentTab === EPaymentMethod.Nexttel
        // },
        // {
        //     label: "ExpressUnion",
        //     onClick: () => { setAddCurrentTab(EPaymentMethod.ExpressUnion) },
        //     startIcon: <MdMobileFriendly size={16} />,
        //     isActive: addCurrentTab === EPaymentMethod.ExpressUnion
        // },
        // {
        //     label: "YUP",
        //     onClick: () => { setAddCurrentTab(EPaymentMethod.YUP) },
        //     startIcon: <MdMobileFriendly size={16} />,
        //     isActive: addCurrentTab === EPaymentMethod.YUP
        // },
        // {
        //     label: "Banque",
        //     onClick: () => { setAddCurrentTab(EPaymentMethod.BankTransfer) },
        //     startIcon: <GoCreditCard size={16} />,
        //     isActive: addCurrentTab === EPaymentMethod.BankTransfer
        // }
    ]

    return (
        <Stack direction="column">
            {/* <Stack direction="row" p={4}>
                <Typography variant="h5">
                    {t('label.liste-des-comptes-de-reception')}
                </Typography>
            </Stack>
            <Stack>
                <Typography>
                    {t('label.comptes-de-reception-explications')}
                </Typography>
            </Stack> */}
            {getingList && <Stack direction="column" alignItems="center" p={4}>
                <CircularProgress />
            </Stack>}
            <Grid container spacing={4}>
                {!getingList && pModeLists.map((itm) => (
                    <Grid item key={"payement-item-id-" + itm._id} xs={6}>
                        <ReceptionItem
                            onDelete={() => { setToModify(itm); setOpenAlert(true); }}
                            onEdit={() => { handleModifie(itm) }}
                            mode={itm.mode} item={itm} />
                    </Grid>
                ))}
                <Grid item key={"payement-item-id-add-1"} xs={6}>
                    <AddReceptionItem label={t('label.ajouter-un-compte')} onClick={handleAddReception} />
                </Grid>
            </Grid>
            <PnBDialogue action={AddDialgAction} fullWidth open={showDlg} setOpen={setShowDlg} title={toModify ? t('label.modifier') : t('label.nouveau-compte-de-reception')} >
                <Stack direction="column" width="100%" >
                    {!toModify && <GroupeButton actions={addSwitchActions} />}
                    <Stack direction="column" width="100%"
                        p={4} borderRadius={addCurrentTab === EPaymentMethodCategory.Paypal ? "0px 6px 6px 6px" : "6px"}
                        bgcolor={colors.blueGrey[50]}>
                        {{
                            [EPaymentMethodCategory.Paypal]: (
                                <PypalForm formData={formData} onChange={setFormeData} />
                            ),
                            [EPaymentMethodCategory.MobileMoney]: (
                                <MobileForms formData={formDataMob} onChange={setFormeDataMob} />
                            ),
                            // [EPaymentMethod.Orange]: (
                            //     <MobileForms formData={formDataMob} onChange={setFormeDataMob} />
                            // ),
                            // [EPaymentMethod.MTN]: (
                            //     <MobileForms formData={formDataMob} onChange={setFormeDataMob} />
                            // ),
                            // [EPaymentMethod.Nexttel]: (
                            //     <MobileForms formData={formDataMob} onChange={setFormeDataMob} />
                            // ),
                            // [EPaymentMethod.ExpressUnion]: (
                            //     <MobileForms formData={formDataMob} onChange={setFormeDataMob} />
                            // ),
                            // [EPaymentMethod.YUP]: (
                            //     <MobileForms formData={formDataMob} onChange={setFormeDataMob} />
                            // ),
                            // [EPaymentMethod.BankTransfer]: (
                            //     <BanqueForms formData={formDataBa} onChange={setFormeDataBa} />
                            // )
                        }[addCurrentTab]}
                    </Stack>
                </Stack>
            </PnBDialogue>
            <PnbAlertDialog
                title={`${t('label.supprimer')} ${toModify ? toModify[nameKey[toModify.mode]] : ''}?`}
                open={openAlert} setOpen={setOpenAlert}
                onValidate={handleDeleteItemValidate}
                warning
                loading={isDeleting}
            >
                <Typography>
                    {`${t('label.voulez-vous-vraiment')} ${toModify ? toModify[nameKey[toModify.mode]] : ''} ${t('label.de-la-liste-des')}?`}
                </Typography>
            </PnbAlertDialog>
        </Stack >
    )
}

const omitedBase = (obj: any) => ({ ...obj, createdAt: undefined, updatedAt: undefined, __v: undefined, mode: undefined })

// const TestList = [1, 2, 3].map((i) => ({
//     id: i,
//     name: "Item " + i,
//     mode: i === 1 ? EPaymentMethod.Paypal : (i === 2 ? EPaymentMethod.MobileMoney : EPaymentMethod.BanckAccount),
//     details: "Details for item " + i
// }))