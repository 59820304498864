import i18n from "../../i18n/config";

export enum EStatus {
    PENDING = "pending",
    OPEN = "open",
    APPROVED = "approved",
    REJECTED = "rejected",
    CANCELLED = "cancelled",
    CANCELLED_BY_ADMIN = "cancelled by admin",
    CHECKED_IN = "checked in",
    CHECKED_OUT = "checked out",
    CLOSED = "closed",
}

export const getStatusLabel = (name: string): string => {
    switch (name) {
        case EStatus.PENDING:
            return i18n.t("reservation.status.pending");
        case EStatus.OPEN:
            return i18n.t("reservation.status.open");
        case EStatus.APPROVED:
            return i18n.t("reservation.status.approved");
        case EStatus.REJECTED:
            return i18n.t("reservation.status.rejected");
        case EStatus.CANCELLED:
            return i18n.t("reservation.status.cancelled");
        case EStatus.CANCELLED_BY_ADMIN:
            return i18n.t("reservation.status.cancelled-by-admin");
        case EStatus.CLOSED:
            return i18n.t("reservation.status.closed");
        default:
            return name;
    }
};
