import React from "react";
import { makeStyles, createStyles, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) =>
    createStyles({
        popItem: {
            minWidth: 286,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 16,
            paddingRight: 4,
            paddingBottom: 16,
            color: "rgb(34, 34, 34)",

            "&:not(:last-child)": {
                borderBottom: "1px solid rgb(235, 235, 235)",
            },
        },
        popItemLeft: {},
        popItemRight: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 104,
            height: 32,
            color: "rbg(34,34,34)",
            fontSize: 16,
            fontWeight: 400,
        },
        popTextTitle: {
            fontSize: 16,
            fontWeight: 600,
            marginBottom: 2,
        },
        popTextSubTitle: {
            fontSize: 14,
            fontWeight: 400,
            color: "rgb(113, 113, 113)",
        },
        popItemIcon: {
            width: 32,
            height: 32,
            cursor: "pointer",
            display: "inline-flex",
            margin: 0,
            padding: 0,
            textAlign: "center",
            border: "1px solid rgb(176, 176, 176)",
            color: "rgb(113, 113, 113)",
            outline: "none",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(255, 255, 255)",
            borderRadius: "50%",

            "&:hover": {
                color: "rgb(34, 34, 34)",
                borderColor: "rgb(34, 34, 34)",
                background: "rgb(255, 255, 255)",
            },
        },
        popItemNbr: {
            color: "#222222",
            fontSize: 16,
        },
    })
);

type Props = {
    title: string;
    subtitle: string;
    value: number;
    increment: () => void;
    decrement: () => void;
};

const ItemPopover: React.FC<Props> = ({
    title,
    subtitle,
    value,
    increment,
    decrement,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.popItem}>
            <div className={classes.popItemLeft}>
                <div className={classes.popTextTitle}>{title}</div>
                <div className={classes.popTextSubTitle}>{subtitle}</div>
            </div>
            <div className={classes.popItemRight}>
                <IconButton
                    aria-label="remove"
                    className={classes.popItemIcon}
                    onClick={() => decrement()}
                    disabled={value <= 0 ? true : false}
                >
                    <RemoveIcon />
                </IconButton>
                <div className={classes.popItemNbr}>{value}</div>
                <IconButton
                    aria-label="add"
                    className={classes.popItemIcon}
                    onClick={() => increment()}
                >
                    <AddIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default ItemPopover;
