import React from "react";
import { UserContext } from "../contexts/AppContext";
import { defaultUserValue } from "../utils/defaultNullValues";
import { IUser } from "../utils/UserInterfaces";

export const UserProvider = (props: { children?: JSX.Element }) => {
    const [user, setUser] = React.useState<IUser>(defaultUserValue);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {props.children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return React.useContext(UserContext);
};
