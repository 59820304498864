import Api from "../https/Api";
import { IUser } from "../utils/UserInterfaces";

const http = new Api();

export const GetUserByArrayOfIds = (ids: string[]):Promise<IUser[] | null> => http.post('users/from-ids',{
    users: ids
})
.then((response) => {
    if (response.success) return response.data;
    else return null
}).catch((err) => {
    console.log(err);
    return null
});

export const ChangeUserPassword = (datas: any):Promise<any> => http.put('users/changePassword/update', 
    datas
).then((response) => {
    if (response.success) return response.message;
}).catch((err) =>{
    console.log(err)
})

export const ChangeEmail = (data: any, id: string):Promise<any> => http.put(`users/updatePartial/${id}`, 
    data
).then((response) => {
    if (response.success) return response.message;
}).catch((err) => {
    console.log(err)
})