import {
    makeStyles,
    createStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from "@material-ui/core";
import React from "react";
import { LoginForm } from "./LoginForm";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            minWidth: 400,
            maxWidth: 500,
        },
        title: {
            borderBottom: "1px solid #EBEBEB",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Josefin Sans",
            fontWeight: 600,
            color: "#000000",
            fontSize: 20,
        },
        close: {
            padding: 5,
            position: "absolute",
            right: 20,
        },
    })
);

export function LoginDialog(props: {
    handleClose: () => void;
    open: boolean;
    handleClickOpenRegister: () => void;
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle classes={{ root: classes.title }}>
                <IconButton
                    onClick={props.handleClose}
                    className={classes.close}
                >
                    <CloseIcon />
                </IconButton>
                {t("SignIn.title")}
            </DialogTitle>
            <DialogContent>
                <LoginForm
                    handleClose={props.handleClose}
                    handleClickOpenRegister={props.handleClickOpenRegister}
                />
            </DialogContent>
        </Dialog>
    );
}
