

export enum EPropertyType {
    APPARTEMENT = 'APPARTEMENT',
    VILLA = 'VILLA',
    HOTEL = 'HOTEL',
    CHAMBRE_PRIVE = 'CHAMBRE_PRIVE',
    CHAMBRE_COMMUNE = 'CHAMBRE_COMMUNE',
}

export enum EPropertyErrorMessages{
    EmptyNameHotel='EmptyNameHotel',
    EmptyBed='EmptyBed',
    EmptyBathroom='EmptyBathroom',
    EmptyToilet='EmptyToilet',
    EmptyName='EmptyName',
    EmptyRoom='EmptyRoom',
    EmptyGuest='EmptyGuest',
    EmptyType='EmptyType',
    InsufficientPictures='InsufficientPictures',
    EmptyRoomType='EmptyRoomType',
    EmptyRegulations='EmptyRegulations',
}

export enum EAddressErrorMessages{
    EmptyCity='EmptyCity',
    EmptyZipcode='EmptyZipcode',
    EmptyCountry='EmptyCountry',
    EmptyStreet='EmptyStreet',
    EmptyState='EmptyState'
}

export enum EAnnounceErrorMessages{
    EmptyDescription='EmptyDescription',
    EmptyPrice='EmptyPrice',
    EmptyQuartier='EmptyQuartier',
    EmptyPaypal='EmptyPaypal',
    NullPenalty='NullPenalty',
    EmptyContactName='EmptyContactName',
    EmptyContactPhone='EmptyContactPhone',
    EmptyPreferredMethod='EmptyPreferredMethod',
    EmptyOrangeAccount='EmptyOrangeAccount',
    EmptyMTNAccount='EmptyMTNAccount',
    EmptyExpressUnionAccount='EmptyExpressUnionAccount',
    EmptyLocationDescription='EmptyLocationDescription',
}
export enum HotelErrorMessages{
    Emptyname='EmptyCompany',
    EmptyPrice='EmptyPrice',
    EmptyOwnerName='EmptyOwnerName'
}
export enum TypeHotelErrorMessages{
    EmptyRoom='EmptyRoom',
    EmptyType='EmptyType',
}
export enum ESearchErrorMessages{
    EmptyName='entrer pays',
}
