import React from "react";
import { ConfirmationReservationService } from "../pages/Reservation/services/ConfirmationReservationService";

const reservationService = new ConfirmationReservationService();

export function DohoneProvider(props: { children: React.ReactNode }) {
    const [dohoneInfo, setDohoneInfo] = React.useState<DohoneInfo | null>(null);

    const refresh = React.useCallback(() => {
        reservationService
            .getDohoneInfo()
            .then(setDohoneInfo)
            .catch(() => {});
    }, []);

    React.useEffect(() => {
        reservationService
            .getDohoneInfo()
            .then(setDohoneInfo)
            .catch(() => {});
    }, []);

    const value = React.useMemo(
        () => ({ info: dohoneInfo, refresh }),
        [dohoneInfo, refresh]
    );

    return (
        <DohoneContext.Provider value={value}>
            {props.children}
        </DohoneContext.Provider>
    );
}

type DohoneInfo = {
    commercialName: string;
    codeMarchand: string;
};

export type DohoneContextType = {
    info: DohoneInfo | null;
    refresh: () => void;
};

const DohoneContext = React.createContext<DohoneContextType>({
    info: null,
    refresh: () => {},
});

export function useDohone() {
    return React.useContext(DohoneContext);
}
