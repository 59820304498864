import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ConfirmDialog(props: { open: boolean; handleClose: () => void; handleConfirm: () => void; title: string, description: string }) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.description}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} style={{ color: 'grey' }}>{t('confirmDialog.buttonCancel')}</Button>
          <Button onClick={props.handleConfirm} style={{ color: '#890608' }}>{t('confirmDialog.buttonConfirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ShowConfirmDialog = (props: { open: boolean; handleClose: () => void; handleConfirm: () => void; title: string, description: string }) => {

  return (
    <ConfirmDialog {...props} />
  )

}