
import { DataAnnounceAd, Order, useStyles } from './ListTableAnnounceAd';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useTranslation } from 'react-i18next';

export interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        announceAd: keyof DataAnnounceAd
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}


interface HeadCell {
    disablePadding: boolean;
    id: keyof DataAnnounceAd;
    label: string;
    numeric: boolean;
}




export function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
    (announceAd: keyof DataAnnounceAd) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, announceAd);
    };
    
    const {t} = useTranslation();
    
    const headCells: HeadCell[] = [
        {
            id: "announceConcerned",
            numeric: false,
            disablePadding: true,
            label: t('label.annonce-concernee'),
        },
        {
            id: "placement",
            numeric: false,
            disablePadding: true,
            label: t('label.emplacement'),
        },
        {
            id: "duration",
            numeric: false,
            disablePadding: false,
            label: t('label.duree'),
        },
        { 
            id: "startDate", 
            numeric: true, 
            disablePadding: false, 
            label: t('label.date-debut')
        },
        {
            id: "endDate",
            numeric: false,
            disablePadding: false,
            label: t('label.date-fin'),
        },
        {
            id: "price",
            numeric: false,
            disablePadding: false,
            label: t('label.price'),
        },
        {
            id: "counter",
            numeric: false,
            disablePadding: false,
            label: t('label.compte-a-rebours'),
        },
        {
            id: "state",
            numeric: false,
            disablePadding: false,
            label: t('label.statut'),
        }
    ];
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all properties" }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.titleHead}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}