/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { PropagateLoader } from "react-spinners";
import {
    CurrencyContext,
    UserContext,
} from "../../../../../contexts/AppContext";
import Spin from "../../../../../images/Spin";
import {
    createBookingRefundDohone,
    getPaymentMethodFees,
    saveTransaction,
} from "../../../../../Services/reservationServices";
import { paymentMethodList } from "../../../../../utils/App";
import { ConvertedDeviseWithoutMoneySign } from "../../../../../utils/CurrencyConvertFunction";
import {
    EPaymentMethod,
    ETransactionType,
} from "../../../../../utils/enum/PaymentMethod";
import {
    DohonePayoutMode,
    IRefund,
    IRefundDohone,
} from "../../../../../utils/PaymentInterfaces";
import { UserReservationService } from "../../../UserReservations/services/UserReservationService";

const userService = new UserReservationService();
export const RetrieveCreditModal = ({
    openRefundModal,
    handleClose,
}: {
    openRefundModal: boolean;
    handleClose: () => void;
}) => {
    const [isPaypal] = React.useState<boolean>(false);
    const [travellerPaymentMethod, setTravellerPaymentMethod] = React.useState(
        EPaymentMethod.Paypal
    );
    const [travellerPaymentAccount, setTravellerPaymentAccount] =
        React.useState<string>("");
    const [openDialogConfirm, setOpenDialogConfirm] =
        React.useState<boolean>(false);
    const [loadingRefund, setLoadingRefund] = React.useState<boolean>(false);
    const [loadingPage, setLoadingPage] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);

    const currencyContext = React.useContext(CurrencyContext);
    const { currency } = currencyContext;
    const { user, setUser } = React.useContext(UserContext);

    React.useEffect(() => {
        if (user._id !== "") {
            setLoadingPage(true);
            userService
                .getUser(user._id)
                .then((res) => {
                    if (res.success) {
                        setUser(res.data);
                        setLoadingPage(false);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [refreshKey]);

    const classes = useStyles();

    const handleRefund = () => {
        handleCloseConfirmDialog();
        setLoadingRefund(true);

        let _travellerValue: number = 0;
        let _totalRefundPrice: number = 0;
        let _travellerReversalFees: number = 0;
        let _refundTraveller: IRefund;
        let _refundTravellerDohone: IRefundDohone;
        _totalRefundPrice =
            travellerPaymentMethod === EPaymentMethod.Paypal
                ? user.credits.paypal
                : user.credits.mobileMoney;
        getPaymentMethodFees(EPaymentMethod.Paypal)
            .then((response) => {
                _travellerReversalFees =
                    (_totalRefundPrice * (response?.percentage ?? 0)) / 100;
                _travellerValue = _totalRefundPrice - _travellerReversalFees;
                ConvertedDeviseWithoutMoneySign(
                    "XAF",
                    currency,
                    Number(_travellerValue.toFixed(2))
                )
                    .then((value: any): any => {
                        if (travellerPaymentMethod === EPaymentMethod.Paypal) {
                            _refundTraveller = {
                                ..._refundTraveller,
                                amount: {
                                    value,
                                    currency,
                                },
                                reservation: "",
                                recepient_type: "EMAIL",
                                receiver: travellerPaymentAccount ?? "",
                                userId: user._id,
                            };
                            userService
                                .createBookingRefund(_refundTraveller)
                                .then((response) => {
                                    if (response.success) {
                                        saveTransaction({
                                            method: EPaymentMethod.Paypal,
                                            payout_batch_id:
                                                response.data.result
                                                    .batch_header
                                                    .payout_batch_id,
                                            batch_status:
                                                response.data.result
                                                    .batch_header.batch_status,
                                            recipient_type:
                                                response.data.result
                                                    .batch_header
                                                    .sender_batch_header
                                                    .recipient_type,
                                            paymentID: null,
                                            link: response.data.result.links[0]
                                                ?.href,
                                            paymentEmail: "AfriqPnB",
                                            reservation: null,
                                            announceAd: null,
                                            sender_batch_id:
                                                response.data.result
                                                    .batch_header
                                                    .sender_batch_header
                                                    .sender_batch_id || "",
                                            amount:
                                                currency.toLocaleUpperCase() ===
                                                "xaf"
                                                    ? value
                                                    : _travellerValue,
                                            type: ETransactionType.Receiving,
                                            userId: user._id,
                                        })
                                            .then((resp) => {
                                                if (resp.success)
                                                    setRefreshKey(
                                                        (oldKey) => oldKey + 1
                                                    );
                                                handleCloseConfirmDialog();
                                                handleClose();
                                                setLoadingRefund(false);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                handleCloseConfirmDialog();
                                                setLoadingRefund(false);
                                            });
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    handleCloseConfirmDialog();
                                    setLoadingRefund(false);
                                });
                        } else {
                            _refundTravellerDohone = {
                                ..._refundTravellerDohone,
                                destination: `237${travellerPaymentAccount}`,
                                mode:
                                    travellerPaymentMethod ===
                                    EPaymentMethod.Orange
                                        ? DohonePayoutMode.orangeMoney
                                        : travellerPaymentMethod ===
                                          EPaymentMethod.MTN
                                        ? DohonePayoutMode.mtnMobileMoney
                                        : DohonePayoutMode.expressUnion,
                                amount: value,
                                devise: currency,
                                nameDest: user.firstName ?? "",
                                ville: "",
                                pays: "Cameroon",
                                rUserId: user._id ?? "",
                                reservation: "",
                            };
                            createBookingRefundDohone(_refundTravellerDohone)
                                .then(() => {
                                    handleClose();
                                    handleCloseConfirmDialog();
                                    setLoadingRefund(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    handleCloseConfirmDialog();
                                    setLoadingRefund(false);
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        handleCloseConfirmDialog();
                        setLoadingRefund(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                handleCloseConfirmDialog();
                setLoadingRefund(false);
            });
    };

    const onChangePaymentMethod = (event: any) => {
        setTravellerPaymentMethod(event.target.value);
        setTravellerPaymentAccount("");
    };

    const onChangePaymentAccount = (event: any) => {
        setTravellerPaymentAccount(event.target.value);
    };

    const handleOpenConfirmDialog = () => {
        setOpenDialogConfirm(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenDialogConfirm(false);
    };

    const { t } = useTranslation();

    return (
        <div>
            {loadingPage ? (
                <div className={classes.loading}>
                    <PropagateLoader color="#36D7B7" size={15} />
                </div>
            ) : (
                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={openRefundModal}
                        onClose={handleClose}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogTitle id="max-width-dialog-title">
                            {t("label.recuperer-mes-credits")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <Typography
                                            variant="body1"
                                            style={{ color: "#000000" }}
                                        >
                                            {t("label.moyen-de-paiement")}:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Select
                                            id="paymentMethodSelection"
                                            name="travellerPaymentMethod"
                                            variant="outlined"
                                            fullWidth
                                            value={travellerPaymentMethod}
                                            onChange={onChangePaymentMethod}
                                            disabled={isPaypal}
                                        >
                                            {user.country === "Cameroon" ? (
                                                paymentMethodList.map(
                                                    (method) => (
                                                        <MenuItem
                                                            key={
                                                                method.label +
                                                                method.value
                                                            }
                                                            value={method.value}
                                                        >
                                                            {method.label}
                                                        </MenuItem>
                                                    )
                                                )
                                            ) : (
                                                <MenuItem
                                                    value={
                                                        EPaymentMethod.Paypal
                                                    }
                                                >
                                                    Paypal
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <Typography
                                            variant="body1"
                                            style={{ color: "#000000" }}
                                        >
                                            {t("label.compte-de-reception")}:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        {travellerPaymentMethod ===
                                        EPaymentMethod.Paypal ? (
                                            <>
                                                <TextField
                                                    value={
                                                        travellerPaymentAccount
                                                    }
                                                    onChange={
                                                        onChangePaymentAccount
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder={
                                                        "label.exemplePaypal"
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {/* {t(
                                                    "label.aucun-compte-n-a-ete-trouve"
                                                )} */}
                                                <TextField
                                                    value={
                                                        travellerPaymentAccount
                                                    }
                                                    onChange={
                                                        onChangePaymentAccount
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                +237
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.secondarButton}
                                    disabled={loadingRefund}
                                >
                                    {t("label.annuler")}
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.primaryButton}
                                    style={{ marginRight: 10 }}
                                    onClick={handleOpenConfirmDialog}
                                    disabled={
                                        loadingRefund ||
                                        travellerPaymentAccount === ""
                                    }
                                    endIcon={loadingRefund ? <Spin /> : null}
                                >
                                    {t("label.confirmer-l-annulation")}
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openDialogConfirm} maxWidth="sm">
                        <DialogTitle>
                            {travellerPaymentMethod === EPaymentMethod.Paypal
                                ? t("label.retrieve.paypal-credit.title")
                                : t("label.retrieve.mobile-money-credit.title")}
                        </DialogTitle>
                        <DialogContent>
                            {t("label.retrieve.mobile-money-credit.description")}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                className={classes.secondarButton}
                                style={{ textTransform: "none" }}
                                onClick={handleCloseConfirmDialog}
                            >
                                {t("reservation.button.cancel")}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.primaryButton}
                                style={{ textTransform: "none" }}
                                onClick={handleRefund}
                            >
                                {t("reservation.button.valider")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primaryButton: {
            color: "#FFFFFF",
            textTransform: "initial",
            fontSize: 14,
            fontFamily: "Josefin Sans",
            background: "#890608",
            "&:hover": {
                background: "#EA7606",
            },
        },
        secondarButton: {
            textTransform: "initial",
            fontSize: 14,
            fontFamily: "Josefin Sans",
        },
        loading: {
            width: "100%",
            position: "absolute",
            top: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            left: 0,
        },
    })
);
