import Api from "../https/Api";
import { IMessage, IMessageResponse } from "../Models/messagesModels";
import { Accountservice } from "../pages/Admin/Accounts/services/AccountService";
import { ERoles } from "../utils/enum";
import { IRole, IUser } from "../utils/UserInterfaces";

const http = new Api();
const accountService = new Accountservice();

export const getAdminListMessagable = (onlyAdmin?: boolean): Promise<IUser[]> => new Promise((res, rej) => {
    accountService.getRoles().then((resRoles) => {
        if (resRoles.success) {
            let rolData = resRoles.data;
            let role = rolData.find((foundRole: IRole) => (foundRole.name === (onlyAdmin ? ERoles.SUPER_ADMIN : ERoles.ADMIN_SUPPORT)));
            if (role) {
                accountService.getListUsers({ role: role._id ?? '' }).then((result) => {
                    res(result.data);
                }).catch((err) => {
                    rej(err)
                })
            } else {
                rej(null);
            }
        }
    }).catch((errRoles) => {
        rej(errRoles)
    });
})

export const SendMessage = (message: IMessage): Promise<any> => http
    .post('messages/send', message)
    .then((response) => {
        if (response.success) return response.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    });

export const GetMessageFromTo = (to: string, from?: string,): Promise<IMessageResponse[] | null> => http
    .get(`messages/get/${to}/${from ?? '*'}`)
    .then((response) => {
        if (response.success) return response.data;
        else return null
    }).catch((err) => {
        console.log(err);
        return null
    });