import Api from "../https/Api";

const http = new Api();

export const getFooterData = (): Promise<any> => http
    .get('footer-data/')
    .then((response) => response.data)

export const updateFooterData = (links: any): Promise<any> => http
    .post(`footer-data/add-update`, { links })
    .then((response) => response.data)