/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Edit from "@material-ui/icons/Edit";
import { Menu } from "@material-ui/core";
import { DataAnnounceAd as DataRow } from "./List/ListTableAnnounceAd";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { RouteAdminUser } from "../../../Admin/constant/routesName";

export const ContextMenu = (props: {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleDeleteRow: (rowId: string) => void;
  row?: DataRow;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
}) => {
  const showEditBtn = (props.showEditButton != null || props.showEditButton !== undefined) ? props.showEditButton : true
  const showDeleteBtn = (props.showDeleteButton != null || props.showDeleteButton !== undefined) ? props.showDeleteButton : true
 
  const history = useHistory();

  const handleShowPublicPreview = () => {};

  const handleEdit = () => {
    if (props.row) {
        history.push(`${RouteAdminUser.ANNOUNCES_ADS_DISABLED}/${props.row.id}`);
        props.handleClose();
    }
  };

  const handleDeleteRow = () => {
    if (props.row) {
      props.handleDeleteRow(props.row.id);
      props.handleClose();
    }
  };

  const { t } = useTranslation();

  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <Menu
        id="cotenxt-menu-table"
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList>
          {showEditBtn &&  <React.Fragment>
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("contextMenu.editer")}</ListItemText>
            </MenuItem>
            <Divider />
          </React.Fragment>}
          
          {/* { showDeleteBtn && <MenuItem onClick={handleDeleteRow}>
            <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("contextMenu.supprimer")}</ListItemText>
            </MenuItem>
          } */}
        </MenuList>
        
      </Menu>
    </Paper>
  );
};
