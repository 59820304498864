/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { IReservationReq } from "../../../../utils/ReservationInterfaces";
import {
    dateToStringDDMMYYYY,
    getDayNumber,
    getDayNumberProm,
    getPrestayPassedNumber,
    getRefundType,
} from "../../../../utils/App";
import { UserReservationService } from "../services/UserReservationService";
import { ERefundType } from "../../../../utils/enum/RefundType";
import {
    DohonePayoutMode,
    IRefund,
    IRefundDohone,
    ITransaction,
} from "../../../../utils/PaymentInterfaces";
import {
    refundTravellerDefaultValue,
    refundHostDefaultValue,
    dohoneRefundTravellerDefaultValue,
    transactionDefaultValue,
} from "../../../../utils/defaultNullValues";
import { useTranslation } from "react-i18next";
import { AfriqSnackbar } from "../../../components/OverridingMaterial/AfriqSnackbar";
import { ISnackbar } from "../../../../utils/enum/snackBarType";
import { ConvertedDeviseWithoutMoneySign } from "../../../../utils/CurrencyConvertFunction";
import { CurrencyContext } from "../../../../contexts/AppContext";
import { useContext } from "react";
import {
    EPaymentMethod,
    EPaymentMethodCategory,
} from "../../../../utils/enum/PaymentMethod";
import {
    ETarifs,
    getTarifLabel,
    ETransactionType,
    EStatus,
} from "../../../../utils/enum";
import Spin from "../../../../images/Spin";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
        refundTitle: {
            marginTop: 16,
        },
        primaryButton: {
            color: "#FFFFFF",
            textTransform: "initial",
            fontSize: 14,
            fontFamily: "Josefin Sans",
            background: "#890608",
            "&:hover": {
                background: "#EA7606",
            },
        },
        secondarButton: {
            textTransform: "initial",
            fontSize: 14,
            fontFamily: "Josefin Sans",
        },
        formControlLabel: {
            marginTop: theme.spacing(1),
        },
    })
);
const userReservService = new UserReservationService();
export function RefundModal({
    openRefundModal,
    handleClose,
    reservation,
    setRefreshReservationList,
}: {
    openRefundModal: boolean;
    handleClose: () => void;
    reservation: IReservationReq;
    setRefreshReservationList: (val: boolean) => void;
}) {
    const classes = useStyles();
    const [refundTraveller, setRefundTraveller] = React.useState<IRefund>(
        refundTravellerDefaultValue
    );
    const [refundHost, setRefundHost] = React.useState<IRefund>(
        refundHostDefaultValue
    );
    const [refundTravellerDohone, setRefundTravellerDohone] =
        React.useState<IRefundDohone>(dohoneRefundTravellerDefaultValue);
    const [refundHostDohone, setRefundHostDohone] =
        React.useState<IRefundDohone>(dohoneRefundTravellerDefaultValue);
    const [hostPaymentMethod, setHostPaymentMethod] = React.useState(
        EPaymentMethod.Paypal
    );
    const [travellerPaymentMethod, setTravellerPaymentMethod] = React.useState(
        EPaymentMethod.Orange
    );
    const [travellerPaymentAccount, setTravellerPaymentAccount] =
        React.useState<string>("");
    const [totalBookingPrice, setTotalBookingPrice] = React.useState<number>(0);
    const [passedDaysFees, setPassedDaysFees] = React.useState<number>(0);
    const [penaltyDaysFees, setPenaltyDaysFees] = React.useState<number>(0);
    const [travellerRefundFinal, setTravellerRefundFinal] =
        React.useState<number>(0);
    const [cleaningFee, setCleaningFee] = React.useState<number>(0);
    const [loadingRefund, setLoadingRefund] = React.useState<boolean>(false);
    const currencyContext = useContext(CurrencyContext);
    const { currency } = currencyContext;
    const [snackValues, setSnackValues] = React.useState<ISnackbar>({
        type: "success",
        open: false,
        message: "",
    });
    const [transaction, setTransaction] = React.useState<ITransaction>(
        transactionDefaultValue
    );
    const [travellerPaymentMethodFee] = React.useState<number>(0);
    const [isPaypal, setIsPaypal] = React.useState<boolean>(false);
    const [openDialogConfirm, setOpenDialogConfirm] =
        React.useState<boolean>(false);
    const [tarif, setTarif] = React.useState(ETarifs.JOURNALIER);
    // const userContext = useContext(UserContext)
    const { t } = useTranslation();

    React.useEffect(() => {
        if (reservation._id) {
            userReservService
                .getTransactionDetailByReservationId(reservation._id)
                .then((response) => {
                    if (response.success) {
                        setTransaction(response.data);
                        if (
                            response.data &&
                            response.data.method === EPaymentMethod.Paypal
                        ) {
                            setTravellerPaymentMethod(EPaymentMethod.Paypal);
                            setTravellerPaymentAccount(
                                response.data.paymentEmail
                            );
                            setIsPaypal(true);
                        } else if (
                            response.data &&
                            response.data.method === EPaymentMethod.Credit
                        ) {
                            setTravellerPaymentMethod(EPaymentMethod.Credit);
                        }
                    }
                });
        }
    }, [reservation._id]);

    React.useEffect(() => {
        if (openRefundModal) {
            if (reservation.traveller.country) {
                let _totalService: number = 0;
                let _baseBookingPrice = 0;
                let _bookingPrice = 0;
                let _cleaningBookingFee = 0;
                let _totalBookingPrice = 0;
                var _travellerFee = 0;
                let _penaltyPrice = 0;
                let _hostPercentage: number = 0;
                let _hostRefundPrice: number = 0;
                let _creditAmount: number = 0;
                let _bookingAmount: number = 0;

                if (reservation.services.length > 0) {
                    reservation.services.forEach((service) => {
                        _totalService += service.price;
                    });
                    ConvertedDeviseWithoutMoneySign(
                        reservation.announce?.devise || "EUR",
                        currency,
                        Number(_totalService.toFixed(2))
                    )
                        .then((value: any): any => {
                            _totalService = Number(value);
                            if (
                                reservation.announce.property.type.name ===
                                "Hôtel"
                            ) {
                                userReservService
                                    .getSpecialFeeByPropertyType(
                                        reservation.announce?.property?.type
                                            ?.name
                                    )
                                    .then((response) => {
                                        if (response.success) {
                                            _hostPercentage = response.data
                                                ? response.data.percentage ?? 0
                                                : 0;
                                            _baseBookingPrice =
                                                reservation.announce?.price;

                                            _cleaningBookingFee = Number(
                                                (
                                                    reservation.announce
                                                        ?.cleaningFees +
                                                    reservation.announce
                                                        ?.cleaningFees *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            /*total promotion ou non promotion*/
                                            if (reservation.promotion) {
                                                let nbjours_prom =
                                                    getDayNumberProm(
                                                        reservation.dateCheckIn,
                                                        reservation.dateCheckOut,
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .startDate
                                                        ).format("YYYY/MM/DD"),
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .endDate
                                                        ).format("YYYY/MM/DD"),
                                                        reservation.announce
                                                            ?.price,
                                                        reservation.promotion
                                                            .price
                                                    ).DateProm;
                                                let nbjours_simple =
                                                    getDayNumberProm(
                                                        reservation.dateCheckIn,
                                                        reservation.dateCheckOut,
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .startDate
                                                        ).format("YYYY/MM/DD"),
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .endDate
                                                        ).format("YYYY/MM/DD"),
                                                        reservation.announce
                                                            ?.price,
                                                        reservation.promotion
                                                            .price
                                                    ).DateSimple;
                                                const jours =
                                                    nbjours_simple +
                                                    nbjours_prom;
                                                if (
                                                    jours >= 7 &&
                                                    jours < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 +
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.HEBDOMADAIRE
                                                    );
                                                } else if (
                                                    jours >= 14 &&
                                                    jours < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 +
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.QUINZAINE);
                                                } else if (
                                                    jours >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 +
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.MENSUEL);
                                                } else {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price +
                                                            reservation.announce
                                                                ?.price *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.JOURNALIER
                                                    );
                                                }

                                                _bookingAmount =
                                                    transaction.amount ?? 0;

                                                _creditAmount =
                                                    transaction.amountCredit ??
                                                    0;

                                                _totalBookingPrice =
                                                    _bookingAmount +
                                                    _creditAmount;
                                            } else {
                                                let jours = getDayNumber(
                                                    reservation.dateCheckIn,
                                                    reservation.dateCheckOut
                                                );
                                                if (
                                                    jours >= 7 &&
                                                    jours < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 +
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.HEBDOMADAIRE
                                                    );
                                                } else if (
                                                    jours >= 14 &&
                                                    jours < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 +
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.QUINZAINE);
                                                } else if (
                                                    jours >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 +
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.MENSUEL);
                                                } else {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price +
                                                            reservation.announce
                                                                ?.price *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.JOURNALIER
                                                    );
                                                }
                                                _bookingAmount =
                                                    transaction.amount ?? 0;

                                                _creditAmount =
                                                    transaction.amountCredit ??
                                                    0;

                                                _totalBookingPrice =
                                                    _bookingAmount +
                                                    _creditAmount;
                                            }

                                            ConvertedDeviseWithoutMoneySign(
                                                reservation.announce?.devise ||
                                                    "EUR",
                                                currency,
                                                Number(
                                                    _cleaningBookingFee.toFixed(
                                                        2
                                                    )
                                                )
                                            )
                                                .then((value: any): any => {
                                                    setCleaningFee(value);
                                                })
                                                .catch((error) =>
                                                    console.log(error)
                                                );

                                            ConvertedDeviseWithoutMoneySign(
                                                (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                currency,
                                                Number(
                                                    _totalBookingPrice.toFixed(
                                                        2
                                                    )
                                                )
                                            )
                                                .then((value: any): any => {
                                                    setTotalBookingPrice(value);
                                                })
                                                .catch((error) =>
                                                    console.log(error)
                                                );

                                            //--------------------------------
                                            if (
                                                getRefundType(
                                                    new Date(
                                                        reservation.dateCheckIn
                                                    ),
                                                    reservation.announce
                                                        ?.freeRefundDay
                                                ) === ERefundType.PreStay &&
                                                reservation.status[
                                                    reservation.status.length -
                                                        1
                                                ].status === EStatus.APPROVED
                                            ) {
                                                //Pendant le sejour
                                                //send to traveller
                                                const passedDays =
                                                    getPrestayPassedNumber(
                                                        new Date(
                                                            reservation.dateCheckIn
                                                        ),
                                                        reservation.announce
                                                            ?.freeRefundDay
                                                    );
                                                const daysPassedAmount =
                                                    _bookingPrice *
                                                    getPrestayPassedNumber(
                                                        new Date(
                                                            reservation.dateCheckIn
                                                        ),
                                                        reservation.announce
                                                            ?.freeRefundDay
                                                    );
                                                if (
                                                    passedDays >= 7 &&
                                                    passedDays < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 +
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 14 &&
                                                    passedDays < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 +
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 +
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price +
                                                            reservation.announce
                                                                ?.price *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                }
                                                const penaltyFeesAmount =
                                                    reservation.announce
                                                        ?.penaltyDayFeesAfterBookingStarted *
                                                    _penaltyPrice;
                                                const travellerFeesAmount =
                                                    daysPassedAmount +
                                                    penaltyFeesAmount +
                                                    _cleaningBookingFee;
                                                const travellerRefundInitial =
                                                    _totalBookingPrice -
                                                        travellerFeesAmount >
                                                    0
                                                        ? _totalBookingPrice -
                                                          travellerFeesAmount
                                                        : 0;
                                                const travellerReversalFees =
                                                    (travellerRefundInitial *
                                                        travellerPaymentMethodFee) /
                                                    100;
                                                const travellerValue =
                                                    travellerRefundInitial -
                                                        travellerReversalFees >
                                                    0
                                                        ? travellerRefundInitial -
                                                          travellerReversalFees
                                                        : 0;
                                                ConvertedDeviseWithoutMoneySign(
                                                    reservation.announce
                                                        ?.devise || "EUR",
                                                    currency,
                                                    Number(
                                                        daysPassedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setPassedDaysFees(
                                                            value
                                                        );
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                                ConvertedDeviseWithoutMoneySign(
                                                    reservation.announce
                                                        ?.devise || "EUR",
                                                    currency,
                                                    Number(
                                                        penaltyFeesAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setPenaltyDaysFees(
                                                            value
                                                        );
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                                ConvertedDeviseWithoutMoneySign(
                                                    (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                    currency,
                                                    Number(
                                                        travellerValue.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setTravellerRefundFinal(
                                                            value
                                                        );
                                                        if (
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Paypal
                                                        ) {
                                                            setRefundTraveller({
                                                                ...refundTraveller,
                                                                amount: {
                                                                    value: value,
                                                                    currency:
                                                                        currency,
                                                                },
                                                                reservation:
                                                                    reservation._id ||
                                                                    "",
                                                                recepient_type:
                                                                    "EMAIL",
                                                                receiver:
                                                                    travellerPaymentAccount !==
                                                                    ""
                                                                        ? travellerPaymentAccount
                                                                        : "",
                                                            });
                                                        } else {
                                                            setRefundTravellerDohone(
                                                                {
                                                                    ...refundTravellerDohone,
                                                                    destination:
                                                                        travellerPaymentAccount !==
                                                                        ""
                                                                            ? (
                                                                                  reservation
                                                                                      .announce
                                                                                      ?.codePhone +
                                                                                  travellerPaymentAccount
                                                                              ).substring(
                                                                                  1
                                                                              )
                                                                            : "",
                                                                    mode:
                                                                        travellerPaymentMethod ===
                                                                        EPaymentMethod.Orange
                                                                            ? DohonePayoutMode.orangeMoney
                                                                            : travellerPaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                            : DohonePayoutMode.expressUnion,
                                                                    amount: value,
                                                                    devise: currency,
                                                                    nameDest:
                                                                        reservation
                                                                            .traveller
                                                                            .firstName,
                                                                    ville: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .city,
                                                                    pays: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .country,
                                                                    rUserId:
                                                                        reservation
                                                                            .traveller
                                                                            ._id,
                                                                    reservation:
                                                                        reservation._id ||
                                                                        "",
                                                                }
                                                            );
                                                        }
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );

                                                //send to host
                                                if (
                                                    passedDays >= 7 &&
                                                    passedDays < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _baseBookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                            7
                                                        ).toFixed(2)
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 -
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 14 &&
                                                    passedDays < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _baseBookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                            14
                                                        ).toFixed(2)
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 -
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _baseBookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                            30
                                                        ).toFixed(2)
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 -
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else {
                                                    _baseBookingPrice = Number(
                                                        (reservation.announce?.price).toFixed(
                                                            2
                                                        )
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price -
                                                            reservation.announce
                                                                ?.price *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                }
                                                const penaltyDaysBaseAmount =
                                                    reservation.announce
                                                        ?.penaltyDayFeesAfterBookingStarted *
                                                    _hostRefundPrice;
                                                const hostValue =
                                                    reservation.announce
                                                        ?.cleaningFees +
                                                    penaltyDaysBaseAmount +
                                                    _totalService;
                                                userReservService
                                                    .getPaymentMethodFees(
                                                        "Paypal" || "Unknown"
                                                    )
                                                    .then((respMethodFee) => {
                                                        if (
                                                            respMethodFee.success
                                                        ) {
                                                            ConvertedDeviseWithoutMoneySign(
                                                                reservation
                                                                    .announce
                                                                    ?.devise ||
                                                                    "EUR",
                                                                currency,
                                                                Number(
                                                                    hostValue.toFixed(
                                                                        2
                                                                    )
                                                                )
                                                            )
                                                                .then(
                                                                    (
                                                                        value: any
                                                                    ): any => {
                                                                        if (
                                                                            travellerPaymentMethod ===
                                                                                EPaymentMethod.Paypal &&
                                                                            reservation
                                                                                .announce
                                                                                ?.account_paypal
                                                                        ) {
                                                                            setHostPaymentMethod(
                                                                                EPaymentMethod.Paypal
                                                                            );
                                                                            setRefundHost(
                                                                                {
                                                                                    ...refundHost,
                                                                                    amount: {
                                                                                        value: value,
                                                                                        currency:
                                                                                            currency,
                                                                                    },
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                    recepient_type:
                                                                                        "EMAIL",
                                                                                    receiver:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.account_paypal,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            setHostPaymentMethod(
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                    EPaymentMethod.Orange
                                                                                    ? EPaymentMethod.Orange
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? EPaymentMethod.MTN
                                                                                    : EPaymentMethod.ExpressUnion
                                                                            );
                                                                            setRefundHostDohone(
                                                                                {
                                                                                    ...refundHostDohone,
                                                                                    destination:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_orange
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_mtn
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_express_union
                                                                                              ).substring(
                                                                                                  1
                                                                                              ),
                                                                                    mode:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? DohonePayoutMode.orangeMoney
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                                            : DohonePayoutMode.expressUnion,
                                                                                    amount: value,
                                                                                    devise: currency,
                                                                                    nameDest:
                                                                                        reservation
                                                                                            .host
                                                                                            .firstName,
                                                                                    ville: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .city,
                                                                                    pays: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .country,
                                                                                    rUserId:
                                                                                        reservation
                                                                                            .host
                                                                                            ._id,
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) =>
                                                                        console.log(
                                                                            error
                                                                        )
                                                                );
                                                        }
                                                    });
                                            } else if (
                                                getRefundType(
                                                    new Date(
                                                        reservation.dateCheckIn
                                                    ),
                                                    reservation.announce
                                                        ?.freeRefundDay
                                                ) === ERefundType.Paid &&
                                                reservation.status[
                                                    reservation.status.length -
                                                        1
                                                ].status === EStatus.APPROVED
                                            ) {
                                                //Pre-sejour
                                                //Send to traveller
                                                _penaltyPrice = Number(
                                                    (
                                                        reservation.announce
                                                            ?.price +
                                                        reservation.announce
                                                            ?.price *
                                                            (_travellerFee /
                                                                100)
                                                    ).toFixed(2)
                                                );
                                                const penaltyFeesAmount =
                                                    reservation.announce
                                                        ?.penaltyDayFees *
                                                    _penaltyPrice;
                                                const travellerRefundInitial =
                                                    _totalBookingPrice -
                                                        penaltyFeesAmount >
                                                    0
                                                        ? _totalBookingPrice -
                                                          penaltyFeesAmount
                                                        : 0;
                                                const travellerReversalFees =
                                                    (travellerRefundInitial *
                                                        travellerPaymentMethodFee) /
                                                    100;
                                                const travellerValue =
                                                    travellerRefundInitial -
                                                        travellerReversalFees >
                                                    0
                                                        ? travellerRefundInitial -
                                                          travellerReversalFees
                                                        : 0;
                                                ConvertedDeviseWithoutMoneySign(
                                                    reservation.announce
                                                        ?.devise || "EUR",
                                                    currency,
                                                    Number(
                                                        penaltyFeesAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setPenaltyDaysFees(
                                                            value
                                                        );
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                                ConvertedDeviseWithoutMoneySign(
                                                    (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                    currency,
                                                    Number(
                                                        travellerValue.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setTravellerRefundFinal(
                                                            value
                                                        );
                                                        if (
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Paypal
                                                        ) {
                                                            setRefundTraveller({
                                                                ...refundTraveller,
                                                                amount: {
                                                                    value: value,
                                                                    currency:
                                                                        currency,
                                                                },
                                                                reservation:
                                                                    reservation._id ||
                                                                    "",
                                                                recepient_type:
                                                                    "EMAIL",
                                                                receiver:
                                                                    travellerPaymentAccount !==
                                                                    ""
                                                                        ? travellerPaymentAccount
                                                                        : "",
                                                            });
                                                        } else {
                                                            setRefundTravellerDohone(
                                                                {
                                                                    ...refundTravellerDohone,
                                                                    destination:
                                                                        travellerPaymentAccount !==
                                                                        ""
                                                                            ? (
                                                                                  reservation
                                                                                      .announce
                                                                                      ?.codePhone +
                                                                                  travellerPaymentAccount
                                                                              ).substring(
                                                                                  1
                                                                              )
                                                                            : "",
                                                                    mode:
                                                                        travellerPaymentMethod ===
                                                                        EPaymentMethod.Orange
                                                                            ? DohonePayoutMode.orangeMoney
                                                                            : travellerPaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                            : DohonePayoutMode.expressUnion,
                                                                    amount: value,
                                                                    devise: currency,
                                                                    nameDest:
                                                                        reservation
                                                                            .traveller
                                                                            .firstName,
                                                                    ville: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .city,
                                                                    pays: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .country,
                                                                    rUserId:
                                                                        reservation
                                                                            .traveller
                                                                            ._id,
                                                                    reservation:
                                                                        reservation._id ||
                                                                        "",
                                                                }
                                                            );
                                                        }
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );

                                                //Send to host
                                                const hostValue =
                                                    reservation.announce
                                                        ?.penaltyDayFees *
                                                    (_baseBookingPrice -
                                                        (_baseBookingPrice *
                                                            _hostPercentage) /
                                                            100);
                                                userReservService
                                                    .getPaymentMethodFees(
                                                        "Paypal" || "Unknown"
                                                    )
                                                    .then((respMethodFee) => {
                                                        if (
                                                            respMethodFee.success
                                                        ) {
                                                            ConvertedDeviseWithoutMoneySign(
                                                                reservation
                                                                    .announce
                                                                    ?.devise ||
                                                                    "EUR",
                                                                currency,
                                                                Number(
                                                                    hostValue.toFixed(
                                                                        2
                                                                    )
                                                                )
                                                            )
                                                                .then(
                                                                    (
                                                                        value: any
                                                                    ): any => {
                                                                        if (
                                                                            travellerPaymentMethod ===
                                                                                EPaymentMethod.Paypal &&
                                                                            reservation
                                                                                .announce
                                                                                ?.account_paypal
                                                                        ) {
                                                                            setHostPaymentMethod(
                                                                                EPaymentMethod.Paypal
                                                                            );
                                                                            setRefundHost(
                                                                                {
                                                                                    ...refundHost,
                                                                                    amount: {
                                                                                        value: value,
                                                                                        currency:
                                                                                            currency,
                                                                                    },
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                    recepient_type:
                                                                                        "EMAIL",
                                                                                    receiver:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.account_paypal,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            setHostPaymentMethod(
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                    EPaymentMethod.Orange
                                                                                    ? EPaymentMethod.Orange
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? EPaymentMethod.MTN
                                                                                    : EPaymentMethod.ExpressUnion
                                                                            );
                                                                            setRefundHostDohone(
                                                                                {
                                                                                    ...refundHostDohone,
                                                                                    destination:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_orange
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_mtn
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_express_union
                                                                                              ).substring(
                                                                                                  1
                                                                                              ),
                                                                                    mode:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? DohonePayoutMode.orangeMoney
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                                            : DohonePayoutMode.expressUnion,
                                                                                    amount: value,
                                                                                    devise: currency,
                                                                                    nameDest:
                                                                                        reservation
                                                                                            .host
                                                                                            .firstName,
                                                                                    ville: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .city,
                                                                                    pays: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .country,
                                                                                    rUserId:
                                                                                        reservation
                                                                                            .host
                                                                                            ._id,
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) =>
                                                                        console.log(
                                                                            error
                                                                        )
                                                                );
                                                        }
                                                    });
                                            } else {
                                                //Gratuit
                                                const travellerRefundInitial =
                                                    _totalBookingPrice;
                                                const travellerReversalFees =
                                                    (travellerRefundInitial *
                                                        travellerPaymentMethodFee) /
                                                    100;
                                                const travellerValue =
                                                    travellerRefundInitial -
                                                        travellerReversalFees >
                                                    0
                                                        ? travellerRefundInitial -
                                                          travellerReversalFees
                                                        : 0;
                                                ConvertedDeviseWithoutMoneySign(
                                                    (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                    currency,
                                                    Number(
                                                        travellerValue.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setTravellerRefundFinal(
                                                            value
                                                        );
                                                        if (
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Paypal
                                                        ) {
                                                            setRefundTraveller({
                                                                ...refundTraveller,
                                                                amount: {
                                                                    value: value,
                                                                    currency:
                                                                        currency,
                                                                },
                                                                reservation:
                                                                    reservation._id ||
                                                                    "",
                                                                recepient_type:
                                                                    "EMAIL",
                                                                receiver:
                                                                    travellerPaymentAccount !==
                                                                    ""
                                                                        ? travellerPaymentAccount
                                                                        : "",
                                                            });
                                                        } else {
                                                            setRefundTravellerDohone(
                                                                {
                                                                    ...refundTravellerDohone,
                                                                    destination:
                                                                        travellerPaymentAccount !==
                                                                        ""
                                                                            ? (
                                                                                  reservation
                                                                                      .announce
                                                                                      ?.codePhone +
                                                                                  travellerPaymentAccount
                                                                              ).substring(
                                                                                  1
                                                                              )
                                                                            : "",
                                                                    mode:
                                                                        travellerPaymentMethod ===
                                                                        EPaymentMethod.Orange
                                                                            ? DohonePayoutMode.orangeMoney
                                                                            : travellerPaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                            : DohonePayoutMode.expressUnion,
                                                                    amount: value,
                                                                    devise: currency,
                                                                    nameDest:
                                                                        reservation
                                                                            .traveller
                                                                            .firstName,
                                                                    ville: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .city,
                                                                    pays: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .country,
                                                                    rUserId:
                                                                        reservation
                                                                            .traveller
                                                                            ._id,
                                                                    reservation:
                                                                        reservation._id ||
                                                                        "",
                                                                }
                                                            );
                                                        }
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                            }
                                        }
                                    })
                                    .catch((error) => console.log(error));
                            } else {
                                userReservService
                                    .getHostFeeByCountryAndCityId(
                                        reservation.announce?.property?.address
                                            ?.country,
                                        reservation.announce?.property?.address
                                            ?.city
                                    )
                                    .then((respHostFee) => {
                                        if (respHostFee.success) {
                                            _hostPercentage = respHostFee.data
                                                ? respHostFee.data.percentage
                                                : 0;
                                            _baseBookingPrice =
                                                reservation.announce?.price;

                                            _cleaningBookingFee = Number(
                                                (
                                                    reservation.announce
                                                        ?.cleaningFees +
                                                    reservation.announce
                                                        ?.cleaningFees *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            /*total promotion ou non promotion*/
                                            if (reservation.promotion) {
                                                let nbjours_prom =
                                                    getDayNumberProm(
                                                        reservation.dateCheckIn,
                                                        reservation.dateCheckOut,
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .startDate
                                                        ).format("YYYY/MM/DD"),
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .endDate
                                                        ).format("YYYY/MM/DD"),
                                                        reservation.announce
                                                            ?.price,
                                                        reservation.promotion
                                                            .price
                                                    ).DateProm;
                                                let nbjours_simple =
                                                    getDayNumberProm(
                                                        reservation.dateCheckIn,
                                                        reservation.dateCheckOut,
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .startDate
                                                        ).format("YYYY/MM/DD"),
                                                        moment(
                                                            reservation
                                                                .promotion
                                                                .endDate
                                                        ).format("YYYY/MM/DD"),
                                                        reservation.announce
                                                            ?.price,
                                                        reservation.promotion
                                                            .price
                                                    ).DateSimple;
                                                const jours =
                                                    nbjours_simple +
                                                    nbjours_prom;
                                                if (
                                                    jours >= 7 &&
                                                    jours < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 +
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.HEBDOMADAIRE
                                                    );
                                                } else if (
                                                    jours >= 14 &&
                                                    jours < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 +
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.QUINZAINE);
                                                } else if (
                                                    jours >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 +
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.MENSUEL);
                                                } else {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price +
                                                            reservation.announce
                                                                ?.price *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.JOURNALIER
                                                    );
                                                }

                                                _bookingAmount =
                                                    transaction.amount ?? 0;

                                                _creditAmount =
                                                    transaction.amountCredit ??
                                                    0;

                                                _totalBookingPrice =
                                                    _bookingAmount +
                                                    _creditAmount;
                                            } else {
                                                let jours = getDayNumber(
                                                    reservation.dateCheckIn,
                                                    reservation.dateCheckOut
                                                );
                                                if (
                                                    jours >= 7 &&
                                                    jours < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 +
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.HEBDOMADAIRE
                                                    );
                                                } else if (
                                                    jours >= 14 &&
                                                    jours < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 +
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.QUINZAINE);
                                                } else if (
                                                    jours >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 +
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(ETarifs.MENSUEL);
                                                } else {
                                                    _bookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price +
                                                            reservation.announce
                                                                ?.price *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                    setTarif(
                                                        ETarifs.JOURNALIER
                                                    );
                                                }
                                                _bookingAmount =
                                                    transaction.amount ?? 0;

                                                _creditAmount =
                                                    transaction.amountCredit ??
                                                    0;

                                                _totalBookingPrice =
                                                    _bookingAmount +
                                                    _creditAmount;
                                            }

                                            ConvertedDeviseWithoutMoneySign(
                                                reservation.announce?.devise ||
                                                    "EUR",
                                                currency,
                                                Number(
                                                    _cleaningBookingFee.toFixed(
                                                        2
                                                    )
                                                )
                                            )
                                                .then((value: any): any => {
                                                    setCleaningFee(value);
                                                })
                                                .catch((error) =>
                                                    console.log(error)
                                                );

                                            ConvertedDeviseWithoutMoneySign(
                                                (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                currency,
                                                Number(
                                                    _totalBookingPrice.toFixed(
                                                        2
                                                    )
                                                )
                                            )
                                                .then((value: any): any => {
                                                    setTotalBookingPrice(value);
                                                })
                                                .catch((error) =>
                                                    console.log(error)
                                                );

                                            //--------------------------------
                                            if (
                                                getRefundType(
                                                    new Date(
                                                        reservation.dateCheckIn
                                                    ),
                                                    reservation.announce
                                                        ?.freeRefundDay
                                                ) === ERefundType.PreStay &&
                                                reservation.status[
                                                    reservation.status.length -
                                                        1
                                                ].status === EStatus.APPROVED
                                            ) {
                                                //Pendant le sejour
                                                //send to traveller
                                                const passedDays =
                                                    getPrestayPassedNumber(
                                                        new Date(
                                                            reservation.dateCheckIn
                                                        ),
                                                        reservation.announce
                                                            ?.freeRefundDay
                                                    );
                                                const daysPassedAmount =
                                                    _bookingPrice *
                                                    getPrestayPassedNumber(
                                                        new Date(
                                                            reservation.dateCheckIn
                                                        ),
                                                        reservation.announce
                                                            ?.freeRefundDay
                                                    );
                                                if (
                                                    passedDays >= 7 &&
                                                    passedDays < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 +
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 14 &&
                                                    passedDays < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 +
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 +
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else {
                                                    _penaltyPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price +
                                                            reservation.announce
                                                                ?.price *
                                                                (_travellerFee /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                }
                                                const penaltyFeesAmount =
                                                    reservation.announce
                                                        ?.penaltyDayFeesAfterBookingStarted *
                                                    _penaltyPrice;
                                                const travellerFeesAmount =
                                                    daysPassedAmount +
                                                    penaltyFeesAmount +
                                                    _cleaningBookingFee;
                                                const travellerRefundInitial =
                                                    _totalBookingPrice -
                                                        travellerFeesAmount >
                                                    0
                                                        ? _totalBookingPrice -
                                                          travellerFeesAmount
                                                        : 0;
                                                const travellerReversalFees =
                                                    (travellerRefundInitial *
                                                        travellerPaymentMethodFee) /
                                                    100;
                                                const travellerValue =
                                                    travellerRefundInitial -
                                                        travellerReversalFees >
                                                    0
                                                        ? travellerRefundInitial -
                                                          travellerReversalFees
                                                        : 0;
                                                ConvertedDeviseWithoutMoneySign(
                                                    reservation.announce
                                                        ?.devise || "EUR",
                                                    currency,
                                                    Number(
                                                        daysPassedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setPassedDaysFees(
                                                            value
                                                        );
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                                ConvertedDeviseWithoutMoneySign(
                                                    reservation.announce
                                                        ?.devise || "EUR",
                                                    currency,
                                                    Number(
                                                        penaltyFeesAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setPenaltyDaysFees(
                                                            value
                                                        );
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                                ConvertedDeviseWithoutMoneySign(
                                                    (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                    currency,
                                                    Number(
                                                        travellerValue.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setTravellerRefundFinal(
                                                            value
                                                        );
                                                        if (
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Paypal
                                                        ) {
                                                            setRefundTraveller({
                                                                ...refundTraveller,
                                                                amount: {
                                                                    value: value,
                                                                    currency:
                                                                        currency,
                                                                },
                                                                reservation:
                                                                    reservation._id ||
                                                                    "",
                                                                recepient_type:
                                                                    "EMAIL",
                                                                receiver:
                                                                    travellerPaymentAccount !==
                                                                    ""
                                                                        ? travellerPaymentAccount
                                                                        : "",
                                                            });
                                                        } else {
                                                            setRefundTravellerDohone(
                                                                {
                                                                    ...refundTravellerDohone,
                                                                    destination:
                                                                        travellerPaymentAccount !==
                                                                        ""
                                                                            ? (
                                                                                  reservation
                                                                                      .announce
                                                                                      ?.codePhone +
                                                                                  travellerPaymentAccount
                                                                              ).substring(
                                                                                  1
                                                                              )
                                                                            : "",
                                                                    mode:
                                                                        travellerPaymentMethod ===
                                                                        EPaymentMethod.Orange
                                                                            ? DohonePayoutMode.orangeMoney
                                                                            : travellerPaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                            : DohonePayoutMode.expressUnion,
                                                                    amount: value,
                                                                    devise: currency,
                                                                    nameDest:
                                                                        reservation
                                                                            .traveller
                                                                            .firstName,
                                                                    ville: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .city,
                                                                    pays: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .country,
                                                                    rUserId:
                                                                        reservation
                                                                            .traveller
                                                                            ._id,
                                                                    reservation:
                                                                        reservation._id ||
                                                                        "",
                                                                }
                                                            );
                                                        }
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );

                                                //send to host
                                                if (
                                                    passedDays >= 7 &&
                                                    passedDays < 14 &&
                                                    reservation.announce
                                                        ?.priceWeekly &&
                                                    reservation.announce
                                                        ?.priceWeekly > 0
                                                ) {
                                                    _baseBookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                            7
                                                        ).toFixed(2)
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceWeekly /
                                                                7 -
                                                            (reservation
                                                                .announce
                                                                ?.priceWeekly /
                                                                7) *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 14 &&
                                                    passedDays < 30 &&
                                                    reservation.announce
                                                        ?.priceBiWeekly &&
                                                    reservation.announce
                                                        ?.priceBiWeekly > 0
                                                ) {
                                                    _baseBookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                            14
                                                        ).toFixed(2)
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceBiWeekly /
                                                                14 -
                                                            (reservation
                                                                .announce
                                                                ?.priceBiWeekly /
                                                                14) *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else if (
                                                    passedDays >= 30 &&
                                                    reservation.announce
                                                        ?.priceMonthly &&
                                                    reservation.announce
                                                        ?.priceMonthly > 0
                                                ) {
                                                    _baseBookingPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                            30
                                                        ).toFixed(2)
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.priceMonthly /
                                                                30 -
                                                            (reservation
                                                                .announce
                                                                ?.priceMonthly /
                                                                30) *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                } else {
                                                    _baseBookingPrice = Number(
                                                        (reservation.announce?.price).toFixed(
                                                            2
                                                        )
                                                    );
                                                    _hostRefundPrice = Number(
                                                        (
                                                            reservation.announce
                                                                ?.price -
                                                            reservation.announce
                                                                ?.price *
                                                                (_hostPercentage /
                                                                    100)
                                                        ).toFixed(2)
                                                    );
                                                }
                                                const penaltyDaysBaseAmount =
                                                    reservation.announce
                                                        ?.penaltyDayFeesAfterBookingStarted *
                                                    _hostRefundPrice;
                                                const hostValue =
                                                    reservation.announce
                                                        ?.cleaningFees +
                                                    penaltyDaysBaseAmount +
                                                    _totalService;
                                                userReservService
                                                    .getPaymentMethodFees(
                                                        "Paypal" || "Unknown"
                                                    )
                                                    .then((respMethodFee) => {
                                                        if (
                                                            respMethodFee.success
                                                        ) {
                                                            ConvertedDeviseWithoutMoneySign(
                                                                reservation
                                                                    .announce
                                                                    ?.devise ||
                                                                    "EUR",
                                                                currency,
                                                                Number(
                                                                    hostValue.toFixed(
                                                                        2
                                                                    )
                                                                )
                                                            )
                                                                .then(
                                                                    (
                                                                        value: any
                                                                    ): any => {
                                                                        if (
                                                                            travellerPaymentMethod ===
                                                                                EPaymentMethod.Paypal &&
                                                                            reservation
                                                                                .announce
                                                                                ?.account_paypal
                                                                        ) {
                                                                            setHostPaymentMethod(
                                                                                EPaymentMethod.Paypal
                                                                            );
                                                                            setRefundHost(
                                                                                {
                                                                                    ...refundHost,
                                                                                    amount: {
                                                                                        value: value,
                                                                                        currency:
                                                                                            currency,
                                                                                    },
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                    recepient_type:
                                                                                        "EMAIL",
                                                                                    receiver:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.account_paypal,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            setHostPaymentMethod(
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                    EPaymentMethod.Orange
                                                                                    ? EPaymentMethod.Orange
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? EPaymentMethod.MTN
                                                                                    : EPaymentMethod.ExpressUnion
                                                                            );
                                                                            setRefundHostDohone(
                                                                                {
                                                                                    ...refundHostDohone,
                                                                                    destination:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_orange
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_mtn
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_express_union
                                                                                              ).substring(
                                                                                                  1
                                                                                              ),
                                                                                    mode:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? DohonePayoutMode.orangeMoney
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                                            : DohonePayoutMode.expressUnion,
                                                                                    amount: value,
                                                                                    devise: currency,
                                                                                    nameDest:
                                                                                        reservation
                                                                                            .host
                                                                                            .firstName,
                                                                                    ville: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .city,
                                                                                    pays: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .country,
                                                                                    rUserId:
                                                                                        reservation
                                                                                            .host
                                                                                            ._id,
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) =>
                                                                        console.log(
                                                                            error
                                                                        )
                                                                );
                                                        }
                                                    });
                                            } else if (
                                                getRefundType(
                                                    new Date(
                                                        reservation.dateCheckIn
                                                    ),
                                                    reservation.announce
                                                        ?.freeRefundDay
                                                ) === ERefundType.Paid &&
                                                reservation.status[
                                                    reservation.status.length -
                                                        1
                                                ].status === EStatus.APPROVED
                                            ) {
                                                //Pre-sejour
                                                //Send to traveller
                                                _penaltyPrice = Number(
                                                    (
                                                        reservation.announce
                                                            ?.price +
                                                        reservation.announce
                                                            ?.price *
                                                            (_travellerFee /
                                                                100)
                                                    ).toFixed(2)
                                                );
                                                const penaltyFeesAmount =
                                                    reservation.announce
                                                        ?.penaltyDayFees *
                                                    _penaltyPrice;
                                                const travellerRefundInitial =
                                                    _totalBookingPrice -
                                                        penaltyFeesAmount >
                                                    0
                                                        ? _totalBookingPrice -
                                                          penaltyFeesAmount
                                                        : 0;
                                                const travellerReversalFees =
                                                    (travellerRefundInitial *
                                                        travellerPaymentMethodFee) /
                                                    100;
                                                const travellerValue =
                                                    travellerRefundInitial -
                                                        travellerReversalFees >
                                                    0
                                                        ? travellerRefundInitial -
                                                          travellerReversalFees
                                                        : 0;
                                                ConvertedDeviseWithoutMoneySign(
                                                    reservation.announce
                                                        ?.devise || "EUR",
                                                    currency,
                                                    Number(
                                                        penaltyFeesAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setPenaltyDaysFees(
                                                            value
                                                        );
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                                ConvertedDeviseWithoutMoneySign(
                                                    (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                    currency,
                                                    Number(
                                                        travellerValue.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setTravellerRefundFinal(
                                                            value
                                                        );
                                                        if (
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Paypal
                                                        ) {
                                                            setRefundTraveller({
                                                                ...refundTraveller,
                                                                amount: {
                                                                    value: value,
                                                                    currency:
                                                                        currency,
                                                                },
                                                                reservation:
                                                                    reservation._id ||
                                                                    "",
                                                                recepient_type:
                                                                    "EMAIL",
                                                                receiver:
                                                                    travellerPaymentAccount !==
                                                                    ""
                                                                        ? travellerPaymentAccount
                                                                        : "",
                                                            });
                                                        } else {
                                                            setRefundTravellerDohone(
                                                                {
                                                                    ...refundTravellerDohone,
                                                                    destination:
                                                                        travellerPaymentAccount !==
                                                                        ""
                                                                            ? (
                                                                                  reservation
                                                                                      .announce
                                                                                      ?.codePhone +
                                                                                  travellerPaymentAccount
                                                                              ).substring(
                                                                                  1
                                                                              )
                                                                            : "",
                                                                    mode:
                                                                        travellerPaymentMethod ===
                                                                        EPaymentMethod.Orange
                                                                            ? DohonePayoutMode.orangeMoney
                                                                            : travellerPaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                            : DohonePayoutMode.expressUnion,
                                                                    amount: value,
                                                                    devise: currency,
                                                                    nameDest:
                                                                        reservation
                                                                            .traveller
                                                                            .firstName,
                                                                    ville: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .city,
                                                                    pays: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .country,
                                                                    rUserId:
                                                                        reservation
                                                                            .traveller
                                                                            ._id,
                                                                    reservation:
                                                                        reservation._id ||
                                                                        "",
                                                                }
                                                            );
                                                        }
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );

                                                //Send to host
                                                const hostValue =
                                                    reservation.announce
                                                        ?.penaltyDayFees *
                                                    (_baseBookingPrice -
                                                        (_baseBookingPrice *
                                                            _hostPercentage) /
                                                            100);
                                                userReservService
                                                    .getPaymentMethodFees(
                                                        "Paypal" || "Unknown"
                                                    )
                                                    .then((respMethodFee) => {
                                                        if (
                                                            respMethodFee.success
                                                        ) {
                                                            ConvertedDeviseWithoutMoneySign(
                                                                reservation
                                                                    .announce
                                                                    ?.devise ||
                                                                    "EUR",
                                                                currency,
                                                                Number(
                                                                    hostValue.toFixed(
                                                                        2
                                                                    )
                                                                )
                                                            )
                                                                .then(
                                                                    (
                                                                        value: any
                                                                    ): any => {
                                                                        if (
                                                                            travellerPaymentMethod ===
                                                                                EPaymentMethod.Paypal &&
                                                                            reservation
                                                                                .announce
                                                                                ?.account_paypal
                                                                        ) {
                                                                            setHostPaymentMethod(
                                                                                EPaymentMethod.Paypal
                                                                            );
                                                                            setRefundHost(
                                                                                {
                                                                                    ...refundHost,
                                                                                    amount: {
                                                                                        value: value,
                                                                                        currency:
                                                                                            currency,
                                                                                    },
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                    recepient_type:
                                                                                        "EMAIL",
                                                                                    receiver:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.account_paypal,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            setHostPaymentMethod(
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                    EPaymentMethod.Orange
                                                                                    ? EPaymentMethod.Orange
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? EPaymentMethod.MTN
                                                                                    : EPaymentMethod.ExpressUnion
                                                                            );
                                                                            setRefundHostDohone(
                                                                                {
                                                                                    ...refundHostDohone,
                                                                                    destination:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_orange
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_mtn
                                                                                              ).substring(
                                                                                                  1
                                                                                              )
                                                                                            : (
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.codePhone +
                                                                                                  reservation
                                                                                                      .announce
                                                                                                      ?.account_express_union
                                                                                              ).substring(
                                                                                                  1
                                                                                              ),
                                                                                    mode:
                                                                                        reservation
                                                                                            .announce
                                                                                            ?.favoritePaymentMethod ===
                                                                                        EPaymentMethod.Orange
                                                                                            ? DohonePayoutMode.orangeMoney
                                                                                            : reservation
                                                                                                  .announce
                                                                                                  ?.favoritePaymentMethod ===
                                                                                              EPaymentMethod.MTN
                                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                                            : DohonePayoutMode.expressUnion,
                                                                                    amount: value,
                                                                                    devise: currency,
                                                                                    nameDest:
                                                                                        reservation
                                                                                            .host
                                                                                            .firstName,
                                                                                    ville: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .city,
                                                                                    pays: reservation
                                                                                        .announce
                                                                                        ?.property
                                                                                        .address
                                                                                        .country,
                                                                                    rUserId:
                                                                                        reservation
                                                                                            .host
                                                                                            ._id,
                                                                                    reservation:
                                                                                        reservation._id ||
                                                                                        "",
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) =>
                                                                        console.log(
                                                                            error
                                                                        )
                                                                );
                                                        }
                                                    });
                                            } else {
                                                //Gratuit
                                                const travellerRefundInitial =
                                                    _totalBookingPrice;
                                                const travellerReversalFees =
                                                    (travellerRefundInitial *
                                                        travellerPaymentMethodFee) /
                                                    100;
                                                const travellerValue =
                                                    travellerRefundInitial -
                                                        travellerReversalFees >
                                                    0
                                                        ? travellerRefundInitial -
                                                          travellerReversalFees
                                                        : 0;
                                                ConvertedDeviseWithoutMoneySign(
                                                    (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                                    currency,
                                                    Number(
                                                        travellerValue.toFixed(
                                                            2
                                                        )
                                                    )
                                                )
                                                    .then((value: any): any => {
                                                        setTravellerRefundFinal(
                                                            value
                                                        );
                                                        if (
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Paypal
                                                        ) {
                                                            setRefundTraveller({
                                                                ...refundTraveller,
                                                                amount: {
                                                                    value: value,
                                                                    currency:
                                                                        currency,
                                                                },
                                                                reservation:
                                                                    reservation._id ||
                                                                    "",
                                                                recepient_type:
                                                                    "EMAIL",
                                                                receiver:
                                                                    travellerPaymentAccount !==
                                                                    ""
                                                                        ? travellerPaymentAccount
                                                                        : "",
                                                            });
                                                        } else {
                                                            setRefundTravellerDohone(
                                                                {
                                                                    ...refundTravellerDohone,
                                                                    destination:
                                                                        travellerPaymentAccount !==
                                                                        ""
                                                                            ? (
                                                                                  reservation
                                                                                      .announce
                                                                                      ?.codePhone +
                                                                                  travellerPaymentAccount
                                                                              ).substring(
                                                                                  1
                                                                              )
                                                                            : "",
                                                                    mode:
                                                                        travellerPaymentMethod ===
                                                                        EPaymentMethod.Orange
                                                                            ? DohonePayoutMode.orangeMoney
                                                                            : travellerPaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? DohonePayoutMode.mtnMobileMoney
                                                                            : DohonePayoutMode.expressUnion,
                                                                    amount: value,
                                                                    devise: currency,
                                                                    nameDest:
                                                                        reservation
                                                                            .traveller
                                                                            .firstName,
                                                                    ville: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .city,
                                                                    pays: reservation
                                                                        .announce
                                                                        ?.property
                                                                        .address
                                                                        .country,
                                                                    rUserId:
                                                                        reservation
                                                                            .traveller
                                                                            ._id,
                                                                    reservation:
                                                                        reservation._id ||
                                                                        "",
                                                                }
                                                            );
                                                        }
                                                    })
                                                    .catch((error) =>
                                                        console.log(error)
                                                    );
                                            }
                                        }
                                    })
                                    .catch((error) => console.log(error));
                            }
                            // }
                        })
                        .catch((error) => console.log(error));
                } else {
                    if (reservation.announce.property.type.name === "Hôtel") {
                        userReservService
                            .getSpecialFeeByPropertyType(
                                reservation.announce?.property?.type?.name
                            )
                            .then((response) => {
                                if (response.success) {
                                    _hostPercentage = response.data
                                        ? response.data.percentage ?? 0
                                        : 0;
                                    _baseBookingPrice =
                                        reservation.announce?.price;
                                    _bookingPrice = Number(
                                        (
                                            reservation.announce?.price +
                                            reservation.announce?.price *
                                                (_travellerFee / 100)
                                        ).toFixed(2)
                                    );
                                    _cleaningBookingFee = Number(
                                        (
                                            reservation.announce?.cleaningFees +
                                            reservation.announce?.cleaningFees *
                                                (_travellerFee / 100)
                                        ).toFixed(2)
                                    );

                                    /*total promotion ou non promotion*/
                                    if (reservation.promotion) {
                                        let nbjours_prom = getDayNumberProm(
                                            reservation.dateCheckIn,
                                            reservation.dateCheckOut,
                                            moment(
                                                reservation.promotion.startDate
                                            ).format("YYYY/MM/DD"),
                                            moment(
                                                reservation.promotion.endDate
                                            ).format("YYYY/MM/DD"),
                                            reservation.announce?.price,
                                            reservation.promotion.price
                                        ).DateProm;
                                        let nbjours_simple = getDayNumberProm(
                                            reservation.dateCheckIn,
                                            reservation.dateCheckOut,
                                            moment(
                                                reservation.promotion.startDate
                                            ).format("YYYY/MM/DD"),
                                            moment(
                                                reservation.promotion.endDate
                                            ).format("YYYY/MM/DD"),
                                            reservation.announce?.price,
                                            reservation.promotion.price
                                        ).DateSimple;
                                        const jours =
                                            nbjours_simple + nbjours_prom;
                                        if (
                                            jours >= 7 &&
                                            jours < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 +
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.HEBDOMADAIRE);
                                        } else if (
                                            jours >= 14 &&
                                            jours < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 +
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.QUINZAINE);
                                        } else if (
                                            jours >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 +
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.MENSUEL);
                                        } else {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price +
                                                    reservation.announce
                                                        ?.price *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.JOURNALIER);
                                        }
                                        _bookingAmount =
                                            transaction.amount ?? 0;

                                        _creditAmount =
                                            transaction.amountCredit ?? 0;

                                        _totalBookingPrice =
                                            _bookingAmount + _creditAmount;
                                    } else {
                                        let jours = getDayNumber(
                                            reservation.dateCheckIn,
                                            reservation.dateCheckOut
                                        );
                                        if (
                                            jours >= 7 &&
                                            jours < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 +
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.HEBDOMADAIRE);
                                        } else if (
                                            jours >= 14 &&
                                            jours < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 +
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.QUINZAINE);
                                        } else if (
                                            jours >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 +
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.MENSUEL);
                                        } else {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price +
                                                    reservation.announce
                                                        ?.price *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.JOURNALIER);
                                        }
                                        _bookingAmount =
                                            transaction.amount ?? 0;

                                        _creditAmount =
                                            transaction.amountCredit ?? 0;

                                        _totalBookingPrice =
                                            _bookingAmount + _creditAmount;
                                    }

                                    ConvertedDeviseWithoutMoneySign(
                                        reservation.announce?.devise || "EUR",
                                        currency,
                                        Number(_cleaningBookingFee.toFixed(2))
                                    )
                                        .then((value: any): any => {
                                            setCleaningFee(value);
                                        })
                                        .catch((error) => console.log(error));

                                    ConvertedDeviseWithoutMoneySign(
                                        (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                        currency,
                                        Number(_totalBookingPrice.toFixed(2))
                                    )
                                        .then((value: any): any => {
                                            setTotalBookingPrice(value);
                                        })
                                        .catch((error) => console.log(error));
                                    //--------------------------------
                                    if (
                                        getRefundType(
                                            new Date(reservation.dateCheckIn),
                                            reservation.announce?.freeRefundDay
                                        ) === ERefundType.PreStay &&
                                        reservation.status[
                                            reservation.status.length - 1
                                        ].status === EStatus.APPROVED
                                    ) {
                                        //Pendant le sejour
                                        //send to traveller
                                        const passedDays =
                                            getPrestayPassedNumber(
                                                new Date(
                                                    reservation.dateCheckIn
                                                ),
                                                reservation.announce
                                                    ?.freeRefundDay
                                            );
                                        const daysPassedAmount =
                                            _bookingPrice *
                                            getPrestayPassedNumber(
                                                new Date(
                                                    reservation.dateCheckIn
                                                ),
                                                reservation.announce
                                                    ?.freeRefundDay
                                            );
                                        if (
                                            passedDays >= 7 &&
                                            passedDays < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 +
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 14 &&
                                            passedDays < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 +
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 +
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        } else {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price +
                                                    reservation.announce
                                                        ?.price *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        }
                                        const penaltyFeesAmount =
                                            reservation.announce
                                                ?.penaltyDayFeesAfterBookingStarted *
                                            _penaltyPrice;
                                        const travellerFeesAmount =
                                            daysPassedAmount +
                                            penaltyFeesAmount +
                                            _cleaningBookingFee;
                                        const travellerRefundInitial =
                                            _totalBookingPrice -
                                                travellerFeesAmount >
                                            0
                                                ? _totalBookingPrice -
                                                  travellerFeesAmount
                                                : 0;
                                        const travellerReversalFees =
                                            (travellerRefundInitial *
                                                travellerPaymentMethodFee) /
                                            100;
                                        const travellerValue =
                                            travellerRefundInitial -
                                                travellerReversalFees >
                                            0
                                                ? travellerRefundInitial -
                                                  travellerReversalFees
                                                : 0;
                                        ConvertedDeviseWithoutMoneySign(
                                            reservation.announce?.devise ||
                                                "EUR",
                                            currency,
                                            Number(daysPassedAmount.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setPassedDaysFees(value);
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                        ConvertedDeviseWithoutMoneySign(
                                            reservation.announce?.devise ||
                                                "EUR",
                                            currency,
                                            Number(penaltyFeesAmount.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setPenaltyDaysFees(value);
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                        ConvertedDeviseWithoutMoneySign(
                                            (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                            currency,
                                            Number(travellerValue.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setTravellerRefundFinal(value);
                                                if (
                                                    travellerPaymentMethod ===
                                                    EPaymentMethod.Paypal
                                                ) {
                                                    setRefundTraveller({
                                                        ...refundTraveller,
                                                        amount: {
                                                            value: value,
                                                            currency: currency,
                                                        },
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                        recepient_type: "EMAIL",
                                                        receiver:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? travellerPaymentAccount
                                                                : "",
                                                    });
                                                } else {
                                                    setRefundTravellerDohone({
                                                        ...refundTravellerDohone,
                                                        destination:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? (
                                                                      reservation
                                                                          .announce
                                                                          ?.codePhone +
                                                                      travellerPaymentAccount
                                                                  ).substring(1)
                                                                : "",
                                                        mode:
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Orange
                                                                ? DohonePayoutMode.orangeMoney
                                                                : travellerPaymentMethod ===
                                                                  EPaymentMethod.MTN
                                                                ? DohonePayoutMode.mtnMobileMoney
                                                                : DohonePayoutMode.expressUnion,
                                                        amount: value,
                                                        devise: currency,
                                                        nameDest:
                                                            reservation
                                                                .traveller
                                                                .firstName,
                                                        ville: reservation
                                                            .announce?.property
                                                            .address.city,
                                                        pays: reservation
                                                            .announce?.property
                                                            .address.country,
                                                        rUserId:
                                                            reservation
                                                                .traveller._id,
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                    });
                                                }
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );

                                        //send to host
                                        if (
                                            passedDays >= 7 &&
                                            passedDays < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _baseBookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly / 7
                                                ).toFixed(2)
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 -
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 14 &&
                                            passedDays < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _baseBookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly / 14
                                                ).toFixed(2)
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 -
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _baseBookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly / 30
                                                ).toFixed(2)
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 -
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        } else {
                                            _baseBookingPrice = Number(
                                                (reservation.announce?.price).toFixed(
                                                    2
                                                )
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price -
                                                    reservation.announce
                                                        ?.price *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        }
                                        const penaltyDaysBaseAmount =
                                            reservation.announce
                                                ?.penaltyDayFeesAfterBookingStarted *
                                            _hostRefundPrice;
                                        const hostValue =
                                            reservation.announce?.cleaningFees +
                                            penaltyDaysBaseAmount;
                                        userReservService
                                            .getPaymentMethodFees(
                                                "Paypal" || "Unknown"
                                            )
                                            .then((respMethodFee) => {
                                                if (respMethodFee.success) {
                                                    ConvertedDeviseWithoutMoneySign(
                                                        reservation.announce
                                                            ?.devise || "EUR",
                                                        currency,
                                                        Number(
                                                            hostValue.toFixed(2)
                                                        )
                                                    )
                                                        .then(
                                                            (
                                                                value: any
                                                            ): any => {
                                                                if (
                                                                    travellerPaymentMethod ===
                                                                        EPaymentMethod.Paypal &&
                                                                    reservation
                                                                        .announce
                                                                        ?.account_paypal
                                                                ) {
                                                                    setHostPaymentMethod(
                                                                        EPaymentMethod.Paypal
                                                                    );
                                                                    setRefundHost(
                                                                        {
                                                                            ...refundHost,
                                                                            amount: {
                                                                                value: value,
                                                                                currency:
                                                                                    currency,
                                                                            },
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                            recepient_type:
                                                                                "EMAIL",
                                                                            receiver:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.account_paypal,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setHostPaymentMethod(
                                                                        reservation
                                                                            .announce
                                                                            ?.favoritePaymentMethod ===
                                                                            EPaymentMethod.Orange
                                                                            ? EPaymentMethod.Orange
                                                                            : reservation
                                                                                  .announce
                                                                                  ?.favoritePaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? EPaymentMethod.MTN
                                                                            : EPaymentMethod.ExpressUnion
                                                                    );
                                                                    setRefundHostDohone(
                                                                        {
                                                                            ...refundHostDohone,
                                                                            destination:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_orange
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_mtn
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_express_union
                                                                                      ).substring(
                                                                                          1
                                                                                      ),
                                                                            mode:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? DohonePayoutMode.orangeMoney
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? DohonePayoutMode.mtnMobileMoney
                                                                                    : DohonePayoutMode.expressUnion,
                                                                            amount: value,
                                                                            devise: currency,
                                                                            nameDest:
                                                                                reservation
                                                                                    .host
                                                                                    .firstName,
                                                                            ville: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .city,
                                                                            pays: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .country,
                                                                            rUserId:
                                                                                reservation
                                                                                    .host
                                                                                    ._id,
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        )
                                                        .catch((error) =>
                                                            console.log(error)
                                                        );
                                                }
                                            });
                                    } else if (
                                        getRefundType(
                                            new Date(reservation.dateCheckIn),
                                            reservation.announce?.freeRefundDay
                                        ) === ERefundType.Paid &&
                                        reservation.status[
                                            reservation.status.length - 1
                                        ].status === EStatus.APPROVED
                                    ) {
                                        //Pre-sejour
                                        //Send to traveller
                                        _penaltyPrice = Number(
                                            (
                                                reservation.announce?.price +
                                                reservation.announce?.price *
                                                    (_travellerFee / 100)
                                            ).toFixed(2)
                                        );
                                        const penaltyFeesAmount =
                                            reservation.announce
                                                ?.penaltyDayFees *
                                            _penaltyPrice;
                                        const travellerRefundInitial =
                                            _totalBookingPrice -
                                                penaltyFeesAmount >
                                            0
                                                ? _totalBookingPrice -
                                                  penaltyFeesAmount
                                                : 0;
                                        const travellerReversalFees =
                                            (travellerRefundInitial *
                                                travellerPaymentMethodFee) /
                                            100;
                                        const travellerValue =
                                            travellerRefundInitial -
                                                travellerReversalFees >
                                            0
                                                ? travellerRefundInitial -
                                                  travellerReversalFees
                                                : 0;
                                        ConvertedDeviseWithoutMoneySign(
                                            reservation.announce?.devise ||
                                                "EUR",
                                            currency,
                                            Number(penaltyFeesAmount.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setPenaltyDaysFees(value);
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                        ConvertedDeviseWithoutMoneySign(
                                            (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                            currency,
                                            Number(travellerValue.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setTravellerRefundFinal(value);
                                                if (
                                                    travellerPaymentMethod ===
                                                    EPaymentMethod.Paypal
                                                ) {
                                                    setRefundTraveller({
                                                        ...refundTraveller,
                                                        amount: {
                                                            value: value,
                                                            currency: currency,
                                                        },
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                        recepient_type: "EMAIL",
                                                        receiver:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? travellerPaymentAccount
                                                                : "",
                                                    });
                                                } else {
                                                    setRefundTravellerDohone({
                                                        ...refundTravellerDohone,
                                                        destination:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? (
                                                                      reservation
                                                                          .announce
                                                                          ?.codePhone +
                                                                      travellerPaymentAccount
                                                                  ).substring(1)
                                                                : "",
                                                        mode:
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Orange
                                                                ? DohonePayoutMode.orangeMoney
                                                                : travellerPaymentMethod ===
                                                                  EPaymentMethod.MTN
                                                                ? DohonePayoutMode.mtnMobileMoney
                                                                : DohonePayoutMode.expressUnion,
                                                        amount: value,
                                                        devise: currency,
                                                        nameDest:
                                                            reservation
                                                                .traveller
                                                                .firstName,
                                                        ville: reservation
                                                            .announce?.property
                                                            .address.city,
                                                        pays: reservation
                                                            .announce?.property
                                                            .address.country,
                                                        rUserId:
                                                            reservation
                                                                .traveller._id,
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                    });
                                                }
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );

                                        //Send to host
                                        const hostValue =
                                            reservation.announce
                                                ?.penaltyDayFees *
                                            (_baseBookingPrice -
                                                (_baseBookingPrice *
                                                    _hostPercentage) /
                                                    100);
                                        userReservService
                                            .getPaymentMethodFees(
                                                "Paypal" || "Unknown"
                                            )
                                            .then((respMethodFee) => {
                                                if (respMethodFee.success) {
                                                    ConvertedDeviseWithoutMoneySign(
                                                        reservation.announce
                                                            ?.devise || "EUR",
                                                        currency,
                                                        Number(
                                                            hostValue.toFixed(2)
                                                        )
                                                    )
                                                        .then(
                                                            (
                                                                value: any
                                                            ): any => {
                                                                if (
                                                                    travellerPaymentMethod ===
                                                                        EPaymentMethod.Paypal &&
                                                                    reservation
                                                                        .announce
                                                                        ?.account_paypal
                                                                ) {
                                                                    setHostPaymentMethod(
                                                                        EPaymentMethod.Paypal
                                                                    );
                                                                    setRefundHost(
                                                                        {
                                                                            ...refundHost,
                                                                            amount: {
                                                                                value: value,
                                                                                currency:
                                                                                    currency,
                                                                            },
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                            recepient_type:
                                                                                "EMAIL",
                                                                            receiver:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.account_paypal,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setHostPaymentMethod(
                                                                        reservation
                                                                            .announce
                                                                            ?.favoritePaymentMethod ===
                                                                            EPaymentMethod.Orange
                                                                            ? EPaymentMethod.Orange
                                                                            : reservation
                                                                                  .announce
                                                                                  ?.favoritePaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? EPaymentMethod.MTN
                                                                            : EPaymentMethod.ExpressUnion
                                                                    );
                                                                    setRefundHostDohone(
                                                                        {
                                                                            ...refundHostDohone,
                                                                            destination:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_orange
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_mtn
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_express_union
                                                                                      ).substring(
                                                                                          1
                                                                                      ),
                                                                            mode:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? DohonePayoutMode.orangeMoney
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? DohonePayoutMode.mtnMobileMoney
                                                                                    : DohonePayoutMode.expressUnion,
                                                                            amount: value,
                                                                            devise: currency,
                                                                            nameDest:
                                                                                reservation
                                                                                    .host
                                                                                    .firstName,
                                                                            ville: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .city,
                                                                            pays: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .country,
                                                                            rUserId:
                                                                                reservation
                                                                                    .host
                                                                                    ._id,
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        )
                                                        .catch((error) =>
                                                            console.log(error)
                                                        );
                                                }
                                            });
                                    } else {
                                        //Gratuit
                                        const travellerRefundInitial =
                                            _totalBookingPrice;
                                        const travellerReversalFees =
                                            (travellerRefundInitial *
                                                travellerPaymentMethodFee) /
                                            100;
                                        const travellerValue =
                                            travellerRefundInitial -
                                                travellerReversalFees >
                                            0
                                                ? travellerRefundInitial -
                                                  travellerReversalFees
                                                : 0;
                                        ConvertedDeviseWithoutMoneySign(
                                            (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                            currency,
                                            Number(travellerValue.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setTravellerRefundFinal(value);
                                                if (
                                                    travellerPaymentMethod ===
                                                    EPaymentMethod.Paypal
                                                ) {
                                                    setRefundTraveller({
                                                        ...refundTraveller,
                                                        amount: {
                                                            value: value,
                                                            currency: currency,
                                                        },
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                        recepient_type: "EMAIL",
                                                        receiver:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? travellerPaymentAccount
                                                                : "",
                                                    });
                                                } else {
                                                    setRefundTravellerDohone({
                                                        ...refundTravellerDohone,
                                                        destination:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? (
                                                                      reservation
                                                                          .announce
                                                                          ?.codePhone +
                                                                      travellerPaymentAccount
                                                                  ).substring(1)
                                                                : "",
                                                        mode:
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Orange
                                                                ? DohonePayoutMode.orangeMoney
                                                                : travellerPaymentMethod ===
                                                                  EPaymentMethod.MTN
                                                                ? DohonePayoutMode.mtnMobileMoney
                                                                : DohonePayoutMode.expressUnion,
                                                        amount: value,
                                                        devise: currency,
                                                        nameDest:
                                                            reservation
                                                                .traveller
                                                                .firstName,
                                                        ville: reservation
                                                            .announce?.property
                                                            .address.city,
                                                        pays: reservation
                                                            .announce?.property
                                                            .address.country,
                                                        rUserId:
                                                            reservation
                                                                .traveller._id,
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                    });
                                                }
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                    }
                                }
                            })
                            .catch((error) => console.log(error));
                    } else {
                        userReservService
                            .getHostFeeByCountryAndCityId(
                                reservation.announce?.property?.address
                                    ?.country,
                                reservation.announce?.property?.address?.city
                            )
                            .then((respHostFee) => {
                                if (respHostFee.success) {
                                    _hostPercentage = respHostFee.data
                                        ? respHostFee.data.percentage
                                        : 0;
                                    _baseBookingPrice =
                                        reservation.announce?.price;
                                    _bookingPrice = Number(
                                        (
                                            reservation.announce?.price +
                                            reservation.announce?.price *
                                                (_travellerFee / 100)
                                        ).toFixed(2)
                                    );
                                    _cleaningBookingFee = Number(
                                        (
                                            reservation.announce?.cleaningFees +
                                            reservation.announce?.cleaningFees *
                                                (_travellerFee / 100)
                                        ).toFixed(2)
                                    );

                                    /*total promotion ou non promotion*/
                                    if (reservation.promotion) {
                                        let nbjours_prom = getDayNumberProm(
                                            reservation.dateCheckIn,
                                            reservation.dateCheckOut,
                                            moment(
                                                reservation.promotion.startDate
                                            ).format("YYYY/MM/DD"),
                                            moment(
                                                reservation.promotion.endDate
                                            ).format("YYYY/MM/DD"),
                                            reservation.announce?.price,
                                            reservation.promotion.price
                                        ).DateProm;
                                        let nbjours_simple = getDayNumberProm(
                                            reservation.dateCheckIn,
                                            reservation.dateCheckOut,
                                            moment(
                                                reservation.promotion.startDate
                                            ).format("YYYY/MM/DD"),
                                            moment(
                                                reservation.promotion.endDate
                                            ).format("YYYY/MM/DD"),
                                            reservation.announce?.price,
                                            reservation.promotion.price
                                        ).DateSimple;
                                        const jours =
                                            nbjours_simple + nbjours_prom;
                                        if (
                                            jours >= 7 &&
                                            jours < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 +
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.HEBDOMADAIRE);
                                        } else if (
                                            jours >= 14 &&
                                            jours < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 +
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.QUINZAINE);
                                        } else if (
                                            jours >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 +
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.MENSUEL);
                                        } else {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price +
                                                    reservation.announce
                                                        ?.price *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.JOURNALIER);
                                        }
                                        _bookingAmount =
                                            transaction.amount ?? 0;

                                        _creditAmount =
                                            transaction.amountCredit ?? 0;

                                        _totalBookingPrice =
                                            _bookingAmount + _creditAmount;
                                    } else {
                                        let jours = getDayNumber(
                                            reservation.dateCheckIn,
                                            reservation.dateCheckOut
                                        );
                                        if (
                                            jours >= 7 &&
                                            jours < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 +
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.HEBDOMADAIRE);
                                        } else if (
                                            jours >= 14 &&
                                            jours < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 +
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.QUINZAINE);
                                        } else if (
                                            jours >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 +
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.MENSUEL);
                                        } else {
                                            _bookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price +
                                                    reservation.announce
                                                        ?.price *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                            setTarif(ETarifs.JOURNALIER);
                                        }
                                        _bookingAmount =
                                            transaction.amount ?? 0;

                                        _creditAmount =
                                            transaction.amountCredit ?? 0;

                                        _totalBookingPrice =
                                            _bookingAmount + _creditAmount;
                                    }

                                    ConvertedDeviseWithoutMoneySign(
                                        reservation.announce?.devise || "EUR",
                                        currency,
                                        Number(_cleaningBookingFee.toFixed(2))
                                    )
                                        .then((value: any): any => {
                                            setCleaningFee(value);
                                        })
                                        .catch((error) => console.log(error));

                                    ConvertedDeviseWithoutMoneySign(
                                        (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                        currency,
                                        Number(_totalBookingPrice.toFixed(2))
                                    )
                                        .then((value: any): any => {
                                            setTotalBookingPrice(value);
                                        })
                                        .catch((error) => console.log(error));
                                    //--------------------------------
                                    if (
                                        getRefundType(
                                            new Date(reservation.dateCheckIn),
                                            reservation.announce?.freeRefundDay
                                        ) === ERefundType.PreStay &&
                                        reservation.status[
                                            reservation.status.length - 1
                                        ].status === EStatus.APPROVED
                                    ) {
                                        //Pendant le sejour
                                        //send to traveller
                                        const passedDays =
                                            getPrestayPassedNumber(
                                                new Date(
                                                    reservation.dateCheckIn
                                                ),
                                                reservation.announce
                                                    ?.freeRefundDay
                                            );
                                        const daysPassedAmount =
                                            _bookingPrice *
                                            getPrestayPassedNumber(
                                                new Date(
                                                    reservation.dateCheckIn
                                                ),
                                                reservation.announce
                                                    ?.freeRefundDay
                                            );
                                        if (
                                            passedDays >= 7 &&
                                            passedDays < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 +
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 14 &&
                                            passedDays < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 +
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 +
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        } else {
                                            _penaltyPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price +
                                                    reservation.announce
                                                        ?.price *
                                                        (_travellerFee / 100)
                                                ).toFixed(2)
                                            );
                                        }
                                        const penaltyFeesAmount =
                                            reservation.announce
                                                ?.penaltyDayFeesAfterBookingStarted *
                                            _penaltyPrice;
                                        const travellerFeesAmount =
                                            daysPassedAmount +
                                            penaltyFeesAmount +
                                            _cleaningBookingFee;
                                        const travellerRefundInitial =
                                            _totalBookingPrice -
                                                travellerFeesAmount >
                                            0
                                                ? _totalBookingPrice -
                                                  travellerFeesAmount
                                                : 0;
                                        const travellerReversalFees =
                                            (travellerRefundInitial *
                                                travellerPaymentMethodFee) /
                                            100;
                                        const travellerValue =
                                            travellerRefundInitial -
                                                travellerReversalFees >
                                            0
                                                ? travellerRefundInitial -
                                                  travellerReversalFees
                                                : 0;
                                        ConvertedDeviseWithoutMoneySign(
                                            reservation.announce?.devise ||
                                                "EUR",
                                            currency,
                                            Number(daysPassedAmount.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setPassedDaysFees(value);
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                        ConvertedDeviseWithoutMoneySign(
                                            reservation.announce?.devise ||
                                                "EUR",
                                            currency,
                                            Number(penaltyFeesAmount.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setPenaltyDaysFees(value);
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                        ConvertedDeviseWithoutMoneySign(
                                            (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                            currency,
                                            Number(travellerValue.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setTravellerRefundFinal(value);
                                                if (
                                                    travellerPaymentMethod ===
                                                    EPaymentMethod.Paypal
                                                ) {
                                                    setRefundTraveller({
                                                        ...refundTraveller,
                                                        amount: {
                                                            value: value,
                                                            currency: currency,
                                                        },
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                        recepient_type: "EMAIL",
                                                        receiver:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? travellerPaymentAccount
                                                                : "",
                                                    });
                                                } else {
                                                    setRefundTravellerDohone({
                                                        ...refundTravellerDohone,
                                                        destination:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? (
                                                                      reservation
                                                                          .announce
                                                                          ?.codePhone +
                                                                      travellerPaymentAccount
                                                                  ).substring(1)
                                                                : "",
                                                        mode:
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Orange
                                                                ? DohonePayoutMode.orangeMoney
                                                                : travellerPaymentMethod ===
                                                                  EPaymentMethod.MTN
                                                                ? DohonePayoutMode.mtnMobileMoney
                                                                : DohonePayoutMode.expressUnion,
                                                        amount: value,
                                                        devise: currency,
                                                        nameDest:
                                                            reservation
                                                                .traveller
                                                                .firstName,
                                                        ville: reservation
                                                            .announce?.property
                                                            .address.city,
                                                        pays: reservation
                                                            .announce?.property
                                                            .address.country,
                                                        rUserId:
                                                            reservation
                                                                .traveller._id,
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                    });
                                                }
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );

                                        //send to host
                                        if (
                                            passedDays >= 7 &&
                                            passedDays < 14 &&
                                            reservation.announce?.priceWeekly &&
                                            reservation.announce?.priceWeekly >
                                                0
                                        ) {
                                            _baseBookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly / 7
                                                ).toFixed(2)
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceWeekly /
                                                        7 -
                                                    (reservation.announce
                                                        ?.priceWeekly /
                                                        7) *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 14 &&
                                            passedDays < 30 &&
                                            reservation.announce
                                                ?.priceBiWeekly &&
                                            reservation.announce
                                                ?.priceBiWeekly > 0
                                        ) {
                                            _baseBookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly / 14
                                                ).toFixed(2)
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceBiWeekly /
                                                        14 -
                                                    (reservation.announce
                                                        ?.priceBiWeekly /
                                                        14) *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        } else if (
                                            passedDays >= 30 &&
                                            reservation.announce
                                                ?.priceMonthly &&
                                            reservation.announce?.priceMonthly >
                                                0
                                        ) {
                                            _baseBookingPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly / 30
                                                ).toFixed(2)
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.priceMonthly /
                                                        30 -
                                                    (reservation.announce
                                                        ?.priceMonthly /
                                                        30) *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        } else {
                                            _baseBookingPrice = Number(
                                                (reservation.announce?.price).toFixed(
                                                    2
                                                )
                                            );
                                            _hostRefundPrice = Number(
                                                (
                                                    reservation.announce
                                                        ?.price -
                                                    reservation.announce
                                                        ?.price *
                                                        (_hostPercentage / 100)
                                                ).toFixed(2)
                                            );
                                        }
                                        const penaltyDaysBaseAmount =
                                            reservation.announce
                                                ?.penaltyDayFeesAfterBookingStarted *
                                            _hostRefundPrice;
                                        const hostValue =
                                            reservation.announce?.cleaningFees +
                                            penaltyDaysBaseAmount;
                                        userReservService
                                            .getPaymentMethodFees(
                                                "Paypal" || "Unknown"
                                            )
                                            .then((respMethodFee) => {
                                                if (respMethodFee.success) {
                                                    ConvertedDeviseWithoutMoneySign(
                                                        reservation.announce
                                                            ?.devise || "EUR",
                                                        currency,
                                                        Number(
                                                            hostValue.toFixed(2)
                                                        )
                                                    )
                                                        .then(
                                                            (
                                                                value: any
                                                            ): any => {
                                                                if (
                                                                    travellerPaymentMethod ===
                                                                        EPaymentMethod.Paypal &&
                                                                    reservation
                                                                        .announce
                                                                        ?.account_paypal
                                                                ) {
                                                                    setHostPaymentMethod(
                                                                        EPaymentMethod.Paypal
                                                                    );
                                                                    setRefundHost(
                                                                        {
                                                                            ...refundHost,
                                                                            amount: {
                                                                                value: value,
                                                                                currency:
                                                                                    currency,
                                                                            },
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                            recepient_type:
                                                                                "EMAIL",
                                                                            receiver:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.account_paypal,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setHostPaymentMethod(
                                                                        reservation
                                                                            .announce
                                                                            ?.favoritePaymentMethod ===
                                                                            EPaymentMethod.Orange
                                                                            ? EPaymentMethod.Orange
                                                                            : reservation
                                                                                  .announce
                                                                                  ?.favoritePaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? EPaymentMethod.MTN
                                                                            : EPaymentMethod.ExpressUnion
                                                                    );
                                                                    setRefundHostDohone(
                                                                        {
                                                                            ...refundHostDohone,
                                                                            destination:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_orange
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_mtn
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_express_union
                                                                                      ).substring(
                                                                                          1
                                                                                      ),
                                                                            mode:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? DohonePayoutMode.orangeMoney
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? DohonePayoutMode.mtnMobileMoney
                                                                                    : DohonePayoutMode.expressUnion,
                                                                            amount: value,
                                                                            devise: currency,
                                                                            nameDest:
                                                                                reservation
                                                                                    .host
                                                                                    .firstName,
                                                                            ville: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .city,
                                                                            pays: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .country,
                                                                            rUserId:
                                                                                reservation
                                                                                    .host
                                                                                    ._id,
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        )
                                                        .catch((error) =>
                                                            console.log(error)
                                                        );
                                                }
                                            });
                                    } else if (
                                        getRefundType(
                                            new Date(reservation.dateCheckIn),
                                            reservation.announce?.freeRefundDay
                                        ) === ERefundType.Paid &&
                                        reservation.status[
                                            reservation.status.length - 1
                                        ].status === EStatus.APPROVED
                                    ) {
                                        //Pre-sejour
                                        //Send to traveller
                                        _penaltyPrice = Number(
                                            (
                                                reservation.announce?.price +
                                                reservation.announce?.price *
                                                    (_travellerFee / 100)
                                            ).toFixed(2)
                                        );
                                        const penaltyFeesAmount =
                                            reservation.announce
                                                ?.penaltyDayFees *
                                            _penaltyPrice;
                                        const travellerRefundInitial =
                                            _totalBookingPrice -
                                                penaltyFeesAmount >
                                            0
                                                ? _totalBookingPrice -
                                                  penaltyFeesAmount
                                                : 0;
                                        const travellerReversalFees =
                                            (travellerRefundInitial *
                                                travellerPaymentMethodFee) /
                                            100;
                                        const travellerValue =
                                            travellerRefundInitial -
                                                travellerReversalFees >
                                            0
                                                ? travellerRefundInitial -
                                                  travellerReversalFees
                                                : 0;
                                        ConvertedDeviseWithoutMoneySign(
                                            reservation.announce?.devise ||
                                                "EUR",
                                            currency,
                                            Number(penaltyFeesAmount.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setPenaltyDaysFees(value);
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                        ConvertedDeviseWithoutMoneySign(
                                            (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                            currency,
                                            Number(travellerValue.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setTravellerRefundFinal(value);
                                                if (
                                                    travellerPaymentMethod ===
                                                    EPaymentMethod.Paypal
                                                ) {
                                                    setRefundTraveller({
                                                        ...refundTraveller,
                                                        amount: {
                                                            value: value,
                                                            currency: currency,
                                                        },
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                        recepient_type: "EMAIL",
                                                        receiver:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? travellerPaymentAccount
                                                                : "",
                                                    });
                                                } else {
                                                    setRefundTravellerDohone({
                                                        ...refundTravellerDohone,
                                                        destination:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? (
                                                                      reservation
                                                                          .announce
                                                                          ?.codePhone +
                                                                      travellerPaymentAccount
                                                                  ).substring(1)
                                                                : "",
                                                        mode:
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Orange
                                                                ? DohonePayoutMode.orangeMoney
                                                                : travellerPaymentMethod ===
                                                                  EPaymentMethod.MTN
                                                                ? DohonePayoutMode.mtnMobileMoney
                                                                : DohonePayoutMode.expressUnion,
                                                        amount: value,
                                                        devise: currency,
                                                        nameDest:
                                                            reservation
                                                                .traveller
                                                                .firstName,
                                                        ville: reservation
                                                            .announce?.property
                                                            .address.city,
                                                        pays: reservation
                                                            .announce?.property
                                                            .address.country,
                                                        rUserId:
                                                            reservation
                                                                .traveller._id,
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                    });
                                                }
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );

                                        //Send to host
                                        const hostValue =
                                            reservation.announce
                                                ?.penaltyDayFees *
                                            (_baseBookingPrice -
                                                (_baseBookingPrice *
                                                    _hostPercentage) /
                                                    100);
                                        userReservService
                                            .getPaymentMethodFees(
                                                "Paypal" || "Unknown"
                                            )
                                            .then((respMethodFee) => {
                                                if (respMethodFee.success) {
                                                    ConvertedDeviseWithoutMoneySign(
                                                        reservation.announce
                                                            ?.devise || "EUR",
                                                        currency,
                                                        Number(
                                                            hostValue.toFixed(2)
                                                        )
                                                    )
                                                        .then(
                                                            (
                                                                value: any
                                                            ): any => {
                                                                if (
                                                                    travellerPaymentMethod ===
                                                                        EPaymentMethod.Paypal &&
                                                                    reservation
                                                                        .announce
                                                                        ?.account_paypal
                                                                ) {
                                                                    setHostPaymentMethod(
                                                                        EPaymentMethod.Paypal
                                                                    );
                                                                    setRefundHost(
                                                                        {
                                                                            ...refundHost,
                                                                            amount: {
                                                                                value: value,
                                                                                currency:
                                                                                    currency,
                                                                            },
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                            recepient_type:
                                                                                "EMAIL",
                                                                            receiver:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.account_paypal,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setHostPaymentMethod(
                                                                        reservation
                                                                            .announce
                                                                            ?.favoritePaymentMethod ===
                                                                            EPaymentMethod.Orange
                                                                            ? EPaymentMethod.Orange
                                                                            : reservation
                                                                                  .announce
                                                                                  ?.favoritePaymentMethod ===
                                                                              EPaymentMethod.MTN
                                                                            ? EPaymentMethod.MTN
                                                                            : EPaymentMethod.ExpressUnion
                                                                    );
                                                                    setRefundHostDohone(
                                                                        {
                                                                            ...refundHostDohone,
                                                                            destination:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_orange
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_mtn
                                                                                      ).substring(
                                                                                          1
                                                                                      )
                                                                                    : (
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.codePhone +
                                                                                          reservation
                                                                                              .announce
                                                                                              ?.account_express_union
                                                                                      ).substring(
                                                                                          1
                                                                                      ),
                                                                            mode:
                                                                                reservation
                                                                                    .announce
                                                                                    ?.favoritePaymentMethod ===
                                                                                EPaymentMethod.Orange
                                                                                    ? DohonePayoutMode.orangeMoney
                                                                                    : reservation
                                                                                          .announce
                                                                                          ?.favoritePaymentMethod ===
                                                                                      EPaymentMethod.MTN
                                                                                    ? DohonePayoutMode.mtnMobileMoney
                                                                                    : DohonePayoutMode.expressUnion,
                                                                            amount: value,
                                                                            devise: currency,
                                                                            nameDest:
                                                                                reservation
                                                                                    .host
                                                                                    .firstName,
                                                                            ville: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .city,
                                                                            pays: reservation
                                                                                .announce
                                                                                ?.property
                                                                                .address
                                                                                .country,
                                                                            rUserId:
                                                                                reservation
                                                                                    .host
                                                                                    ._id,
                                                                            reservation:
                                                                                reservation._id ||
                                                                                "",
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        )
                                                        .catch((error) =>
                                                            console.log(error)
                                                        );
                                                }
                                            });
                                    } else {
                                        //Gratuit
                                        const travellerRefundInitial =
                                            _totalBookingPrice;
                                        const travellerReversalFees =
                                            (travellerRefundInitial *
                                                travellerPaymentMethodFee) /
                                            100;
                                        const travellerValue =
                                            travellerRefundInitial -
                                                travellerReversalFees >
                                            0
                                                ? travellerRefundInitial -
                                                  travellerReversalFees
                                                : 0;
                                        ConvertedDeviseWithoutMoneySign(
                                            (_creditAmount === 0) ? reservation.announce?.devise : 'XAF',
                                            currency,
                                            Number(travellerValue.toFixed(2))
                                        )
                                            .then((value: any): any => {
                                                setTravellerRefundFinal(value);
                                                if (
                                                    travellerPaymentMethod ===
                                                    EPaymentMethod.Paypal
                                                ) {
                                                    // console.log({value})
                                                    setRefundTraveller({
                                                        ...refundTraveller,
                                                        amount: {
                                                            value: value,
                                                            currency: currency,
                                                        },
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                        recepient_type: "EMAIL",
                                                        receiver:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? travellerPaymentAccount
                                                                : "",
                                                    });
                                                } else {
                                                    setRefundTravellerDohone({
                                                        ...refundTravellerDohone,
                                                        destination:
                                                            travellerPaymentAccount !==
                                                            ""
                                                                ? (
                                                                      reservation
                                                                          .announce
                                                                          ?.codePhone +
                                                                      travellerPaymentAccount
                                                                  ).substring(1)
                                                                : "",
                                                        mode:
                                                            travellerPaymentMethod ===
                                                            EPaymentMethod.Orange
                                                                ? DohonePayoutMode.orangeMoney
                                                                : travellerPaymentMethod ===
                                                                  EPaymentMethod.MTN
                                                                ? DohonePayoutMode.mtnMobileMoney
                                                                : DohonePayoutMode.expressUnion,
                                                        amount: value,
                                                        devise: currency,
                                                        nameDest:
                                                            reservation
                                                                .traveller
                                                                .firstName,
                                                        ville: reservation
                                                            .announce?.property
                                                            .address.city,
                                                        pays: reservation
                                                            .announce?.property
                                                            .address.country,
                                                        rUserId:
                                                            reservation
                                                                .traveller._id,
                                                        reservation:
                                                            reservation._id ||
                                                            "",
                                                    });
                                                }
                                            })
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                    }
                                }
                            })
                            .catch((error) => console.log(error));
                    }
                }
            }
        }
    }, [
        reservation,
        openRefundModal,
        currency,
        travellerPaymentMethodFee,
        travellerPaymentAccount,
        travellerPaymentMethod,
    ]);

    const handleOpenConfirmDialog = () => {
        setOpenDialogConfirm(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenDialogConfirm(false);
    };

    const saveTransactionHost = (
        data: any,
        amount: number,
        redirect: boolean = false,
        transactDevise: string,
        announceDevise: string
    ) => {
        if (transactDevise.toUpperCase() === announceDevise.toUpperCase()) {
            userReservService
                .saveTransaction({
                    method: EPaymentMethod.Paypal,
                    payout_batch_id: data.batch_status,
                    batch_status: data.batch_status,
                    recipient_type: data.recipient_type,
                    paymentID: data.batch_header.payout_batch_id,
                    link: data.links[0].href,
                    paymentEmail: "AfriqPnb",
                    reservation: reservation._id || null,
                    announceAd: null,
                    sender_batch_id: data.sender_batch_id || "",
                    amount: amount,
                    type: ETransactionType.OwnerPay,
                })
                .then((resp) => {
                    if (redirect) {
                        setSnackValues({
                            ...snackValues,
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-effectue-avec-succes"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    }
                })
                .catch((err) => {
                    setSnackValues({
                        type: "error",
                        open: true,
                        message: t(
                            "snackbar.messages.remboursement-non-effectue"
                        ),
                    });
                    setTimeout(() => {
                        setRefreshReservationList(true);
                        setLoadingRefund(false);
                    }, 2000);
                });
        } else {
            ConvertedDeviseWithoutMoneySign(
                transactDevise,
                announceDevise,
                amount
            )
                .then((value: any): any => {
                    userReservService
                        .saveTransaction({
                            method: EPaymentMethod.Paypal,
                            payout_batch_id: data.batch_status,
                            batch_status: data.batch_status,
                            recipient_type: data.recipient_type,
                            paymentID: data.batch_header.payout_batch_id,
                            link: data.links[0].href,
                            paymentEmail: "AfriqPnb",
                            reservation: reservation._id || null,
                            announceAd: null,
                            sender_batch_id: data.sender_batch_id || "",
                            amount: value,
                            type: ETransactionType.OwnerPay,
                        })
                        .then((resp) => {
                            if (redirect) {
                                setSnackValues({
                                    ...snackValues,
                                    open: true,
                                    message: t(
                                        "snackbar.messages.remboursement-effectue-avec-succes"
                                    ),
                                });
                                setTimeout(() => {
                                    setRefreshReservationList(true);
                                    setLoadingRefund(false);
                                }, 2000);
                            }
                        })
                        .catch((err) => {
                            setLoadingRefund(false);
                            setSnackValues({
                                type: "error",
                                open: true,
                                message: t(
                                    "snackbar.messages.remboursement-non-effectue"
                                ),
                            });
                            setTimeout(() => {
                                setRefreshReservationList(true);
                            }, 2000);
                        });
                })
                .catch((error) => console.log(error));
        }
    };

    const handleRefund = () => {
        handleCloseConfirmDialog();
        setLoadingRefund(true);
        if (
            getRefundType(
                new Date(reservation.dateCheckIn),
                reservation.announce?.freeRefundDay
            ) === ERefundType.Free ||
            reservation.status[reservation.status.length - 1].status !==
                EStatus.APPROVED
        ) {
            if (travellerPaymentMethod === EPaymentMethod.Paypal) {
                userReservService
                    .createBookingRefundCredit({
                        amount: refundTraveller.amount,
                        reservation: refundTraveller.reservation,
                        method: EPaymentMethodCategory.Paypal,
                        isCancellation: true,
                    })
                    .then((response) => {
                        if (response.success) {
                            // userContext.setUser (respone)
                            setSnackValues({
                                ...snackValues,
                                open: true,
                                message: t(
                                    "snackbar.messages.remboursement-effectue-avec-succes"
                                ),
                            });
                            setTimeout(() => {
                                setRefreshReservationList(true);
                                setLoadingRefund(false);
                            }, 2000);
                        }
                    })
                    .catch((err) => {
                        setSnackValues({
                            type: "error",
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-non-effectue"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    });
            } else {
                userReservService
                    .createBookingRefundCredit({
                        amount: {
                            value: refundTravellerDohone.amount,
                            currency: refundTravellerDohone.devise,
                        },
                        reservation: refundTravellerDohone.reservation,
                        method: EPaymentMethodCategory.MobileMoney,
                        isCancellation: true,
                    })
                    .then((response) => {
                        if (response.success) {
                            setSnackValues({
                                ...snackValues,
                                open: true,
                                message: t(
                                    "snackbar.messages.remboursement-effectue-avec-succes"
                                ),
                            });
                            setTimeout(() => {
                                setRefreshReservationList(true);
                                setLoadingRefund(false);
                            }, 2000);
                        }
                    })
                    .catch((err) => {
                        setSnackValues({
                            type: "error",
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-non-effectue"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    });
            }
        } else if (
            getRefundType(
                new Date(reservation.dateCheckIn),
                reservation.announce?.freeRefundDay
            ) === ERefundType.Paid &&
            reservation.status[reservation.status.length - 1].status ===
                EStatus.APPROVED
        ) {
            if (travellerPaymentMethod === EPaymentMethod.Paypal) {
                userReservService
                    .createBookingRefundCredit({
                        amount: refundTraveller.amount,
                        reservation: refundTraveller.reservation,
                        method: EPaymentMethodCategory.Paypal,
                        isCancellation: true,
                    })
                    .then((response) => {
                        if (response.success) {
                            if (hostPaymentMethod === EPaymentMethod.Paypal) {
                                userReservService
                                    .createBookingRefund(refundHost)
                                    .then((resp) => {
                                        if (
                                            resp.success &&
                                            resp.data.statusCode === 201
                                        ) {
                                            saveTransactionHost(
                                                resp.data.result,
                                                refundHost.amount.value,
                                                true,
                                                refundHost.amount.currency,
                                                reservation.announce?.devise
                                            );
                                            setSnackValues({
                                                ...snackValues,
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-effectue-avec-succes"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                            }, 2000);
                                        }
                                    })
                                    .catch((err) => {
                                        setSnackValues({
                                            type: "error",
                                            open: true,
                                            message: t(
                                                "snackbar.messages.remboursement-non-effectue"
                                            ),
                                        });
                                        setTimeout(() => {
                                            setRefreshReservationList(true);
                                            setLoadingRefund(false);
                                        }, 2000);
                                    });
                            } else {
                                userReservService
                                    .createBookingRefundDohoneHost(
                                        refundHostDohone
                                    )
                                    .then((resp) => {
                                        if (resp.success) {
                                            setSnackValues({
                                                ...snackValues,
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-effectue-avec-succes"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                                setLoadingRefund(false);
                                            }, 2000);
                                        }
                                    })
                                    .catch((err) => {
                                        setSnackValues({
                                            type: "error",
                                            open: true,
                                            message: t(
                                                "snackbar.messages.remboursement-non-effectue"
                                            ),
                                        });
                                        setTimeout(() => {
                                            setRefreshReservationList(true);
                                            setLoadingRefund(false);
                                        }, 2000);
                                    });
                            }
                        }
                    })
                    .catch((err) => {
                        setSnackValues({
                            type: "error",
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-non-effectue"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    });
            } else {
                userReservService
                    .createBookingRefundCredit({
                        amount: {
                            value: refundTravellerDohone.amount,
                            currency: refundTravellerDohone.devise,
                        },
                        reservation: refundTravellerDohone.reservation,
                        method: EPaymentMethodCategory.MobileMoney,
                        isCancellation: true,
                    })
                    .then((response) => {
                        if (response.success) {
                            if (
                                reservation.status[
                                    reservation.status.length - 1
                                ].status === EStatus.APPROVED
                            ) {
                                if (
                                    hostPaymentMethod === EPaymentMethod.Paypal
                                ) {
                                    userReservService
                                        .createBookingRefund(refundHost)
                                        .then((resp) => {
                                            if (
                                                resp.success &&
                                                resp.data.statusCode === 201
                                            ) {
                                                saveTransactionHost(
                                                    resp.data.result,
                                                    refundHost.amount.value,
                                                    true,
                                                    refundHost.amount.currency,
                                                    reservation.announce?.devise
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            setSnackValues({
                                                type: "error",
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-non-effectue"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                                setLoadingRefund(false);
                                            }, 2000);
                                        });
                                } else {
                                    userReservService
                                        .createBookingRefundDohoneHost(
                                            refundHostDohone
                                        )
                                        .then((resp) => {
                                            if (resp.success) {
                                                setSnackValues({
                                                    ...snackValues,
                                                    open: true,
                                                    message: t(
                                                        "snackbar.messages.remboursement-effectue-avec-succes"
                                                    ),
                                                });
                                                setTimeout(() => {
                                                    setRefreshReservationList(
                                                        true
                                                    );
                                                    setLoadingRefund(false);
                                                }, 2000);
                                            }
                                        })
                                        .catch((err) => {
                                            setSnackValues({
                                                type: "error",
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-non-effectue"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                                setLoadingRefund(false);
                                            }, 2000);
                                        });
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        setSnackValues({
                            type: "error",
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-non-effectue"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    });
            }
        } else if (
            getRefundType(
                new Date(reservation.dateCheckIn),
                reservation.announce?.freeRefundDay
            ) === ERefundType.PreStay &&
            reservation.status[reservation.status.length - 1].status ===
                EStatus.APPROVED
        ) {
            if (travellerPaymentMethod === EPaymentMethod.Paypal) {
                userReservService
                    .createBookingRefundCredit({
                        amount: refundTraveller.amount,
                        reservation: refundTraveller.reservation,
                        method: EPaymentMethodCategory.Paypal,
                        isCancellation: true,
                    })
                    .then((response) => {
                        if (response.success) {
                            if (hostPaymentMethod === EPaymentMethod.Paypal) {
                                userReservService
                                    .createBookingRefund(refundHost)
                                    .then((resp) => {
                                        if (
                                            resp.success &&
                                            resp.data.statusCode === 201
                                        ) {
                                            saveTransactionHost(
                                                resp.data.result,
                                                refundHost.amount.value,
                                                true,
                                                refundHost.amount.currency,
                                                reservation.announce?.devise
                                            );
                                            setSnackValues({
                                                ...snackValues,
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-effectue-avec-succes"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                            }, 2000);
                                        }
                                    })
                                    .catch((err) => {
                                        setSnackValues({
                                            type: "error",
                                            open: true,
                                            message: t(
                                                "snackbar.messages.remboursement-non-effectue"
                                            ),
                                        });
                                        setTimeout(() => {
                                            setRefreshReservationList(true);
                                            setLoadingRefund(false);
                                        }, 2000);
                                    });
                            } else {
                                userReservService
                                    .createBookingRefundDohoneHost(
                                        refundHostDohone
                                    )
                                    .then((resp) => {
                                        if (resp.success) {
                                            setSnackValues({
                                                ...snackValues,
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-effectue-avec-succes"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                                setLoadingRefund(false);
                                            }, 2000);
                                        }
                                    })
                                    .catch((err) => {
                                        setSnackValues({
                                            type: "error",
                                            open: true,
                                            message: t(
                                                "snackbar.messages.remboursement-non-effectue"
                                            ),
                                        });
                                        setTimeout(() => {
                                            setRefreshReservationList(true);
                                            setLoadingRefund(false);
                                        }, 2000);
                                    });
                            }
                        }
                    })
                    .catch((err) => {
                        setSnackValues({
                            type: "error",
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-non-effectue"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    });
            } else {
                userReservService
                    .createBookingRefundCredit({
                        amount: {
                            value: refundTravellerDohone.amount,
                            currency: refundTravellerDohone.devise,
                        },
                        reservation: refundTravellerDohone.reservation,
                        method: EPaymentMethodCategory.MobileMoney,
                        isCancellation: true,
                    })
                    .then((response) => {
                        if (response.success) {
                            if (
                                reservation.status[
                                    reservation.status.length - 1
                                ].status === EStatus.APPROVED
                            ) {
                                if (
                                    hostPaymentMethod === EPaymentMethod.Paypal
                                ) {
                                    userReservService
                                        .createBookingRefund(refundHost)
                                        .then((resp) => {
                                            if (
                                                resp.success &&
                                                resp.data.statusCode === 201
                                            ) {
                                                saveTransactionHost(
                                                    resp.data.result,
                                                    refundHost.amount.value,
                                                    true,
                                                    refundHost.amount.currency,
                                                    reservation.announce?.devise
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            setSnackValues({
                                                type: "error",
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-non-effectue"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                                setLoadingRefund(false);
                                            }, 2000);
                                        });
                                } else {
                                    userReservService
                                        .createBookingRefundDohoneHost(
                                            refundHostDohone
                                        )
                                        .then((resp) => {
                                            if (resp.success) {
                                                setSnackValues({
                                                    ...snackValues,
                                                    open: true,
                                                    message: t(
                                                        "snackbar.messages.remboursement-effectue-avec-succes"
                                                    ),
                                                });
                                                setTimeout(() => {
                                                    setRefreshReservationList(
                                                        true
                                                    );
                                                    setLoadingRefund(false);
                                                }, 2000);
                                            }
                                        })
                                        .catch((err) => {
                                            setSnackValues({
                                                type: "error",
                                                open: true,
                                                message: t(
                                                    "snackbar.messages.remboursement-non-effectue"
                                                ),
                                            });
                                            setTimeout(() => {
                                                setRefreshReservationList(true);
                                                setLoadingRefund(false);
                                            }, 2000);
                                        });
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        setSnackValues({
                            type: "error",
                            open: true,
                            message: t(
                                "snackbar.messages.remboursement-non-effectue"
                            ),
                        });
                        setTimeout(() => {
                            setRefreshReservationList(true);
                            setLoadingRefund(false);
                        }, 2000);
                    });
            }
        }
    };

    //Reset snackbar
    const handleCloseSnackbar = () => {
        setSnackValues({ ...snackValues, open: false });
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openRefundModal}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {t(
                        "reservation.label.information-d-annulation-et-de-resrvation"
                    )}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#000000" }}
                                >
                                    {t("confirmDialog.reservation-en-cours")} :
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("confirmDialog.date-de-debut")}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {dateToStringDDMMYYYY(
                                        new Date(reservation.dateCheckIn)
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("confirmDialog.date-de-fin")}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {dateToStringDDMMYYYY(
                                        new Date(reservation.dateCheckOut)
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("label.duree")}:
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {getDayNumber(
                                        reservation.dateCheckIn,
                                        reservation.dateCheckOut
                                    )}{" "}
                                    {t("label.nuitees")}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("label.tarif-applique")}:
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {getTarifLabel(tarif)}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("label.nb-voyageur")}:
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {`${
                                        reservation.travellerAdultTotal +
                                        reservation.travellerChildTotal
                                    } ${t("label.voyageurs")}`}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("label.montant-de")}:
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {totalBookingPrice} {currency.toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#000000" }}
                                >
                                    {t(
                                        "label.informations-sur-les-remboursements"
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("label.moyen-de-paiement")}:
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                {isPaypal ? (
                                    EPaymentMethod.Paypal
                                ) : (
                                    <span>Mobile money</span>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("confirmDialog.date-dannulation")}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {dateToStringDDMMYYYY(new Date())}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("confirmDialog.heure-dannulation")}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {new Date().getHours() +
                                        `:${
                                            new Date().getMinutes() < 10
                                                ? 0
                                                : ""
                                        }` +
                                        new Date().getMinutes()}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("reservation.label.frais-sejour-passe")}{" "}
                                    :
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {passedDaysFees} {currency.toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("reservation.label.frais-penalites")} :
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {`${penaltyDaysFees} ${currency.toUpperCase()}`}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t("reservation.label.frais-de-nettoyage")}{" "}
                                    :
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {getRefundType(
                                        new Date(reservation.dateCheckIn),
                                        reservation.announce?.freeRefundDay
                                    ) === ERefundType.PreStay
                                        ? cleaningFee
                                        : 0}{" "}
                                    {currency.toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={4}>
                                <Divider />
                            </Grid>
                            <Grid item md={2}></Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ color: "#000000" }}
                                >
                                    {t(
                                        "reservation.label.votre-remboursement-total"
                                    )}{" "}
                                    :
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1">
                                    {travellerRefundFinal}{" "}
                                    {currency.toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.secondarButton}
                    >
                        {t("label.annuler")}
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.primaryButton}
                        style={{ marginRight: 10 }}
                        onClick={handleOpenConfirmDialog}
                        disabled={loadingRefund}
                        endIcon={loadingRefund ? <Spin /> : null}
                    >
                        {t("label.confirmer-l-annulation")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogConfirm} maxWidth="sm">
                <DialogTitle>
                    {t("reservation.dialog.annulation.title")}
                </DialogTitle>
                <DialogContent>
                    {t("reservation.dialog.annulation.description")}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className={classes.secondarButton}
                        style={{ textTransform: "none" }}
                        onClick={handleCloseConfirmDialog}
                    >
                        {t("reservation.button.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        style={{ textTransform: "none" }}
                        onClick={handleRefund}
                    >
                        {t("reservation.button.valider")}
                    </Button>
                </DialogActions>
            </Dialog>

            <AfriqSnackbar {...snackValues} handleClose={handleCloseSnackbar} />
        </React.Fragment>
    );
}
