import React from "react";
import { LoadingMenuContext } from "../contexts/AppContext";

export const LoadingMenuProvider = (props: {children?: JSX.Element}) => {
    const [loadingMenu, setLoadingMenu] = React.useState(false);

    return (
        <LoadingMenuContext.Provider value={{loadingMenu, setLoadingMenu}}>
            {props.children}
        </LoadingMenuContext.Provider>
    )
};

export const useLoadingMenu = () => {
    return React.useContext(LoadingMenuContext);
};
