import {
    makeStyles,
    createStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from "@material-ui/core";
// import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Register } from "./Register";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import React from "react";

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            minWidth: 400,
            maxWidth: 1024,
        },
        title: {
            borderBottom: "1px solid #EBEBEB",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        close: {
            padding: 5,
            position: "absolute",
            right: 20,
        },
        content: {
            padding: "20px 90px",
            [theme.breakpoints.down("sm")]: {
                padding: "20px 60px"
            },
            [theme.breakpoints.down("xs")]: {
                padding: "20px 40px"
            },
        },
    })
);

export function RegisterDialog(props: {
    handleClose: () => void;
    open: boolean;
    handleOpenLoginDialog: () => void;
}) {
    React.useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    const classes = useStyles();
    const { t } = useTranslation();
    // const [, setContainerRef] = React.useState<HTMLElement | null>(
    //     null
    // );
    // const scrollbarRef = React.useRef<PerfectScrollbar | null>(null);

    return (
        <Dialog
            open={props.open}
            classes={{ paper: classes.paper }}
            onClose={props.handleClose}
        >
            <DialogTitle classes={{ root: classes.title }}>
                <IconButton
                    onClick={props.handleClose}
                    className={classes.close}
                >
                    <CloseIcon />
                </IconButton>
                {t("SignUp.title")}
            </DialogTitle>

            <DialogContent classes={{ root: classes.content }}>
                {/* <PerfectScrollbar
                    containerRef={setContainerRef}
                    ref={scrollbarRef}
                > */}
                    <Register
                        handleOpenLoginDialog={props.handleOpenLoginDialog}
                        handleClose={props.handleClose}
                    />
                {/* </PerfectScrollbar> */}
            </DialogContent>
        </Dialog>
    );
}
