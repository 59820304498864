import { grey, red } from '@material-ui/core/colors';
import { Stack, Avatar, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import { colors } from '../../../styles/defaultColors';
import React from 'react';
import { DateToStringRefToday } from '.';
import { getRoleLabel } from '../../../utils/App';


export const ChatItemCorespendant = (
    props: {
        adminId: string,
        avLetter: string,
        name: string,
        ufunction: string,
        lastMessageDate: Date,
        newMessage: number,
        avColor: string,
        onClick: () => void
    }
) => {
    const { avLetter, name, ufunction, lastMessageDate, newMessage, avColor, onClick } = props;
    return (
        <MenuItem onClick={onClick} sx={{ padding: 0, borderRadius: 2 }}>
            <Stack flex={1} direction="row" p={1} alignItems="center" justifyContent="space-between" spacing={1}>
                <Avatar sx={{ bgcolor: avColor, color: "#fff" }}>{avLetter[0] ?? 'U'}</Avatar>
                <Stack direction="column">
                    <Typography sx={{
                        color: colors.orangePng
                    }} component="div">
                        {name}
                    </Typography>
                    <Typography maxWidth="80px" sx={{
                        color: grey[500],
                        fontSize: 12,
                        fontWeight: 500,
                    }} component="div">
                        {getRoleLabel(ufunction)}
                    </Typography>
                </Stack>
                <Stack direction="column" spacing={1} flex={1} alignItems="flex-end">
                    <Typography sx={{
                        color: grey[500],
                        fontSize: 12,
                        fontWeight: 500,
                    }} component="div">
                        {DateToStringRefToday(lastMessageDate)}
                    </Typography>
                    <Box>
                        {newMessage > 0 && <Box sx={{
                            fontSize: 10,
                            display: "flex",
                        }} width="20px" height="20px"
                            alignItems="center" justifyContent="center"
                            borderRadius={99} bgcolor={red[600]} color={"#fff"} p={1}>
                            {newMessage}
                        </Box>}
                        {newMessage <= 0 && <CheckIcon sx={{ color: grey[600] }} />}
                    </Box>
                </Stack>
            </Stack>
        </MenuItem>
    )
}