import Api from "../../../../https/Api";

export interface IResetPasswordInput {
  email: string;
  password?: string;
  confirmPassword?: string;
  resetPasswordCode?: string;
}

export class ResetPasswordService {
  private api;
  constructor() {
    this.api = new Api();
  }

  async generatePasswordResetCode(user: Partial<IResetPasswordInput>) {
    return this.api.post("users/generate-reset-password-code", user);
  }

  async resetUserPassword(user: IResetPasswordInput) {
    return this.api.post("users/reset-user-password", user);
  }
}
