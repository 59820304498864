import i18n from "../../i18n/config";

export enum ELinks {
    FAQ = "FAQ",
    A_PROPOS_DE_NOUS = "A propos de nous",
    NOS_VALEURS = "Nos valeurs",
    NOUS_CONTACTER = "Nous contacter",
    GARANTIE_DE_PROTECTION_DES_VOYAGEURS = "Garantie de protection des voyageurs",
    CGU = "Condition générales d'utilisation",
    PDC = "Politiques de confidentialités",
    FACEBOOK = "Facebook",
    INSTAGRAM = "Instagram",
    TWITTER = "Twitter",
}

export enum ELinksURL {
    FAQ = "/footer/faq",
    A_PROPOS_DE_NOUS = "/footer/aboutUs",
    NOS_VALEURS = "/footer/ourValues",
    NOUS_CONTACTER = "/footer/contactUs",
    GARANTIE_DE_PROTECTION_DES_VOYAGEURS = "/footer/tpg",
    CGU = "/footer/gcu",
    PDC = "/footer/dpp",
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    TWITTER = "twitter",
}

export function getLinksLabel(name: string): string {
    if (name === ELinks.A_PROPOS_DE_NOUS) {
        return i18n.t("label.a-propos-de-nous");
    } else if (name === ELinks.NOS_VALEURS) {
        return i18n.t("label.nos-valeurs");
    } else if (name === ELinks.NOUS_CONTACTER) {
        return i18n.t("label.nous-contacter");
    } else if (name === ELinks.GARANTIE_DE_PROTECTION_DES_VOYAGEURS) {
        return i18n.t("label.garantie-de-protection-des-voyageurs");
    } else if (name === ELinks.CGU) {
        return i18n.t("label.gcu");
    } else if (name === ELinks.PDC) {
        return i18n.t("label.dpp");
    }

    return name;
}
