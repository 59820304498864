import 'date-fns';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Moment } from 'moment';

export default function AfriqDatePicker({selectedDate, setSelectedDate}: {selectedDate:Moment | null , setSelectedDate: (val:Moment | null)=>void}) {

  const handleDateChange = (dateVal: Moment | null) => {
    setSelectedDate(dateVal);
  };
  

  return (
      <MuiPickersUtilsProvider utils={MomentUtils} >
        <Grid container>
          {
              selectedDate &&
              <KeyboardDatePicker
                disableToolbar
                variant="dialog"
                format="DD/MM/YYYY"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          }
        </Grid>
      </MuiPickersUtilsProvider>
  );
}