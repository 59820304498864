
import * as React from 'react';
import {useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Grid, Slide, Stack, Tooltip, Zoom} from "@mui/material";
import {CurrencyItem} from "./CurrencyItem";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {CurrencyContext} from "../../contexts/AppContext";
import {CurrencyService} from "../Admin/MyProfilAdmin/SettingCurrency/services/CurrencyService";
import {useTranslation} from "react-i18next"

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle
            sx={{
                m: 0,
                p: 2,
                marginLeft: '20px',
                fontFamily: 'Josefin Sans',
                fontSize: 24,
                fontWeight: 700,
                color: "#333"
            }} {...other}
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const currencyService = new CurrencyService();

export function CurrencySelection() {
    const [open, setOpen] = React.useState(false);

    const currencyContext = useContext(CurrencyContext);
    const {currency, setCurrency} = currencyContext;
    const [currencies, setCurrencies] = React.useState<any>([]);
    const [, setSelectedUnit] = React.useState<string>(currency?.toLowerCase());
    const theme = useTheme();
    const bp = useMediaQuery(theme.breakpoints.down(1025));
    const [, setLoading] = React.useState(false);

    React.useEffect(() => {
        currencyService
            .getAllCurrency()
            .then((response) => {
                if (response.success) {
                    setCurrencies(response.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { t } = useTranslation();
    return (
        <Stack direction={bp ? 'row' : 'column'}>
            <Tooltip title={`${t('label.choisissez-votre-devise')}`} arrow TransitionComponent={Zoom}>
                <Button style={{color: bp ? 'rgb(137,6,8)' : 'rgb(137,6,8)'}} onClick={handleClickOpen} sx={{color: '#000'}}>
                    {currency}
                </Button>

            </Tooltip>
            <Dialog
                TransitionComponent={Slide}
                keepMounted
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                    {t('label.choisissez-votre-devise')}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Typography marginLeft='20px' fontFamily="Josefin Sans" fontSize={16} fontWeight={700}
                                color="#333">
                        {t('label.toutes-les-devises')}
                    </Typography>
                    <Grid container direction='row' flexWrap='wrap' justifyContent={"flex-start"}>
                        {currencies.map((item: any, index: any) => (
                            <CurrencyItem currencyP={item} key={index} setItem={setSelectedUnit} item={currency}
                                          setCurrency={setCurrency}
                                          handleClose={handleClose}/>
                        ))}
                    </Grid>

                </DialogContent>
            </Dialog>
        </Stack>
    )
}
