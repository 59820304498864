import React from "react";
import Slider from "react-slick";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { BookingCardPhotoSlide } from "./BookingCardPhotoSlide";
import { ImageLightBox } from "../../../components/ImageLightBox/ImageLightBox";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        slidePhoto: {
            // display: 'flex',
            // maxWidth: '296px',
            // height: '296px',
            // marginBottom: '50px'
        },
        previewContainer: {
            // borderBottom: '4px solid #890609',
            marginBottom: theme.spacing(1),
            background: "#5e5e5e",
        },
    })
);

export const BookingCardPhoto = (props: {
    photos: any;
    width: number;
    height: number;
}) => {
    const classes = styles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [openImageLightBox, setOpenImageLightbox] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState<number>(0);

    const imageList = props.photos.map((photo: any) => photo.url);

    const handleOpenImageLightBox = () => {
        setOpenImageLightbox(true);
    };

    const handleCloseImageLightBox = () => {
        setOpenImageLightbox(false);
    };

    return (
        <>
            <div className={classes.previewContainer}>
                <Slider {...settings}>
                    {props.photos.map((photo: any, index: any) => (
                        <BookingCardPhotoSlide
                            key={index}
                            filename={photo.filename}
                            fileURL={photo.url}
                            handleOpenImageLightBox={handleOpenImageLightBox}
                        />
                    ))}
                </Slider>
            </div>

            <Grid container>
                <Grid item>
                    <ImageLightBox
                        isOpen={openImageLightBox}
                        handleClose={handleCloseImageLightBox}
                        images={imageList}
                        photoIndex={photoIndex}
                        setPhotoIndex={setPhotoIndex}
                    />
                </Grid>
            </Grid>
        </>
    );
};
