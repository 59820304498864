import i18n from '../../i18n/config'

export enum ERights {
    MY_PROFILE = "My Profile",
    MY_ACTIVITY = "My Activity",
    GESTION_ANNONCES = "Gestion annonces",
    GESTION_RESERVATIONS = "Gestion reservations",
    GESTION_VERIFICATIONS = "Gestion verifications",
    GESTION_PAIEMENTS = "Gestion paiements",
    GESTION_COMMENTAIRES = "Gestion commentaires",
    CATALOG = "Catalogues",
    CATALOG_PROPERTIES = "Catalogue annonces",
    CATALOG_VEHICLES = "Catalogue vehicules",
    DEMANDE_HOTEL = "Demande Hotel",
    GESTION_PAYS = "Gestion Pays",
    GESTION_UTILISATEURS = "Gestion Utilisateurs",
    GESTION_DE_ROLES = "Gestions de Rôles",
    VENTES_CRM = "Ventes / CRM",
    WEB_ANALYTICS = "Web Analytics",
    CRM_DASHBOARD = "CRM Dashboard",
    FINANCES = "Finances",
    MARKETING = "Marketing",
    EQUIPMENTS= "Equipments",
    BLOG = "Blog",
    GUIDE = "Guide touristique",
    POPUP= "Popup image",
    SETTINGS = "Settings"
}

export function getRightsLabel(name: string):string{
    if(name===ERights.MY_PROFILE){
      return i18n.t('backoffice.menu.mon-profil')
    }else if(name===ERights.MY_ACTIVITY){
        return i18n.t('backoffice.menu.mes-activités') 
    }else if(name===ERights.GESTION_ANNONCES){
        return i18n.t('backoffice.submenu.gestion-des-annonces')
    }else if(name===ERights.GESTION_RESERVATIONS){
        return i18n.t('backoffice.submenu.gestion-des-reservations')
    }else if(name===ERights.GESTION_VERIFICATIONS){
        return i18n.t('backoffice.submenu.gestion-des-verifications')
    }else if(name===ERights.GESTION_PAIEMENTS){
        return i18n.t('backoffice.submenu.gestion-des-paiements')
    }else if(name===ERights.GESTION_COMMENTAIRES){
        return i18n.t('backoffice.submenu.gestion-des-commentaires')
    }else if(name===ERights.CATALOG){
        return i18n.t('backoffice.menu.catalogs')
    }else if(name===ERights.CATALOG_PROPERTIES){
        return i18n.t('backoffice.submenu.catalog-properties')
    }else if(name===ERights.CATALOG_VEHICLES){
        return i18n.t('backoffice.submenu.catalog-vehicles')
    }else if(name===ERights.DEMANDE_HOTEL){
        return i18n.t('label.demande-d-hotel')
    }else if(name===ERights.GESTION_PAYS){
        return i18n.t('backoffice.menu.gestion-des-pays')
    }else if(name===ERights.GESTION_UTILISATEURS){
        return i18n.t('backoffice.menu.gestion-des-utilisateurs')
    }else if(name===ERights.GESTION_DE_ROLES){
        return i18n.t('backoffice.menu.gestion-des-roles')
    }else if(name===ERights.VENTES_CRM){
        return i18n.t('backoffice.menu.gestion-des-ventes')
    }else if(name===ERights.WEB_ANALYTICS){
        return i18n.t('backoffice.menu.statistiques-web')
    }
  
    return name
  }