/* eslint-disable react/prop-types */
import { useRef, useState } from "react";
// import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { IoMdMore } from "react-icons/io";
// import { FiEdit } from "react-icons/fi";
import { BiShow, BiDetail, 
  // BiReceipt 
} from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function TableMoreMenu({ onEdit, onDelete, announceId, reservationId }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <IoMdMore width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          sx={{ color: "text.primary" }}
        >
          <ListItemIcon>
            <BiDetail width={24} height={24} />
          </ListItemIcon>
          <Link to={`/booking-details/${reservationId}`} 
            target="_blank"
          >
            <ListItemText
              primary={t('label.voir-la-reservation')}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </Link>
        </MenuItem>

        {/* <MenuItem
          onClick={() => {
            onDelete();
            setIsOpen(false);
          }}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <HiOutlineTrash width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        <MenuItem
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <BiShow width={24} height={24} />
          </ListItemIcon>
          <Link to={`/details-announce-${announceId}`} 
            target="_blank"
          >
            <ListItemText
              primary={t('label.voir-l-annonce')}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </Link>
        </MenuItem>

        {/* <MenuItem
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <BiReceipt width={24} height={24} />
          </ListItemIcon>
          <Link to={`/booking-receipt-${reservationId}`} 
            target="_blank"
          >
            <ListItemText
              primary={t('label.voir-la-facture')}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </Link>
        </MenuItem> */}
      </Menu>
    </>
  );
}
