import i18n from "../i18n/config";
import { EAnnounceStatus, ERoles, ETarifs } from "./enum";
import DOMPurify from "dompurify";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { EPaymentMethod } from "../utils/enum/PaymentMethod";
import { EAdsPlacement } from "./enum/AdsPlacement";
import { ERefundType } from "./enum/RefundType";
import { IAnnounce } from "./AnnounceInterfaces";
import { EPropertyTypeName } from "./enum/PropertyTypeName";

export const searchCode = (nameKey: string, myArray: any) => {
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].name.toLowerCase() === nameKey.toLowerCase()) {
            return myArray[i].currencyCode;
        }
    }
};
export const createMarkup = (html: string) => {
    return {
        __html: DOMPurify.sanitize(html),
    };
};

export const dateToStringDDMMYYYY = (date: Date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return dd + "/" + mm + "/" + yyyy;
};

export const formattedURL = (url: string): string => {
    return url.replace(/\/+/g, "/");
};

export const fileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function generateYears(from: number) {
    const currentYear = new Date().getFullYear();
    const yearArray = [];
    if (from > currentYear) {
        yearArray.push(currentYear);
    } else {
        for (let index = from; index <= currentYear; index++) {
            yearArray.push(index);
        }
    }
    return yearArray;
}

export function getDayNumber(start: string, end: string): number {
    var startDate = moment(start, "YYYY-MM-DD");
    var endDate = moment(end, "YYYY-MM-DD");

    return Math.round(moment.duration(endDate.diff(startDate)).asDays());
}

export function getTarif(
    start: string,
    end: string,
    announce: IAnnounce
): string {
    const days = getDayNumber(start, end);

    if (
        days >= 7 &&
        days < 14 &&
        announce.priceWeekly &&
        announce.priceWeekly > 0
    ) {
        return ETarifs.HEBDOMADAIRE;
    } else if (
        days >= 14 &&
        days < 30 &&
        announce.priceBiWeekly &&
        announce.priceBiWeekly > 0
    ) {
        return ETarifs.QUINZAINE;
    } else if (
        days >= 30 &&
        announce.priceMonthly &&
        announce.priceMonthly > 0
    ) {
        return ETarifs.MENSUEL;
    } else {
        return ETarifs.JOURNALIER;
    }
}

let s: number;
let DateProm: number;
let DateSimple: number;
export function getDayNumberProm(
    start: string,
    end: string,
    promStart: string,
    promFin: string,
    pricesimple: number,
    priceProm: number
): any {
    var startDate = new Date(start);
    var endDate = new Date(end);
    var promstartDate = new Date(promStart);
    var promendDate = new Date(promFin);
    var startDate1 = moment(startDate, "YYYY-MM-DD");
    var endDate1 = moment(endDate, "YYYY-MM-DD");
    var promstartDate1 = moment(promstartDate, "YYYY-MM-DD");
    var promendDate1 = moment(promendDate, "YYYY-MM-DD");
    if (startDate >= promstartDate && endDate <= promendDate) {
        DateProm = moment.duration(endDate1.diff(startDate1)).asDays();
        DateSimple = 0;
        s = priceProm * DateProm;
    } else if (
        startDate <= promstartDate &&
        promstartDate < endDate &&
        endDate <= promendDate
    ) {
        DateProm = Math.round(
            moment.duration(endDate1.diff(promstartDate1)).asDays() + 0.2
        );
        DateSimple = Math.round(
            moment.duration(promstartDate1.diff(startDate1)).asDays() - 0.2
        );
        s = pricesimple * DateSimple + priceProm * DateProm;
    } else if (
        startDate >= promstartDate &&
        startDate < promendDate &&
        endDate >= promendDate
    ) {
        DateSimple = Math.round(
            moment.duration(endDate1.diff(promendDate1)).asDays() - 0.2
        );
        DateProm = Math.round(
            moment.duration(promendDate1.diff(startDate1)).asDays() + 0.2
        );
        s = pricesimple * DateSimple + priceProm * DateProm;
    } else if (startDate <= promstartDate && endDate >= promendDate) {
        DateSimple = Math.round(
            moment.duration(endDate1.diff(promendDate1)).asDays() +
                moment.duration(promstartDate1.diff(startDate1)).asDays() -
                0.2
        );
        DateProm = Math.round(
            moment.duration(promendDate1.diff(promstartDate1)).asDays() + 0.2
        );
        s =
            pricesimple *
                moment.duration(endDate1.diff(promendDate1)).asDays() +
            pricesimple *
                moment.duration(promstartDate1.diff(startDate1)).asDays() +
            priceProm *
                moment.duration(promendDate1.diff(promstartDate1)).asDays();
    } else {
        DateSimple = Math.round(
            moment.duration(endDate1.diff(startDate1)).asDays()
        );
        DateProm = 0;
        s = pricesimple * DateSimple;
    }
    return { s: s, DateProm: DateProm, DateSimple: DateSimple };
}

export function getRoleLabel(name: string): string {
    if (name === ERoles.SUPER_ADMIN) {
        return i18n.t("SignUp.super-admin");
    } else if (name === ERoles.COUNTRY_ADMIN) {
        return "Country Admin";
    } else if (name === ERoles.ADMIN_SUPPORT) {
        return i18n.t("SignUp.admin-support");
    } else if (name === ERoles.HOTEL_ADMIN) {
        return i18n.t("SignUp.hotel-admin");
    } else if (name === ERoles.SALES_AGENT) {
        return i18n.t("SignUp.sales-agent");
    } else if (name === ERoles.GUEST) {
        return "Guest";
    } else if (name === ERoles.HOST) {
        return i18n.t("SignUp.host");
    } else if (name === ERoles.CLERK) {
        return i18n.t("SignUp.clerk");
    } else if (name === ERoles.TRAVELLER) {
        return i18n.t("SignUp.traveller");
    }
    return name;
}

export function getRefundType(
    bookingDate: Date,
    freeRefundDay: number,
    bookingLastDate?: Date
) {
    if (bookingLastDate) {
        let bookingEndDate = moment(bookingLastDate, "YYYY-MM-DD");
        if (moment.duration(bookingEndDate.diff(new Date())).asDays() < 0)
            return ERefundType.Impossible;
    }
    let bookingStartDate = moment(bookingDate, "YYYY-MM-DD");
    const countedDay = moment
        .duration(bookingStartDate.diff(new Date()))
        .asDays();
    if (countedDay < 0) {
        return ERefundType.PreStay;
    } else if (countedDay >= freeRefundDay) {
        return ERefundType.Free;
    } else {
        return ERefundType.Paid;
    }
}
export function getRefundTypeFact(
    bookingDate: Date,
    freeRefundDay: number,
    dateAnulation: Date,
    bookingLastDate?: Date
) {
    if (bookingLastDate) {
        let bookingEndDate = moment(bookingLastDate, "YYYY-MM-DD");
        if (moment.duration(bookingEndDate.diff(new Date())).asDays() < 0)
            return ERefundType.Impossible;
    }
    let bookingStartDate = moment(bookingDate, "YYYY-MM-DD");
    const countedDay = moment
        .duration(bookingStartDate.diff(new Date(dateAnulation)))
        .asDays();
    if (countedDay < 0) {
        return ERefundType.PreStay;
    } else if (countedDay >= freeRefundDay) {
        return ERefundType.Free;
    } else {
        return ERefundType.Paid;
    }
}

export function getPrestayPassedNumber(
    bookingDate: Date,
    freeRefundDay: number
): number {
    const state = getRefundType(bookingDate, freeRefundDay);
    if (state === ERefundType.PreStay) {
        const bookingStartDate = moment(bookingDate, "YYYY-MM-DD");
        const countedDay = moment
            .duration(bookingStartDate.diff(new Date()))
            .asDays();
        return Number(Math.abs(Math.round(countedDay)).toFixed(1));
    } else {
        return 0;
    }
}
export function getPrestayPassedNumberFact(
    bookingDate: Date,
    freeRefundDay: number,
    dateFact: Date
): number {
    // console.log("dateFact",dateFact)
    const state = getRefundType(bookingDate, freeRefundDay);
    if (state === ERefundType.PreStay) {
        const bookingStartDate = moment(bookingDate, "YYYY-MM-DD");
        const countedDay = moment
            .duration(bookingStartDate.diff(new Date(dateFact)))
            .asDays();
        return Number(Math.abs(Math.round(countedDay)).toFixed(1));
    } else {
        return 0;
    }
}

export function getAnnounceStatusLabel(name: string): string {
    if (name === EAnnounceStatus.Submitted) {
        return i18n.t("label.subtmitted");
    } else if (name === EAnnounceStatus.PendingUser) {
        return i18n.t("label.pending-sales-agent");
    } else if (name === EAnnounceStatus.UserAccept) {
        return i18n.t("label.task-acceped");
    } else if (name === EAnnounceStatus.UserRefuse) {
        return i18n.t("label.task-refused");
    } else if (name === EAnnounceStatus.VerificationStarted) {
        return i18n.t("label.verification-started");
    } else if (name === EAnnounceStatus.PendingValidation) {
        return i18n.t("label.pending-validation");
    } else if (name === EAnnounceStatus.Suspended) {
        return i18n.t("label.suspended");
    } else if (name === EAnnounceStatus.Published) {
        return i18n.t("label.published");
    } else if (name === EAnnounceStatus.Drafted) {
        return i18n.t("label.drafted");
    } else if (name === EAnnounceStatus.SuspendedByUser) {
        return i18n.t("label.suspendedByUser");
    }
    return name;
}

export function getPropertyTypeName(name: string): string {
    if (name === EPropertyTypeName.Room) {
        return i18n.t("label.Room");
    } else if (name === EPropertyTypeName.Apartment) {
        return i18n.t("label.Apartment");
    } else if (name === EPropertyTypeName.House) {
        return i18n.t("label.House");
    } else if (name === EPropertyTypeName.Suite) {
        return i18n.t("label.Suite");
    } else if (name === EPropertyTypeName.Hotel) {
        return i18n.t("label.Hotel");
    } else if (name === EPropertyTypeName.Vehicle) {
        return i18n.t("label.Vehicle");
    } else if (name === EPropertyTypeName.Experience) {
        return i18n.t("label.Experience");
    }
    return name;
}




export function getRefundTypeLabel(name: string) {
    if (name === ERefundType.Impossible) {
        return i18n.t("label.non-remboursable");
    } else if (name === ERefundType.Free) {
        return i18n.t("label.remboursable-gratuitement");
    } else if (name === ERefundType.Paid) {
        return i18n.t("label.remboursable-avec-penalites");
    } else if (name === ERefundType.PreStay) {
        return i18n.t("label.jours-restantes-remboursables");
    }

    return name;
}

export function getPlacementAdsLabel(name: string): string {
    if (name === EAdsPlacement.HomePage) {
        return i18n.t("label.page-d-accueuil");
    } else if (name === EAdsPlacement.SearchPage) {
        return i18n.t("label.page-de-recherche");
    }

    return name;
}

export function getYesOrNotLabel(name: string): string {
    if (name === "OUI") {
        return i18n.t("label.oui");
    } else if (name === "NON") {
        return i18n.t("label.non");
    }

    return name;
}

export function getHeaderLabel(name: string): string {
    if (name === "id") {
        return i18n.t("label.reference");
    } else if (name === "propertyConcerned") {
        return i18n.t("listTable.labels.proprieteConcernee");
    } else if (name === "publishDate") {
        return i18n.t("listTable.labels.publieeLe");
    } else if (name === "country") {
        return i18n.t("listTable.labels.pays");
    } else if (name === "city") {
        return i18n.t("listTable.labels.ville");
    } else if (name === "state") {
        return i18n.t("listTable.labels.statut");
    } else if (name === "assignedTo") {
        return i18n.t("listTable.labels.assigneeA");
    } else if (name === "ambassador") {
        return i18n.t("search.ambassador");
    } else if (name === "allowPublishing") {
        return i18n.t("listTable.labels.autoriser-la-publication"); 
    } else if (name === "seeDetail") {
        return i18n.t("listTable.labels.voir-en-detail");
    } else if (name === "property") {
        return i18n.t("label.propriete");
    } else if (name === "dateCheckIn") {
        return i18n.t("label.date-d-entree");
    } else if (name === "dateCheckOut") {
        return i18n.t("label.date-de-sortie");
    } else if (name === "updatedAt") {
        return i18n.t("label.date-de-modification");
    } else if (name === "travellerNumber") {
        return i18n.t("label.traveller-number");
    } else if (name === "address") {
        return i18n.t("listTable.labels.adresse");
    } else if (name === "duration") {
        return i18n.t("label.age-de-la-demande");
    } else if (name === "quartier") {
        return i18n.t("label.quartier");
    } else if (name === "receptionAccount") {
        return i18n.t("label.receptionAccount");
    } else if (name === "owner") {
        return i18n.t("label.proprietaire");
    } else if (name === "user") {
        return i18n.t("label.utilisateur");
    } else if (name === "announce") {
        return i18n.t("label.annonce");
    } else if (name === "transactionType") {
        return i18n.t("label.type-de-transaction");
    } else if (name === "amount") {
        return i18n.t("label.amount");
    } else if (name === "transactionDate") {
        return i18n.t("label.date-de-transaction");
    } else if (name === "bookingDate") {
        return i18n.t("label.date-de-reservation");
    } else if (name === "firstName") {
        return i18n.t("Profil.firstname");
    } else if (name === "lastName") {
        return i18n.t("Profil.lastname");
    } else if (name === "email") {
        return i18n.t("Profil.email");
    } else if (name === "phone") {
        return i18n.t("Profil.phone");
    } else if (name === "phoneUrgent") {
        return i18n.t("Profil.phoneUrgent");
    } else if (name === "announceId") {
        return i18n.t("label.reference-de-l-annonce");
    } else if (name === "idReservation") {
        return i18n.t("label.id-de-la-reservation");
    } else if (name === "transactionRef") {
        return i18n.t("label.reference-de-la-transaction");
    } else if (name === "penalisation") {
        return i18n.t("label.penalisation");
    } else if (name === "penalizedCredit") {
        return i18n.t("label.penalized-credit");
    } else if (name === "label") {
        return i18n.t("label.label");
    } else if (name === "demandDate") {
        return i18n.t("label.demand-date");
    } else if (name === "activate") {
        return i18n.t("label.verouillage");
    } else if (name === "pause") {
        return i18n.t("label.pause-paiement");
    } else if (name === "id") {
        return i18n.t("catalog.id");
    } else if (name === "AdsIdRef") {
    return i18n.t("catalog.AdsIdRef");
    } else if (name === "State") {
    return i18n.t("catalog.State");
    } else if (name === "PropertieName") {
    return i18n.t("catalog.PropertieName");
    } else if (name === "PropertieCity") {
    return i18n.t("catalog.PropertieCity");
    } else if (name === "publishDate") {
    return i18n.t("catalog.publishDate");
    } else if (name === "Quartier") {
    return i18n.t("catalog.Quartier");
    } else if (name === "PropertieTypeName") {
    return i18n.t("catalog.PropertieTypeName");
    } else if (name === "PropertieResName") {
    return i18n.t("catalog.PropertieResName");
    } else if (name === "NbrRooms") {    
        return i18n.t("catalog.NbrRooms");
    } else if (name === "NbrBathrooms") {
        return i18n.t("catalog.NbrBathrooms");
    } else if (name === "NbrBeds") {
        return i18n.t("catalog.NbrBeds");
    } else if (name === "TarifDeBase") {
        return i18n.t("catalog.TarifDeBase");
    } else if (name === "TarifHebdo") {
        return i18n.t("catalog.TarifHebdo");
    } else if (name === "TarifQuinzaine") {
        return i18n.t("catalog.TarifQuinzaine");
    } else if (name === "TarifMensuel") {
        return i18n.t("catalog.TarifMensuel");
    } else if (name === "prefmethod") {
        return i18n.t("catalog.prefmethod");
    } else if (name === "OrangeNbr") {
        return i18n.t("catalog.OrangeNbr");
    } else if (name === "MtnNbr") {
        return i18n.t("catalog.MtnNbr");
    } else if (name === "Paypal") {
        return i18n.t("catalog.Paypal");
    } else if (name === "CancPolicy") {
        return i18n.t("catalog.CancPolicy");
    } else if (name === "CleanFee") {
        return i18n.t("catalog.CleanFee");
    } else if (name === "SeuilGrat") {
        return i18n.t("catalog.SeuilGrat");
    } else if (name === "Penalty1") {
        return i18n.t("catalog.Penalty1");
    } else if (name === "Penalty2") {
        return i18n.t("catalog.Penalty2");
    } else if (name === "SeuilGratNR") {
        return i18n.t("catalog.SeuilGratNR");
    } else if (name === "PenaltyNR") {
        return i18n.t("catalog.PenaltyNR");
    } else if (name === "FocalPerson") {
        return i18n.t("catalog.FocalPerson");
    } else if (name === "FocalPhone") {
        return i18n.t("catalog.FocalPhone");
    } else if (name === "FocalDesc") {
        return i18n.t("catalog.FocalDesc");
    } else if (name === "Ambassador") {
        return i18n.t("catalog.Ambassador");
    } else if (name === "DateAmbassador") {
        return i18n.t("catalog.DateAmbassador");
    } else if (name === "Business") {
        return i18n.t("catalog.Business");
    } else if (name === "Luxury") {
        return i18n.t("catalog.Luxury");
    } else if (name === "Perle") {
        return i18n.t("catalog.Perle");
    } else if (name === "Standard") {
        return i18n.t("catalog.Standard");
    } else if (name === "Prestige") {
        return i18n.t("catalog.Prestige");
    } else if (name === "Premium") {
        return i18n.t("catalog.Premium");
    } else if (name === "OwnerFullName") {
        return i18n.t("catalog.OwnerFullName");
    } else if (name === "OwnerLastName") {
        return i18n.t("catalog.OwnerLastName");
    } else if (name === "OwnerEmail") {
        return i18n.t("catalog.OwnerEmail");
    } else if (name === "OwnerPhone") {
        return i18n.t("catalog.OwnerPhone");
    } else if (name === "OwnerCountry") {
        return i18n.t("catalog.OwnerCountry");
    } else if (name === "OwnerLangage") {
        return i18n.t("catalog.OwnerLangage");
    } else if (name === "OwnerRelation") {
        return i18n.t("catalog.OwnerRelation");
    } else if (name === "OwnerUgrPhone") {
        return i18n.t("catalog.OwnerUgrPhone");
    } else if (name === "OwnerUrgentRole") {
        return i18n.t("catalog.OwnerUrgentRole");
    } else if (name === "createdAt") {
        return i18n.t("catalog.createdAt");
    } else if (name === "updatedAt") {
        return i18n.t("catalog.updatedAt");
    } else if (name === "Piscine") {
        return i18n.t("catalog.Piscine");
    } else if (name === "Internet ") {
        return i18n.t("catalog.Internet ");
    } else if (name === "Générateur") {
        return i18n.t("catalog.Générateur");
    } else if (name === "Forage") {
        return i18n.t("catalog.Forage");
    } else if (name === "Proximité") {
        return i18n.t("catalog.Proximité");
    } else if (name === "VideoLink") {
        return i18n.t("catalog.VideoLink");
    } else if (name === "Geolocalisation") {
        return i18n.t("catalog.Geolocalisation");
    } else if (name === "Caution") {
        return i18n.t("catalog.Caution");
    } else if (name === "MaxGuests") {
        return i18n.t("catalog.MaxGuests");
    } else if (name === "Devise") {
        return i18n.t("catalog.Devise");
    }
    return name;
}

export function getDashboardListMenusForHost(): String[] {
    return [
        "services",
        "promotions",
        "boost",
        "reservations",
        "settings",
        "dashboard",
        "chat",
        "payments",
        "receptions",
        "profil",
        "notifications",
        "favoris",
        "credit",
    ];
}

export function getDashboardListMenusForTraveller(): String[] {
    return [
        "settings",
        "dashboard",
        "chat",
        "reservations",
        "payments",
        "receptions",
        "profil",
        "notifications",
        "favoris",
        "credit",
    ];
}

export function removeTags(str: string) {
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, "");
}

export const paymentMethodList: { label: string; value: string }[] = [
    {
        label: i18n.t("label.paypal"),
        value: EPaymentMethod.Paypal,
    },
    {
        label: "Orange Money Cameroun",
        value: EPaymentMethod.Orange,
    },
    {
        label: "MTN Mobile Money Cameroon",
        value: EPaymentMethod.MTN,
    } /* ,
    {
        label: "ExpressUnion",
        value: EPaymentMethod.ExpressUnion,
    }, */
];

export const paymentMethodMobileList: { label: string; value: string }[] = [
    {
        label: "Orange Money Cameroun",
        value: EPaymentMethod.Orange,
    },
    {
        label: "MTN Mobile Money Cameroon",
        value: EPaymentMethod.MTN,
    } /*,
    {
        label: "ExpressUnion",
        value: EPaymentMethod.ExpressUnion,
    },*/
];

export const getProviderIdentifier = (operator_name: string) => {
    switch (operator_name) {
        case "Orange":
            return "62402";
        case "MTN":
            return "62401";
        case "Nexttel":
            return "62404";
        case "ExpressUnion":
            return "EUMM";
        case "YUP":
            return "TAGPAY";
        default:
            break;
    }
};

export const adsPlacementList: { label: string; value: string }[] = [
    {
        label: i18n.t("label.page-d-accueuil"),
        value: EAdsPlacement.HomePage,
    },
    {
        label: i18n.t("label.page-de-recherche"),
        value: EAdsPlacement.SearchPage,
    },
];

export function generateMonthOfYear(local?: string) {
    const months = Array.from({ length: 12 }, (item, i) => {
        return new Date(0, i).toLocaleString(local ? local : "en-US", {
            month: "long",
        });
    });

    return months;
}
export function totalPayecredit(credit: number, reservation: number) {
    let rest: number = credit - reservation;
    // console.log("jereo ato hhhhh", credit, "-", reservation, "=", rest);
    if (credit > 0 && rest >= 0) {
        // console.log(1);
        return rest.toFixed(2);
    } else if (credit > 0 && rest < 0 && Math.abs(rest) > 100) {
        // console.log(2);
        return rest.toFixed(2); 
    } else return 0;
}

export const TestPasswordStrength = (value: string) => {
    let strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`()_/^':;!@#$%^&*.+\\={}[|\"<,>?])(?=.{8,})"
    );
    let mediumRegex = new RegExp(
        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    if (strongRegex.test(value)) {
        return "green";
    } else if (mediumRegex.test(value)) {
        return "orange";
    } else {
        return "red";
    }
};
export function SortArray(x: any, y: any) {
    if (x.name < y.name) {
        return -1;
    }
    if (x.name > y.name) {
        return 1;
    }
    return 0;
}

export const currencyAccepted = [
    "AUD",
    "BRL",
    "CAD",
    "CNY",
    "CZK",
    "DKK",
    "EUR",
    "HKD",
    "HUF",
    "ILS",
    "JPY",
    "MYR",
    "MXN",
    "TWD",
    "NZD",
    "NOK",
    "PHP",
    "PLN",
    "GBP",
    "RUB",
    "SGD",
    "SEK",
    "CHF",
    "THB",
    "USD",
];

export const isHtml = (content: string): boolean => {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlRegex.test(content);
};
