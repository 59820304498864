import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles=makeStyles((theme:Theme)=>createStyles({
    inpuText: {
        marginBottom: 14,
        marginTop: 6
    },
    inputLabel: {
        fontSize: 14,
        fontFamily: "Josefin Sans",
        color: "#000000",
    },
}))

export interface ISelectProps{
    id: string;
    name: string;
    price:number
}

export const AfriqAnnouncesSelectList = ({handleSelectAnnounce, listAnnounces, selectedAnnounce}:{handleSelectAnnounce: (v: ISelectProps)=>void, listAnnounces:ISelectProps[], selectedAnnounce: ISelectProps}) => {
    const classes = useStyles()
    const handleChange=(target:any, value:any)=>{
        handleSelectAnnounce(value as ISelectProps);
    }
    
    const { t } = useTranslation();

    return (
        <div>
            <Grid item lg={12} sm={12} xs={12}>
                <label
                    htmlFor="announceList"
                    className={classes.inputLabel}
                >
                    {t("label.selectionner-une-annonce")}
                </label>
            </Grid>
            <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                className={classes.inpuText}
            >
                <Autocomplete
                    id="selectAnnounceList"
                    options={listAnnounces}
                    getOptionLabel={(option) => option !== null ? option.name : ""}
                    onChange={handleChange}
                    value={selectedAnnounce}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                />
                {/* <Select
                    id="announceList"
                    name="property"
                    variant="outlined"
                    fullWidth
                    value={announce.property}
                    onChange={onChangeProperty}
                >
                    {propertyList.map(
                        (
                            property: IProperty,
                            index: number
                        ) => (
                            <MenuItem
                                value={property._id}
                                key={index}
                            >
                                {property.name}
                            </MenuItem>
                        )
                    )}
                </Select> */}
            </Grid>
        </div>
    )
}
