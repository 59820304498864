import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ImageLightBox = (props: {
    images: string[];
    isOpen: boolean;
    photoIndex: number;
    setPhotoIndex: React.Dispatch<React.SetStateAction<number>>;
    handleClose: () => void;
}) => {
    // const [photoIndex, setPhotoIndex] = React.useState<number>(0);
    return (
        <div>
            {props.isOpen && (
                <Lightbox
                    mainSrc={props.images[props.photoIndex]}
                    nextSrc={
                        props.images[
                            (props.photoIndex + 1) % props.images.length
                        ]
                    }
                    prevSrc={
                        props.images[
                            (props.photoIndex - 1) % props.images.length
                        ]
                    }
                    onCloseRequest={props.handleClose}
                    onMovePrevRequest={() => {
                        return props.setPhotoIndex(
                            (props.photoIndex + props.images.length - 1) %
                                props.images.length
                        );
                    }}
                    onMoveNextRequest={() => {
                        return props.setPhotoIndex(
                            (props.photoIndex + props.images.length + 1) %
                                props.images.length
                        );
                    }}
                />
            )}
        </div>
    );
};
