export enum Messages {
  UNAUTHORIZED = "Unauthorized",
  EMAIL_ALREADY_USED = "Email already used",
  USER_ADDED = "User added",
  INCOMPLETE_DATA_INPUT = "Error : Incomplete data input",
  USER_NOT_FOUND = "User not found",
  USER_UPDATED_SUCCESSFULLY = "User updated successfully",
  USER_DELETED_SUCCESSFULLY = "User deleted successfully",
  PASSWORD_NOT_MATCH = "Password not match",
  RESET_PASSWORD_CODE_REQUIRED = "Reset password code required",
  RESET_PASSWORD_CODE_NOT_FOUND = "Reset password code not found",
  RESET_PASSWORD_CODE_SENT = "Reset password code sent ",
  USER_PASSWORD_UPDATED = "User password successfully updated ",
  TOKEN_NOT_VALID = "Token not valid",
  TOKEN_NOT_EXPIRED = "Token not expired",
  WRONG_PASSWORD = "Wrong password",
  EMAIL_OR_PASSWORD_CANNOT_BE_NULL = "Email or password cannot be null",
  TOKEN_CANNOT_BE_NULL = "Token cannot be null",
  PASSWORD_AND_CONFIRM_PASSWORD_CANNOT_BE_NULL = "Password and confirm password cannot be null",
  AVATAR_NOT_UPLOADED = "Error, avatar not uploaded",
  OLD_AVATAR_PHOTO_COULDNT_BE_REMOVED = "Old Avatar photo counldn't be removed",
  NETWORK_ERROR = "Network error, please try later",
}
