import {
    makeStyles,
    createStyles,
    TextField,
    InputAdornment,
    Typography,
    IconButton,
} from "@material-ui/core";
import React, { useContext } from "react";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import Button from "@material-ui/core/Button";
import SessionHandler from "../../../https/SessionHandler";
import { LoginService, IUserAuth } from "./services/LoginService";
import { useHistory } from "react-router-dom";
import Spin from "../../../images/Spin";
import { Error, Visibility, VisibilityOff } from "@material-ui/icons";
import { colors } from "../../../styles/defaultColors";
import {
    getErrorLabel,
    UserErrorMessage,
    UserErrorMessageFromServer,
} from "../../../utils/UserInterfaces";
import { GeneratePasswordCodeDialog } from "../ResetPassword/GeneratePasswordCodeDialog";
import { setTimeout } from "timers";
import { UserContext } from "../../../contexts/AppContext";
import { useTranslation } from "react-i18next";
import { ERoles } from "../../../utils/enum";
import { RouteAdmin } from "../../Admin/constant/routesName";
import i18n from "../../../i18n/config";
import { useQuery } from "../../../utils/App";

const useStyles = makeStyles((theme) =>
    createStyles({
        formLoginContainer: {
            height: "auto",
            marginTop: 20,
        },
        inputForm: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
        forgotPassword: {
            textAlign: "right",
            textDecoration: "none",
            marginBottom: theme.spacing(2),
        },
        register: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "40px auto 20px auto",
        },
        registerLink: {
            color: "#EA7606",
        },
        link: {
            textAlign: "right",
            textDecoration: "none",
            color: "inherit",
            fontWeight: 600,
        },
        error: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        buttonLink: {
            textAlign: "right",
            textDecoration: "none",
            color: "#7D7D57",
            textTransform: "none",
            fontFamily: "Josefin Sans",
            fontSize: 14,
        },
        buttons: {
            marginBottom: theme.spacing(2),
        },
        loginButton: {
            width: "100%",
            background: "#890608",
            color: "white",
            borderRadius: 100,
            fontFamily: "Josefin Sans",
            fontSize: 14,
            textTransform: "none",
            padding: "8px 14px",
            "&:hover": {
                background: "#B5551B",
            },
        },
        textField: {
            fontFamily: "Josefin Sans",
            fontSize: 14,
            color: "#000000",
        },
        buttonLinkSing: {
            color: "#EA7606",
            fontFamily: "Josefin Sans",
            fontSize: 16,
            textTransform: "none",
        },
        notMember: {
            fontFamily: "Josefin Sans",
            fontSize: 16,
        },
        icon: {
            position: "relative",
            top: -4,
        },
        divider: {
            width: 140,
            height: 1,
            background: "#EBEBEB",
            margin: "40px auto",
        },
        "@global": {
            ".MuiInputBase-input": {
                fontFamily: "Josefin Sans",
                fontSize: 14,
                color: "#000000",
            },
            ".MuiInputBase-input::placeholder": {
                opacity: 0.7,
            },
        },
    })
);

const loginService = new LoginService();
export function LoginForm(props: {
    handleClose: () => void;
    handleClickOpenRegister: () => void;
}) {
    let query = useQuery();
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = React.useState({
        email: "",
        password: "",
        lang: "FR",
        showPassword: false,
        remember_me: false,
    });
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const userContext = useContext(UserContext);
    const { setUser } = userContext;

    const [openResetDialog, setOpenResetDialog] = React.useState(false);

    const [handlePassword, setHandlePassword] = React.useState({
        password: "",
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setHandlePassword({
            ...handlePassword,
            showPassword: !handlePassword.showPassword,
        });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleOpenResetDialog = () => {
        setOpenResetDialog(true);
    };

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handlOpenRegister = () => {
        props.handleClickOpenRegister();
    };

    const handleChange = (prop: any) => (event: any) => {
        setError("");
        setValues({
            ...values,
            [prop]:
                prop === "password"
                    ? event.target.value
                    : event.target.value.toLowerCase(),
        });
    };

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        setError("");

        if (!values.email) {
            setError("EMAIL");
            return;
        }

        if (!values.password) {
            setError("PWD");
            return;
        }

        setLoading(true);
        const userAuth: IUserAuth = {
            email: values.email,
            password: values.password,
        };
        loginService
            .authentificate(userAuth)
            .then((response: any) => {
                if (response.success) {
                    const { user } = response;
                    sessionHandler.saveAccessToken(user.token);
                    sessionHandler.saveRefreshToken(user.refreshToken);
                    setUser(user.data);

                    if (user.data.lang) {
                        i18n.changeLanguage(user.data.lang.toLowerCase());
                    }
                    if (
                        user.data.role.name === ERoles.SUPER_ADMIN ||
                        user.data.role.name === ERoles.ADMIN_SUPPORT ||
                        user.data.role.name === ERoles.SALES_AGENT ||
                        user.data.role.name === ERoles.COUNTRY_ADMIN
                    ) {
                        history.push(RouteAdmin.ACTIVITY_ANNOUNCE);
                    } else {
                        if (query.get("redirectPage")) {
                            history.push(`/${query.get("redirectPage")}`);
                        } 
                    }
                    props.handleClose();
                } else {
                    setError("ACCOUNT");
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((err: any) => {
                if (err.error === UserErrorMessageFromServer.NotFound) {
                    setError("ACCOUNT");
                } else if (
                    err.error === UserErrorMessageFromServer.WrongPassword
                ) {
                    setError("ACCOUNT");
                } else if (
                    err.error === UserErrorMessageFromServer.UnConfirmedAccount
                ) {
                    setError("UNCONFIRM ACCOUNT");
                } else {
                    setError("ACCOUNT");
                }
                setLoading(false);
            });
    };

    return (
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className={classes.formLoginContainer}
        >
            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="input-email-with-icon-textfield"
                    type="email"
                    fullWidth
                    variant="outlined"
                    label="Email"
                    onChange={handleChange("email")}
                    classes={{ root: classes.textField }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="input-password-with-icon-textfield"
                    type={handlePassword.showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    value={values.password}
                    onChange={handleChange("password")}
                    label={t("SignIn.password")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKeyOutlinedIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {handlePassword.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            {error && (
                <div className={classes.error}>
                    <Typography>
                        <Error />
                        <br />
                        {error === "EMAIL" &&
                            getErrorLabel(UserErrorMessage.EmptyEmail)}{" "}
                        {error === "EMAIL WRONG" &&
                            getErrorLabel(UserErrorMessage.WrongPassword)}{" "}
                        {error === "PWD" &&
                            getErrorLabel(UserErrorMessage.EmptyPassword)}
                        {error === "PWD WRONG" &&
                            getErrorLabel(UserErrorMessage.WrongPassword)}
                        {error === "ACCOUNT" &&
                            getErrorLabel(UserErrorMessage.NotFound)}{" "}
                        {error === "UNCONFIRM ACCOUNT" &&
                            getErrorLabel(
                                UserErrorMessage.UnConfrimAccount
                            )}{" "}
                    </Typography>
                </div>
            )}

            <div className={classes.buttons}>
                <div className={classes.forgotPassword}>
                    <Button
                        className={classes.buttonLink}
                        onClick={handleOpenResetDialog}
                    >
                        {t("SignIn.passforgot")}
                    </Button>
                    <GeneratePasswordCodeDialog
                        open={openResetDialog}
                        handleClose={handleCloseResetDialog}
                    />
                </div>
                <Button
                    type="submit"
                    endIcon={loading ? <Spin /> : null}
                    disabled={loading}
                    className={classes.loginButton}
                >
                    {t("SignIn.button")}
                </Button>
            </div>

            <div className={classes.divider}></div>

            <div className={classes.register}>
                <Typography className={classes.notMember}>
                    {t("SignIn.notmember")}
                </Typography>
                <div className={classes.registerLink}>
                    <Button
                        className={classes.buttonLinkSing}
                        onClick={handlOpenRegister}
                    >
                        {t("SignIn.signup")}
                    </Button>
                </div>
            </div>
        </form>
    );
}
